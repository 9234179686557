import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import svTranslations from './locales/sv/translation.json';
import daTranslations from './locales/da/translation.json';
import fiTranslations from './locales/fi/translation.json';
import enTranslations from './locales/fi/translation.json';
import nbTranslations from './locales/nb/translation.json';

import { country, locale } from 'environment';
import { isDate, format as formatDate } from 'date-fns';
import { pluralizerPostProcessor } from './pluralizerPostProcessor';

const resources = {
  SE: { translation: svTranslations },
  DK: { translation: daTranslations },
  FI: { translation: fiTranslations },
  EN: { translation: enTranslations },
  NO: { translation: nbTranslations },
} as const;

i18n
  .use(initReactI18next)
  .use(pluralizerPostProcessor)
  .init({
    interpolation: {
      format: (value, format) => {
        if (isDate(value) && format) {
          return formatDate(value, format, { locale });
        }
        if (format === 'currency') {
          if (country === 'SE' || country === 'DK' || country === 'NO') {
            return `${value} kr`;
          } else if (country === 'FI') {
            return `${value} EUR`;
          }
        }

        return value;
      },
      escape: (str) => str,
    },
    resources,
    lng: country,
  });
