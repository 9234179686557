import { StyledSubSection, StyledSubSectionContainer } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { LoggedinDevice, logoutDevice } from 'services/deviceService';
import { OtherDevice } from './OtherDevice';
import { useState } from 'react';
import { AnimatePresence, Variants } from 'framer-motion';
import { byLastSeenDescending } from './OtherDevices.utils';
import { useSnackbars } from 'hooks';
import { trackLogoutOtherDevice } from 'tracking';
import { StyledLogoutButton } from './OtherDevices.styles';

type OtherDevicesProps = {
  devices: LoggedinDevice[];
  onLogout?: (deviceId: string) => void;
};

const animations: Variants = {
  hidden: { opacity: 0.0, transition: { duration: 0.3 } },
  visible: { opacity: 1.0 },
};

export const OtherDevices = ({ devices, onLogout }: OtherDevicesProps) => {
  const [loggingOutDeviceIds, setLoggingOutDeviceIds] = useState<string[]>([]);
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();

  const { t } = useTranslation();

  const onLogoutClicked = async (deviceId: string) => {
    setLoggingOutDeviceIds((prev) => [...prev, deviceId]);
    try {
      trackLogoutOtherDevice(deviceId);
      await logoutDevice(deviceId);
      createPositiveSnackbar(t('NAV_DEVICES_LOGOUT_DEVICE_SUCCESS_MESSAGE'));
      setLoggingOutDeviceIds([]);
      onLogout?.(deviceId);
    } catch (ex) {
      createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
    }
  };

  return (
    <StyledSubSectionContainer>
      <AnimatePresence>
        {devices.sort(byLastSeenDescending).map((device) => {
          return (
            <StyledSubSection
              key={device.deviceId}
              variants={animations}
              initial="visible"
              exit="hidden"
            >
              <OtherDevice device={device} />
              <StyledLogoutButton
                variant="secondary"
                onClick={() => {
                  onLogoutClicked(device.deviceId);
                }}
                disabled={loggingOutDeviceIds.includes(device.deviceId)}
                isLoading={loggingOutDeviceIds.includes(device.deviceId)}
              >
                {t('NAV_DEVICES_DEVICE_LOG_OUT')}
              </StyledLogoutButton>
            </StyledSubSection>
          );
        })}
      </AnimatePresence>
    </StyledSubSectionContainer>
  );
};
