const UPPER_CASE = /[A-Z]/;
const LOWER_CASE = /[a-z]/;
const NUMBER = /[0-9]/;
const SPECIAL_CHARACTER = /[!@#$%&/=?_.,:;-]/;

const CHARACTER_RULES = [UPPER_CASE, LOWER_CASE, NUMBER, SPECIAL_CHARACTER];

// The validation rules are as follows:
// 1. At least 8 chars
// 2. has three of the following rules
//   i. at least one A-Z character
//   ii. at least one a-z character
//   iii. at least one special character (!@#$%&/=?_.,:;-)
//   iv. at least one number

export const validatePassword = (password: string): boolean =>
  password.length >= 8 &&
  CHARACTER_RULES.reduce((response, rule) => response + (rule.test(password) ? 1 : 0), 0) >= 3;
