import { useState, createRef, useCallback, useEffect } from 'react';
import {
  StyledChevron,
  StyledDropDown,
  StyledDropDownContainer,
  StyledDropDownList,
  StyledDropDownListItem,
} from './SettingsDropDown.styles';
import { Variants } from 'framer-motion';
import { useClickOutside } from '@telia-company/tv.oneapp-web-ui';

const MAX_ITEMS_BEFORE_SCROLL = 6;

const animations: Variants = {
  open: {
    display: 'inherit',
    opacity: 1,
    transition: { duration: 0.3 },
  },
  closing: {
    display: 'inherit',
    opacity: 0,
    transition: { duration: 0.3 },
  },
  closed: {
    opacity: 0,
    display: 'none',
  },
} as const;

export type DropDownItem = {
  id: string;
  label: string;
};

type SettingsDropDownProps = {
  items: DropDownItem[];
  selectedItem?: DropDownItem;
  label: string;
  onSelect: (item: DropDownItem) => void;
};

export const SettingsDropDown = ({
  items,
  selectedItem,
  label,
  onSelect,
}: SettingsDropDownProps) => {
  const [open, setOpen] = useState(false);
  const containerRef = createRef<HTMLDivElement>();

  const [variant, setVariant] = useState('closed');

  const [itemsWhileOpen, setItemsWhileOpen] = useState(items);

  const onClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  useClickOutside(containerRef, onClickOutside);

  useEffect(() => {
    // Refresh the items when opening the dropdown
    // This is because if we change the items during the closing it will look
    // weird
    if (open) {
      setItemsWhileOpen(items);
    }
  }, [open, items, containerRef]);

  useEffect(() => {
    setVariant(open ? 'open' : 'closing');
  }, [open]);

  // We want to scroll the list back to top when closed, and have no good
  // way to do it in a variant, so I had to create a three state animation
  // where I listen to when the "closing" is done, and then scroll to the top
  // before actually setting the final "closed"
  const onAnimationComplete = useCallback(
    (animatedVariant: string) => {
      if (animatedVariant === 'closing') {
        // Having ref on the dropdown doesn't seem to work - don't know if it's
        // because it's a framer motion component that makes it difficult :/
        containerRef.current?.querySelector('.dropdown')?.scroll({ top: 0 });
        setVariant('closed');
      }
    },
    [containerRef],
  );

  return (
    <StyledDropDownContainer ref={containerRef}>
      <StyledDropDown
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        {label}
        <StyledChevron open={open} />
        <StyledDropDownList
          className="dropdown" /* for query purposes */
          scrollable={items.length >= MAX_ITEMS_BEFORE_SCROLL}
          animate={variant}
          initial="closed"
          variants={animations}
          onAnimationComplete={onAnimationComplete}
        >
          {itemsWhileOpen.map((item, i) => (
            <StyledDropDownListItem
              className={item.id === selectedItem?.id ? 'selected' : ''}
              selected={item.id === selectedItem?.id}
              onClick={() => onSelect(item)}
              key={i}
            >
              {item.label}
            </StyledDropDownListItem>
          ))}
        </StyledDropDownList>
      </StyledDropDown>
    </StyledDropDownContainer>
  );
};
