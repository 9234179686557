import { useFetchFunction } from 'hooks/useFetchFunction';
import { getRentalPinCodeState } from 'services/userService';

export const useGetRentalPinCodeState = () => {
  const {
    loading,
    error,
    data: rentalPinCodeState,
    refresh: refreshRentalPinCodeState,
  } = useFetchFunction(getRentalPinCodeState);
  return { loading, error, rentalPinCodeState, refreshRentalPinCodeState };
};
