import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledLogoContainer = styled.div`
  width: 16rem;
  & svg {
    height: 24px;

    ${mq.L`
    height: 32px;
  `}
  }
`;

export const IconWrapper = styled.div`
  display: inline-flex;
`;

export const StyledTeliaLogoContainer = styled.div`
  display: flex;
`;
export const StyledLogoLink = styled.a`
  display: flex;
`;
