import { Country, Environment } from 'environment';
import { shouldUseEnvironmentsProviderWithCookies } from 'providers/utils';
import { COOKIE_HANDLER, COOKIE_NAMES } from './cookieHandler.utils';
import { isDev } from 'utils';

export const getEnvironment = () => {
  if (shouldUseEnvironmentsProviderWithCookies()) {
    return getEnvironmentFromCookieOrEnv();
  }
  return process.env.REACT_APP_ENVIRONMENT as Environment;
};

export const getCountry = () => {
  if (shouldUseEnvironmentsProviderWithCookies()) {
    return getCountryFromCookieOrEnv();
  }
  return process.env.REACT_APP_COUNTRY as Country;
};

export const getEnvironmentFromCookieOrEnv = (): Environment => {
  if (!isDev()) return process.env.REACT_APP_ENVIRONMENT as Environment;

  const environmentFromCookie = COOKIE_HANDLER.getCookie(COOKIE_NAMES.ENVIRONMENT);
  if (environmentFromCookie) {
    return environmentFromCookie as Environment;
  }
  return process.env.REACT_APP_ENVIRONMENT as Environment;
};

export const getCountryFromCookieOrEnv = (): Country => {
  if (!isDev()) return process.env.REACT_APP_COUNTRY as Country;

  const countryFromCookie = COOKIE_HANDLER.getCookie(COOKIE_NAMES.COUNTRY);
  if (countryFromCookie) {
    return countryFromCookie as Country;
  }
  return process.env.REACT_APP_COUNTRY as Country;
};

// @todo: might not be needed, then remove
export const initializeCookiesFromEnv = () => {
  // early return when not using this
  if (!shouldUseEnvironmentsProviderWithCookies()) return;

  const environmentFromCookieOrEnv = getEnvironmentFromCookieOrEnv();
  const countryFromCookieOrEnv = getCountryFromCookieOrEnv();

  COOKIE_HANDLER.setCookie(COOKIE_NAMES.ENVIRONMENT, environmentFromCookieOrEnv);
  COOKIE_HANDLER.setCookie(COOKIE_NAMES.COUNTRY, countryFromCookieOrEnv);
};
