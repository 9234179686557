import { ClientInformationIF } from '@telia-company/tv.common-sdk/dist/abstractions/client/ClientInformationIF';
import { DataManager } from '@telia-company/tv.common-sdk/dist/DataManager';
import { RequestContentNotFoundError } from '@telia-company/tv.common-sdk/dist/ErrorTypes';
import { Log } from '@telia-company/tv.common-sdk/dist/Log';
import { ServiceConfig } from '@telia-company/tv.common-sdk/dist/ServiceConfig';
import { country } from 'environment';

export type MessageBar = {
  id: string;
  content: string;
  severity: 'ALERT' | 'INFO';
  url?: string;
  filterPageIds?: string[];
};

type GetMessagesProps = {
  cacheClass: string;
};

export const getMessageBars = async ({ cacheClass }: GetMessagesProps): Promise<MessageBar[]> => {
  const logger = Log.instance()('messagebar.getMessageBars');
  try {
    const clientInformation = await ClientInformationIF.resolve().getClientInformation();
    const dataManager = DataManager.instance();
    const messageBarService = ServiceConfig.getService('messagebar');
    const url = messageBarService.getUrl(
      `/${country.toLowerCase()}/${clientInformation.client.toLowerCase()}/messagebar.json`,
    );
    const response = await dataManager.sendGet(url, cacheClass);
    return response as unknown as MessageBar[];
  } catch (error: unknown) {
    // Don't need to log if we get a 404
    if (!(error instanceof RequestContentNotFoundError)) {
      logger.error('Failed to fetch message bars', error);
    }
  }
  return [];
};
