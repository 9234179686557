import styled from '@emotion/styled';
import { mq, Icon, Button } from '@telia-company/tv.oneapp-web-ui';
import { headingTertiary, textPrimary } from 'theme/theme.utils';

export const StyledContainer = styled.div`
  padding: 0 16px;
  text-alignment: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
  max-width: 390px;
  margin-top: 32px;

  ${mq.S`
    margin-top: 64px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const StyledCheckmarkIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 6rem;
  height: 6rem;

  background-color: ${({ theme }) => theme.designTokens.color.button.secondaryDefault};
  border-radius: 4rem;
  box-sizing: border-box;
`;

export const StyledIcon = styled(Icon)`
  height: 3.125rem;
  width: 3.125rem;
`;

export const StyledHeader = styled.h1`
  margin-top: 24px;
  margin-bottom: 0px;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  ${({ theme }) => headingTertiary(theme)}

  ${mq.S`
  text-align:center;
  `}
`;

export const StyledInformation = styled.p`
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  ${({ theme }) => textPrimary(theme)}
  text-align: left;
  margin-top: 16px;
  text-align: left;
  ${mq.S`
    text-align:center;
  `}
`;

export const StyledButton = styled(Button)`
  margin-top: 40px;
  width: 100%;
`;
