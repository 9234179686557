import { css } from '@emotion/react';
// For some reason, we need to import the /macro, because it doesn't work in create-react-app
import styled from '@emotion/styled/macro';
import { m } from 'framer-motion';
import {
  Section,
  StyledSubSectionContainer,
  StyledSubSectionContent,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { textSecondary } from 'theme/theme.utils';

export const StyledPurchaseHistoryItem = styled.a`
  background: ${({ theme }) => theme.designTokens.color.background.tertiary};
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 0;
  transition: border-radius 0.2 ease-in-out;
`;
export const StyledPurchaseHistoryItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledPurchaseHistoryItemLabel = styled(StyledSubSectionLabel)`
  padding-right: 1rem;
`;

export const StyledPurchaseHistoryItemPrice = styled(StyledSubSectionContent)`
  white-space: nowrap;
  align-self: flex-start;
`;

export const StyledPurchaseHistoryItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledPurchaseHistoryItemInfo = styled(StyledSubSectionContent)`
  margin-bottom: 0;
`;

export const StyledShowMoreButton = styled.button`
  all: unset;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  place-self: end;
  ${({ theme }) => textSecondary(theme)}
`;

type StyledPurchaseListProps = {
  collapsed: boolean;
};

const BottomRadius = css`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const TopRadius = css`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const StyledPurchaseList = styled(StyledSubSectionContainer)<StyledPurchaseListProps>`
  & > ${StyledPurchaseHistoryItem}:first-of-type {
    ${TopRadius}
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      & > ${StyledPurchaseHistoryItem}:last-of-type {
        ${BottomRadius}
      }
    `}
`;

export const StyledPurchaseHistoryCollapsable = styled(m.div)`
  ${Section}
  overflow: hidden;
  margin-bottom: 1rem;
  height: 0;

  ${StyledPurchaseHistoryItem} {
    &:last-of-type {
      ${BottomRadius}
    }
  }
`;
