import { Helmet, HelmetProps } from 'react-helmet';
import { locale } from 'environment';
import { t } from 'i18next';
import { getFaviconLinkHTMLAttributes } from 'utils/getFaviconLinkHTMLAttributes.utils';
import { getScript } from 'utils/getScript.utils';

export const DecoratedHelmet = ({ ...props }: HelmetProps) => {
  const title = t('COMMON_BRAND_TELIA');
  const link = getFaviconLinkHTMLAttributes();
  const script = getScript();

  return (
    <Helmet
      htmlAttributes={{ lang: locale.code }}
      title={title}
      link={link}
      script={script}
      {...props}
    />
  );
};
