import { Button } from '@telia-company/tv.oneapp-web-ui';
import { useLogger, useSnackbars } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forgotPinCode, getUser } from 'services/userService';

export const ForgotPinButton = () => {
  const { t } = useTranslation();
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();
  const logger = useLogger('ForgotPinButton');
  const [busy, setBusy] = useState(false);

  const onClick = async () => {
    try {
      setBusy(true);
      const [{ email }] = await Promise.all([getUser(), forgotPinCode()]);
      createPositiveSnackbar(t('SETTINGS_RENTAL_PIN_RESET_PIN_INFORMATION', { email }));
    } catch (error) {
      logger.error('Failed to reset pin email', error);
      createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
    } finally {
      setBusy(false);
    }
  };

  return (
    <Button variant="ghost" disabled={busy} isLoading={busy} onClick={onClick}>
      {t('SETTINGS_RENTAL_PIN_FORGOT_BUTTON_LABEL')}
    </Button>
  );
};
