import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SmartLink } from 'components/SmartLink';
import { navigationText, navigationTextEmphasis } from 'theme/theme.utils';

export const StyledNavItem = styled(SmartLink)`
  position: relative;
  margin-right: 8px;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  border: 0;
  padding: 12px 16px;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: ${({ theme }) => theme.designTokens.radius.navItem};

  &:first-letter {
    text-transform: capitalize;
  }

  ${({ theme }) => navigationText(theme)}
  ${({ theme }) => {
    return css`
      &:not(.active):hover,
      &:not(.active):focus {
        background-color: ${theme.designTokens.color.navigation.currentPrimary};
        > span {
          color: ${theme.designTokens.color.text.primaryDark};
        }
      }

      &:hover {
        opacity: 1;
      }

      // Preserves the width of the item when changing font-weight
      &:after {
        display: block;
        content: attr(title);
        font-weight: ${theme.designTokens.typography.navigationTextEmphasis.fontWeight};
        color: transparent;
        overflow: hidden;
        visibility: hidden;
        height: 0;
      }
    `;
  }}
`;

export const StyledLinkLabel = styled.span`
  ${({ theme }) => css`
    ${navigationTextEmphasis(theme)}
    transition: background-color 0.3s ease, color 0.3s ease;
    color: ${theme.designTokens.color.text.primary};
  `}
`;
