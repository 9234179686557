import { Overlay } from 'components/Overlay';
import { LazyMotion, Variants } from 'framer-motion';
import { useState } from 'react';
import { User } from 'services/userService';
import { MenuItem } from '../../hooks';
import { MenuItemLink } from './MenuItemLink';
import {
  StyledSideMenuContainer,
  StyledSideMenuGeneralSection,
  StyledSideMenuUserSection,
} from './SideMenu.styles';

const SIDEBAR_WIDTH = 300;

const animations: Variants = {
  visible: {
    left: 0,
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    left: -SIDEBAR_WIDTH,
    transition: {
      duration: 0.3,
    },
  },
};

type SideMenuProps = {
  open: boolean;
  user?: User;
  menuItems: MenuItem[];
  userMenuItems?: MenuItem[];
  onDismiss?: () => void;
};

// lazy load features from framer-motion on demand
const loadFeatures = () =>
  import('../../../Footer/utils/fetchFramerMotionFeatures').then((res) => res.default);

export const SideMenu = ({ onDismiss, open, user, menuItems, userMenuItems }: SideMenuProps) => {
  const [draggingX, setDraggingX] = useState(false);
  return (
    <Overlay visible={open} onClick={() => onDismiss?.()}>
      <LazyMotion features={loadFeatures}>
        <StyledSideMenuContainer
          animate={open ? 'visible' : 'hidden'}
          variants={animations}
          initial="hidden"
          exit="hidden"
          drag="x"
          dragConstraints={{
            left: -SIDEBAR_WIDTH,
            right: 0,
          }}
          dragDirectionLock={true}
          dragElastic={false}
          dragMomentum={false}
          onDirectionLock={(axis) => {
            if (axis === 'x') {
              setDraggingX(true);
            }
          }}
          onDragEnd={() => {
            if (draggingX && onDismiss) {
              onDismiss();
            }
            setDraggingX(false);
          }}
        >
          <StyledSideMenuGeneralSection>
            {menuItems.map((menuItem, i) => (
              <MenuItemLink onClick={() => onDismiss?.()} key={i} menuItem={menuItem} />
            ))}
          </StyledSideMenuGeneralSection>
          {userMenuItems && (
            <StyledSideMenuUserSection>
              {userMenuItems.map((menuItem, i) => (
                <MenuItemLink onClick={() => onDismiss?.()} key={i} menuItem={menuItem} />
              ))}
            </StyledSideMenuUserSection>
          )}
        </StyledSideMenuContainer>
      </LazyMotion>
    </Overlay>
  );
};
