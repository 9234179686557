import { Input } from '@telia-company/tv.oneapp-web-ui';
import { StyledPinFieldContainer, StyledMessage } from './PinField.styles';
import { ClipboardEvent, ComponentProps, FocusEvent, FormEvent, useEffect, useRef } from 'react';

type PinFieldProps = {
  className?: string;
  length: number;
  masked?: boolean;
  onValueChanged?: (value: string) => void;
  state?: ComponentProps<typeof Input>['state'];
  message?: string;
  autocomplete?: string;
};
export const PinField = ({
  length,
  className,
  masked,
  onValueChanged,
  state,
  message,
  autocomplete,
}: PinFieldProps) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const emitOnValueChanged = () => {
    const combinedValue = inputRefs.current.map((input) => input?.value ?? '').join('');
    onValueChanged?.(combinedValue);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (value.length === 1 && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    emitOnValueChanged();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && !e.currentTarget.value) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const pasteValue = (value: string) => {
    for (let i = 0; i < length; i++) {
      const inputRef = inputRefs.current[i];
      if (inputRef) {
        inputRef.value = value[i] ?? '';
      }
    }
    emitOnValueChanged();
    const lastPastedInput = inputRefs.current[value.length - 1];
    if (lastPastedInput) {
      lastPastedInput.focus();
      lastPastedInput.selectionStart = 1;
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    pasteValue(e.clipboardData.getData('text'));
  };

  const handleBeforeInput = (e: FormEvent<HTMLInputElement>, index: number) => {
    const inputEvent = e.nativeEvent as InputEvent;

    if (!inputEvent.data) {
      return;
    }

    // Clear the input to allow for replacing the content with a single character
    if (inputEvent.data.length === 1) {
      const inputRef = inputRefs.current[index];
      if (inputRef) {
        inputRef.value = '';
      }
      return;
    }

    // We have a long string of data, so we need to prevent the default behavior and behave like we're
    // handling a paste event
    e.preventDefault();
    pasteValue(inputEvent.data);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    event.target.select();
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  return (
    <StyledPinFieldContainer className={className} state={state}>
      <ul>
        {Array.from({ length }).map((_, index) => (
          <li key={index}>
            <input
              autoComplete={autocomplete}
              type={masked ? 'password' : 'tel'}
              maxLength={1}
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onBeforeInput={(e) => handleBeforeInput(e, index)}
              onPaste={handlePaste}
              onFocus={handleFocus}
            />
          </li>
        ))}
      </ul>
      {message && <StyledMessage state={state}>{message}</StyledMessage>}
    </StyledPinFieldContainer>
  );
};
