import { CenteredSpinner } from 'components';
import { ConfirmModal } from 'components/modals';
import { useLogger, useSnackbars } from 'hooks';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { useTranslation } from 'react-i18next';
import { getAccountInfo } from 'services/userService';
import { getCreateEmailModalInfo } from './getCreateEmailModalInfo.utils';

type CreateEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateEmailModal = ({ isOpen, onClose }: CreateEmailModalProps) => {
  const logger = useLogger('CreateEmailModal');
  const { t } = useTranslation();
  const { data: accountInfo, loading, error } = useFetchFunction(getAccountInfo);
  const { createNegativeSnackbar } = useSnackbars();

  // Only report error when modal is open
  if (error && isOpen) {
    logger.error('Failed to load account info', error);
    createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
    onClose();
  }

  const modalInfo = accountInfo ? getCreateEmailModalInfo(accountInfo) : undefined;

  const onConfirmModalResponse = (confirmed: boolean) => {
    modalInfo?.onConfirmModalResponse?.(confirmed);
    onClose();
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title={t('SETTINGS_RENTAL_PIN_REQUEST_EMAIL_MISSING_TITLE')}
      onResponse={onConfirmModalResponse}
      confirmDisabled={loading}
      confirmLabel={modalInfo?.confirmLabel ?? '...'}
    >
      {!modalInfo ? <CenteredSpinner /> : <div>{modalInfo.description}</div>}
    </ConfirmModal>
  );
};
