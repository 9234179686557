import { Log } from '@telia-company/tv.common-sdk/dist/Log';
import { Component, ComponentType, ErrorInfo, ReactNode } from 'react';
import { SomethingWentWrong } from './components';

export type ErrorBoundaryFallbackProps = {
  retry: () => void;
};

export type ErrorBoundaryProps = {
  onError?: (
    error: Error,
    errorInfo: ErrorInfo,
  ) => ComponentType<ErrorBoundaryFallbackProps> | undefined;
  children?: ReactNode;
};

type ErrorBoundaryState = {
  error?: Error;
  errorInfo?: ErrorInfo;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const logger = Log.instance()('ErrorBoundary');
    logger.error('Caught error', error, errorInfo);
    this.setState({ error, errorInfo });
  }

  private onRetry() {
    this.setState({ error: undefined, errorInfo: undefined });
  }

  render(): ReactNode {
    const retry = this.onRetry.bind(this);
    if (this.state.error) {
      if (this.props.onError) {
        const { error, errorInfo } = this.state;
        const Component = this.props.onError(error, errorInfo!);
        if (Component) {
          return <Component retry={() => retry()} />;
        }
      }
      return <SomethingWentWrong retry={() => retry()} />;
    }
    return this.props.children;
  }
}
