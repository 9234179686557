import styled from '@emotion/styled';

export const StyledStaticHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;

type StyledMarginTopProps = {
  marginTop: number;
};
export const StyledMarginTop = styled.div<StyledMarginTopProps>`
  position: relative;
  height: ${({ marginTop }) => marginTop}px;
`;
