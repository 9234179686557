import { Event, createSingleton } from '@telia-company/tv.common-sdk/dist/Singleton';
import {
  AuthenticationEvent,
  Authentication as SdkAuthentication,
} from '@telia-company/tv.common-sdk-authentication/dist/Authentication';
import { Log } from '@telia-company/tv.common-sdk/dist/Log';

const authenticationEvent = new Event<AuthenticationEvent>();

SdkAuthentication.statics.events.authentication.on((event) => {
  authenticationEvent.emit(event);
});

export const Authentication = createSingleton(
  'oneapp-settings/sdk/Authentication',
  (statics) => {
    const authentication = SdkAuthentication.instance();
    const logger = Log.instance()('authenticate');

    const openLoginPage = async () => {
      const { loginUrl } = await authentication.getOAuthInfo({
        returnUrl: window.location.href,
      });
      window.location.href = loginUrl;
    };

    const openCreateAccountPage = async () => {
      const { createAccountUrl, loginUrl } = await authentication.getOAuthInfo({
        returnUrl: window.location.href,
      });

      window.location.href = createAccountUrl || loginUrl;
    };

    const isLoggedin = () => {
      return authentication.isLoggedin();
    };

    const login = async (code: string, redirect: string): Promise<void> => {
      try {
        await authentication.login({
          type: 'OAUTH',
          authorizationGrant: code,
          autoLogin: true,
        });
        window.location.href = redirect;
      } catch (ex) {
        logger.error('Failed to OAUTH login', ex);
        openLoginPage();
      }
    };

    const logout = () => SdkAuthentication.instance().logout();

    const registerDevice = async () => {
      // Registering the device. If this fail, we still move
      // on - but with a log about the error
      try {
        await SdkAuthentication.instance().registerDevice();
      } catch (ex) {
        logger.error('Failed to register device', ex);
      }
    };

    return {
      isLoggedin,
      openLoginPage,
      openCreateAccountPage,
      login,
      logout,
      registerDevice,
    };
  },
  {
    events: {
      authentication: authenticationEvent,
    },
  },
);
