import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogger } from 'hooks';
import { StyledHeading, StyledInformation } from './ErrorComponent.styles';

type ErrorComponentProps = {
  error: unknown;
};

export const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const logger = useLogger('components/ErrorComponent');
  const { t } = useTranslation();
  useEffect(() => {
    logger.error('Error occurred', error);
  }, [logger, error]);

  return (
    <>
      <StyledHeading>{t('ERROR_GENERIC_HEADING')}</StyledHeading>
      <StyledInformation>{t('ERROR_GENERIC_INFORMATION')}</StyledInformation>
    </>
  );
};
