import { useTranslation } from 'react-i18next';
import { StyledServicesContainer, StyledServicesHeader } from './VAS.styles';
import { UserSubscription } from 'services/userService';
import { VASItem } from './VASItem';

type VASProps = {
  userSubscription: UserSubscription;
};

export const VAS = ({ userSubscription }: VASProps) => {
  const { t } = useTranslation();
  return (
    <StyledServicesContainer>
      <StyledServicesHeader>{t('SUBSCRIPTION_VAS_HEADER')}</StyledServicesHeader>
      {userSubscription.services.map((service) => (
        <VASItem userSubscription={userSubscription} service={service} />
      ))}
    </StyledServicesContainer>
  );
};
