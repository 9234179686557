import { getParser } from 'bowser';

const parser = getParser(window?.navigator.userAgent);

// Following this spec: https://teliatv4media.atlassian.net/wiki/spaces/BCORE/pages/1244209922/MMS
// And how this is reported by the angular web:
// https://git.tvm.telia.com/repos/tve-gui/-/blob/master/core-integration/src/device/Info.js

export const getPlatformName = () => {
  const os = parser.getOSName(true);
  // Logging appenders have hardcoded checks for "mac" and not macos
  if (os === 'macos') {
    return 'mac';
  }
  //Otherwise they check for windows, linux or "tvegui_on_unknown_platform"... will be fixed in the sdk versions.
  return os || 'tvegui_on_unknown_platform';
};

export const getVendor = () => {
  const { vendor } = parser.getPlatform();
  return vendor;
};

export const getPlatformVersion = () => {
  return parser.getOSVersion() || 'unknown_platformVersion';
};

export const getModel = () => {
  const { type, model } = parser.getPlatform();
  const platform = getPlatformName();

  if (type === 'desktop') {
    return `${platform}_${type}`;
  }
  if (model === 'iPhone' || model === 'iPad') {
    return model.toLowerCase();
  }
  if (type === 'mobile') {
    return `${platform}_phone`;
  }
  if (type) {
    return `${platform}_${type}`;
  }
  return 'unknown_model';
};
