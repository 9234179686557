import { Button } from '@telia-company/tv.oneapp-web-ui';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionContent,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';
import { useGetEmailChangeLabelAndLink } from './hooks';
import { SmartLink } from 'components';

type EmailProps = {
  user?: User;
  hideChangeButton?: boolean;
};

export const Email = ({ user, hideChangeButton }: EmailProps) => {
  const { t } = useTranslation();
  const { changeLabel, changeLink } = useGetEmailChangeLabelAndLink();

  return (
    <StyledSubSection>
      <div>
        <StyledSubSectionLabel>{t('ACCOUNT_INFO_EMAIL_SECTION_HEADING')}</StyledSubSectionLabel>
        <StyledSubSectionContent>{user ? user.email : '...'}</StyledSubSectionContent>
      </div>
      {!hideChangeButton && (
        <StyledButtonContainer>
          <Button as={SmartLink} variant="secondary" disabled={!user} to={changeLink}>
            {changeLabel}
          </Button>
        </StyledButtonContainer>
      )}
    </StyledSubSection>
  );
};
