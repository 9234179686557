import { useScroll } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import { StyledHeaderContainer } from './HeaderContainer.styles';

type HeaderContainerProps = {
  className?: string;
  children?: ReactNode;
};

export const HeaderContainer = ({ children, className }: HeaderContainerProps) => {
  const { scrollY } = useScroll();
  const [opaque, setOpaque] = useState(window.scrollY > 0);

  useEffect(() => {
    return scrollY.onChange((value) => {
      setOpaque(value > 0);
    });
  }, [scrollY]);

  return (
    <StyledHeaderContainer className={className} opaque={opaque}>
      {children}
    </StyledHeaderContainer>
  );
};
