import { ChannelWithRegionalPreference } from './hooks/useGetRegionalChannels';

const regionChannelLabel = (channel: ChannelWithRegionalPreference, region: string) => `${region}`;

export const getDropDownItems = (channel: ChannelWithRegionalPreference) =>
  channel.regionalChannels.map(({ id, region }) => ({
    id: id,
    label: regionChannelLabel(channel, region),
  }));

export const getSelectedDropDownItem = (channel: ChannelWithRegionalPreference) => {
  return getDropDownItems(channel).find(({ id }) => id === channel.preferredRegionalChannel?.id);
};

export const getPreferredChannellLabel = (channel: ChannelWithRegionalPreference) => {
  const preferredRegionalChannel = channel.regionalChannels.find(
    ({ id }) => id === channel.preferredRegionalChannel?.id,
  );
  return preferredRegionalChannel?.region;
};
