import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { navigationText } from 'theme/theme.utils';

export const StyledFiltersContainer = styled.div<{ opaque: boolean }>`
  z-index: 3;
  display: flex;
  > *:not(:last-child) {
    margin-left: 8px;
  }
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  margin-bottom: 18px;
  margin-top: -76px;
  width: 100%;
  position: fixed;
  transition: background-color 0.3s ease;
  overflow-x: auto;
  scrollbar-color: transparent;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ${mq.S`
    z-index: 2;
    padding-left: 40px;
    padding-top: 24px;
  `}

  ${mq.L`
    margin-bottom: 16px;
    margin-top: -90px;
  `}

  ${({ opaque, theme }) =>
    opaque &&
    css`
      box-shadow: 0 8px 10px ${theme.designTokens.color.background.primary};
    `};
  background-color: ${({ theme, opaque }) =>
    opaque ? theme.designTokens.color.background.primary : 'transparent'};
`;

type StyledFilterProps = {
  active?: boolean;
};

export const StyledFilter = styled.div<StyledFilterProps>`
  position: relative;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  border: 0;
  padding: 12px 16px;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: ${({ theme }) => theme.designTokens.radius.navItem};

  ${({ theme }) => navigationText(theme)}

  &:after {
    display: block;
    content: attr(title);
    font-weight: ${({ theme }) => theme.designTokens.typography.navigationTextEmphasis.fontWeight};
    color: transparent;
    overflow: hidden;
    visibility: hidden;
    height: 0;
  }

  ${({ theme, active }) =>
    active
      ? css`
          background-color: ${theme.designTokens.color.navigation.currentSecondary};
          color: ${theme.designTokens.color.navigation.currentPrimary};
        `
      : css`
          &:hover,
          &:focus {
            background-color: ${theme.designTokens.color.navigation.currentSecondary};
          }
        `}}
`;
