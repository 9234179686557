import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

type StyledHeaderContainerProps = {
  opaque: boolean;
};

export const StyledHeaderContainer = styled.div(({ opaque }: StyledHeaderContainerProps) => {
  const theme = useTheme();
  return `
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      height: 80px;
      padding: 0 32px;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      justify-content: space-between;
      user-select: none;
      z-index: 4;
      transition: 0.3s ease;
      ${opaque && `box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);`};
      background-color: ${opaque ? theme.designTokens.color.background.primary : 'transparent'};
    `;
});
