import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Log } from '@telia-company/tv.common-sdk/dist/Log';
import { country } from 'environment';
import { User } from 'services/userService';
import {
  getUserProfileType,
  OfferImpression,
  trackEvent,
  UpsellSelectContent,
  UpsellSelectSubscription,
} from 'tracking.utils';

export const initTracking = () => {
  initGTM();
};

const initGTM = () => {
  // Setup data layer
  window.dataLayer = window.dataLayer || [];

  if (Config.get('gtm/enabled')) {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KZ7N4LD');`;

    document.body.appendChild(script);
  }
};

export const trackSetUserProperties = (user: User) => {
  const logger = Log.instance()('trackSetUserProperties');
  const app_name = Config.get('gtm/appName');

  if (!user.currentProfileId) {
    logger.error("Can't track set_user_properties - profile is missing");
    return;
  }

  if (!app_name) {
    logger.error("Can't track set_user_properties - config for gtm/appName is not set");
    return;
  }

  const currentProfile = user.profiles.find((profile) => profile.id === user.currentProfileId);

  trackEvent({
    event: 'set_user_properties',
    app_name,
    client: 'account-web',
    user_market: country.toLowerCase(),
    user_id: user.id,
    user_profile_id: user.currentProfileId,
    user_profile_type: (currentProfile && getUserProfileType(currentProfile)) ?? 'UNKNOWN',
    user_subscriptions: user.subscriptions.map(({ id }) => id),
    user_subscription_types: Array.from(new Set(user.subscriptions.map(({ type }) => type))),
  });
};

export const trackPage = () => {
  trackEvent({
    event: 'page',
  });
};

export const trackLogoutAllDevices = () => {
  trackEvent({
    event: 'logout_all_devices',
  });
};

export const trackLogoutOtherDevice = (deviceId: string) => {
  trackEvent({
    event: 'logout_other_device',
    device_id: deviceId,
  });
};

type TrackOverlayProps = {
  overlayTitle: string;
};

export const trackOverlay = ({ overlayTitle }: TrackOverlayProps) => {
  trackEvent({
    event: 'overlay',
    overlay_title: overlayTitle,
  });
};

type TrackPanelImpressionProps = {
  panelId: string;
  panelRow: number;
};

export const trackPanelImpression = ({ panelId, panelRow }: TrackPanelImpressionProps) => {
  trackEvent({
    event: 'panel_impression',
    panel_id: panelId,
    panel_row_index: panelRow,
  });
};

type TrackPanelAssetImpression = {
  contentMediaId: string;
  panelId: string;
  panelRow: number;
};

export const trackPanelAssetImpression = ({
  contentMediaId,
  panelId,
  panelRow,
}: TrackPanelAssetImpression) => {
  trackEvent({
    event: 'asset_impression',
    content_media_id: contentMediaId,
    panel_id: panelId,
    panel_row_index: panelRow,
  });
};

type TrackPanelAssetClick = {
  contentMediaId: string;
  panelId: string;
  panelRow: number;
};

export const trackPanelAssetClick = ({
  contentMediaId,
  panelId,
  panelRow,
}: TrackPanelAssetClick) => {
  trackEvent({
    event: 'asset_click',
    content_media_id: contentMediaId,
    panel_id: panelId,
    panel_row_index: panelRow,
  });
};

export const trackCancelSubscription = (packageId: string) => {
  trackEvent({
    event: 'cancel_subscription',
    cancellation_package_id: packageId,
  });
};

export const trackResumeSubscription = () => {
  trackEvent({
    event: 'resume_subscription',
  });
};

type TrackOfferImpressionProps = {
  packageId: OfferImpression['offer_package_id'];
  bindingTime: OfferImpression['offer_binding_time'];
};

export const trackOfferImpression = ({ packageId, bindingTime }: TrackOfferImpressionProps) => {
  trackEvent({
    event: 'offer_impression',
    offer_package_id: packageId,
    offer_binding_time: bindingTime,
  });
};

type TrackOfferClickProps = {
  packageId: OfferImpression['offer_package_id'];
  bindingTime: OfferImpression['offer_binding_time'];
};

export const trackOfferClick = ({ packageId, bindingTime }: TrackOfferClickProps) => {
  trackEvent({
    event: 'offer_click',
    offer_package_id: packageId,
    offer_binding_time: bindingTime,
  });
};

type TrackUpsellSelectContentProps = {
  sellingPointTexts: UpsellSelectContent['upsell_content_type'][];
};

export const trackUpsellSelectContent = ({ sellingPointTexts }: TrackUpsellSelectContentProps) => {
  trackEvent({
    event: 'upsell_select_content',
    upsell_content_type: sellingPointTexts.join('|'),
  });
};

type TrackUpsellSelectSubscriptionProps = {
  productId: UpsellSelectSubscription['upsell_subscription_type'];
};

export const trackUpsellSelectSubscription = ({
  productId,
}: TrackUpsellSelectSubscriptionProps) => {
  trackEvent({
    event: 'upsell_select_subscription',
    upsell_subscription_type: productId,
  });
};

type TrackUpsellNoPackageClickProps = {
  buttonText: string;
};

export const trackUpsellNoPackageClick = ({ buttonText }: TrackUpsellNoPackageClickProps) => {
  trackEvent({
    event: 'upsell_no_package_click',
    upsell_button_text: buttonText,
  });
};

type TrackRedirectProps = {
  to: string;
};

export const trackRedirect = ({ to }: TrackRedirectProps) => {
  trackEvent({
    event: 'redirect',
    target_page_name: to,
  });
};

type TrackConfirmChangeToPackageProps = {
  changeToPackageId: string;
};

export const trackConfirmChangeToPackage = ({
  changeToPackageId,
}: TrackConfirmChangeToPackageProps) => {
  trackEvent({
    event: 'confirm_change_to_package',
    change_to_package_id: changeToPackageId,
  });
};
