import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { StyledFullscreen } from './Fullscreen.styles';
import { useFullscreenContext } from './FullscreenContext';

type FullscreenProps = {
  className?: string;
  children?: ReactNode;
};

export const Fullscreen = ({ className, children }: FullscreenProps) => {
  const { fullscreen, setFullscreen } = useFullscreenContext();

  useEffect(() => {
    setFullscreen(true);
    return () => setFullscreen(false);
  }, [setFullscreen]);

  return fullscreen
    ? createPortal(
        <StyledFullscreen className={className}>{children}</StyledFullscreen>,
        document.getElementById('fullscreen')!,
      )
    : null;
};
