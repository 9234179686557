import { FixedItem, SelectableItem } from 'services/selectionMenuService';
import { StyledGridContainer, StyledSectionSpinner } from './SelectionMenuGridView.styles';
import { isSelectableItem } from '../isSelectableItem.utils';
import { FixedCard, SelectableCard } from './Card';
import { useContext } from 'react';
import { SelectionMenuContext } from '../SelectionMenuContext';

type SelectionMenuGridViewProps = {
  items: (SelectableItem | FixedItem)[];
};

export const SelectionMenuGridView = ({ items }: SelectionMenuGridViewProps) => {
  const { loading } = useContext(SelectionMenuContext);
  return (
    <StyledGridContainer>
      {loading ? (
        <StyledSectionSpinner />
      ) : (
        items.map((item) =>
          isSelectableItem(item) ? (
            <SelectableCard key={item.id} item={item} />
          ) : (
            <FixedCard key={item.id} item={item} />
          ),
        )
      )}
    </StyledGridContainer>
  );
};
