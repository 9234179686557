import { NavigatorSection } from 'pages';
import { NavigationDropDown } from './NavigationDropDown';

type NavigationSmallProps = {
  sections: NavigatorSection[];
};

export const NavigationSmall = ({ sections }: NavigationSmallProps) => {
  return <NavigationDropDown sections={sections} />;
};
