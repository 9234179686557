import { useOnAppInteraction } from 'hooks/useOnAppInteraction';
import { MessageItem } from './components';
import { StyledMessageContainer } from './MessageBar.styles';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { getMessages, removeMessage } from 'services/messagesService';

const ONE_MINUTE_IN_MS = 60000;

export const MessageBar = () => {
  const { data: messages, refresh } = useFetchFunction(getMessages);

  useOnAppInteraction({ callback: refresh, waitFor: ONE_MINUTE_IN_MS });
  if (!messages?.length) {
    return null;
  }

  const dismissMessage = (id: string) => {
    removeMessage(id);
    refresh();
  };

  return (
    <StyledMessageContainer>
      {messages.map((message) => (
        <MessageItem
          key={message.id}
          message={message}
          onDismiss={() => dismissMessage(message.id)}
        />
      ))}
    </StyledMessageContainer>
  );
};
