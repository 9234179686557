import { PostProcessorModule } from 'i18next';

/**
 * A post processor that divides the value by a pipe character.
 *
 * If it's divisable by three, it takes into account the `count` option provided and
 * returns the first part if `count` is 0, the second part if `count` is one, and the
 * third part if 'count' is anything else.
 *
 * If it's divisable by two, it takes into account the `count` option provided and
 * returns the first part if `count` is 1, and the second part if `count` is anything else.
 *
 * @example
 * t('0 bananas | 1 banana | {{count}} bananas', { count: 0, postProcessor: 'pluralizer' }) === '0 bananas';
 * t('0 bananas | 1 banana | {{count}} bananas', { count: 2, postProcessor: 'pluralizer' }) === '2 bananas';
 * t('1 banana | {{count}} bananas', { count: 1, postProcessor: 'pluralizer' }) === '1 banana';
 *
 * @see https://www.i18next.com/misc/creating-own-plugins#post-processor
 */
export const pluralizerPostProcessor: PostProcessorModule = {
  type: 'postProcessor',
  name: 'pluralizer',
  process(value, key, options, translator) {
    if (options.count !== undefined) {
      const parts = value.split('|').map((part) => part.trim());
      if (parts.length === 3) {
        return parts[options.count] ?? parts[2];
      } else if (parts.length === 2) {
        return parts[options.count === 1 ? 0 : 1];
      }
    }
    return value; // Return the original value if no count is provided
  },
};
