import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledProgressBarWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 6px;
  background-color: ${({ theme }) => theme.designTokens.color.content.progressDuration};
  border-radius: 5px;
  margin-top: 8px;
  ${mq.L`
    margin-top: 24px;
  `}
`;

export const StyledProgressBarFill = styled.div<{ percent: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.3s;
  width: ${({ percent }) => Math.min(percent, 100)}%;
  background-color: ${({ theme, percent }) =>
    percent < 100
      ? theme.designTokens.color.infoDialogue.importantBackgroundDefault
      : theme.designTokens.color.infoDialogue.errorBackgroundDefault};
  border-radius: 5px;
`;
