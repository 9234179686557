import { Icon } from '@telia-company/tv.oneapp-web-ui';
import styled from '@emotion/styled';
import { textSecondary } from 'theme/theme.utils';

export const StyledContainer = styled.div`
  margin-bottom: 24px;
`;

export const StyledPinInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 4rem;
`;

export const StyledPinInput = styled.input`
  width: 56px;
  height: 56px;
  display: inline-block;
  border: 0;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin-right: 16px;
  :last-of-type {
    margin-right: 0;
  }
`;

export const StyledErrorMessage = styled.div`
  display: flex;
  ${({ theme }) => textSecondary(theme)}
  padding: 16px 0 8px 0;
  color: ${({ theme }) => theme.designTokens.color.text.primary};
`;

export const StyledSuccessMessage = styled.div`
  ${({ theme }) => textSecondary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.success};
`;

export const StyledErrorIcon = styled(Icon)`
  margin-right: 8px;
  width: 16px;
`;
