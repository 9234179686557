import styled from '@emotion/styled';

export const StyledSearchLink = styled.a`
  display: inline-block;
  justify-self: flex-end;
  padding: 12px;
  & svg {
    height: 24px;
    width: 24px;
  }

  border-radius: 50%;

  transition: opacity 0.2s, color 0.2s, background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.designTokens.color.button.ghostHover};
  }
`;
