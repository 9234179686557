import { LoggedinDevice } from 'services/deviceService';
import { getDeviceLocation, getDeviceName } from './Device.utils';
import {
  StyledDeviceContainer,
  StyledDeviceInfoContainer,
  StyledDeviceLastUsed,
  StyledDeviceLocation,
  StyledDeviceName,
} from './Device.styles';
import { useTranslation } from 'react-i18next';

type OtherDeviceProps = {
  device: LoggedinDevice;
};

export const OtherDevice = ({ device: loggedinDevice }: OtherDeviceProps) => {
  const { t } = useTranslation();
  return (
    <StyledDeviceContainer>
      <StyledDeviceInfoContainer>
        <StyledDeviceName>{getDeviceName(loggedinDevice)}</StyledDeviceName>
        <StyledDeviceLastUsed>
          {t('NAV_DEVICES_DEVICE_LAST_USED', {
            date: new Date(loggedinDevice.lastSeen),
          })}
        </StyledDeviceLastUsed>
        <StyledDeviceLocation>
          {t('NAV_DEVICES_DEVICE_LOCATION', {
            location: getDeviceLocation(loggedinDevice) || t('NAV_DEVICES_DEVICE_UNKNOWN_LOCATION'),
          })}
        </StyledDeviceLocation>
      </StyledDeviceInfoContainer>
    </StyledDeviceContainer>
  );
};
