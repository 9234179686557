import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { headingPrimary, textPrimary } from 'theme/theme.utils';

export const StyledHeading = styled.h1`
  ${({ theme }) => css`
    ${headingPrimary(theme)}
    margin-top: 0;
  `}
`;

export const StyledInformation = styled.p`
  ${({ theme }) => css`
    ${textPrimary(theme)}
  `}
`;
