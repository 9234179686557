import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

type TitledRouteProps = {
  title: string;
};

export const TitledRoute = ({ title: suffix }: TitledRouteProps) => {
  const { t } = useTranslation();

  let prefix = t('COMMON_BRAND_TELIA');

  const title = suffix ? `${prefix} - ${suffix}` : prefix;

  return (
    <>
      <Helmet title={title} />
      <Outlet />
    </>
  );
};
