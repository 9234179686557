import { createRoot } from 'react-dom/client';
import { App } from 'App';
import { init as initConfig } from 'config';
import { refresh as initFeatureToggles } from 'featureToggles';
import { Authentication } from 'sdk/authentication';
import 'i18n';
import { initSdk } from 'sdk';
import 'services';
import { initTracking, trackSetUserProperties } from 'tracking';
import { getUser } from 'services/userService';
import * as medallia from 'utils/medallia';

(async () => {
  await initSdk();
  await initConfig();
  await initFeatureToggles();

  medallia.init();

  const authentication = Authentication.instance();

  // Are we in the process of logging in?
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  if (code) {
    url.searchParams.delete('code');
    const { pathname, search } = url;
    authentication.login(code, `${pathname}${search}`);
    return;
  }

  // initialize oneTrust, gtm and track the user
  initTracking();

  if (await authentication.isLoggedin()) {
    getUser().then((user) => {
      trackSetUserProperties(user);
    });

    // Always register device when we're decidedly logged in
    await authentication.registerDevice();
  }

  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement!);
  root.render(<App />);
})();
