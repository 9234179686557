import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledInput = styled.input`
  width: 64px;
  height: 64px;
  border-radius: 6px;
  color: white;
  text-align: center;
  -webkit-text-security: disc;
  background: ${({ theme }) => theme.designTokens.color.background.surface};
  border: 1px solid ${({ theme }) => theme.designTokens.color.border.backgroundSurface};
  font-size: ${({ theme }) => theme.designTokens.typography.textPrimary.fontSize};

  :focus {
    outline: none;
    border: 1px solid white;
  }
`;
