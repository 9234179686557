import {
  StyledSubSection,
  StyledSubSectionContainer,
  StyledSubSectionContent,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import {
  StyledInfoContainer,
  StyledInputWrapper,
  StyledRequestPinButtonContainer,
} from './PinCodeSubSectionWithPinRequestButton.styles';

import { PinCodeRequestButton } from './PinCodeRequestButton';

type PinCodeSubSectionWithPinRequestButtonProps = {
  label: string;
};

export const PinCodeSubSectionWithPinRequestButton = ({
  label,
}: PinCodeSubSectionWithPinRequestButtonProps) => {
  const { t } = useTranslation();
  return (
    <StyledSubSectionContainer>
      <StyledSubSection>
        <StyledInputWrapper>
          <StyledInfoContainer>
            <StyledSubSectionLabel>{label}</StyledSubSectionLabel>
            <StyledSubSectionContent>
              {t('SETTINGS_RENTAL_PIN_REQUEST_DESCRIPTION')}
            </StyledSubSectionContent>
          </StyledInfoContainer>
          <StyledRequestPinButtonContainer>
            <PinCodeRequestButton />
          </StyledRequestPinButtonContainer>
        </StyledInputWrapper>
      </StyledSubSection>
    </StyledSubSectionContainer>
  );
};
