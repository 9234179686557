import { PreferredLanguages } from '@telia-company/tv.common-sdk-legacy-pack/dist/PreferredLanguages';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, useGetLanguages } from './useGetLanguages';

export const useGetSubtitleLanguages = () => {
  const preferredLanguages = PreferredLanguages.instance();
  const { t } = useTranslation();
  const languages = useGetLanguages();
  const { data: languageCode, refresh } = useFetchFunction(
    preferredLanguages.getPreferredSubtitleLanguage,
  );
  const [subtitleLanguages, setSubtitleLanguages] = useState(languages);
  const [preferredSubtitleLanguage, setPreferredSubtitleLanguage] = useState<Language>();
  useEffect(() => {
    if (languages) {
      const languagesWithOff: Language[] = [
        {
          code: 'off',
          name: t('SETTINGS_DEVICE_PREFERRED_LANGUAGE_NO_SUBTITLES'),
        },
        ...languages,
      ];

      const preferredLanguage = languagesWithOff.find(({ code }) => code === languageCode);
      setSubtitleLanguages([...languagesWithOff]);
      setPreferredSubtitleLanguage(preferredLanguage);
    }
  }, [languageCode, languages, t]);

  return { subtitleLanguages, preferredSubtitleLanguage, refresh };
};
