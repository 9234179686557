import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { useTranslation } from 'react-i18next';

export const useGetPasswordChangeLabelAndLink = () => {
  const { t } = useTranslation();

  const url = Config.get('change-password-url');
  if (!url) {
    return {
      changeLabel: t('COMMON_BUTTON_CHANGE'),
      changeLink: '/account/password',
    };
  } else {
    return {
      changeLabel: t('COMMON_BUTTON_CHANGE_TELIA'),
      changeLink: url,
    };
  }
};
