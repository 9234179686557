import * as messagebar from './backend/messagebar';

const cacheClass = 'messagesService';

export type MessageItemVariant = 'negative' | 'attention' | 'informative' | 'neutral';

export type Message = {
  id: string;
  text: string;
  variant?: MessageItemVariant;
  link?: string;
};

type RemovedMessagesMap = { [key: string]: boolean };

const REMOVED_MESSAGES_STORAGE_KEY = 'REMOVED_MESSAGES';

const getRemovedMessagesMap = () => {
  return JSON.parse(
    localStorage.getItem(REMOVED_MESSAGES_STORAGE_KEY) ?? '{}',
  ) as RemovedMessagesMap;
};

const setRemovedMessagesMap = (map: RemovedMessagesMap) => {
  localStorage.setItem(REMOVED_MESSAGES_STORAGE_KEY, JSON.stringify(map));
};

const convertSeverityToVariant = (
  severity: messagebar.MessageBar['severity'],
): MessageItemVariant => {
  return severity === 'ALERT' ? 'negative' : 'attention';
};

export const getMessages = async (): Promise<Message[]> => {
  const messageBars = await messagebar.getMessageBars({ cacheClass });

  const removedMessagesMap = getRemovedMessagesMap();

  const allMessages: Message[] = messageBars
    .map((item) => {
      // Lets filter out all messages that have targeted page ids
      if (item.filterPageIds && item?.filterPageIds.length > 0) {
        return undefined;
      }

      return {
        id: item.id,
        text: item.content,
        link: item.url ?? undefined,
        variant: convertSeverityToVariant(item.severity),
      };
    })
    .filter((item) => item !== undefined) as Message[];

  const allMessageIds = allMessages.map(({ id }) => id);

  const obsoleteRemovedIds = Object.keys(removedMessagesMap).filter(
    (id) => !allMessageIds.includes(id),
  );

  const prunedRemovedMessagesMap: RemovedMessagesMap = {};

  for (const [k, v] of Object.entries(removedMessagesMap)) {
    // Prune the message ids that do no longer exist
    if (obsoleteRemovedIds.includes(k)) {
      continue;
    }
    // Retain the messages that are still "alive" in the removed message map
    prunedRemovedMessagesMap[k] = v;
  }

  const filteredMessages = allMessages.filter((item) => !prunedRemovedMessagesMap[item.id]) ?? [];

  setRemovedMessagesMap(prunedRemovedMessagesMap);

  return filteredMessages;
};

export const removeMessage = (messageId: string) => {
  setRemovedMessagesMap({ ...getRemovedMessagesMap(), [messageId]: true });
};
