import { Switch } from '@telia-company/tv.oneapp-web-ui';
import { useSnackbars } from 'hooks';

import {
  StyledSubSection,
  StyledSubSectionContent,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useCallback, useEffect, useState } from 'react';
import { MfaStatus } from 'services/userService';
import { DeleteMfaModal } from './components/RemoveMfaModal';
import { useTranslation } from 'react-i18next';
import { CreateMfaModal } from './components/CreateMfaModal';

type MfaStatusSubSectionProps = {
  mfaStatus?: MfaStatus;
  verificationMethod: MfaStatus['verificationMethod'];
  canChange: boolean;
  defaultValue?: string;
};
export const MfaStatusSubSection = ({
  mfaStatus,
  verificationMethod,
  canChange,
  defaultValue,
}: MfaStatusSubSectionProps) => {
  const { createPositiveSnackbar } = useSnackbars();
  const [deleteMfaModalOpen, setDeleteMfaModalOpen] = useState(false);
  const [createMfaModalOpen, setCreateMfaModalOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState(!!mfaStatus);
  const { t } = useTranslation();

  useEffect(() => {
    setSwitchValue(!!mfaStatus);
  }, [mfaStatus]);

  const labelMap: Record<MfaStatus['verificationMethod'], string> = {
    email: t('MFA_SECTION_AUTHENTICATE_EMAIL'),
    sms: t('MFA_SECTION_AUTHENTICATE_SMS'),
  };

  const value = mfaStatus?.value
    ? t('MFA_SECTION_VERIFIED_VALUE', { value: mfaStatus.value })
    : t('MFA_SECTION_UNSET_VALUE');

  const onDeleteMfaModalClose = useCallback(
    (deleted: boolean) => {
      setDeleteMfaModalOpen(false);
      if (deleted) {
        createPositiveSnackbar(t('MFA_SECTION_SUCCESSFUL_REMOVAL_MESSAGE'));
      }
      setSwitchValue(!!mfaStatus);
    },
    [createPositiveSnackbar, mfaStatus, t],
  );

  const onCreateMfaModalClose = useCallback(
    (created: boolean) => {
      setCreateMfaModalOpen(false);
      if (created) {
        switch (verificationMethod) {
          case 'sms':
            createPositiveSnackbar(t('MFA_SECTION_SUCCESSFUL_ACTIVATION_MESSAGE_SMS'));
            break;
          case 'email':
            createPositiveSnackbar(t('MFA_SECTION_SUCCESSFUL_ACTIVATION_MESSAGE_EMAIL'));
            break;
        }
      }
      setSwitchValue(!!mfaStatus);
    },
    [createPositiveSnackbar, mfaStatus, t, verificationMethod],
  );

  return (
    <>
      <StyledSubSection>
        <div>
          <StyledSubSectionLabel>{labelMap[verificationMethod]}</StyledSubSectionLabel>
          <StyledSubSectionContent>{value}</StyledSubSectionContent>
        </div>
        {canChange && (
          <Switch
            value={switchValue}
            onValueChange={(value) => {
              setSwitchValue(value);
              if (value) {
                setCreateMfaModalOpen(true);
              } else {
                setDeleteMfaModalOpen(true);
              }
            }}
          />
        )}
      </StyledSubSection>
      {mfaStatus && (
        <DeleteMfaModal
          mfaStatus={mfaStatus}
          isOpen={deleteMfaModalOpen}
          onClose={onDeleteMfaModalClose}
        />
      )}
      <CreateMfaModal
        isOpen={createMfaModalOpen}
        onClose={onCreateMfaModalClose}
        verificationMethod={verificationMethod}
        value={defaultValue ?? ''}
      />
    </>
  );
};
