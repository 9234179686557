import { ClientInformationIF } from '@telia-company/tv.common-sdk/dist/abstractions/client/ClientInformationIF';
import { country, environment } from 'environment';

ClientInformationIF.implement(() => ({
  getClientInformation: async () => {
    return {
      client: 'ACCOUNTWEB',
      country: country,
      environment: environment === 'PILOT' ? 'STAGE' : 'PROD',
      name: process.env.REACT_APP_NAME || '<unknown>',
      version: process.env.REACT_APP_VERSION || '<unknown>',
      whiteLabelBrand: 'TELIA',
    };
  },
}));
