import styled from '@emotion/styled';
import { headingPrimary, textPrimary } from 'theme/theme.utils';
import { Button, Icon, mq } from '@telia-company/tv.oneapp-web-ui';
import { PinField } from '.';

export const StyledForm = styled.form`
  text-align: center;
  max-width: 312px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  margin-bottom: 200px;
`;

export const StyledHeader = styled.h1`
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  ${({ theme }) => headingPrimary(theme)}
  text-align: left;
  padding: 16px;
  ${mq.S`
    margin-top: 64px;
    text-align: center;
  `}
`;

export const StyledLabel = styled.label`
  text-align: left;
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  ${({ theme }) => textPrimary(theme)};
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;

export const StyledPinField = styled(PinField)`
  margin-top: 8px;
`;

export const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  ${({ theme }) => textPrimary(theme)}
`;

export const StyledErrorIcon = styled(Icon)`
  margin-right: 8px;
  width: 16px;
`;
