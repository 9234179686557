import { oneAppUrl } from 'utils';
import { FooterDataType } from '../Footer';
import { ColumnLink } from '../column';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';

export const footerTeliaNorwegian = (appLinks: ColumnLink[]): FooterDataType => ({
  copyrightText: ['© Telia · Del av Telia Company', 'Org. nr. 981 929 055'],
  footerColumns: [
    {
      heading: 'Telia Play',
      linkList: [...appLinks],
    },
    {
      heading: 'Informasion',
      linkList: [
        {
          link: `${Config.get('one-app-telia-url')}/kontakt-oss/`,
          name: 'Kontakt Telia',
        },
        {
          link: oneAppUrl('/about'),
          name: 'Om tjenesten',
        },
        {
          link: Config.get('support-web-url'),
          name: 'Kundeservice',
        },
        {
          link: oneAppUrl('/privacy-policy'),
          name: 'Personvernerklæring',
        },
        {
          link: oneAppUrl('/cookie-policy'),
          name: 'Informasjonskapsler',
        },
        {
          link: '',
          name: 'Innstillinger for informasjonskapsler',
          type: 'button',
        },
      ],
    },
  ],
});
