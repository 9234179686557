import { useSnackbar } from '@telia-company/tv.oneapp-web-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useSnackbars = () => {
  const createSnackbar = useSnackbar();
  const { t } = useTranslation();

  const createPositiveSnackbar = useCallback(
    (content: React.ReactText | React.ReactNode) => {
      createSnackbar({
        autoDismiss: true,
        variant: 'positive',
        content,
        dismissOnActionClick: true,
        actionButtonText: t('SNACKBAR_DISMISS_LABEL'),
      });
    },
    [createSnackbar, t],
  );

  const createNegativeSnackbar = useCallback(
    (content: React.ReactText | React.ReactNode) => {
      createSnackbar({
        autoDismiss: true,
        variant: 'negative',
        content,
        dismissOnActionClick: true,
        actionButtonText: t('SNACKBAR_DISMISS_LABEL'),
      });
    },
    [createSnackbar, t],
  );

  const createNeutralSnackbar = useCallback(
    (content: React.ReactText | React.ReactNode) => {
      createSnackbar({
        autoDismiss: true,
        variant: 'neutral',
        content,
        dismissOnActionClick: true,
        actionButtonText: t('SNACKBAR_DISMISS_LABEL'),
      });
    },
    [createSnackbar, t],
  );

  return {
    createPositiveSnackbar,
    createNegativeSnackbar,
    createNeutralSnackbar,
  };
};
