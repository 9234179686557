import styled from '@emotion/styled';
import { Button, mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledScoreTableContainer = styled.div`
  --max-height: calc(100vh - 32px);
  @supports (height: 100dvh) {
    --max-height: calc(100dvh - 32px);
  }

  cursor: pointer;
  background-color: ${({ theme }) => theme.designTokens.color.background.tertiary};
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  padding: 16px;
  z-index: 100;
  margin-left: -16px;
  margin-right: -16px;
  width: 100%;
  height: max-content;

  max-height: var(--max-height);

  box-shadow: 0px -8px 16px 2px rgba(0, 0, 0, 0.7);

  border-top: 1px solid rgba(255, 255, 255, 0.2);
  position: fixed;
  top: 100vh;
  top: 100dvh;
  touch-action: none;

  ${mq.S`
    width: 100%;
    left: 16px;
  `}

  ${mq.L`
    cursor: default;
    box-shadow: none;
    z-index: 2;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    position: sticky;
    max-height: calc(100vh - 192px); /* some margin to the bottom */
    top: 64px;
    bottom: auto;
    border-top: none;
    max-width: 392px;
    padding: 24px;
  `}
`;

export const StyledExpandContractScoreTableButton = styled(Button)`
  width: 40px;
  height: 40px;

  &:focus,
  &:hover {
    background: transparent;
  }

  border-radius: 100vw;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
