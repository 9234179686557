import { Icon } from '@telia-company/tv.oneapp-web-ui';

import {
  IconWrapper,
  StyledLogoContainer,
  StyledTeliaLogoContainer,
  StyledLogoLink,
} from './LogoFooter.styles';

type Props = {
  href: string;
};

export const LogoFooter = ({ href, ...props }: Props) => {
  return (
    <StyledLogoContainer {...props}>
      <IconWrapper aria-label="Telia">
        <StyledTeliaLogoContainer>
          <StyledLogoLink href={href} aria-label="Telia">
            <Icon name="LogoTeliaWhite" />
          </StyledLogoLink>
        </StyledTeliaLogoContainer>
      </IconWrapper>
    </StyledLogoContainer>
  );
};
