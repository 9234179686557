import { DataManager } from '@telia-company/tv.common-sdk/dist/DataManager';
import { ServiceConfig } from '@telia-company/tv.common-sdk/dist/ServiceConfig';

export type SubscriptionTreeItem = {
  id: string;
  name: string;
  iconUrl?: string;
  subscriptionIds: string[];
};

type SubscriptionsResponse = {
  subscriptions: SubscriptionTreeItem[];
};

type getSubscriptionsProps = {
  cacheClass: string;
};

export const getSubscriptions = async ({
  cacheClass,
}: getSubscriptionsProps): Promise<SubscriptionsResponse> => {
  const dataManager = DataManager.instance();
  const cvsGateway = ServiceConfig.getService('cvsgateway');
  const url = cvsGateway.getUrl('/subscriptions/v1');
  return (await dataManager.sendGet(url, cacheClass)) as SubscriptionsResponse;
};
