import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  StyledButton,
  StyledContainer,
  StyledIcon,
  StyledTitle,
} from './SomethingWentWrong.styles';

type SomethingWentWrongProps = {
  retry: () => void;
};
export const SomethingWentWrong = ({ retry }: SomethingWentWrongProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const locationRef = useRef(location);

  useEffect(() => {
    if (locationRef.current !== location) {
      retry();
      locationRef.current = location;
    }
  }, [location, locationRef, retry]);

  return (
    <StyledContainer>
      <StyledIcon name="Warning" />
      <StyledTitle>{t('COMMON_ERROR')}</StyledTitle>
      <StyledButton variant="secondary" onClick={() => retry()}>
        {t('COMMON_BUTTON_RETRY')}
      </StyledButton>
    </StyledContainer>
  );
};
