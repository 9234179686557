import { StyledSubSectionLabel } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { WobbleSwitch } from '..';
import {
  StyledChangePinCodeButton,
  StyledInputGroupShort,
  StyledSubSectionContainerShort,
  StyledSubSectionShort,
} from './PinCodeSubSection.styles';

type PinCodeSubSectionProps = {
  loading?: boolean;
  label: string;
  pinCodeState: boolean;
  onChangePinCodeState: (state: boolean) => void;
  canChangePinCode: boolean;
  changePinCodeLabel?: string;
  onChangePinCode?: () => void;
};

export const PinCodeSubSection = ({
  label,
  pinCodeState,
  changePinCodeLabel,
  canChangePinCode,
  onChangePinCode,
  loading,
  onChangePinCodeState,
}: PinCodeSubSectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledSubSectionContainerShort>
        <StyledInputGroupShort>
          <StyledSubSectionShort>
            <StyledSubSectionLabel>{label}</StyledSubSectionLabel>
            <WobbleSwitch
              value={pinCodeState}
              onValueChange={onChangePinCodeState}
              disabled={loading}
            />
          </StyledSubSectionShort>
        </StyledInputGroupShort>
      </StyledSubSectionContainerShort>
      {onChangePinCode && canChangePinCode && (
        <StyledChangePinCodeButton
          variant="ghost"
          onClick={onChangePinCode}
          isLoading={loading}
          disabled={loading}
        >
          {changePinCodeLabel || t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}
        </StyledChangePinCodeButton>
      )}
    </>
  );
};
