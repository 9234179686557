import { gql } from '@apollo/client';

export const GET_MAIN_MENU_QUERY = gql`
  query getMainMenu {
    mainMenu {
      items {
        ...PageLink
      }
    }
  }

  fragment PageLink on PageLink {
    name
    id
  }
`;
