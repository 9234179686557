import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { environment, country } from 'environment';
import { isDev } from 'utils';

export const init = async () => {
  const configs = await import(
    `./config.${country.toLowerCase()}.${environment.toLowerCase()}.telia`
  );
  Config.setConfigs(configs.default);

  if (isDev()) {
    Config.set('common-sdk/Log/logLevel', 'INFO');
  }
};
