import styled from '@emotion/styled';
import { textPrimaryEmphasis } from 'theme/theme.utils';

export const StyledItemState = styled.div`
  ${({ theme }) => textPrimaryEmphasis(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  margin-top: 8px;
`;

export const StyledItemDescription = styled.div`
  margin-top: 8px;
`;

export const StyledLogo = styled.img`
  height: 48px;
`;
