import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePinModal, BasePinModalOnResponse, BasePinModalGetValidationError } from '..';

type ChangePinModalProps = {
  isOpen: boolean;
  validatePinCode?: (pinCode: string) => Promise<boolean>;
  onResponse: BasePinModalOnResponse;
  showCancel?: boolean;
  customButtons?: ReactNode;
};

export const ChangePinModal = ({
  isOpen,
  validatePinCode,
  onResponse,
  showCancel = true,
  customButtons,
}: ChangePinModalProps) => {
  const { t } = useTranslation();

  const getValidationError: BasePinModalGetValidationError = useCallback(
    async (pinCode) => {
      if (!validatePinCode) {
        return;
      }
      const validateOk = await validatePinCode(pinCode);
      if (!validateOk) {
        return t('SETTINGS_LOCKER_WRONG_PIN_ERROR');
      }
    },
    [t, validatePinCode],
  );

  return (
    <BasePinModal
      isOpen={isOpen}
      title={t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}
      testid={'SETTINGS_LOCKER_CHANGE_PIN_CODE'}
      information={t('SETTINGS_LOCKER_ENTER_CURRENT_PIN_CODE')}
      confirmLabel={t('SETTINGS_LOCKER_SET_PIN_CODE_BUTTON_NEXT')}
      getValidationError={validatePinCode ? getValidationError : undefined}
      onResponse={onResponse}
      showCancel={showCancel}
      customButtons={customButtons}
    />
  );
};
