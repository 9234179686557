import { Country } from 'environment';

export const getPhoneNumberPlaceholder = (country: Country) => {
  switch (country) {
    case 'DK':
      return '+4512345678';
    case 'SE':
      return '+46701234567';
    case 'NO':
      return '+4791234567';
    case 'FI':
      return '+358401234567';
  }
};
