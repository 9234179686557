import * as purchaseGateway from './backend/purchaseGateway';

const cacheClass = 'mediaPrepaidService';

export type MediaPrepaid = {
  id: string;
  quantity: number;
  start: Date;
  end: Date;
  timesUsed: number;
};

export const getMediaPrepaids = async (): Promise<MediaPrepaid[]> => {
  const prepaids = await purchaseGateway.getMediaPrepaids({ cacheClass });
  return prepaids.map((prepaid) => ({
    id: prepaid.id,
    quantity: prepaid.quantity,
    start: new Date(prepaid.startTime),
    end: new Date(prepaid.endTime),
    timesUsed: prepaid.timeUsed,
  }));
};
