import { PreferredRegionalChannels } from '@telia-company/tv.common-sdk-legacy-pack/dist/PreferredRegionalChannels';
import { GetRegionalChannelsQuery } from 'gql/generated';
import * as commonGraph from './backend/commonGraph';

export type RegionalChannel = {
  id: string;
  region: string;
};

export type Channel = {
  id: string;
  name: string;
  regionalChannels: RegionalChannel[];
};

const convertChannel = ({
  id,
  name,
  regionalChannels,
}: NonNullable<GetRegionalChannelsQuery['regionalChannels']>['items'][number]): Channel => {
  const convertedRegionalChannels =
    regionalChannels.map(({ id, regionName }) => ({
      id,
      region: regionName,
    })) || [];

  return {
    id,
    name,
    regionalChannels: convertedRegionalChannels,
  };
};

export const getRegionalChannels = async (channelIds: string[]): Promise<Channel[]> => {
  const result = await commonGraph.getRegionalChannels(channelIds);
  return result.data.regionalChannels?.items.map(convertChannel) || [];
};

export const setRegionalChannel = async (channelId: string, regionalChannelId: string) => {
  const preferredRegionalChannels = PreferredRegionalChannels.instance();
  const regionalChannels = await preferredRegionalChannels.getPreferredRegionalChannels();
  regionalChannels[channelId] = regionalChannelId;
  await preferredRegionalChannels.setPreferredRegionalChannels(regionalChannels);
};
