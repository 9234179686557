import {
  FormEvent,
  FocusEvent,
  KeyboardEvent,
  RefObject,
  useRef,
  forwardRef,
  useImperativeHandle,
  ClipboardEventHandler,
} from 'react';
import { StyledContainer, StyledInput } from './PinField.styles';

export type PinFieldHandle = {
  clear: () => void;
  focus: () => void;
};

export type PinFieldChangeEvent = {
  value: string;
  lastIndexChanged: number;
};

type PinFieldProps = {
  className?: string;
  onChange?: (event: PinFieldChangeEvent) => void;
  autoFocus?: boolean;
};

export const PinField = forwardRef<PinFieldHandle, PinFieldProps>(
  ({ className, onChange, autoFocus }, ref) => {
    const inputRefs: RefObject<HTMLInputElement>[] = [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ];

    const onInput = (index: number, { target }: FormEvent<HTMLInputElement>) => {
      // Somehow typescript doesn't recognize the target as a given HTMLInputElement
      if (!(target instanceof HTMLInputElement)) {
        return;
      }

      if (target.value && index < inputRefs.length - 1) {
        inputRefs[index + 1].current?.focus();
      }

      onChange?.({
        value: inputRefs.map(({ current }) => current?.value).join(''),
        lastIndexChanged: index,
      });
    };

    const onKeyDown = (index: number, { key, target }: KeyboardEvent<HTMLInputElement>) => {
      // Somehow typescript doesn't recognize the target as a given HTMLInputElement
      if (!(target instanceof HTMLInputElement)) {
        return;
      }

      if (key === 'Backspace' && !target.value && index > 0) {
        inputRefs[index - 1].current?.focus();
      }
    };

    const onFocus = (index: number, { target }: FocusEvent<HTMLInputElement>) => {
      target.select();
    };

    const onPaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
      event.preventDefault();
      const paste = event.clipboardData?.getData('text');
      if (paste?.length === inputRefs.length) {
        paste.split('').forEach((char, index) => {
          inputRefs[index].current!.value = char;
        });
        onChange?.({
          value: paste,
          lastIndexChanged: paste.length - 1,
        });

        inputRefs[paste.length - 1].current?.focus();
      }
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        inputRefs.forEach((ref) => {
          if (ref.current) {
            ref.current.value = '';
          }
        });
      },
      focus: () => {
        inputRefs[0].current?.focus();
      },
    }));

    return (
      <StyledContainer className={className}>
        {inputRefs.map((ref, index) => (
          <StyledInput
            autoFocus={autoFocus && index === 0}
            type="text"
            inputMode="numeric"
            pattern="[0-9]"
            key={index}
            maxLength={1}
            onFocus={(event) => onFocus(index, event)}
            onInput={(event) => onInput(index, event)}
            onKeyDown={(event) => onKeyDown(index, event)}
            ref={ref}
            onPaste={onPaste}
          />
        ))}
      </StyledContainer>
    );
  },
);
