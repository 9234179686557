export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AddToMyListResult: ['AddToMyListResultGeneralError', 'AddToMyListResultOk'],
    AddToUserChannelListResult: [
      'AddToUserChannelListResultGeneralError',
      'AddToUserChannelListResultOk',
    ],
    AllProgress: ['Progress', 'TimeProgress'],
    AssumeProfileResult: ['AssumeProfileResultOk', 'AssumeProfileResultWrongPinCodeError'],
    Capabilities: ['CapabilityGroup', 'ClientCapability', 'MutationCapability'],
    ChannelDisplayHint: ['LiveOnlyChannelDisplayHint', 'NormalChannelDisplayHint'],
    ContinueWatchingListContent: ['Episode', 'Movie', 'SportEvent'],
    ContinueWatchingPanelItemContent: ['Episode', 'Movie', 'SportEvent'],
    CreateProfileResult: [
      'CreateProfileResultGeneralError',
      'CreateProfileResultMaxNumberOfProfilesError',
      'CreateProfileResultOk',
      'CreateProfileResultThemeNotAllowedError',
    ],
    DeleteProfileResult: [
      'DeleteProfileResultCannotDeleteActiveProfileError',
      'DeleteProfileResultGeneralError',
      'DeleteProfileResultOk',
    ],
    DeleteRecordingResult: ['DeleteRecordingResultError', 'DeleteRecordingResultOk'],
    DisplayHint: ['DisplayHintGrid', 'DisplayHintList', 'DisplayHintSwimlane'],
    Error: [
      'AddToMyListResultGeneralError',
      'AddToUserChannelListResultGeneralError',
      'AssumeProfileResultWrongPinCodeError',
      'CreateProfileResultGeneralError',
      'CreateProfileResultMaxNumberOfProfilesError',
      'CreateProfileResultThemeNotAllowedError',
      'DeleteProfileResultCannotDeleteActiveProfileError',
      'DeleteProfileResultGeneralError',
      'DeleteRecordingResultError',
      'RemoveFromMyListResultGeneralError',
      'RemoveFromUserChannelListResultGeneralError',
      'RemoveListFromContinueWatchingResultGeneralError',
      'ScheduleRecordingResultError',
      'UnscheduleRecordingResultError',
      'UpdateChildLockResultMalformedPinCodeError',
      'UpdateChildLockResultNoPinCodeAllowedError',
      'UpdateProfileResultGeneralError',
      'UpdateProfileResultProfileDoesNotExistError',
      'UpdateProfileResultThemeNotAllowedError',
    ],
    Label: ['BasicLabel', 'TimeSensitiveLabel'],
    LinkInterface: [
      'LinkChannel',
      'LinkDeep',
      'LinkEpisode',
      'LinkMovie',
      'LinkPage',
      'LinkPanel',
      'LinkPlay',
      'LinkSeries',
      'LinkSportEvent',
      'LinkSportEventList',
      'LinkStatic',
      'LinkStore',
    ],
    Media: ['Episode', 'Movie', 'SportEvent'],
    MediaImages: ['EpisodeImages', 'MovieImages', 'SeriesImages', 'SportEventImages'],
    MediaPanelItemContent: ['Episode', 'Movie', 'Series', 'SportEvent'],
    MediasAndSeriesContent: ['Episode', 'Movie', 'Series', 'SportEvent'],
    MetadataItem: [
      'MetadataBadgeIMDB',
      'MetadataItemIcon',
      'MetadataItemString',
      'MetadataTextBadge',
      'TimeSensitiveMetadataItemIcon',
    ],
    MetadataItemAgeRatingContent: ['MetadataItemIcon', 'MetadataItemString'],
    MiniBlock: ['QuotaInformationMiniBlock'],
    MyListContent: ['Movie', 'Series', 'SportEvent'],
    MyListPanelItemContent: ['Movie', 'Series', 'SportEvent'],
    NestedCapabilities: ['MutationCapability'],
    Panel: [
      'ChannelsPanel',
      'ContinueWatchingPanel',
      'HotspotLoggedOutPanel',
      'LeaguePanel',
      'MediaPanel',
      'MyListPanel',
      'PageLinkPanel',
      'RecordingsPanel',
      'RentalsPanel',
      'SelectionMediaPanel',
      'ShowcasePanel',
      'SingleFeaturePanel',
      'SingleFeaturePanelV2',
      'StaticNetflixPanel',
      'StaticRecentSearchesPanel',
      'StoresPanel',
      'SubscriptionPanel',
      'TimelinePanel',
    ],
    PanelX: [
      'ChannelsPanelX',
      'HotspotLoggedOutPanelX',
      'PosterListPanel',
      'ShowcasePanelX',
      'SingleFeaturePanelX',
      'StaticNetflixPanelX',
      'SubscriptionPanelX',
    ],
    PlayerRelatedContentPanel: ['TimelinePanel'],
    PosterCapability: [
      'ClientCapability',
      'LinkCapability',
      'MutationCapability',
      'PlayableLinkCapability',
    ],
    ProgramMedia: ['Episode', 'Movie', 'SportEvent'],
    RecordingListContent: ['Episode', 'Movie', 'Series', 'SportEvent'],
    RecordingResultMedia: ['Episode', 'Movie', 'Series', 'SportEvent'],
    RefreshMediaMedia: ['Episode', 'Movie', 'Series', 'SportEvent'],
    RemoveFromMyListResult: ['RemoveFromMyListResultGeneralError', 'RemoveFromMyListResultOk'],
    RemoveFromUserChannelListResult: [
      'RemoveFromUserChannelListResultGeneralError',
      'RemoveFromUserChannelListResultOk',
    ],
    RemoveListFromContinueWatchingResult: [
      'RemoveListFromContinueWatchingResultGeneralError',
      'RemoveListFromContinueWatchingResultOk',
    ],
    RentalMedia: ['Episode', 'Movie'],
    RentalsPanelItemContent: ['Movie', 'Series'],
    ScheduleRecordingResult: ['ScheduleRecordingResultError', 'ScheduleRecordingResultOk'],
    SearchResultContent: ['Episode', 'Movie', 'Series', 'SportEvent'],
    ShowcasePromotionContent: ['Channel', 'Episode', 'Movie', 'Series', 'SportEvent'],
    SingleFeaturePanelContent: ['Movie', 'Series', 'SportEvent'],
    SingleFeaturePanelMedia: ['Movie', 'Series', 'SportEvent'],
    SportEventListSectionItemContent: ['Movie', 'Series', 'SportEvent'],
    SubscriptionInfoLink: ['HttpLink', 'LinkStatic'],
    SubscriptionProduct: [
      'SubscriptionProductDualEntry',
      'SubscriptionProductExternalDualEntry',
      'SubscriptionProductExternalFallback',
      'SubscriptionProductExternalFieldSales',
      'SubscriptionProductExternalIAP',
      'SubscriptionProductExternalPartnerSales',
      'SubscriptionProductExternalStaff',
      'SubscriptionProductExternalStandard',
      'SubscriptionProductExternalTVE',
      'SubscriptionProductFallback',
      'SubscriptionProductFieldSales',
      'SubscriptionProductIAP',
      'SubscriptionProductPartnerSales',
      'SubscriptionProductSelection',
      'SubscriptionProductStaff',
      'SubscriptionProductStandard',
      'SubscriptionProductTVE',
    ],
    SubscriptionsDisplay: ['SubscriptionInfo', 'SubscriptionList'],
    TimeSensitiveLabelContent: ['BasicLabel', 'DatePatternLabel'],
    TimelinePanelItemContent: ['Episode', 'Movie', 'SportEvent'],
    UnscheduleRecordingResult: ['UnscheduleRecordingResultError', 'UnscheduleRecordingResultOk'],
    UpdateChildLockResult: [
      'UpdateChildLockResultMalformedPinCodeError',
      'UpdateChildLockResultNoPinCodeAllowedError',
      'UpdateChildLockResultOk',
    ],
    UpdateProfileResult: [
      'UpdateProfileResultGeneralError',
      'UpdateProfileResultOk',
      'UpdateProfileResultProfileDoesNotExistError',
      'UpdateProfileResultThemeNotAllowedError',
    ],
  },
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: any;
};

export type AccountProvider = {
  __typename?: 'AccountProvider';
  description: Scalars['String'];
  link?: Maybe<AccountProviderLink>;
  title: Scalars['String'];
};

export type AccountProviderLink = {
  __typename?: 'AccountProviderLink';
  text: Scalars['String'];
  url: Scalars['String'];
};

export type AddToMyListInput = {
  id: Scalars['String'];
  type: MyListIdType;
};

export type AddToMyListResult = AddToMyListResultGeneralError | AddToMyListResultOk;

export type AddToMyListResultGeneralError = Error & {
  __typename?: 'AddToMyListResultGeneralError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type AddToMyListResultOk = {
  __typename?: 'AddToMyListResultOk';
  content?: Maybe<MyListContent>;
  refreshMedias?: Maybe<RefreshMedias>;
};

export type AddToUserChannelListResult =
  | AddToUserChannelListResultGeneralError
  | AddToUserChannelListResultOk;

export type AddToUserChannelListResultGeneralError = Error & {
  __typename?: 'AddToUserChannelListResultGeneralError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type AddToUserChannelListResultOk = {
  __typename?: 'AddToUserChannelListResultOk';
  channelId: Scalars['ID'];
};

export enum AgeGroup {
  Adult = 'ADULT',
  Child = 'CHILD',
  Child0_6 = 'CHILD0_6',
}

export type AgeRating = {
  __typename?: 'AgeRating';
  content?: Maybe<MetadataItemAgeRatingContent>;
  number: Scalars['Int'];
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-09.md Use content instead */
  readable: Scalars['String'];
};

export type AgeRatingReadableArgs = {
  input?: InputMaybe<AgeRatingInput>;
};

export type AgeRatingInput = {
  type?: InputMaybe<AgeRatingReadableType>;
};

export enum AgeRatingReadableType {
  /** E.g. "Lämplig från 11 år". */
  Long = 'LONG',
  /** E.g. "7+". */
  Short = 'SHORT',
}

export type AirDate = {
  __typename?: 'AirDate';
  endDate: DateTime;
  startDate: DateTime;
};

export type AirtimeLabel = {
  __typename?: 'AirtimeLabel';
  /** Whether the broadcast is ongoing or not, at the time of fetching. */
  isBroadcasting: Scalars['Boolean'];
  /**
   * Broadcast in real-time, as events happen, in the present.
   * E.g. a live broadcasted soccer game.
   * Not to be confused with if this content currently available.
   * This is information given to use by the provider and
   * content may be lacking this live flag, even if it is indeed live.
   * Only use this to decorate the UI, not to do logical decisions.
   */
  isLive: Scalars['Boolean'];
  text: Scalars['String'];
};

export type AllProgress = Progress | TimeProgress;

export type Announcement = {
  __typename?: 'Announcement';
  text: Scalars['String'];
  type: AnnouncementType;
};

export enum AnnouncementType {
  Helpful = 'HELPFUL',
  Important = 'IMPORTANT',
}

export type AssetAnalytics = {
  __typename?: 'AssetAnalytics';
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-10-15.md Use tracking_id instead. */
  content_media_id?: Maybe<Scalars['String']>;
  tracking_id?: Maybe<Scalars['String']>;
};

export type AssumeProfileInput = {
  id: Scalars['ID'];
  pinCode?: InputMaybe<Scalars['String']>;
};

export type AssumeProfileResult = AssumeProfileResultOk | AssumeProfileResultWrongPinCodeError;

export type AssumeProfileResultOk = {
  __typename?: 'AssumeProfileResultOk';
  profileToken: Scalars['String'];
};

export type AssumeProfileResultWrongPinCodeError = Error & {
  __typename?: 'AssumeProfileResultWrongPinCodeError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Availability = {
  __typename?: 'Availability';
  from?: Maybe<AvailabilityInfo>;
  to?: Maybe<AvailabilityInfo>;
};

export type AvailabilityInfo = {
  __typename?: 'AvailabilityInfo';
  /** ISO 8601 DateTime */
  isoString: Scalars['String'];
  /**
   * Milliseconds until this period in time.
   * NOTE:
   * The largest value a INT field can present is (2^31) - 1, if the actual value is larger than this, (2^31) - 1 will be returned.
   * The smallest value INT field can present is -(2^31), if the actual value is smaller than this, -(2^31) will be returned.
   * @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-04-21.md Please use sTo
   */
  msTo: Scalars['Int'];
  sTo: Scalars['Int'];
  /**
   * Text to display to user.
   * NOTE: When available to is more than 3 months from now, null will be returned for availability.to.text
   */
  text?: Maybe<Scalars['String']>;
  /** Millisecond based epoch timestamp */
  timestamp: Scalars['Timestamp'];
};

export type Avatar = {
  __typename?: 'Avatar';
  altText: Scalars['String'];
  /** @deprecated No longer used */
  body: Image;
  head: Image;
  id: Scalars['ID'];
  square: Image;
};

export type Badges = {
  __typename?: 'Badges';
  price?: Maybe<Price>;
  promotedSubscription?: Maybe<PromotedSubscriptionBadge>;
  purchased?: Maybe<PurchasedBadge>;
  rented?: Maybe<RentedBadge>;
  surroundSound?: Maybe<SurroundSoundBadge>;
  uhd?: Maybe<UhdBadge>;
};

export type BasicLabel = {
  __typename?: 'BasicLabel';
  basicLabelType: LabelRenderType;
  text: Scalars['String'];
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2023-03-06.md Use basicLabelType instead */
  type: LabelRenderType;
};

export type Capabilities = CapabilityGroup | ClientCapability | MutationCapability;

export type CapabilityGroup = {
  __typename?: 'CapabilityGroup';
  capabilities?: Maybe<Array<NestedCapabilities>>;
  description?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  icon: MetadataItemIcon;
  text: Scalars['String'];
};

export type CdpAnalytics = {
  __typename?: 'CdpAnalytics';
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-10-16.md Analysis not interested in this. */
  content_asset_category?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-05-07.md The graph isn't reliable here, clients must provide this data. */
  content_asset_playback?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-10-16.md Analysis not interested in this. */
  content_asset_title?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-10-16.md Analysis not interested in this. */
  content_asset_type?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-10-16.md Use tracking_id instead. */
  content_media_id?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-10-16.md Analysis not interested in this. */
  content_series_title?: Maybe<Scalars['String']>;
  page_name?: Maybe<Scalars['String']>;
  tracking_id?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-10-16.md Analysis not interested in this. */
  trailer?: Maybe<Scalars['Boolean']>;
};

export type CdpData = {
  __typename?: 'CdpData';
  analytics?: Maybe<CdpAnalytics>;
  announcement?: Maybe<Announcement>;
  backdrop?: Maybe<Image>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-11-25.md  Use capabilities2 instead. */
  capabilities?: Maybe<Array<PosterCapability>>;
  capabilities2?: Maybe<Array<Capabilities>>;
  description?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Label>>;
  metadataRow?: Maybe<Array<MetadataItem>>;
  metadataRowThree?: Maybe<Array<MetadataItem>>;
  metadataRowTwo?: Maybe<Array<MetadataItem>>;
  metadataTable?: Maybe<Array<MetadataTable>>;
  /** If the current media is an UHD media, this field contains information to the customer on how to play it in UHD */
  playInfo?: Maybe<Scalars['String']>;
  playback?: Maybe<Playback>;
  provider?: Maybe<Provider>;
  purchaseInfo?: Maybe<Scalars['String']>;
  relatedPanels?: Maybe<RelatedPanels>;
  siblingInfo?: Maybe<CdpSiblingInfo>;
  title: Scalars['String'];
  upcomingEpisode?: Maybe<UpcomingEpisode>;
};

export type CdpPrefetch = {
  __typename?: 'CdpPrefetch';
  backdropImage?: Maybe<Image>;
};

/** https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-08-28.md */
export type CdpSiblingInfo = {
  __typename?: 'CdpSiblingInfo';
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  items: Array<CdpSiblingItem>;
};

export type CdpSiblingItem = {
  __typename?: 'CdpSiblingItem';
  a11y?: Maybe<CdpSiblingItemA11y>;
  heading: Scalars['String'];
  icon?: Maybe<Icon>;
  link: SelectableLink;
  metadataRow?: Maybe<Array<MetadataItem>>;
  metadataRowTwo?: Maybe<Array<MetadataItem>>;
};

export type CdpSiblingItemIconArgs = {
  imageTypePrio?: InputMaybe<Array<ImageContentTypePrio>>;
};

export type CdpSiblingItemA11y = {
  __typename?: 'CdpSiblingItemA11y';
  label: Scalars['String'];
};

export type Channel = {
  __typename?: 'Channel';
  analytics: AssetAnalytics;
  badges: ChannelBadges;
  channelNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayHint: ChannelDisplayHint;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-01-12.md Use icons2 instead */
  icons: Icons;
  icons2: ChannelIcons;
  id: Scalars['ID'];
  inEngagement: Scalars['Boolean'];
  isFavorite: Scalars['Boolean'];
  labels: ChannelLabels;
  name: Scalars['String'];
  playback: ChannelPlayback;
  /** If fillGaps is explicitly set to true, program.endTime will be extended until the next programs startTime */
  programs: Programs;
  programsByTime: Programs;
  /**
   * Contains a collection of *all* available programs for the channel.
   * The amount of data available differs between white labels. Telia typically has
   * a few days of passed programs and a week of future programs. C More has less.
   *
   * Difference between this and the 'programs' field is that this field will
   *
   * * seamlessly give programs for the next day (and the day after that and after that...)
   * whereas 'programs' will only give you data until end of the day the timestamp belongs to.
   * * allow for negative offsets. E.g. say that "I want the 10 items before <timestamp>"
   */
  programsTimetable: ProgramsTimetable;
  /**
   * Applies to NPvr record and watch channels. The program has to be recorded to be able to watch it.
   * If a channel is blocked from beeing viewed live, recordAndWatch is explicitly set to true.
   */
  recordAndWatch: Scalars['Boolean'];
  regionalChannels?: Maybe<RegionalChannels>;
  userData?: Maybe<ChannelUserData>;
};

export type ChannelProgramsArgs = {
  fillGaps?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['Timestamp']>;
};

export type ChannelProgramsByTimeArgs = {
  input: ProgramByTimeInput;
};

export type ChannelRegionalChannelsArgs = {
  input?: InputMaybe<RegionalChannelsArgs>;
};

export enum ChannelAccessFilter {
  All = 'ALL',
  HasAccess = 'HAS_ACCESS',
}

export type ChannelBadges = {
  __typename?: 'ChannelBadges';
  promotedSubscription?: Maybe<PromotedSubscriptionBadge>;
};

export type ChannelBuySubscription = {
  __typename?: 'ChannelBuySubscription';
  item: SubscriptionProduct;
};

export type ChannelDisplayHint = LiveOnlyChannelDisplayHint | NormalChannelDisplayHint;

export type ChannelIcons = {
  __typename?: 'ChannelIcons';
  compact: Icons;
  large: Icons;
};

export type ChannelLabels = {
  __typename?: 'ChannelLabels';
  /** Typically rendered in the top left corner of a poster */
  primary?: Maybe<Label>;
  /** Typically rendered in the top right corner of a poster */
  secondary?: Maybe<Label>;
};

export type ChannelMetadata = {
  __typename?: 'ChannelMetadata';
  badges: ChannelBadges;
  channelNumber?: Maybe<Scalars['Int']>;
  icons2: ChannelIcons;
  id: Scalars['ID'];
  inEngagement: Scalars['Boolean'];
  isFavorite: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ChannelMetadataList = {
  __typename?: 'ChannelMetadataList';
  items: Array<ChannelMetadata>;
};

export type ChannelMetadataListInput = {
  /**
   * Either filter channels base on user engagement or not.
   * If not supplied, the default value for Telia is **HAS_ACCESS**, and the default value for C More is **ALL**.
   */
  userAccessFilter?: InputMaybe<ChannelAccessFilter>;
};

export type ChannelPlayback = {
  __typename?: 'ChannelPlayback';
  buy?: Maybe<ChannelPlaybackBuy>;
  play?: Maybe<ChannelPlaybackPlay>;
};

export type ChannelPlaybackBuy = {
  __typename?: 'ChannelPlaybackBuy';
  /**
   * If channel is not in engagement, this will list the subscriptions needed to be able
   * to play it.
   */
  subscriptions?: Maybe<Array<ChannelBuySubscription>>;
};

export type ChannelPlaybackPlay = {
  __typename?: 'ChannelPlaybackPlay';
  /** If channel is in engagement, this will provide the playback spec needed to play it */
  playbackSpec: PlaybackSpec;
};

export type ChannelUserData = {
  __typename?: 'ChannelUserData';
  /** @deprecated Use Channel.inEngagement instead */
  inEngagement: Scalars['Boolean'];
};

export type Channels = {
  __typename?: 'Channels';
  channelItems: Array<Channel>;
  epgDateList: EpgDateList;
  pageInfo: PageInfo;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead. */
  trackingItems: Array<TrackingItem>;
};

export type ChannelsPanel = Panel & {
  __typename?: 'ChannelsPanel';
  channels: Channels;
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  /** @deprecated Please use showMore */
  link?: Maybe<PanelLink>;
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

export type ChannelsPanelChannelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ChannelsPanelX = {
  __typename?: 'ChannelsPanelX';
  analytics: PanelAnalytics;
  channelsContent?: Maybe<Channels>;
  id: Scalars['ID'];
  kicker?: Maybe<Scalars['String']>;
  refreshEvents?: Maybe<Array<RefreshEvent>>;
  showMore?: Maybe<SelectableLink>;
  title?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-20.md Use analytics field instead. */
  trackingData: Scalars['String'];
};

export type ChannelsPanelXChannelsContentArgs = {
  config?: InputMaybe<PosterListPanelInput>;
};

export type ChildLock = {
  __typename?: 'ChildLock';
  enabled: Scalars['Boolean'];
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-18.md No longer used */
  pinCode?: Maybe<Scalars['String']>;
};

export type ClientCapability = PosterCapability & {
  __typename?: 'ClientCapability';
  clientCapabilityType: ClientCapabilityType;
  id: Scalars['ID'];
  text: Scalars['String'];
};

export enum ClientCapabilityType {
  Download = 'DOWNLOAD',
  RecentSearchesRemove = 'RECENT_SEARCHES_REMOVE',
  RecentSearchesRemoveAll = 'RECENT_SEARCHES_REMOVE_ALL',
}

/** Holds information that should be used for pagination. */
export type CollectionPageInfo = {
  __typename?: 'CollectionPageInfo';
  /** Number of items that exists, after to the current results */
  nextItems: Scalars['Int'];
  /** Number of items that exists, previous to the current results */
  previousItems: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Color = {
  __typename?: 'Color';
  hex: Scalars['String'];
  rgb: Array<Scalars['Int']>;
};

export type ConfirmationDialog = {
  __typename?: 'ConfirmationDialog';
  cancel: Scalars['String'];
  confirm: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ContentExtraction = {
  __typename?: 'ContentExtraction';
  genres?: Maybe<Array<Scalars['String']>>;
};

export type ContinueWatchingLabels = {
  __typename?: 'ContinueWatchingLabels';
  /**
   * New content label highlight that when a new episode of a series you have been following appears to be available.
   * The text will be 'New season' if it's episodeNumber is 1, otherwise the text will be 'New episode'
   * @deprecated Use PosterLabels instead.
   */
  newContent?: Maybe<NewContentLabel>;
  /**
   * Typically rendered in the top left corner of a poster
   * @deprecated Use PosterLabels instead.
   */
  primary?: Maybe<Label>;
  /**
   * Typically rendered in the top right corner of a poster
   * @deprecated Use PosterLabels instead.
   */
  secondary?: Maybe<Label>;
};

export type ContinueWatchingList = {
  __typename?: 'ContinueWatchingList';
  items?: Maybe<Array<ContinueWatchingListItem>>;
  pageInfo: PageInfo;
};

export type ContinueWatchingListContent = Episode | Movie | SportEvent;

export type ContinueWatchingListItem = {
  __typename?: 'ContinueWatchingListItem';
  media: ContinueWatchingListContent;
  posterLabels: PosterLabels;
};

export type ContinueWatchingPanel = Panel & {
  __typename?: 'ContinueWatchingPanel';
  continueWatchingContent: ContinueWatchingPanelContent;
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

export type ContinueWatchingPanelContinueWatchingContentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ContinueWatchingPanelContent = {
  __typename?: 'ContinueWatchingPanelContent';
  items?: Maybe<Array<ContinueWatchingPanelItem>>;
  pageInfo: PageInfo;
};

export type ContinueWatchingPanelItem = {
  __typename?: 'ContinueWatchingPanelItem';
  id: Scalars['ID'];
  /** @deprecated Use PosterLabels instead. */
  labels: ContinueWatchingLabels;
  media: ContinueWatchingPanelItemContent;
  posterLabels: PosterLabels;
};

export type ContinueWatchingPanelItemContent = Episode | Movie | SportEvent;

export type CreateProfileInput = {
  ageGroup: AgeGroup;
  alias: Scalars['String'];
  avatarId: Scalars['ID'];
};

export type CreateProfileResult =
  | CreateProfileResultGeneralError
  | CreateProfileResultMaxNumberOfProfilesError
  | CreateProfileResultOk
  | CreateProfileResultThemeNotAllowedError;

export type CreateProfileResultGeneralError = Error & {
  __typename?: 'CreateProfileResultGeneralError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type CreateProfileResultMaxNumberOfProfilesError = Error & {
  __typename?: 'CreateProfileResultMaxNumberOfProfilesError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type CreateProfileResultOk = {
  __typename?: 'CreateProfileResultOk';
  profile: Profile;
};

export type CreateProfileResultThemeNotAllowedError = Error & {
  __typename?: 'CreateProfileResultThemeNotAllowedError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type DatePatternLabel = {
  __typename?: 'DatePatternLabel';
  dateForPattern: DateTime;
  pattern: Scalars['String'];
  rounding: DatePatternRounding;
  type: LabelRenderType;
};

export enum DatePatternRounding {
  Ceil = 'CEIL',
  Floor = 'FLOOR',
  Round = 'ROUND',
}

export type DateTime = {
  __typename?: 'DateTime';
  /** ISO 8601 DateTime/RFC 3339 compliant in the format of '2022-11-17T12:05:00.000Z' */
  isoString: Scalars['String'];
  /**
   * Milliseconds until this period in time.
   * NOTE:
   * The largest value a INT field can present is (2^31) - 1, if the actual value is larger than this, (2^31) - 1 will be returned.
   * The smallest value INT field can present is -(2^31), if the actual value is smaller than this, -(2^31) will be returned.
   * @deprecated Please use sTo. We're hitting the integer limit.
   */
  msTo: Scalars['Int'];
  /** The distance between the given date and now in words */
  readableDistance?: Maybe<Scalars['String']>;
  sTo: Scalars['Int'];
  /** Millisecond based epoch timestamp */
  timestamp: Scalars['Timestamp'];
};

export type DateTimeReadableDistanceArgs = {
  type?: InputMaybe<DateTimeReadableDistance>;
};

export enum DateTimeReadableDistance {
  /**
   * Will return e.g. "less than an hour", "3 hours", "1 day", "15 days", "2020-12-24 (if more than 1 month)".
   *
   * Returns null for dates more than 3 months in the future,
   * indicating that this is so far away that we shouldn't show
   * it to the user.
   */
  Fuzzy = 'FUZZY',
  /** E.g. "4 hours" or "43 minutes" if less than 1 hour. */
  HoursOrMinutes = 'HOURS_OR_MINUTES',
}

export type DaysLeftLabel = {
  __typename?: 'DaysLeftLabel';
  text: Scalars['String'];
};

export type DebugContinueWatchingList = {
  __typename?: 'DebugContinueWatchingList';
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeepLink = {
  __typename?: 'DeepLink';
  activationUrl?: Maybe<Scalars['String']>;
  appleAppStoreUrl?: Maybe<Scalars['String']>;
  /** To be used for logging purposes only. Not to be used for logic. */
  applicationId: Scalars['String'];
  audioLanguages: Array<Language>;
  googlePlayStoreId?: Maybe<Scalars['String']>;
  kreaTvAppId?: Maybe<Scalars['String']>;
  serviceName: Scalars['String'];
  uri: Scalars['String'];
  validFrom: DateTime;
  validTo: DateTime;
};

export enum DeepLinkUriType {
  App = 'APP',
  Web = 'WEB',
}

export type DeleteProfileInput = {
  id: Scalars['ID'];
};

export type DeleteProfileResult =
  | DeleteProfileResultCannotDeleteActiveProfileError
  | DeleteProfileResultGeneralError
  | DeleteProfileResultOk;

export type DeleteProfileResultCannotDeleteActiveProfileError = Error & {
  __typename?: 'DeleteProfileResultCannotDeleteActiveProfileError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type DeleteProfileResultGeneralError = Error & {
  __typename?: 'DeleteProfileResultGeneralError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type DeleteProfileResultOk = {
  __typename?: 'DeleteProfileResultOk';
  deleted: Scalars['Boolean'];
};

export type DeleteRecordingResult = DeleteRecordingResultError | DeleteRecordingResultOk;

export type DeleteRecordingResultError = Error & {
  __typename?: 'DeleteRecordingResultError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type DeleteRecordingResultOk = {
  __typename?: 'DeleteRecordingResultOk';
  media?: Maybe<RecordingResultMedia>;
  refreshMedias?: Maybe<RefreshMedias>;
};

/**
 * Currently, a
 * * MEDIUM description tries to be <= 150 characters, and
 * * LONG description tries to be <= 250 characters, and
 * * STANDALONE description tries to be <= 425 characters.
 *
 * These are however wishes and if the wish can't be fulfilled
 * longer descriptions will be served.
 *
 * Default is that big screen clients are given LONG descriptions and
 * small screen clients are given MEDIUM descriptions.
 *
 * This may change without notice.
 */
export enum DescriptionLength {
  ClientDefault = 'CLIENT_DEFAULT',
  Long = 'LONG',
  Medium = 'MEDIUM',
  Standalone = 'STANDALONE',
}

/**
 * The DisplayHint is a wish from the Editors as for how the client should present
 * the content. E.g. a panel can be a "highlight" and the client should display this
 * larger, with stand out colors, or somewhere along those lines.
 *
 * Whether or not this wish is granted is up to each client (and each client's designer).
 * All clients **must** handle the main types _swimlane_, _grid_ and _list_.
 *
 * If a client does not understand a specific subType, it **must** fallback to using the default
 * subType of that client. As with any unions, new types and subTypes can be added without notice.
 */
export type DisplayHint = DisplayHintGrid | DisplayHintList | DisplayHintSwimlane;

export type DisplayHintGrid = {
  __typename?: 'DisplayHintGrid';
  gridSubType?: Maybe<DisplayHintGridSubType>;
};

export enum DisplayHintGridSubType {
  ClientDefault = 'CLIENT_DEFAULT',
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE',
  Toplist = 'TOPLIST',
}

export type DisplayHintList = {
  __typename?: 'DisplayHintList';
  listSubType?: Maybe<DisplayHintListSubType>;
};

export enum DisplayHintListSubType {
  ClientDefault = 'CLIENT_DEFAULT',
}

export type DisplayHintSwimlane = {
  __typename?: 'DisplayHintSwimlane';
  swimlaneSubType?: Maybe<DisplayHintSwimlaneSubType>;
};

export enum DisplayHintSwimlaneSubType {
  ClientDefault = 'CLIENT_DEFAULT',
  Highlight = 'HIGHLIGHT',
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE',
  Toplist = 'TOPLIST',
}

export type Duration = {
  __typename?: 'Duration';
  /** E.g. 1 h 55 min */
  readableShort: Scalars['String'];
  /** Duration in seconds */
  seconds: Scalars['Int'];
};

export type EditorialPage = {
  __typename?: 'EditorialPage';
  body: RichText;
};

export type EmptyView = {
  __typename?: 'EmptyView';
  cta?: Maybe<EmptyViewCta>;
  heading?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type EmptyViewCta = {
  __typename?: 'EmptyViewCTA';
  link: SelectableLink;
  text: Scalars['String'];
};

export type EpgDate = {
  __typename?: 'EpgDate';
  from: Scalars['Timestamp'];
  label: Scalars['String'];
  /**
   * There are three special values can be expected for name, which are 'yesterday', 'today' and 'tomorrow'.
   * Other than these three special values, name will be a date string, eg. 2022-06-05
   */
  name: Scalars['String'];
  to: Scalars['Timestamp'];
};

export type EpgDateList = {
  __typename?: 'EpgDateList';
  items: Array<EpgDate>;
  refreshAt: Scalars['Timestamp'];
};

export type Episode = {
  __typename?: 'Episode';
  actors?: Maybe<Array<Scalars['String']>>;
  ageRating?: Maybe<AgeRating>;
  audioLanguages: Array<Language>;
  availability: Availability;
  /**
   * The episode is playable from this time.
   * This could either be when a VOD is made playable or when the next linear broadcast time is.
   * @deprecated Please use availability.from instead.
   */
  availableFrom?: Maybe<DateTime>;
  /** Whether the episode is playable or not, at the time of fetching. */
  availableNow?: Maybe<Scalars['Boolean']>;
  badges: Badges;
  cdp: CdpData;
  channel?: Maybe<Channel>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use description instead */
  descriptionLong?: Maybe<Scalars['String']>;
  directors?: Maybe<Array<Scalars['String']>>;
  downloadAvailable: Scalars['Boolean'];
  duration?: Maybe<Duration>;
  /** When rendering the EPG, this field must be used instead of the normal title */
  epgTitle: Scalars['String'];
  episodeNumber: EpisodeNumber;
  genre?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: EpisodeImages;
  /** @deprecated Use cdp.labels instead. */
  labels: Labels;
  /** @deprecated There should be no need for this. Let Graph team know if you're basing logic on this */
  mediaType?: Maybe<MediaType>;
  /** The episode after this episode, based on seasonal metadata. Filter param determines if content outside of users engagement gets returned or not */
  nextEpisode?: Maybe<Episode>;
  originalAirDate2?: Maybe<OriginalAirDate>;
  playback?: Maybe<Playback>;
  price?: Maybe<Price>;
  productionCountries?: Maybe<Array<Scalars['String']>>;
  provider?: Maybe<Provider>;
  seasonNumber: SeasonNumber;
  series: Series;
  /** @deprecated Use provider instead. Let the Graph team know if this doesn't suffice */
  store?: Maybe<Store>;
  subGenres?: Maybe<Array<Scalars['String']>>;
  subtitleLanguages?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  trailers?: Maybe<Array<Trailer>>;
  /** Market-aware links with url patterns registered by app receivers which allows deeplinking to episode cdp or autoplay. */
  universalLinks?: Maybe<UniversalMediaLinks>;
  userData?: Maybe<EpisodeUserData>;
  /**
   * Episode Vignettes fallback to series vignettes if an episode one is not available.
   * @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used
   */
  vignette: Vignettes;
  yearProduction?: Maybe<YearProduction>;
};

export type EpisodeDescriptionArgs = {
  length?: InputMaybe<DescriptionLength>;
};

export type EpisodeNextEpisodeArgs = {
  userAccessFilter?: InputMaybe<NextEpisodeAccessFilter>;
};

export type EpisodeImages = {
  __typename?: 'EpisodeImages';
  /**
   * Backdrops are _not_ title treated and you need to present the media title to make sense.
   * backdrop16x9 will automatically fallback to the same image as screenshot16x9, you do therefor
   * not need to request screenshot16x9 if you're requesting backdrop16x9.
   */
  backdrop16x9?: Maybe<Image>;
  id: Scalars['ID'];
  /**
   * Screenshots are _not_ title treated and you need to present the media title to make sense.
   * An episode screenshot may be used in lieu of a backdrop if the UX demands it.
   * You should however always request and fallback to backdrop16x9 is a screenshot 16x9 is missing.
   */
  screenshot16x9?: Maybe<Image>;
  /**
   * The showcard is 'title treated' and is to be used by itself without overlaying text.
   * Due to lacking image coverage, there are a few sports instances where this isn't true,
   * these are to be treated as bugs.
   */
  showcard2x3?: Maybe<ShowcardImage>;
  /**
   * The showcard is 'title treated' and is to be used by itself without overlaying text.
   * Due to lacking image coverage, there are a few sports instances where this isn't true,
   * these are to be treated as bugs.
   */
  showcard16x9?: Maybe<ShowcardImage>;
};

export type EpisodeItem = {
  __typename?: 'EpisodeItem';
  episode: Episode;
  id: Scalars['ID'];
  listing: EpisodeListing;
};

export type EpisodeListInput = {
  startEpisodeId?: InputMaybe<Scalars['String']>;
};

export type EpisodeListing = {
  __typename?: 'EpisodeListing';
  description?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  metadataRow: Array<MetadataItem>;
};

export type EpisodeListingDescriptionArgs = {
  length?: InputMaybe<DescriptionLength>;
};

export type EpisodeNumber = {
  __typename?: 'EpisodeNumber';
  number: Scalars['Int'];
  readable: Scalars['String'];
};

export type EpisodeUserData = {
  __typename?: 'EpisodeUserData';
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
  progress?: Maybe<Progress>;
  /** This is a shorthand for playback.play.rental.item.rentalInfo */
  rentalInfo?: Maybe<RentalInfo>;
};

export type Episodes = {
  __typename?: 'Episodes';
  /** @deprecated Use episodeList instead */
  episodeItems: Array<Episode>;
  episodeList: Array<EpisodeItem>;
  pageInfo: PageInfo;
};

export type EpisodesContent = {
  __typename?: 'EpisodesContent';
  collectionPageInfo: CollectionPageInfo;
  episodeList?: Maybe<Array<Maybe<EpisodeItem>>>;
};

/** Allows for negative offsets. E.g. say that "I want the 10 items before startEpisodeId" */
export type EpisodesListInput = {
  limit: Scalars['Int'];
  /** Only applicable for NPVR, filter episodes based on recording state */
  npvrState?: InputMaybe<Array<RecordingState>>;
  offset: Scalars['Int'];
  /** Starting offset. If omitted, will start from the first episode */
  startEpisodeId?: InputMaybe<Scalars['String']>;
};

export type Error = {
  code: Scalars['String'];
  message: Scalars['String'];
};

export type FilterInput = {
  key: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type FilterOption = {
  __typename?: 'FilterOption';
  key: Scalars['String'];
  selected: Scalars['Boolean'];
  title: Scalars['String'];
};

export type FilterSection = {
  __typename?: 'FilterSection';
  description?: Maybe<Scalars['String']>;
  filterOptions?: Maybe<Array<FilterOption>>;
  header?: Maybe<Scalars['String']>;
};

/** Currently only used by Telia brand. */
export type HotspotLoggedOutPanel = Panel & {
  __typename?: 'HotspotLoggedOutPanel';
  id: Scalars['ID'];
  item?: Maybe<HotspotLoggedOutPanelItem>;
  title: Scalars['String'];
};

export type HotspotLoggedOutPanelItem = {
  __typename?: 'HotspotLoggedOutPanelItem';
  activationLink?: Maybe<Scalars['String']>;
  activationLinkText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Image;
  primaryLink?: Maybe<Scalars['String']>;
  primaryLinkText?: Maybe<Scalars['String']>;
  secondaryLink?: Maybe<Scalars['String']>;
  secondaryLinkText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Only Web needs to support this panel. */
export type HotspotLoggedOutPanelX = {
  __typename?: 'HotspotLoggedOutPanelX';
  analytics: PanelAnalytics;
  hotspotLoggedOutContent?: Maybe<HotspotLoggedOutPanelItem>;
  id: Scalars['ID'];
};

export type HttpLink = {
  __typename?: 'HttpLink';
  text?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type Icon = {
  __typename?: 'Icon';
  contentType?: Maybe<ImageContentType>;
  /**
   * URI Encoded URL ready to be sent into image service.
   *
   * The url may be pointing towards a service which is not scaled for
   * production loads. Therefor this **must** be passed through an image service
   * caching the data.
   */
  source: Scalars['String'];
  /**
   * Non URI Encoded version of _source_.
   *
   * The url may be pointing towards a service which is not scaled for
   * production loads. Therefor this **must** be passed through an image service
   * caching the data.
   * @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-11-20.md Use 'source'
   */
  sourceNonEncoded: Scalars['String'];
};

export type Icons = {
  __typename?: 'Icons';
  dark: Icon;
  light: Icon;
};

export type IconsDarkArgs = {
  typePrio?: InputMaybe<Array<ImageContentTypePrio>>;
};

export type IconsLightArgs = {
  typePrio?: InputMaybe<Array<ImageContentTypePrio>>;
};

/**
 * **The image url must be passed through the image proxy.**
 * Client's *can not* request the image directly, as the image origins
 * can not handle that type of load.
 *
 * Example:
 *
 * `https://imageproxy.b17g.services/convert/?resize=152x80&shape=nocut&source=<source>`
 *
 *
 * **This is also true for SVG images**, however they can not be resized,
 * but can be requested without resizing params:
 * `https://imageproxy.b17g.services/convert/?source=<source>`
 */
export type Image = {
  __typename?: 'Image';
  colors?: Maybe<ImageColors>;
  contentType: ImageContentType;
  id: Scalars['ID'];
  /** **The image url must be passed through the image proxy.** */
  source: Scalars['String'];
  /**
   * **The image url must encoded and be passed through the image proxy.**
   * Prefer using `source` instead of `sourceNonEncoded`
   * @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-11-20.md Use 'source'
   */
  sourceNonEncoded: Scalars['String'];
};

/**
 * Automatically extracted Color Palette & Blurhash for this image.
 * Note: For the first ever request for a specific images' extracted properties this will return null - the data for this field will then be populated by background jobs.
 * You can expect at least a few seconds from the first ever request until the data will be returned in consequetive requests.
 */
export type ImageColors = {
  __typename?: 'ImageColors';
  /** BlurHash is a compact representation of a placeholder for an image. See [https://github.com/woltapp/blurhash](https://github.com/woltapp/blurhash) for implementation */
  blurHash?: Maybe<Scalars['String']>;
  muteBgColor?: Maybe<Color>;
};

export enum ImageContentType {
  /** Unknown bitmap image, could be png or jpg. */
  Bitmap = 'BITMAP',
  /** Even SVG images must be pased through the image proxy, for caching reasons. */
  Svg = 'SVG',
}

/**
 * Used to supply a prioritized list of image content types,
 * which will return the first matching type. E.g `[SVG]` will first
 * try to find an SVG image, if that does not exist it will always
 * fallback to a png/jpg pixel image and try to find that.
 *
 * Use `Image.contentType` to determine what type of image you get back.
 */
export enum ImageContentTypePrio {
  Svg = 'SVG',
}

export type Label = BasicLabel | TimeSensitiveLabel;

export enum LabelRenderType {
  Airtime = 'AIRTIME',
  Announcement = 'ANNOUNCEMENT',
  DisappearsIn = 'DISAPPEARS_IN',
  FreeView = 'FREE_VIEW',
  Live = 'LIVE',
  Package = 'PACKAGE',
  Price = 'PRICE',
  Recurring = 'RECURRING',
  Rented = 'RENTED',
  Unavailable = 'UNAVAILABLE',
  Upcoming = 'UPCOMING',
}

export type Labels = {
  __typename?: 'Labels';
  /** Airtime represents when the next linear broadcast will occur for this media, if the media has no linear broadcasts - this will be null */
  airtime?: Maybe<AirtimeLabel>;
  /**
   * For sports, this label will be available when the availability is 3 days or less.
   * For other media types, this label will be available when the availability is 14 days or less.
   */
  daysLeft?: Maybe<DaysLeftLabel>;
  premiereAnnouncement?: Maybe<PremiereAnnouncementLabel>;
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type LeagueLink = {
  __typename?: 'LeagueLink';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: LeagueLinkImages;
  title: Scalars['String'];
};

export type LeagueLinkImages = {
  __typename?: 'LeagueLinkImages';
  icon: Image;
  league3x2: Image;
};

/** Deprecated, use PageLinkPanel to get the same content */
export type LeaguePanel = Panel & {
  __typename?: 'LeaguePanel';
  /** @deprecated Use PageLinkPanel to get the same content */
  id: Scalars['ID'];
  /** @deprecated Use PageLinkPanel to get the same content */
  leagueContent: LeaguePanelContent;
  /** @deprecated Use PageLinkPanel to get the same content */
  title: Scalars['String'];
};

/** Deprecated, use PageLinkPanel to get the same content */
export type LeaguePanelLeagueContentArgs = {
  input?: InputMaybe<LeaguePanelInput>;
};

export type LeaguePanelContent = {
  __typename?: 'LeaguePanelContent';
  items?: Maybe<Array<LeaguePanelItem>>;
  pageInfo: PageInfo;
};

export type LeaguePanelInput = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type LeaguePanelItem = {
  __typename?: 'LeaguePanelItem';
  league: LeagueLink;
};

/**
 * A linear broadcast. Note that this is only valid if current time is between
 * startTime and endTime. This means that the content can be in the future and
 * the client needs to check the validity before trying to start playback.
 *
 * Passed events are never returned
 */
export type Linear = {
  __typename?: 'Linear';
  /**
   * The linear channel to which the media belongs to, may be used to "Go to live",
   * when playing a startover.
   */
  channel?: Maybe<LinearChannel>;
  /** Broadcast end time. Playback can only be started before this */
  endTime: DateTime;
  /** @deprecated For debug purposes only */
  id?: Maybe<Scalars['String']>;
  /**
   * Broadcast in real-time, as events happen, in the present.
   * E.g. a live broadcasted soccer game.
   * Not to be confused with if this content currently available.
   * This is information given to use by the provider and
   * content may be lacking this live flag, even if it is indeed live.
   * Only use this to decorate the UI, not to do logical decisions.
   */
  isLive: Scalars['Boolean'];
  /**
   * If this is a re-run and has been aired previously.
   * Only use this to decorate the UI, not to do logical decisions.
   */
  isRerun: Scalars['Boolean'];
  playbackSpec: PlaybackSpec;
  /** Broadcast start time. Playback can only be started after this */
  startTime: DateTime;
  /** If current user is allowed to startover this broadcast this will be set */
  startover?: Maybe<Startover>;
};

export type LinearChannel = {
  __typename?: 'LinearChannel';
  id: Scalars['ID'];
  playbackSpec: PlaybackSpec;
};

export type Link = {
  __typename?: 'Link';
  /** @deprecated Please use 'to' */
  id: Scalars['ID'];
  to: Scalars['String'];
  type: LinkType;
};

export type LinkCapability = PosterCapability & {
  __typename?: 'LinkCapability';
  /** @deprecated Please use link3 */
  link: Link;
  link3: LinkInterface;
  linkCapabilityType: LinkCapabilityType;
  text: Scalars['String'];
};

export enum LinkCapabilityType {
  MoreInfoLink = 'MORE_INFO_LINK',
}

export type LinkChannel = LinkInterface & {
  __typename?: 'LinkChannel';
  to: Scalars['String'];
};

export type LinkDeep = LinkInterface & {
  __typename?: 'LinkDeep';
  appleAppStoreUrl?: Maybe<Scalars['String']>;
  /** To be used for logging purposes only. Not to be used for logic. */
  applicationId: Scalars['String'];
  googlePlayStoreId?: Maybe<Scalars['String']>;
  kreaTvAppId?: Maybe<Scalars['String']>;
  serviceName: Scalars['String'];
  to: Scalars['String'];
};

export type LinkEpisode = LinkInterface & {
  __typename?: 'LinkEpisode';
  to: Scalars['String'];
};

export type LinkInterface = {
  to: Scalars['String'];
};

export type LinkMovie = LinkInterface & {
  __typename?: 'LinkMovie';
  to: Scalars['String'];
};

export type LinkPage = LinkInterface & {
  __typename?: 'LinkPage';
  to: Scalars['String'];
};

export type LinkPanel = LinkInterface & {
  __typename?: 'LinkPanel';
  to: Scalars['String'];
};

export type LinkPlay = LinkInterface & {
  __typename?: 'LinkPlay';
  to: Scalars['String'];
};

export type LinkSeries = LinkInterface & {
  __typename?: 'LinkSeries';
  to: Scalars['String'];
};

export type LinkSportEvent = LinkInterface & {
  __typename?: 'LinkSportEvent';
  to: Scalars['String'];
};

export type LinkSportEventList = LinkInterface & {
  __typename?: 'LinkSportEventList';
  to: Scalars['String'];
};

export type LinkStatic = LinkInterface & {
  __typename?: 'LinkStatic';
  to: Scalars['String'];
};

export type LinkStore = LinkInterface & {
  __typename?: 'LinkStore';
  to: Scalars['String'];
};

export enum LinkType {
  Channel = 'CHANNEL',
  Episode = 'EPISODE',
  Movie = 'MOVIE',
  Page = 'PAGE',
  /** The Panel is guaranteed to be a PageLinkPanel. Use pageLinkQuery(id: "") query to get the content. */
  Panel = 'PANEL',
  Play = 'PLAY',
  Series = 'SERIES',
  Sportevent = 'SPORTEVENT',
  Sporteventlist = 'SPORTEVENTLIST',
  Static = 'STATIC',
  Store = 'STORE',
}

export type LiveOnlyChannelDisplayHint = {
  __typename?: 'LiveOnlyChannelDisplayHint';
  /** Text to display when no program data exists */
  noProgramsText: Scalars['String'];
};

export type MainMenu = {
  __typename?: 'MainMenu';
  items: Array<PageLink>;
};

export type MainMenuTree = {
  __typename?: 'MainMenuTree';
  items: Array<MenuItem>;
};

export enum MaybeBoolean {
  No = 'NO',
  NotApplicable = 'NOT_APPLICABLE',
  Yes = 'YES',
}

export type Media = Episode | Movie | SportEvent;

export enum MediaAndSeriesType {
  Media = 'MEDIA',
  Series = 'SERIES',
}

export type MediaImages = EpisodeImages | MovieImages | SeriesImages | SportEventImages;

export type MediaPanel = Panel & {
  __typename?: 'MediaPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  kicker?: Maybe<Scalars['String']>;
  /** @deprecated Please use showMore */
  link?: Maybe<PanelLink>;
  mediaContent: MediaPanelContent;
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

export type MediaPanelMediaContentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type MediaPanelContent = {
  __typename?: 'MediaPanelContent';
  id: Scalars['ID'];
  items?: Maybe<Array<MediaPanelItem>>;
  pageInfo: PageInfo;
};

export type MediaPanelItem = {
  __typename?: 'MediaPanelItem';
  media: MediaPanelItemContent;
  posterLabels: PosterLabels;
};

export type MediaPanelItemContent = Episode | Movie | Series | SportEvent;

export enum MediaType {
  Clip = 'CLIP',
  Movie = 'MOVIE',
  News = 'NEWS',
  Program = 'PROGRAM',
  ScriptedSeries = 'SCRIPTED_SERIES',
  Sports = 'SPORTS',
}

export type MediasAndSeries = {
  __typename?: 'MediasAndSeries';
  media: MediasAndSeriesContent;
  posterLabels: PosterLabels;
};

export type MediasAndSeriesContent = Episode | Movie | Series | SportEvent;

export type MediasAndSeriesIdsInput = {
  id: Scalars['String'];
  type: MediasAndSeriesIdsType;
};

export enum MediasAndSeriesIdsType {
  Media = 'MEDIA',
  Series = 'SERIES',
}

export type MediasAndSeriesInput = {
  ids: Array<MediasAndSeriesIdsInput>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  link: PageLink;
  subMenu?: Maybe<SubMenuItem>;
};

export type Message = {
  __typename?: 'Message';
  content: Scalars['String'];
  /** Optional field. If not present, message should be displayed on ALL pages, otherwise message should be displayed only on pages where page id is in this list. */
  filterPageIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  /** @deprecated This was a C More only feature. */
  removable: Scalars['Boolean'];
  severity: MessageSeverity;
  url?: Maybe<Scalars['String']>;
};

export enum MessageSeverity {
  Alert = 'ALERT',
  Info = 'INFO',
}

export type MessageView = {
  __typename?: 'MessageView';
  streamingPubSub?: Maybe<MessageViewStreamingPubSub>;
};

export type MessageViewStreamingPubSubArgs = {
  config: MessageViewStreamingPubSubInput;
};

/** https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-13.md */
export type MessageViewStreamingPubSub = {
  __typename?: 'MessageViewStreamingPubSub';
  description?: Maybe<Scalars['String']>;
  header: MessageViewStreamingPubSubHeader;
  heading?: Maybe<Scalars['String']>;
  images?: Maybe<MediaImages>;
  streamsTable?: Maybe<Array<MessageViewStreamingPubSubStreamsTableRow>>;
};

/** https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-13.md */
export type MessageViewStreamingPubSubHeadingArgs = {
  format?: InputMaybe<StyledTextFormat>;
};

export type MessageViewStreamingPubSubHeader = {
  __typename?: 'MessageViewStreamingPubSubHeader';
  heading?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  metadataRow?: Maybe<Array<MetadataItem>>;
  progress?: Maybe<AllProgress>;
};

export type MessageViewStreamingPubSubInput = {
  message: Scalars['String'];
};

export type MessageViewStreamingPubSubStreamsTableRow = {
  __typename?: 'MessageViewStreamingPubSubStreamsTableRow';
  contentTitle?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  icon?: Maybe<MetadataItemIcon>;
};

export type Messages = {
  __typename?: 'Messages';
  items: Array<Message>;
};

export type MetadataBadgeImdb = {
  __typename?: 'MetadataBadgeIMDB';
  /** To be presented to the right of the logo, contains the rating, e.g. '7.8 */
  text: Scalars['String'];
  /** Leads to media on the IMDB site */
  url: Scalars['String'];
};

export type MetadataExtraction = {
  __typename?: 'MetadataExtraction';
  page?: Maybe<PageExtraction>;
};

export type MetadataExtractionPageArgs = {
  config: PageExtractionConfig;
};

export type MetadataItem =
  | MetadataBadgeImdb
  | MetadataItemIcon
  | MetadataItemString
  | MetadataTextBadge
  | TimeSensitiveMetadataItemIcon;

export type MetadataItemAgeRatingContent = MetadataItemIcon | MetadataItemString;

export type MetadataItemIcon = {
  __typename?: 'MetadataItemIcon';
  /** @deprecated In development. Waiting for translations. */
  a11y: MetadataItemIconA11y;
  color: Scalars['String'];
  /** @deprecated Use name and color instead. */
  iconId: MetadataItemIconId;
  name: Scalars['String'];
};

export type MetadataItemIconA11y = {
  __typename?: 'MetadataItemIconA11y';
  label: Scalars['String'];
};

export enum MetadataItemIconId {
  NotAvailable = 'NOT_AVAILABLE',
  SeriesRecordingOngoing = 'SERIES_RECORDING_ONGOING',
  SeriesRecordingRecorded = 'SERIES_RECORDING_RECORDED',
  SeriesRecordingScheduled = 'SERIES_RECORDING_SCHEDULED',
  SingleRecordingOngoing = 'SINGLE_RECORDING_ONGOING',
  SingleRecordingRecorded = 'SINGLE_RECORDING_RECORDED',
  SingleRecordingScheduled = 'SINGLE_RECORDING_SCHEDULED',
}

export type MetadataItemString = {
  __typename?: 'MetadataItemString';
  text: Scalars['String'];
};

export type MetadataTable = {
  __typename?: 'MetadataTable';
  content?: Maybe<Array<MetadataItem>>;
  heading: Scalars['String'];
};

export type MetadataTextBadge = {
  __typename?: 'MetadataTextBadge';
  icon?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  textBadgeType: MetadataTextBadgeType;
  /** @deprecated Use textBadgeType instead */
  type: MetadataTextBadgeType;
};

export enum MetadataTextBadgeType {
  AgeRating = 'AGE_RATING',
  DownloadAvailable = 'DOWNLOAD_AVAILABLE',
  PictureQuality = 'PICTURE_QUALITY',
  SoundQuality = 'SOUND_QUALITY',
}

export type MiniBlock = QuotaInformationMiniBlock;

export type MiniBlockContainer = {
  __typename?: 'MiniBlockContainer';
  blocks?: Maybe<Array<Maybe<MiniBlock>>>;
};

export type Movie = {
  __typename?: 'Movie';
  actors?: Maybe<Array<Scalars['String']>>;
  ageRating?: Maybe<AgeRating>;
  audioLanguages: Array<Language>;
  availability: Availability;
  /**
   * The movie is playable from this time.
   * This could either be when a VOD is made playable or when the next linear broadcast time is.
   * @deprecated Please use availability.from instead.
   */
  availableFrom?: Maybe<DateTime>;
  /** Whether the movie is playable or not, at the time of fetching. */
  availableNow?: Maybe<Scalars['Boolean']>;
  badges: Badges;
  cdp: CdpData;
  channel?: Maybe<Channel>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use description instead */
  descriptionLong?: Maybe<Scalars['String']>;
  directors?: Maybe<Array<Scalars['String']>>;
  downloadAvailable: Scalars['Boolean'];
  duration?: Maybe<Duration>;
  /** When rendering the EPG, this field must be used instead of the normal title */
  epgTitle: Scalars['String'];
  genre?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: MovieImages;
  /** @deprecated Use cdp.labels instead. */
  labels: Labels;
  /** @deprecated There should be no need for this. Let Graph team know if you're basing logic on this */
  mediaType?: Maybe<MediaType>;
  originalAirDate2?: Maybe<OriginalAirDate>;
  playback?: Maybe<Playback>;
  price?: Maybe<Price>;
  productionCountries?: Maybe<Array<Scalars['String']>>;
  provider?: Maybe<Provider>;
  ratings: Ratings;
  relatedPanels?: Maybe<RelatedPanels>;
  /** @deprecated Use provider instead. Let the Graph team know if this doesn't suffice */
  store?: Maybe<Store>;
  subGenres?: Maybe<Array<Scalars['String']>>;
  subtitleLanguages?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  trailers?: Maybe<Array<Trailer>>;
  /** Market-aware links with url patterns registered by app receivers which allows deeplinking to movie cdp or autoplay. */
  universalLinks?: Maybe<UniversalMediaLinks>;
  userData?: Maybe<MovieUserData>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used */
  vignette: Vignettes;
  yearProduction?: Maybe<YearProduction>;
};

export type MovieDescriptionArgs = {
  length?: InputMaybe<DescriptionLength>;
};

export type MovieImages = {
  __typename?: 'MovieImages';
  /**
   * Backdrops are _not_ title treated and you need to present the media title to make sense.
   * backdrop16x9 will automatically fallback to the same image as screenshot16x9, you do therefor
   * not need to request screenshot16x9 if you're requesting backdrop16x9.
   */
  backdrop16x9?: Maybe<Image>;
  id: Scalars['ID'];
  /** Screenshots are _not_ title treated and you need to present the media title to make sense. */
  screenshot16x9?: Maybe<Image>;
  showcard2x3?: Maybe<ShowcardImage>;
  showcard16x9?: Maybe<ShowcardImage>;
};

export type MovieUserData = {
  __typename?: 'MovieUserData';
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
  progress?: Maybe<Progress>;
  /** This is a shorthand for playback.play.rental.item.rentalInfo */
  rentalInfo?: Maybe<RentalInfo>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addToFavoriteChannelList: AddToUserChannelListResult;
  addToMyList2: AddToMyListResult;
  assumeProfile: AssumeProfileResult;
  createProfile: CreateProfileResult;
  deleteProfile: DeleteProfileResult;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-09-11.md Use deleteRecording2 instead */
  deleteRecording: DeleteRecordingResult;
  deleteRecording2: DeleteRecordingResult;
  removeFromFavoriteChannelList: RemoveFromUserChannelListResult;
  removeFromMyList2: RemoveFromMyListResult;
  removeListFromContinueWatching: RemoveListFromContinueWatchingResult;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-09-11.md Use scheduleRecording2 instead */
  scheduleRecording: ScheduleRecordingResult;
  /** Does not yet support SEASON, otherwise all is good. */
  scheduleRecording2: ScheduleRecordingResult;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-09-11.md Use unScheduleRecording2 instead */
  unscheduleRecording: UnscheduleRecordingResult;
  /** Does not yet support SEASON, otherwise all is good. */
  unscheduleRecording2: UnscheduleRecordingResult;
  updateChildLock: UpdateChildLockResult;
  updateProfile: UpdateProfileResult;
};

export type MutationAddToFavoriteChannelListArgs = {
  id: Scalars['String'];
};

export type MutationAddToMyList2Args = {
  input: AddToMyListInput;
};

export type MutationAssumeProfileArgs = {
  input: AssumeProfileInput;
};

export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};

export type MutationDeleteProfileArgs = {
  input: DeleteProfileInput;
};

export type MutationDeleteRecordingArgs = {
  id: Scalars['ID'];
  type: RecordingIdType;
};

export type MutationDeleteRecording2Args = {
  input: NpvrInput;
};

export type MutationRemoveFromFavoriteChannelListArgs = {
  id: Scalars['String'];
};

export type MutationRemoveFromMyList2Args = {
  input: RemoveFromMyListInput;
};

export type MutationRemoveListFromContinueWatchingArgs = {
  input: RemoveListFromContinueWatchingInput;
};

export type MutationScheduleRecordingArgs = {
  id: Scalars['ID'];
  type: RecordingIdType;
};

export type MutationScheduleRecording2Args = {
  input: NpvrInput;
};

export type MutationUnscheduleRecordingArgs = {
  id: Scalars['ID'];
  type: RecordingIdType;
};

export type MutationUnscheduleRecording2Args = {
  input: NpvrInput;
};

export type MutationUpdateChildLockArgs = {
  input: UpdateChildLockInput;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationCapability = PosterCapability & {
  __typename?: 'MutationCapability';
  capabilityGroups?: Maybe<Array<Scalars['String']>>;
  confirmation?: Maybe<ConfirmationDialog>;
  icon: MetadataItemIcon;
  mutationCapabilityType: MutationCapabilityType;
  payload: MutationCapabilityPayload;
  text: Scalars['String'];
};

export type MutationCapabilityPayload = {
  __typename?: 'MutationCapabilityPayload';
  id: Scalars['String'];
  type: MutationPayloadType;
};

export enum MutationCapabilityType {
  ContinueWatchingRemove = 'CONTINUE_WATCHING_REMOVE',
  FavoriteAdd = 'FAVORITE_ADD',
  FavoriteRemove = 'FAVORITE_REMOVE',
  NpvrDeleteSeason = 'NPVR_DELETE_SEASON',
  NpvrDeleteSeries = 'NPVR_DELETE_SERIES',
  NpvrDeleteSingle = 'NPVR_DELETE_SINGLE',
  NpvrRecordSeason = 'NPVR_RECORD_SEASON',
  NpvrRecordSeries = 'NPVR_RECORD_SERIES',
  NpvrRecordSingle = 'NPVR_RECORD_SINGLE',
  NpvrStopRecordSeason = 'NPVR_STOP_RECORD_SEASON',
  NpvrStopRecordSeries = 'NPVR_STOP_RECORD_SERIES',
  NpvrStopRecordSingle = 'NPVR_STOP_RECORD_SINGLE',
}

export enum MutationPayloadType {
  Media = 'MEDIA',
  /** SEASON ID TYPE IS ONLY AVAILABLE FOR NORWAY ATM */
  Season = 'SEASON',
  Series = 'SERIES',
}

export type MyList = {
  __typename?: 'MyList';
  items?: Maybe<Array<MyListItem>>;
  pageInfo: PageInfo;
};

export type MyListContent = Movie | Series | SportEvent;

export enum MyListIdType {
  Media = 'MEDIA',
  Series = 'SERIES',
}

export type MyListItem = {
  __typename?: 'MyListItem';
  media?: Maybe<MyListContent>;
  posterLabels: PosterLabels;
};

export type MyListPanel = Panel & {
  __typename?: 'MyListPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  myListContent: MyListPanelContent;
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

export type MyListPanelMyListContentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type MyListPanelContent = {
  __typename?: 'MyListPanelContent';
  items?: Maybe<Array<MyListPanelItem>>;
  pageInfo: PageInfo;
};

export type MyListPanelItem = {
  __typename?: 'MyListPanelItem';
  /** @deprecated Use PosterLabels instead. */
  labels: MyListPanelLabels;
  media: MyListPanelItemContent;
  posterLabels: PosterLabels;
};

export type MyListPanelItemContent = Movie | Series | SportEvent;

export type MyListPanelLabels = {
  __typename?: 'MyListPanelLabels';
  /**
   * Typically rendered in the top left corner of a poster
   * @deprecated Use PosterLabels instead.
   */
  primary?: Maybe<Label>;
  /**
   * Typically rendered in the top right corner of a poster
   * @deprecated Use PosterLabels instead.
   */
  secondary?: Maybe<Label>;
};

export type NpvrInput = {
  id: Scalars['ID'];
  type: MutationPayloadType;
};

export type NestedCapabilities = MutationCapability;

export type NewContentLabel = {
  __typename?: 'NewContentLabel';
  text: Scalars['String'];
};

export enum NextEpisodeAccessFilter {
  All = 'ALL',
  HasAccess = 'HAS_ACCESS',
}

export type NormalChannelDisplayHint = {
  __typename?: 'NormalChannelDisplayHint';
  noProgramsText: Scalars['String'];
};

export type NpvrInfo = {
  __typename?: 'NpvrInfo';
  expirationDate: DateTime;
  originalAirDate: AirDate;
  /**
   * If the recording belongs to a series recording, then information about the series recording is provided
   * Gives null for a non series recording
   */
  series?: Maybe<SeriesInfo>;
};

export type NpvrLive = {
  __typename?: 'NpvrLive';
  playbackSpec: PlaybackSpec;
};

export type NpvrStartOver = {
  __typename?: 'NpvrStartOver';
  playbackSpec: PlaybackSpec;
};

export type NumberOfEpisodes = {
  __typename?: 'NumberOfEpisodes';
  number: Scalars['Int'];
  readable: Scalars['String'];
};

export type NumberOfSeasons = {
  __typename?: 'NumberOfSeasons';
  number: Scalars['Int'];
  readable: Scalars['String'];
};

export enum Order {
  Asc = 'ASC',
  Default = 'DEFAULT',
  Desc = 'DESC',
}

/**
 * This is a temporary solution until playback has been re-modeled
 *
 * * text + startTime fields are filtered to mediaType NEWS.
 * * previouslyAiredText is a ready to use string, but otherwise the same value as OriginalAirDate.
 */
export type OriginalAirDate = {
  __typename?: 'OriginalAirDate';
  previouslyAiredText?: Maybe<Scalars['String']>;
  startTime?: Maybe<DateTime>;
  text?: Maybe<Scalars['String']>;
};

export enum PackageType {
  Standard = 'STANDARD',
  Vas = 'VAS',
}

export type Page = {
  __typename?: 'Page';
  analytics: PageAnalytics;
  /** To be displayed when a page is empty */
  empty?: Maybe<EmptyView>;
  id: Scalars['ID'];
  pagePanels?: Maybe<PagePanels>;
  slots?: Maybe<Slots>;
  subPages?: Maybe<PageLinks>;
  title?: Maybe<Scalars['String']>;
  /** Holds market-aware links with url patterns registered by app receivers which allows deeplinking to pages. */
  universalLink?: Maybe<UniversalPageLink>;
};

export type PagePagePanelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PageSubPagesArgs = {
  input?: InputMaybe<SubPagesArgs>;
};

export type PageAnalytics = {
  __typename?: 'PageAnalytics';
  page_name: Scalars['String'];
  page_type: Scalars['String'];
};

export type PageExtraction = {
  __typename?: 'PageExtraction';
  panels?: Maybe<Array<PanelExtractionItem>>;
};

export type PageExtractionPanelsArgs = {
  config?: InputMaybe<PanelExtractionConfig>;
};

export type PageExtractionConfig = {
  id: Scalars['String'];
};

/** Holds information that should be used for pagination. */
export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  /**
   * When making a subsequent request for more data, you need to send the value of
   * 'nextPageOffset' in the 'offset' field the input for the query.
   *
   * Even though you, in your first request, asks for e.g. 10 items, there is a
   * risk that you will only get 9 items back (because something went wrong along the way),
   * therefor you need to base your 'offset' on the current offset and how many items you
   * *asked* for rather than  how many items you got back (and/or are currently holding),
   * to avoid getting duplicates.
   *
   * To avoid you having to hold a state of this, you can just use the value of
   * 'nextPageOffset' and be done with it.
   */
  nextPageOffset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PageLink = {
  __typename?: 'PageLink';
  description?: Maybe<Scalars['String']>;
  /** @deprecated Please use link3 */
  id: Scalars['String'];
  images?: Maybe<PageLinkImages>;
  /** @deprecated Please use link3 */
  link: Link;
  link3: LinkInterface;
  name?: Maybe<Scalars['String']>;
  /** @deprecated Please use link3 */
  type: PageLinkType;
};

export type PageLinkContent = {
  __typename?: 'PageLinkContent';
  /** If set and supported by your client, pre-scroll the panel to have this item index selected */
  defaultScrollPosition?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<PageLink>>;
};

export type PageLinkImages = {
  __typename?: 'PageLinkImages';
  backdrop16x9?: Maybe<Image>;
  icon1x1?: Maybe<Image>;
  showcard2x3?: Maybe<Image>;
  showcard16x9?: Maybe<Image>;
};

export type PageLinkImagesIcon1x1Args = {
  typePrio?: InputMaybe<Array<ImageContentTypePrio>>;
};

export type PageLinkPanel = Panel & {
  __typename?: 'PageLinkPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  pageLinkContent: PageLinkContent;
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

export enum PageLinkType {
  Channel = 'CHANNEL',
  Page = 'PAGE',
  /** The Panel is guaranteed to be a PageLinkPanel. Use pageLinkQuery(id: "") query to get the content. */
  Panel = 'PANEL',
  Sporteventlist = 'SPORTEVENTLIST',
  Static = 'STATIC',
  Store = 'STORE',
}

export type PageLinks = {
  __typename?: 'PageLinks';
  items?: Maybe<Array<PageLink>>;
  pageInfo: PageInfo;
};

export type PagePanels = {
  __typename?: 'PagePanels';
  id: Scalars['String'];
  items?: Maybe<Array<Panel>>;
  pageInfo: PageInfo;
  panels?: Maybe<Array<Maybe<PanelX>>>;
};

export type Panel = {
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type PanelAnalytics = {
  __typename?: 'PanelAnalytics';
  panel_id: Scalars['String'];
};

export type PanelCapability = {
  __typename?: 'PanelCapability';
  text?: Maybe<Scalars['String']>;
  type: PanelCapabilityType;
};

export enum PanelCapabilityType {
  RecentSearchesRemoveAll = 'RECENT_SEARCHES_REMOVE_ALL',
}

export enum PanelDecoration {
  EpisodeList = 'EPISODE_LIST',
  Highlight = 'HIGHLIGHT',
  Toplist = 'TOPLIST',
}

export type PanelDisplay = {
  __typename?: 'PanelDisplay';
  decoration?: Maybe<PanelDecoration>;
  layout: PanelLayout;
  posterOrientation: PosterOrientation;
};

export type PanelExtractionConfig = {
  posterLimit?: Scalars['Int'];
};

export type PanelExtractionItem = {
  __typename?: 'PanelExtractionItem';
  content?: Maybe<Array<ContentExtraction>>;
  id: Scalars['String'];
};

export type PanelFilterOptions = {
  __typename?: 'PanelFilterOptions';
  key: Scalars['String'];
  selected: Scalars['Boolean'];
  title: Scalars['String'];
};

export enum PanelLayout {
  Grid = 'GRID',
  /**
   * LIST is only applicable in specific contexts. A LIST panel may only be rendered by itself.
   * It's not mixed within other panels or other list panels. However, please don't crash if you
   * for reason were to receive multiple panels where one is a list.
   */
  List = 'LIST',
  Swimlane = 'SWIMLANE',
}

export type PanelLink = {
  __typename?: 'PanelLink';
  id: Scalars['String'];
  images?: Maybe<PanelLinkImages>;
  type: PanelLinkType;
};

export type PanelLinkImages = {
  __typename?: 'PanelLinkImages';
  /** Backdrops are _not_ title treated and you need to present the media title to make sense. */
  backdrop16x9?: Maybe<Image>;
  id: Scalars['ID'];
};

export enum PanelLinkType {
  Page = 'PAGE',
  /**
   * The panel links to itself. If you do a panel() query with the id,
   * you're guaranteed to get a panel of the same type as the PanelLink is placed on.
   */
  Self = 'SELF',
  Static = 'STATIC',
  Store = 'STORE',
}

export type PanelSortOptions = {
  __typename?: 'PanelSortOptions';
  key: Scalars['String'];
  order: SortOrder;
  selected: Scalars['Boolean'];
  title: Scalars['String'];
};

/**
 * Contains information about what topic the content of the panel belongs to.
 * Use for decoration purposes, such as to create this url: teliaplay.se/{topic}/{panel-id}
 */
export enum PanelTopic {
  Genre = 'GENRE',
}

export type PanelX =
  | ChannelsPanelX
  | HotspotLoggedOutPanelX
  | PosterListPanel
  | ShowcasePanelX
  | SingleFeaturePanelX
  | StaticNetflixPanelX
  | SubscriptionPanelX;

export type PanelsInputArgs = {
  ids: Array<Scalars['String']>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type PlaySuggestions = {
  __typename?: 'PlaySuggestions';
  panel?: Maybe<PosterListPanel>;
};

export type PlaySuggestionsInput = {
  mediaId: Scalars['String'];
};

export type PlayableLinkCapability = PosterCapability & {
  __typename?: 'PlayableLinkCapability';
  id: Scalars['ID'];
  playableLinkCapabilityType: PlayableLinkCapabilityType;
  text: Scalars['String'];
};

export enum PlayableLinkCapabilityType {
  Play = 'PLAY',
}

/**
 * Describes how the user can play the content,
 * or acquire the content if it is needed to play it.
 *
 * This is personalized data which differs between users.
 */
export type Playback = {
  __typename?: 'Playback';
  buy?: Maybe<PlaybackBuy>;
  play?: Maybe<PlaybackPlay>;
};

/** generic description of buy goes here */
export type PlaybackBuy = {
  __typename?: 'PlaybackBuy';
  deepLinks?: Maybe<Array<PlaybackBuyDeepLink>>;
  est?: Maybe<Array<PlaybackBuyVodEst>>;
  linear?: Maybe<PlaybackBuyLinear>;
  npvr?: Maybe<Array<PlaybackBuyRecording>>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-08-26.md PPP no longer exist in our service */
  ppp?: Maybe<Array<PlaybackBuyPpp>>;
  rental?: Maybe<Array<PlaybackBuyVodRental>>;
  /**
   * This field is mostly relevant for Telia applications since they don't have
   * subscriptions yet, and the playback.buy.subscriptions would be empty
   */
  subscription?: Maybe<Array<PlaybackBuyVodSubscription>>;
  subscriptions?: Maybe<Array<PlaybackBuySubscription>>;
};

export type PlaybackBuyDeepLink = {
  __typename?: 'PlaybackBuyDeepLink';
  item: DeepLink;
};

/**
 * Content that the user need to acquire by upgrading their subscription
 * before starting.
 */
export type PlaybackBuyLinear = {
  __typename?: 'PlaybackBuyLinear';
  item: Linear;
};

/** https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-08-26.md PPP no longer exist in our service */
export type PlaybackBuyPpp = {
  __typename?: 'PlaybackBuyPpp';
  item: Vod;
};

/** Content that the user needs to record before starting. */
export type PlaybackBuyRecording = {
  __typename?: 'PlaybackBuyRecording';
  item: Vod;
  recordableBefore?: Maybe<DateTime>;
};

/**
 * Subscriptions that the user need to upgrade to before starting. E.g.
 * the user is viewing an HBO item but their account does not have access
 * to HBO.
 */
export type PlaybackBuySubscription = {
  __typename?: 'PlaybackBuySubscription';
  item: SubscriptionProduct;
};

/** Content that the user needs to buy before starting. */
export type PlaybackBuyVodEst = {
  __typename?: 'PlaybackBuyVodEst';
  item: Vod;
  priceId: Scalars['String'];
};

/** Content that the user needs to rent before starting. */
export type PlaybackBuyVodRental = {
  __typename?: 'PlaybackBuyVodRental';
  item: Vod;
  priceId: Scalars['String'];
};

/**
 * Content that the user need to acquire by upgrading their subscription
 * before starting. E.g. the user is viewing an HBO item but their account
 * does not have access to HBO.
 */
export type PlaybackBuyVodSubscription = {
  __typename?: 'PlaybackBuyVodSubscription';
  item: Vod;
};

/**
 * Content is included in the user's current subscription
 * and therefore can be started right away.
 */
export type PlaybackPlay = {
  __typename?: 'PlaybackPlay';
  deepLinks?: Maybe<Array<PlaybackPlayDeepLink>>;
  est?: Maybe<Array<PlaybackPlayVodEst>>;
  linear?: Maybe<PlaybackPlayLinear>;
  npvr?: Maybe<Array<PlaybackPlayRecording>>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-08-26.md PPP no longer exist in our service */
  ppp?: Maybe<Array<PlaybackPlayPpp>>;
  rental?: Maybe<Array<PlaybackPlayVodRental>>;
  subscription?: Maybe<Array<PlaybackPlayVodSubscription>>;
};

export type PlaybackPlayDeepLink = {
  __typename?: 'PlaybackPlayDeepLink';
  item: DeepLink;
};

/** The currently active OR next available linear broadcast for this content */
export type PlaybackPlayLinear = {
  __typename?: 'PlaybackPlayLinear';
  item: Linear;
};

/** https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-08-26.md PPP no longer exist in our service */
export type PlaybackPlayPpp = {
  __typename?: 'PlaybackPlayPpp';
  item: Vod;
};

/** Content that the user has recorded and has access to. */
export type PlaybackPlayRecording = {
  __typename?: 'PlaybackPlayRecording';
  item: Vod;
  live?: Maybe<NpvrLive>;
  npvrInfo: NpvrInfo;
  startover?: Maybe<NpvrStartOver>;
};

/** Content that the user has bought and has access to. */
export type PlaybackPlayVodEst = {
  __typename?: 'PlaybackPlayVodEst';
  item: Vod;
};

/** Content that the user has rented and has access to. */
export type PlaybackPlayVodRental = {
  __typename?: 'PlaybackPlayVodRental';
  item: Vod;
  rentalInfo: RentalInfo;
};

/**
 * Content is included in the user's current subscription
 * and therefore can be started right away.
 */
export type PlaybackPlayVodSubscription = {
  __typename?: 'PlaybackPlayVodSubscription';
  item: Vod;
};

/**
 * To start playback, send this data (all fields) *as is* to the player.
 * **Never modify or use this data for anything else than to start playback.**
 */
export type PlaybackSpec = {
  __typename?: 'PlaybackSpec';
  accessControl: Scalars['String'];
  videoId: Scalars['String'];
  videoIdType: Scalars['String'];
  watchMode: Scalars['String'];
};

export type PlayerRelatedContent = {
  __typename?: 'PlayerRelatedContent';
  id: Scalars['ID'];
  panel: PlayerRelatedContentPanel;
  panelX: PosterListPanel;
};

export enum PlayerRelatedContentAccessFilter {
  All = 'ALL',
  HasAccess = 'HAS_ACCESS',
}

export type PlayerRelatedContentInput = {
  playbackMode: PlayerRelatedContentPlaybackMode;
  userAccessFilter?: InputMaybe<PlayerRelatedContentAccessFilter>;
  windowStartAt: Scalars['Timestamp'];
};

export type PlayerRelatedContentPanel = TimelinePanel;

export enum PlayerRelatedContentPlaybackMode {
  Catchup = 'CATCHUP',
  Live = 'LIVE',
}

export type Poster = {
  __typename?: 'Poster';
  a11y: PosterA11y;
  analytics?: Maybe<AssetAnalytics>;
  capabilities: Array<PosterCapability>;
  details: PosterDetails;
  id: Scalars['ID'];
  image?: Maybe<PosterImage>;
  labels: PosterLabels;
  link: SelectableLink;
  prefetch?: Maybe<PosterPrefetch>;
  progress?: Maybe<AllProgress>;
};

export type PosterCapabilitiesArgs = {
  supported: Array<SupportedPosterCapability>;
};

export type PosterImageArgs = {
  typePrio?: InputMaybe<Array<ImageContentTypePrio>>;
};

export type PosterA11y = {
  __typename?: 'PosterA11y';
  contentLabel?: Maybe<Scalars['String']>;
};

export type PosterCapability = {
  text: Scalars['String'];
};

export type PosterDetails = {
  __typename?: 'PosterDetails';
  actionPresenter?: Maybe<PosterDetailsActionPresenter>;
  aside?: Maybe<PosterDetailsAside>;
  focus?: Maybe<PosterDetailsFocus>;
  hover?: Maybe<PosterDetailsHover>;
  overlay?: Maybe<PosterDetailsOverlay>;
  subPoster?: Maybe<PosterDetailsSubPoster>;
};

export type PosterDetailsActionPresenter = {
  __typename?: 'PosterDetailsActionPresenter';
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
};

export type PosterDetailsAside = {
  __typename?: 'PosterDetailsAside';
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  metadataRowOne?: Maybe<Array<MetadataItem>>;
  metadataRowTwo?: Maybe<Array<MetadataItem>>;
};

export type PosterDetailsFocus = {
  __typename?: 'PosterDetailsFocus';
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Label>>;
  /** Team logos, if available */
  logos?: Maybe<Array<Image>>;
  metadataRowOne?: Maybe<Array<MetadataItem>>;
};

export type PosterDetailsHover = {
  __typename?: 'PosterDetailsHover';
  header?: Maybe<Scalars['String']>;
  metadataRowOne?: Maybe<Array<MetadataItem>>;
  metadataRowTwo?: Maybe<Array<MetadataItem>>;
};

export type PosterDetailsOverlay = {
  __typename?: 'PosterDetailsOverlay';
  header?: Maybe<Scalars['String']>;
  metadataRowOne?: Maybe<Array<MetadataItem>>;
  /**
   * The placeholder text is meant to be shown while the poster image is being loaded and removed from UI on success, if that's wanted behaviour on your client.
   * Placeholder text is also meant to be shown if there's no poster image available. Most probably centered in the middle of the poster.
   */
  placeholder?: Maybe<Scalars['String']>;
};

export type PosterDetailsSubPoster = {
  __typename?: 'PosterDetailsSubPoster';
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  metadataRowOne?: Maybe<Array<MetadataItem>>;
};

/**
 * **The image url must be passed through the image proxy.**
 * Client's *can not* request the image directly, as the image origins
 * can not handle that type of load.
 *
 * Example:
 *
 * `https://imageproxy.b17g.services/convert/?resize=152x80&shape=nocut&source=<source>`
 *
 *
 * **This is also true for SVG images**, however they can not be resized,
 * but can be requested without resizing params:
 * `https://imageproxy.b17g.services/convert/?source=<source>`
 */
export type PosterImage = {
  __typename?: 'PosterImage';
  contentType: ImageContentType;
  /** **The image url must be passed through the image proxy.** */
  source: Scalars['String'];
  /**
   * **The image url must encoded and be passed through the image proxy.**
   * Prefer using `source` instead of `sourceNonEncoded`
   * @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-11-20.md Use 'source'
   */
  sourceNonEncoded: Scalars['String'];
};

export type PosterItems = {
  __typename?: 'PosterItems';
  collectionPageInfo: CollectionPageInfo;
  /** If set and supported by your client, pre-scroll the panel to have this item index selected */
  defaultScrollPosition?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<PanelFilterOptions>>;
  filters?: Maybe<FilterSection>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<Poster>>>;
  /** @deprecated Use collectionPageInfo instead. */
  pageInfo: PageInfo;
  refreshAt?: Maybe<DateTime>;
  showMore?: Maybe<SelectableLink>;
  sortOptions?: Maybe<Array<PanelSortOptions>>;
  /**
   * The values in the trackingItems are a best effort from the graph side, e.g in some cases an index could be slightly off.
   * Clients could override those values if they them to be _exact_ - otherwise just pass this on to core tracking
   * @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead.
   */
  trackingItems?: Maybe<Array<Maybe<TrackingItem>>>;
};

export type PosterLabels = {
  __typename?: 'PosterLabels';
  /** Typically rendered in the top left corner of a poster */
  primary?: Maybe<Label>;
  /** Typically rendered in the top right corner of a poster */
  secondary?: Maybe<Label>;
};

export type PosterListPanel = {
  __typename?: 'PosterListPanel';
  analytics: PanelAnalytics;
  capabilities?: Maybe<Array<PanelCapability>>;
  /** @deprecated For debug purposes only */
  debug?: Maybe<Scalars['String']>;
  display: PanelDisplay;
  empty?: Maybe<EmptyView>;
  /**
   * To be displayed when a panel is empty
   * e. g My List
   * @deprecated Use field 'empty' instead
   */
  emptyText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kicker?: Maybe<Scalars['String']>;
  posters?: Maybe<PosterItems>;
  refreshEvents?: Maybe<Array<RefreshEvent>>;
  title?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead. */
  trackingData: Scalars['String'];
};

export type PosterListPanelCapabilitiesArgs = {
  supported: Array<SupportedPanelCapability>;
};

export type PosterListPanelPostersArgs = {
  config?: InputMaybe<PosterListPanelInput>;
};

export type PosterListPanelInput = {
  filter2?: InputMaybe<Array<FilterInput>>;
  /**
   * Limit is treated as a request rather than an order. In certain cases the Graph may respond with more data than asked for simply because it should be displayed
   *
   * Example of such a case is when displaying all play stores in a grid. No matter what limit is requested, the graph will return _all_ play stores.
   */
  limit: Scalars['Int'];
  offset?: Scalars['Int'];
  sort?: InputMaybe<PosterSortInput>;
  specifics?: InputMaybe<PosterSpecificsInput>;
};

export enum PosterOrientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE',
}

export type PosterPrefetch = {
  __typename?: 'PosterPrefetch';
  cdp?: Maybe<CdpPrefetch>;
};

export type PosterSortInput = {
  key: Scalars['String'];
  order: SortOrder;
};

export type PosterSpecificsInput = {
  episodeList?: InputMaybe<EpisodeListInput>;
  previousSearches?: InputMaybe<PreviousSearchesInput>;
  regionalChannelsSelection?: InputMaybe<Array<Scalars['String']>>;
};

/**
 * Use if you need to prefetch data to display in a subsequent view, to make a smooth transition.
 * Do **not** use this data to display in the current view.
 */
export type PrefetchSelectableLink = {
  __typename?: 'PrefetchSelectableLink';
  image?: Maybe<Image>;
  title?: Maybe<Scalars['String']>;
};

export type PremiereAnnouncementLabel = {
  __typename?: 'PremiereAnnouncementLabel';
  text: Scalars['String'];
};

export type PreviousSearchesIds = {
  id: Scalars['String'];
  type: MediaAndSeriesType;
};

export type PreviousSearchesInput = {
  ids?: InputMaybe<Array<PreviousSearchesIds>>;
  posterIds?: InputMaybe<Array<Scalars['String']>>;
};

export type Price = {
  __typename?: 'Price';
  readable: Scalars['String'];
};

export type Profile = {
  __typename?: 'Profile';
  ageGroup: AgeGroup;
  alias: Scalars['String'];
  avatar: Avatar;
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  /** @deprecated No longer used */
  theme?: Maybe<Theme>;
};

export type ProfileResources = {
  __typename?: 'ProfileResources';
  avatars: Array<Avatar>;
  /** @deprecated No longer used */
  themes: Array<Theme>;
};

export type Program = {
  __typename?: 'Program';
  analytics: AssetAnalytics;
  /** Broadcast end time. Playback can only be started before this. */
  endTime: DateTime;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-04-24.md Use playback on the media. */
  hasCatchup: Scalars['Boolean'];
  id: Scalars['ID'];
  /**
   * Broadcast in real-time, as events happen, in the present.
   * E.g. a live broadcasted soccer game.
   * Not to be confused with if this content currently available.
   * This is information given to use by the provider and
   * content may be lacking this live flag, even if it is indeed live.
   * Only use this to decorate the UI, not to do logical decisions.
   */
  live: Scalars['Boolean'];
  media: ProgramMedia;
  programCDP: ProgramCdp;
  /**
   * If this is a re-run and has been aired previously.
   * Only use this to decorate the UI, not to do logical decisions.
   */
  rerun: Scalars['Boolean'];
  /** Broadcast start time. Playback can only be started after this. */
  startTime: DateTime;
  /** Shorthand to get the title of the media or series that this program references */
  title: Scalars['String'];
};

export type ProgramByTimeInput = {
  /** If fillGaps is explicitly set to true, program.endTime will be extended until the next programs startTime */
  fillGaps?: InputMaybe<Scalars['Boolean']>;
  /**
   * Show programs starting at or after this timestamp. If 'includeStartedBeforeFrom' is set to true,
   * the returned list will also contain any program _ongoing_ at 'from'.
   */
  from: Scalars['Timestamp'];
  /** For pagination reasons you might or might not want to include programs that started before the 'from' timestamp */
  includeStartedBeforeFrom?: InputMaybe<Scalars['Boolean']>;
  /** Show programs starting starting before this timestamp */
  to: Scalars['Timestamp'];
};

export type ProgramCdp = {
  __typename?: 'ProgramCDP';
  description?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  metadataRowOne?: Maybe<Array<MetadataItem>>;
  metadataRowTwo?: Maybe<Array<MetadataItem>>;
};

export type ProgramMedia = Episode | Movie | SportEvent;

export type Programs = {
  __typename?: 'Programs';
  id: Scalars['ID'];
  pageInfo: PageInfo;
  programItems: Array<Program>;
};

export type ProgramsTimetable = {
  __typename?: 'ProgramsTimetable';
  programs: ProgramsTimetablePrograms;
  /**
   * Table of content. Contains a list of all days that EPG is available from.
   * The amount of items may differ between brands.
   */
  toc: Array<ProgramsTimetableTocItem>;
};

export type ProgramsTimetableProgramsArgs = {
  input: ProgramsTimetableProgramsInput;
};

export type ProgramsTimetablePrograms = {
  __typename?: 'ProgramsTimetablePrograms';
  collectionPageInfo: CollectionPageInfo;
  id: Scalars['ID'];
  programItems: Array<Program>;
};

export type ProgramsTimetableProgramsInput = {
  /** If fillGaps is explicitly set to true, program.endTime will be extended until the next programs startTime */
  fillGaps?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  /** Offset may be negative to get programs aired before timestamp */
  offset: Scalars['Int'];
  timestamp: Scalars['Timestamp'];
};

export type ProgramsTimetableTocItem = {
  __typename?: 'ProgramsTimetableTocItem';
  firstProgramStartTime?: Maybe<DateTime>;
  name: Scalars['String'];
  numberOfPrograms: Scalars['Int'];
};

export type ProgramsTimetableTocItemNameArgs = {
  format?: InputMaybe<ProgramsTimetableTocItemNameFormat>;
};

export enum ProgramsTimetableTocItemNameFormat {
  /** Will return e.g. "Söndag 17 oktober". */
  Long = 'LONG',
  /** Will return e.g. "17 okt.". */
  Short = 'SHORT',
}

export type Progress = {
  __typename?: 'Progress';
  id?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  /** E.g. '37 min left'. */
  timeLeft?: Maybe<Scalars['String']>;
};

export type PromotedSubscriptionBadge = {
  __typename?: 'PromotedSubscriptionBadge';
  name: Scalars['String'];
};

/**
 * If non-null, the client **must** for contractual reasons, display the icon
 * in close proximity to any media imagery. The client **may** link to the link if it's provided.
 */
export type Provider = {
  __typename?: 'Provider';
  icons: Icons;
  /** @deprecated Please use link3 */
  link?: Maybe<ProviderLink>;
  /** @deprecated Please use link3 */
  link2?: Maybe<Link>;
  link3?: Maybe<LinkInterface>;
  name: Scalars['String'];
};

export type ProviderLink = {
  __typename?: 'ProviderLink';
  /** @deprecated Please use LinkInterface based links */
  id: Scalars['String'];
  /** @deprecated Please use LinkInterface based links */
  type: PageLinkType;
};

export type PurchasedBadge = {
  __typename?: 'PurchasedBadge';
  text: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** @deprecated Don't use. Work in progress. */
  cdp?: Maybe<CdpData>;
  channel?: Maybe<Channel>;
  channelMetadataList: ChannelMetadataList;
  channels: Channels;
  /** @deprecated Don't even think of using this in prod */
  debug?: Maybe<Scalars['String']>;
  editorialPage?: Maybe<EditorialPage>;
  episode?: Maybe<Episode>;
  mainMenu?: Maybe<MainMenu>;
  mainMenuTree?: Maybe<MainMenuTree>;
  media?: Maybe<Media>;
  medias?: Maybe<Array<Maybe<Media>>>;
  /** Limit: max 50 IDs. */
  mediasAndSeries?: Maybe<Array<Maybe<MediasAndSeries>>>;
  messageView: MessageView;
  messages?: Maybe<Messages>;
  /** N.B. This query may only be used if the graph-team have explicitly approved it. */
  metadataExtraction: MetadataExtraction;
  miniBlock?: Maybe<MiniBlock>;
  movie?: Maybe<Movie>;
  /** @deprecated Please do a normal query for either page(id:'container-my-list'), or if unable to do that, a query for panelX(id:'known-panel|container-my-list' */
  myList?: Maybe<MyList>;
  page?: Maybe<Page>;
  panel?: Maybe<Panel>;
  panelX?: Maybe<PanelX>;
  playSuggestions?: Maybe<PlaySuggestions>;
  recordingsListPage?: Maybe<RecordingsListPage>;
  recordingsListPageContent?: Maybe<RecordingsListPageContent>;
  refreshMedias?: Maybe<RefreshMedias>;
  regionalChannels?: Maybe<RegionalChannelsList>;
  rentals?: Maybe<Rentals>;
  search2?: Maybe<SearchResult>;
  /** @deprecated Should not be needed. Use 'panelX' query instead. Tell Graph team if that doesn't suffice */
  season?: Maybe<Season>;
  series?: Maybe<Series>;
  sportEvent?: Maybe<SportEvent>;
  /** Returns at most 100 items */
  sportEventList?: Maybe<SportEventList>;
  store?: Maybe<StorePage>;
  subscriptionProduct?: Maybe<SubscriptionProduct>;
  topShelf: TopShelf;
  topShelf2?: Maybe<TopShelf2>;
  uiResources: UiResources;
  upsellPage: UpsellPage;
  user?: Maybe<User>;
};

export type QueryCdpArgs = {
  id: Scalars['String'];
};

export type QueryChannelArgs = {
  id: Scalars['String'];
  regionalChannelsSelection?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryChannelMetadataListArgs = {
  input?: InputMaybe<ChannelMetadataListInput>;
};

export type QueryChannelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  regionalChannelsSelection?: InputMaybe<Array<Scalars['String']>>;
  userAccessFilter?: InputMaybe<ChannelAccessFilter>;
};

export type QueryEditorialPageArgs = {
  id: Scalars['String'];
};

export type QueryEpisodeArgs = {
  id: Scalars['String'];
};

export type QueryMediaArgs = {
  id: Scalars['String'];
};

export type QueryMediasArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryMediasAndSeriesArgs = {
  input: MediasAndSeriesInput;
};

export type QueryMiniBlockArgs = {
  id: Scalars['String'];
};

export type QueryMovieArgs = {
  id: Scalars['String'];
};

export type QueryMyListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPageArgs = {
  id: Scalars['String'];
};

export type QueryPanelArgs = {
  id: Scalars['String'];
};

export type QueryPanelXArgs = {
  id: Scalars['String'];
};

export type QueryPlaySuggestionsArgs = {
  config: PlaySuggestionsInput;
};

export type QueryRecordingsListPageContentArgs = {
  config: RecordingsListPageContentConfig;
};

export type QueryRefreshMediasArgs = {
  config: RefreshMediasInput;
};

export type QueryRentalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySearch2Args = {
  input: SearchInputArgs;
};

export type QuerySeasonArgs = {
  seasonId: Scalars['String'];
};

export type QuerySeriesArgs = {
  id: Scalars['String'];
};

export type QuerySportEventArgs = {
  id: Scalars['String'];
};

export type QuerySportEventListArgs = {
  input: SportEventListInput;
};

export type QueryStoreArgs = {
  id: Scalars['String'];
};

export type QuerySubscriptionProductArgs = {
  id: Scalars['String'];
};

export type QueryUpsellPageArgs = {
  input: UpsellPageInput;
};

export type QuotaInformationMiniBlock = {
  __typename?: 'QuotaInformationMiniBlock';
  id: Scalars['String'];
  quota?: Maybe<QuotaInformationMiniBlockQuota>;
  refreshEvents?: Maybe<Array<RefreshEvent>>;
};

export type QuotaInformationMiniBlockQuota = {
  __typename?: 'QuotaInformationMiniBlockQuota';
  /** E.g. '302 hours available' */
  availableText?: Maybe<Scalars['String']>;
  /** Color name from design tokens */
  barColor: Scalars['String'];
  /** 0-100%. Use to display a quota bar */
  barPercent: Scalars['Int'];
  /** May hold important about the quota. E.g. being close to, or over, quota */
  infoText?: Maybe<Scalars['String']>;
  /** E.g. '450/500' */
  usageText?: Maybe<Scalars['String']>;
};

export type Rating = {
  __typename?: 'Rating';
  numberOfVotes: Scalars['Int'];
  readableScore: Scalars['String'];
  url: Scalars['String'];
};

export type Ratings = {
  __typename?: 'Ratings';
  imdb?: Maybe<Rating>;
};

export type RecordingEpisodes = {
  __typename?: 'RecordingEpisodes';
  ongoing: Scalars['Int'];
  recorded: Scalars['Int'];
  scheduled: Scalars['Int'];
};

export enum RecordingIdType {
  Media = 'MEDIA',
  Series = 'SERIES',
}

export type RecordingList = {
  __typename?: 'RecordingList';
  items: Array<Maybe<RecordingListItem>>;
  pageInfo: PageInfo;
};

export type RecordingListContent = Episode | Movie | Series | SportEvent;

export type RecordingListItem = {
  __typename?: 'RecordingListItem';
  id: Scalars['ID'];
  media: RecordingListContent;
  npvrInfo: NpvrInfo;
};

export type RecordingListSortInput = {
  key: Scalars['String'];
  order?: RecordingListSortOrder;
};

export enum RecordingListSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum RecordingListTypeInput {
  Program = 'PROGRAM',
  Series = 'SERIES',
}

export type RecordingResultMedia = Episode | Movie | Series | SportEvent;

export enum RecordingState {
  Ongoing = 'ONGOING',
  Recorded = 'RECORDED',
  Scheduled = 'SCHEDULED',
}

export enum RecordingsListItemCardDecoration {
  Multi = 'MULTI',
  Single = 'SINGLE',
}

export type RecordingsListPage = {
  __typename?: 'RecordingsListPage';
  slots?: Maybe<Slots>;
  subMenu?: Maybe<Array<RecordingsListPageSubMenuItem>>;
};

export type RecordingsListPageContent = {
  __typename?: 'RecordingsListPageContent';
  collectionPageInfo: CollectionPageInfo;
  items?: Maybe<Array<RecordingsListPageItem>>;
  refreshAt?: Maybe<DateTime>;
  sortOptions?: Maybe<Array<PanelSortOptions>>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead. */
  trackingItems?: Maybe<Array<TrackingItem>>;
};

export type RecordingsListPageContentConfig = {
  id: Scalars['String'];
  limit: Scalars['Int'];
  offset?: Scalars['Int'];
  sort?: InputMaybe<PosterSortInput>;
};

export type RecordingsListPageItem = {
  __typename?: 'RecordingsListPageItem';
  analytics?: Maybe<AssetAnalytics>;
  capabilities?: Maybe<Array<PosterCapability>>;
  details: RecordingsListPageItemDetails;
  header: Scalars['String'];
  id: Scalars['ID'];
  link: SelectableLink;
  progress?: Maybe<AllProgress>;
  recordingTime: Scalars['String'];
};

export type RecordingsListPageItemCapabilitiesArgs = {
  supported: Array<SupportedPosterCapability>;
};

export type RecordingsListPageItemDetails = {
  __typename?: 'RecordingsListPageItemDetails';
  card?: Maybe<RecordingsListPageItemDetailsCard>;
  focus?: Maybe<RecordingsListPageItemDetailsFocus>;
};

export type RecordingsListPageItemDetailsCard = {
  __typename?: 'RecordingsListPageItemDetailsCard';
  decoration: RecordingsListItemCardDecoration;
  labels?: Maybe<Array<Label>>;
  metadataRowOne?: Maybe<Array<MetadataItem>>;
  metadataRowTwo?: Maybe<Array<MetadataItem>>;
};

export type RecordingsListPageItemDetailsFocus = {
  __typename?: 'RecordingsListPageItemDetailsFocus';
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  metadataRowOne?: Maybe<Array<MetadataItem>>;
  metadataRowTwo?: Maybe<Array<MetadataItem>>;
};

export type RecordingsListPageSubMenuItem = {
  __typename?: 'RecordingsListPageSubMenuItem';
  id: Scalars['ID'];
  text: Scalars['String'];
};

/** RecordingsPanel will only be returned for the Finnish market. No need to query for it for other markets. */
export type RecordingsPanel = Panel & {
  __typename?: 'RecordingsPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  recordingsContent: RecordingsPanelContent;
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

/** RecordingsPanel will only be returned for the Finnish market. No need to query for it for other markets. */
export type RecordingsPanelRecordingsContentArgs = {
  input?: InputMaybe<RecordingsPanelInput>;
};

export type RecordingsPanelContent = {
  __typename?: 'RecordingsPanelContent';
  id: Scalars['ID'];
  items?: Maybe<Array<RecordingListItem>>;
  pageInfo: PageInfo;
  refreshAt?: Maybe<DateTime>;
  /** If set, this panel can be sorted. Present the options as is, in the received order. Send in key and order to resort. */
  sortOptions?: Maybe<Array<PanelSortOptions>>;
};

export type RecordingsPanelInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<RecordingListSortInput>;
};

export enum RefreshEvent {
  ContinueWatching = 'CONTINUE_WATCHING',
  Favorite = 'FAVORITE',
  FavoriteChannels = 'FAVORITE_CHANNELS',
  Npvr = 'NPVR',
  RecentSearch = 'RECENT_SEARCH',
  Rental = 'RENTAL',
}

export type RefreshMediaMedia = Episode | Movie | Series | SportEvent;

export type RefreshMedias = {
  __typename?: 'RefreshMedias';
  medias?: Maybe<Array<Maybe<RefreshMediaMedia>>>;
  posters?: Maybe<Array<Poster>>;
};

export type RefreshMediasIds = {
  id: Scalars['String'];
  type: MediaAndSeriesType;
};

export type RefreshMediasInput = {
  ids: Array<RefreshMediasIds>;
};

export type RegionalChannel = {
  __typename?: 'RegionalChannel';
  /** Is default for the current user. */
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionName: Scalars['String'];
};

export type RegionalChannels = {
  __typename?: 'RegionalChannels';
  id: Scalars['ID'];
  pageInfo: PageInfo;
  regionalChannelItems?: Maybe<Array<RegionalChannel>>;
};

export type RegionalChannelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RegionalChannelsList = {
  __typename?: 'RegionalChannelsList';
  items: Array<RegionalChannelsListItem>;
};

export type RegionalChannelsListItem = {
  __typename?: 'RegionalChannelsListItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  regionalChannels: Array<RegionalChannel>;
};

export type RelatedPanels = {
  __typename?: 'RelatedPanels';
  items?: Maybe<Array<Panel>>;
  pageInfo: PageInfo;
  panels?: Maybe<Array<PosterListPanel>>;
};

export type RelatedPanelsItemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type RelatedPanelsPanelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type RemoveFromMyListInput = {
  id: Scalars['String'];
  type: MyListIdType;
};

export type RemoveFromMyListResult = RemoveFromMyListResultGeneralError | RemoveFromMyListResultOk;

export type RemoveFromMyListResultGeneralError = Error & {
  __typename?: 'RemoveFromMyListResultGeneralError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type RemoveFromMyListResultOk = {
  __typename?: 'RemoveFromMyListResultOk';
  content?: Maybe<MyListContent>;
  refreshMedias?: Maybe<RefreshMedias>;
};

export type RemoveFromUserChannelListResult =
  | RemoveFromUserChannelListResultGeneralError
  | RemoveFromUserChannelListResultOk;

export type RemoveFromUserChannelListResultGeneralError = Error & {
  __typename?: 'RemoveFromUserChannelListResultGeneralError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type RemoveFromUserChannelListResultOk = {
  __typename?: 'RemoveFromUserChannelListResultOk';
  channelId: Scalars['ID'];
};

export type RemoveListFromContinueWatchingInput = {
  ids: Array<Scalars['ID']>;
};

export type RemoveListFromContinueWatchingResult =
  | RemoveListFromContinueWatchingResultGeneralError
  | RemoveListFromContinueWatchingResultOk;

export type RemoveListFromContinueWatchingResultGeneralError = Error & {
  __typename?: 'RemoveListFromContinueWatchingResultGeneralError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type RemoveListFromContinueWatchingResultOk = {
  __typename?: 'RemoveListFromContinueWatchingResultOk';
  ids: Array<Scalars['ID']>;
  refreshMedias?: Maybe<RefreshMedias>;
};

export type Rental = {
  __typename?: 'Rental';
  endTime: DateTime;
  media?: Maybe<RentalMedia>;
  mediaId?: Maybe<Scalars['ID']>;
  startTime: DateTime;
};

/** Holds rental information if content is currently rented. */
export type RentalInfo = {
  __typename?: 'RentalInfo';
  endTime: DateTime;
};

export type RentalMedia = Episode | Movie;

export type RentalSeriesBadge = {
  __typename?: 'RentalSeriesBadge';
  text: Scalars['String'];
};

export type Rentals = {
  __typename?: 'Rentals';
  pageInfo: PageInfo;
  rentalItems?: Maybe<Array<Rental>>;
};

export type RentalsPanel = Panel & {
  __typename?: 'RentalsPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  rentalsContent?: Maybe<RentalsPanelContent>;
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

export type RentalsPanelRentalsContentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RentalsPanelContent = {
  __typename?: 'RentalsPanelContent';
  items?: Maybe<Array<RentalsPanelItem>>;
  pageInfo: PageInfo;
};

export type RentalsPanelItem = {
  __typename?: 'RentalsPanelItem';
  media: RentalsPanelItemContent;
  posterLabels: PosterLabels;
};

export type RentalsPanelItemContent = Movie | Series;

export type RentedBadge = {
  __typename?: 'RentedBadge';
  text: Scalars['String'];
};

export type RichText = {
  __typename?: 'RichText';
  markdown: Scalars['String'];
};

export type ScheduleRecordingResponseMessage = {
  __typename?: 'ScheduleRecordingResponseMessage';
  /** Color name from design tokens */
  backgroundColor: Scalars['String'];
  text: Scalars['String'];
  textColor: Scalars['String'];
};

export type ScheduleRecordingResult = ScheduleRecordingResultError | ScheduleRecordingResultOk;

export type ScheduleRecordingResultError = Error & {
  __typename?: 'ScheduleRecordingResultError';
  /** Possible code: SCHEDULE_RECORDING_GENERIC_FAULT, SCHEDULE_RECORDING_QUOTA_FULL_ERROR */
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ScheduleRecordingResultOk = {
  __typename?: 'ScheduleRecordingResultOk';
  media?: Maybe<RecordingResultMedia>;
  refreshMedias?: Maybe<RefreshMedias>;
  scheduleRecordingMessage?: Maybe<ScheduleRecordingResponseMessage>;
};

export type SearchInputArgs = {
  /**
   * * If supplied as set to 'true', search will include rentals and upsell svods
   * * If supplied as set to 'false', search will not include rentals or upsell svods
   * * If not supplied, then 'searchType.rentals' will determine if rentals is included, and the server
   *   default will determine if upsell svods should be included.
   *
   * If set, 'searchType.rentals' will be ignored
   */
  includeOutOfEngagement?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  mediaTypesFilter?: InputMaybe<Array<SearchMediaType>>;
  offset?: InputMaybe<Scalars['Int']>;
  q: Scalars['String'];
  searchType?: InputMaybe<SearchTypeConfig>;
};

export enum SearchMediaType {
  Clip = 'clip',
  Movie = 'movie',
  News = 'news',
  Program = 'program',
  ScriptedSeries = 'scripted_series',
  Sport = 'sport',
}

export enum SearchRentalsType {
  All = 'ALL',
  None = 'NONE',
}

export type SearchResult = {
  __typename?: 'SearchResult';
  pageInfo: PageInfo;
  posters: Array<Poster>;
  searchItems: Array<SearchResultItem>;
  /**
   * The values in the trackingItems are a best effort from the graph side, e.g in some cases an index could be slightly off.
   * Clients could override those values if they them to be _exact_ - otherwise just pass this on to core tracking
   * @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead.
   */
  trackingItems: Array<TrackingItem>;
};

export type SearchResultContent = Episode | Movie | Series | SportEvent;

export type SearchResultItem = {
  __typename?: 'SearchResultItem';
  media?: Maybe<SearchResultContent>;
  posterLabels: PosterLabels;
};

export enum SearchSubscriptionType {
  All = 'ALL',
  InSubscription = 'IN_SUBSCRIPTION',
  None = 'NONE',
}

export type SearchTypeConfig = {
  /** Will be ignored if includeOutOfEngagement is set to any value */
  rentals?: InputMaybe<SearchRentalsType>;
};

export type Season = {
  __typename?: 'Season';
  description?: Maybe<Scalars['String']>;
  episodes2: EpisodesContent;
  id: Scalars['ID'];
  panel: PosterListPanel;
  seasonNumber: SeasonNumber;
};

export type SeasonDescriptionArgs = {
  length?: InputMaybe<DescriptionLength>;
};

export type SeasonEpisodes2Args = {
  input: EpisodesListInput;
};

export type SeasonLink = {
  __typename?: 'SeasonLink';
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-10-22.md Use 'panelX' query instead */
  id: Scalars['ID'];
  numberOfEpisodes: NumberOfEpisodes;
  panelId: Scalars['String'];
  seasonNumber: SeasonNumber;
};

export type SeasonLinkInput = {
  filter2?: InputMaybe<Array<FilterInput>>;
};

/** Intended use it to render a list of seasons cheaper than to iterate over Series.seasons */
export type SeasonLinks = {
  __typename?: 'SeasonLinks';
  filterOptions?: Maybe<Array<PanelFilterOptions>>;
  filters: FilterSection;
  items: Array<SeasonLink>;
};

export type SeasonNumber = {
  __typename?: 'SeasonNumber';
  number: Scalars['Int'];
  readable: Scalars['String'];
};

export type SelectableLink = {
  __typename?: 'SelectableLink';
  images?: Maybe<SelectableLinkImages>;
  /** @deprecated Please use link3 */
  link: Link;
  link3: LinkInterface;
  prefetch?: Maybe<PrefetchSelectableLink>;
};

export type SelectableLinkImages = {
  __typename?: 'SelectableLinkImages';
  /** Backdrops are _not_ title treated and you need to present the media title to make sense. */
  backdrop16x9?: Maybe<Image>;
  id: Scalars['ID'];
};

export type SelectionMediaPanel = Panel & {
  __typename?: 'SelectionMediaPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  /** @deprecated Please use showMore */
  link?: Maybe<PanelLink>;
  selectionMediaContent: SelectionMediaPanelContent;
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
  topic?: Maybe<PanelTopic>;
};

export type SelectionMediaPanelSelectionMediaContentArgs = {
  config?: InputMaybe<SelectionMediaPanelConfig>;
};

export type SelectionMediaPanelConfig = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SelectionMediaPanelSortInput>;
};

export type SelectionMediaPanelContent = {
  __typename?: 'SelectionMediaPanelContent';
  id: Scalars['ID'];
  items?: Maybe<Array<MediaPanelItem>>;
  pageInfo: PageInfo;
  /** If set, this panel can be sorted. Present the options as is, in the received order. Send in key and order to resort. */
  sortOptions?: Maybe<Array<PanelSortOptions>>;
};

/**
 * If no _SelectionMediaPanelSortInput_ is provided, the default
 * sort of the returned SelectionMediaPanel will be used.
 * The default sort may be different on different stored searches.
 */
export type SelectionMediaPanelSortInput = {
  key: Scalars['String'];
  order: SortOrder;
};

export enum SelectionMediaPanelSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SelectionMediaPanelSortType {
  Availability = 'AVAILABILITY',
  Imdb = 'IMDB',
  Popularity = 'POPULARITY',
  Popularity_1D = 'POPULARITY_1D',
  Popularity_7D = 'POPULARITY_7D',
  Popularity_30D = 'POPULARITY_30D',
  ProductionYear = 'PRODUCTION_YEAR',
  Title = 'TITLE',
}

export type Series = {
  __typename?: 'Series';
  actors?: Maybe<Array<Scalars['String']>>;
  ageRating?: Maybe<AgeRating>;
  badges: SeriesBadges;
  cdp: CdpData;
  channel?: Maybe<Channel>;
  /**
   * **For internal debugging only**. May change or be removed without any notice.
   * The id of the episode from which the series object is created.
   * @deprecated For internal debugging only
   */
  debugBaseMetadataId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  directors?: Maybe<Array<Scalars['String']>>;
  genre?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: SeriesImages;
  isRecordable: Scalars['Boolean'];
  /** @deprecated Should NOT needed. */
  isRentalSeries: Scalars['Boolean'];
  /** @deprecated Use cdp.labels instead. */
  label?: Maybe<Scalars['String']>;
  /** @deprecated There should be no need for this. Let Graph team know if you're basing logic on this */
  mediaType?: Maybe<MediaType>;
  /** Number of episodes that's currently playable on the current device. */
  numberOfEpisodes: NumberOfEpisodes;
  /** Number of seasons that currently have at least one playable episode on the current device. */
  numberOfSeasons: NumberOfSeasons;
  provider?: Maybe<Provider>;
  ratings: Ratings;
  relatedPanels?: Maybe<RelatedPanels>;
  seasonLinks: SeasonLinks;
  /** @deprecated Use provider instead. Let the Graph team know if this doesn't suffice */
  store?: Maybe<Store>;
  subGenres?: Maybe<Array<Scalars['String']>>;
  suggestedEpisode?: Maybe<Episode>;
  title: Scalars['String'];
  /** Market-aware links with url patterns registered by app receivers which allows deeplinking to series CDP. If series is considered a Webview Series, this is reflected in the returned link */
  universalLink?: Maybe<UniversalSeriesLink>;
  upcomingEpisode?: Maybe<UpcomingEpisode>;
  userData?: Maybe<SeriesUserData>;
  /**
   * Series Vignettes fallback to episode vignettes if a series one is not available.
   * @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used
   */
  vignette: Vignettes;
  /** @deprecated No longer used. */
  webview?: Maybe<Webview>;
};

export type SeriesDescriptionArgs = {
  length?: InputMaybe<DescriptionLength>;
};

export type SeriesNumberOfEpisodesArgs = {
  npvrState?: InputMaybe<Array<RecordingState>>;
};

export type SeriesNumberOfSeasonsArgs = {
  npvrState?: InputMaybe<Array<RecordingState>>;
};

export type SeriesSeasonLinksArgs = {
  input?: InputMaybe<SeasonLinkInput>;
  npvrState?: InputMaybe<Array<RecordingState>>;
};

export type SeriesBadges = {
  __typename?: 'SeriesBadges';
  promotedSubscription?: Maybe<PromotedSubscriptionBadge>;
  rental?: Maybe<RentalSeriesBadge>;
  surroundSound?: Maybe<SurroundSoundBadge>;
  uhd?: Maybe<UhdBadge>;
};

export type SeriesImages = {
  __typename?: 'SeriesImages';
  /** Backdrops are _not_ title treated and you need to present the media title to make sense. */
  backdrop16x9?: Maybe<Image>;
  id: Scalars['ID'];
  showcard2x3?: Maybe<ShowcardImage>;
  showcard16x9?: Maybe<ShowcardImage>;
};

export type SeriesInfo = {
  __typename?: 'SeriesInfo';
  /**
   * The active flag is set to true if the series recording is ongoing,
   * the flag is set to false if the series recording has been unscheduled
   */
  active: Scalars['Boolean'];
  episodes: RecordingEpisodes;
  id: Scalars['ID'];
};

export type SeriesUserData = {
  __typename?: 'SeriesUserData';
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
  npvrInfo?: Maybe<SeriesInfo>;
};

/**
 * A showcard image is title treated and is to be used by itself without overlaying text.
 * Due to lacking image coverage, there are a few instances where this isn't true.
 * In these cases we fallback to using a non-title treated image if such an image can
 * be found, and set 'needsTitleTreatment' to 'true', indicating that the client must
 * itself add title treatment to the image.
 */
export type ShowcardImage = {
  __typename?: 'ShowcardImage';
  colors?: Maybe<ImageColors>;
  id: Scalars['ID'];
  needsTitleTreatment: Scalars['Boolean'];
  source: Scalars['String'];
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-11-20.md Use 'source' */
  sourceNonEncoded: Scalars['String'];
};

export type ShowcaseImages = {
  __typename?: 'ShowcaseImages';
  showcard16x9?: Maybe<Image>;
  showcase2x3?: Maybe<Image>;
  /** @deprecated Use showcase2x3 instead */
  showcase7x10?: Maybe<Image>;
  /** @deprecated Use showcase16x9 instead */
  showcase16x7?: Maybe<Image>;
  showcase16x9?: Maybe<Image>;
};

export type ShowcaseLink = {
  __typename?: 'ShowcaseLink';
  id: Scalars['ID'];
  type: ShowcaseLinkType;
};

export enum ShowcaseLinkType {
  Channel = 'CHANNEL',
  Episode = 'EPISODE',
  Movie = 'MOVIE',
  Page = 'PAGE',
  /** The Panel is guaranteed to be a PageLinkPanel. Use pageLinkQuery(id: "") query to get the content. */
  Panel = 'PANEL',
  Series = 'SERIES',
  Sportevent = 'SPORTEVENT',
  Store = 'STORE',
}

export type ShowcasePanel = Panel & {
  __typename?: 'ShowcasePanel';
  id: Scalars['ID'];
  showcaseContent: ShowcasePanelContent;
  /** Showcases never have a title that should be rendered to the user */
  title: Scalars['String'];
};

export type ShowcasePanelShowcaseContentArgs = {
  input?: InputMaybe<ShowcasePanelInput>;
};

export type ShowcasePanelContent = {
  __typename?: 'ShowcasePanelContent';
  items?: Maybe<Array<ShowcasePanelItem>>;
  pageInfo: PageInfo;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead. */
  trackingItems?: Maybe<Array<TrackingItem>>;
};

/** No more than 5 items will ever be returned. */
export type ShowcasePanelInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ShowcasePanelItem = {
  __typename?: 'ShowcasePanelItem';
  analytics: AssetAnalytics;
  cta?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: ShowcaseImages;
  kicker?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  /**
   * Only used by big screens that show a swimlane of posters in a showcase.
   * In certain cases, the poster will *not* link anywhere, but only be used as a backdrop. In those cases, this field will be null.
   */
  poster?: Maybe<Poster>;
  promotion: ShowcasePromotion;
  provider?: Maybe<Provider>;
  showcaseTitle?: Maybe<ShowcaseTitle>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used */
  vignette?: Maybe<Vignettes>;
};

export type ShowcasePanelX = {
  __typename?: 'ShowcasePanelX';
  analytics: PanelAnalytics;
  id: Scalars['ID'];
  showcaseContent?: Maybe<ShowcasePanelContent>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-20.md Use analytics field instead. */
  trackingData: Scalars['String'];
};

export type ShowcasePromotion = {
  __typename?: 'ShowcasePromotion';
  content?: Maybe<ShowcasePromotionContent>;
  /** @deprecated Please use link3 */
  link?: Maybe<ShowcaseLink>;
  /** @deprecated Please use link3 */
  link2?: Maybe<Link>;
  link3?: Maybe<LinkInterface>;
  posterLabels?: Maybe<PosterLabels>;
};

export type ShowcasePromotionContent = Channel | Episode | Movie | Series | SportEvent;

export type ShowcaseTitle = {
  __typename?: 'ShowcaseTitle';
  image?: Maybe<Image>;
  text?: Maybe<Scalars['String']>;
};

/**
 * Currently only used by C More brand.
 * Deprecated, please use the SingleFeaturePanelV2.
 */
export type SingleFeaturePanel = Panel & {
  __typename?: 'SingleFeaturePanel';
  /** @deprecated Use SingleFeaturePanelV2. */
  id: Scalars['ID'];
  /** @deprecated Use SingleFeaturePanelV2. */
  images?: Maybe<SingleFeaturePanelImages>;
  /** @deprecated Use SingleFeaturePanelV2. */
  media: SingleFeaturePanelMedia;
  /** @deprecated Use SingleFeaturePanelV2. */
  subtitle?: Maybe<Scalars['String']>;
  /** @deprecated Use SingleFeaturePanelV2. */
  title: Scalars['String'];
};

export type SingleFeaturePanelContent = Movie | Series | SportEvent;

export type SingleFeaturePanelImages = {
  __typename?: 'SingleFeaturePanelImages';
  backdrop16x9?: Maybe<Image>;
  promo16x9: Image;
};

export type SingleFeaturePanelMedia = Movie | Series | SportEvent;

/** Currently only used by C More brand. */
export type SingleFeaturePanelV2 = Panel & {
  __typename?: 'SingleFeaturePanelV2';
  id: Scalars['ID'];
  singleFeaturePromotion?: Maybe<SingleFeaturePromotion>;
  title: Scalars['String'];
};

export type SingleFeaturePanelX = {
  __typename?: 'SingleFeaturePanelX';
  analytics: PanelAnalytics;
  id: Scalars['ID'];
  singleFeaturePromotion?: Maybe<SingleFeaturePromotion>;
  title?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead. */
  trackingData: Scalars['String'];
};

export type SingleFeaturePrefetch = {
  __typename?: 'SingleFeaturePrefetch';
  cdp?: Maybe<CdpPrefetch>;
};

export type SingleFeaturePromotion = {
  __typename?: 'SingleFeaturePromotion';
  analytics: AssetAnalytics;
  buttonText?: Maybe<Scalars['String']>;
  content?: Maybe<SingleFeaturePanelContent>;
  images?: Maybe<SingleFeaturePanelImages>;
  labels?: Maybe<Array<Label>>;
  /** @deprecated Please use link3 */
  link?: Maybe<Link>;
  link3?: Maybe<LinkInterface>;
  metadataRowOne?: Maybe<Array<MetadataItem>>;
  prefetch?: Maybe<SingleFeaturePrefetch>;
  provider?: Maybe<Provider>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Slots = {
  __typename?: 'Slots';
  /** Currently only used by web */
  bottomRight?: Maybe<MiniBlockContainer>;
  /** Currently used by all clients but web */
  top?: Maybe<MiniBlockContainer>;
};

export type Sort = {
  order?: Order;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SportEvent = {
  __typename?: 'SportEvent';
  ageRating?: Maybe<AgeRating>;
  availability: Availability;
  /**
   * The SportEvent is playable from this time.
   * This could either be when a VOD is made playable or when the next linear broadcast time is.
   * @deprecated Please use availability.from instead.
   */
  availableFrom?: Maybe<DateTime>;
  /** Whether the SportEvent is playable or not, at the time of fetching. */
  availableNow?: Maybe<Scalars['Boolean']>;
  /**
   * The SportEvent is NOT playable from this time.
   * @deprecated Please use availability.to instead.
   */
  availableTo?: Maybe<DateTime>;
  badges: Badges;
  cdp: CdpData;
  channel?: Maybe<Channel>;
  commentators?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use description instead */
  descriptionLong?: Maybe<Scalars['String']>;
  downloadAvailable: Scalars['Boolean'];
  duration?: Maybe<Duration>;
  /** When rendering the EPG, this field must be used instead of the normal title */
  epgTitle: Scalars['String'];
  genre?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: SportEventImages;
  inStudio?: Maybe<Array<Scalars['String']>>;
  /** @deprecated Use cdp.labels instead. */
  labels: Labels;
  league?: Maybe<Scalars['String']>;
  /** @deprecated There should be no need for this. Let Graph team know if you're basing logic on this */
  mediaType?: Maybe<MediaType>;
  originalAirDate2?: Maybe<OriginalAirDate>;
  playback?: Maybe<Playback>;
  playerRelatedContentV2?: Maybe<PlayerRelatedContent>;
  price?: Maybe<Price>;
  productionCountries?: Maybe<Array<Scalars['String']>>;
  provider?: Maybe<Provider>;
  relatedPanels?: Maybe<RelatedPanels>;
  /** @deprecated Use provider instead. Let the Graph team know if this doesn't suffice */
  store?: Maybe<Store>;
  teams: Teams;
  title: Scalars['String'];
  /** Market-aware links with url patterns registered by app receivers which allows deeplinking to sport event CDP or autoplay. */
  universalLinks?: Maybe<UniversalMediaLinks>;
  userData?: Maybe<SportEventUserData>;
  venue?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used */
  vignette: Vignettes;
  yearProduction?: Maybe<YearProduction>;
};

export type SportEventDescriptionArgs = {
  length?: InputMaybe<DescriptionLength>;
};

export type SportEventPlayerRelatedContentV2Args = {
  input: PlayerRelatedContentInput;
};

export type SportEventDate = {
  __typename?: 'SportEventDate';
  /** The value to use when querying for a sport list for a specific date */
  date: Scalars['String'];
  label: Scalars['String'];
};

export type SportEventImages = {
  __typename?: 'SportEventImages';
  awayTeamLogo?: Maybe<Image>;
  /**
   * Backdrops are _not_ title treated and you need to present the media title to make sense.
   * backdrop16x9 will automatically fallback to the same image as screenshot16x9, you do therefor
   * not need to request screenshot16x9 if you're requesting backdrop16x9.
   */
  backdrop16x9?: Maybe<Image>;
  homeTeamLogo?: Maybe<Image>;
  id: Scalars['ID'];
  /**
   * The showcard is 'title treated' and is to be used by itself without overlaying text.
   * Due to lacking image coverage, there are a few sports instances where this isn't true,
   * these are to be treated as bugs.
   */
  showcard2x3?: Maybe<ShowcardImage>;
  /**
   * The showcard is 'title treated' and is to be used by itself without overlaying text.
   * Due to lacking image coverage, there are a few sports instances where this isn't true,
   * these are to be treated as bugs.
   */
  showcard16x9?: Maybe<ShowcardImage>;
};

export type SportEventList = {
  __typename?: 'SportEventList';
  content: SportEventListContent;
  /** The total number of sport events */
  count: Scalars['Int'];
  /** The available dates to query for */
  dates?: Maybe<Array<SportEventDate>>;
};

export type SportEventListContent = {
  __typename?: 'SportEventListContent';
  /** The selected date */
  date?: Maybe<SportEventDate>;
  /** The available genres to query for */
  genres?: Maybe<Array<SportGenre>>;
  /** Sections containing sport events for the selected date */
  sections?: Maybe<Array<SportEventListSection>>;
};

export type SportEventListInput = {
  /** The date to get a sport list for */
  date: Scalars['String'];
  /** The genres to filter the sport list by */
  genres?: InputMaybe<Array<Scalars['String']>>;
  /** Determines whether to only include sport events you can access with your subscription, or all sport events */
  onlyInSubscription?: InputMaybe<Scalars['Boolean']>;
};

export type SportEventListSection = {
  __typename?: 'SportEventListSection';
  items?: Maybe<Array<SportEventListSectionItem>>;
  title: Scalars['String'];
};

export type SportEventListSectionItem = {
  __typename?: 'SportEventListSectionItem';
  endTime: DateTime;
  /** @deprecated Use poster instead. It gives you ready to use data. */
  media: SportEventListSectionItemContent;
  poster: Poster;
  startTime: DateTime;
};

export type SportEventListSectionItemContent = Movie | Series | SportEvent;

export type SportEventUserData = {
  __typename?: 'SportEventUserData';
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
  progress?: Maybe<Progress>;
  /** This is a shorthand for playback.play.rental.item.rentalInfo */
  rentalInfo?: Maybe<RentalInfo>;
};

export type SportGenre = {
  __typename?: 'SportGenre';
  /** The number of sport events in this genre */
  count: Scalars['Int'];
  label: Scalars['String'];
  /** The value to use when querying for sport list filtered by a set of genres */
  value: Scalars['String'];
};

export type Startover = {
  __typename?: 'Startover';
  playbackSpec?: Maybe<PlaybackSpec>;
};

/**
 * StaticNetflixPanel is _only_ used by Android STB.
 * It holds no data by itself but is meant as a placeholder for the client to
 * replace with actual content.
 */
export type StaticNetflixPanel = Panel & {
  __typename?: 'StaticNetflixPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  /** Launch Interaction ID. Needs to be sent to Netflix app when starting it. */
  launchiid: Scalars['String'];
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

/** Only STB-like clients needs to support this panel. */
export type StaticNetflixPanelX = {
  __typename?: 'StaticNetflixPanelX';
  analytics: PanelAnalytics;
  id: Scalars['ID'];
  launchiid: Scalars['String'];
  title: Scalars['String'];
};

/**
 * Holds no data by itself but is meant as a placeholder for the client to
 * replace with actual content.
 */
export type StaticRecentSearchesPanel = Panel & {
  __typename?: 'StaticRecentSearchesPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  title: Scalars['String'];
};

export type Store = {
  __typename?: 'Store';
  badges: StoreBadges;
  buy: StoreBuy;
  icons: Icons;
  id: Scalars['ID'];
  images?: Maybe<StoreImages>;
  name?: Maybe<Scalars['String']>;
};

export type StoreBadges = {
  __typename?: 'StoreBadges';
  promotedSubscription?: Maybe<PromotedSubscriptionBadge>;
};

export type StoreBuy = {
  __typename?: 'StoreBuy';
  /**
   * If store is not in engagement, this will list the subscriptions needed to be able
   * to play it.
   */
  subscriptions?: Maybe<Array<StoreBuySubscription>>;
};

export type StoreBuySubscription = {
  __typename?: 'StoreBuySubscription';
  item: SubscriptionProduct;
};

export type StoreImages = {
  __typename?: 'StoreImages';
  backdrop16x9?: Maybe<Image>;
};

export type StorePage = {
  __typename?: 'StorePage';
  analytics: PageAnalytics;
  badges: StorePageBadges;
  buy: StorePageBuy;
  icon: Icon;
  /** @deprecated Use icon instead */
  icons?: Maybe<Icons>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pagePanels?: Maybe<PagePanels>;
  userData?: Maybe<StorePageUserData>;
};

export type StorePageIconArgs = {
  typePrio?: InputMaybe<Array<ImageContentTypePrio>>;
};

export type StorePagePagePanelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type StorePageBadges = {
  __typename?: 'StorePageBadges';
  promotedSubscription?: Maybe<PromotedSubscriptionBadge>;
};

export type StorePageBuy = {
  __typename?: 'StorePageBuy';
  /**
   * If store is not in engagement, this will list the subscriptions needed to be able
   * to play it.
   */
  subscriptions?: Maybe<Array<StoreBuySubscription>>;
};

export type StorePageUserData = {
  __typename?: 'StorePageUserData';
  /** Whether or not the user has the store in their engagement. */
  inEngagement: Scalars['Boolean'];
};

export type StoresPanel = Panel & {
  __typename?: 'StoresPanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  link?: Maybe<PanelLink>;
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  storesContent: StoresPanelContent;
  title: Scalars['String'];
};

export type StoresPanelStoresContentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type StoresPanelContent = {
  __typename?: 'StoresPanelContent';
  items?: Maybe<Array<Store>>;
  pageInfo: PageInfo;
};

export enum StyledTextFormat {
  /** Unformated text */
  Clean = 'CLEAN',
  /**
   * Text may contain
   *  * bolding (**bold** or __bold__)
   *  * italic (*italic* or _italic_)
   */
  SimpleMarkdown = 'SIMPLE_MARKDOWN',
}

export type SubMenuItem = {
  __typename?: 'SubMenuItem';
  items: Array<MenuItem>;
};

export type SubPagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo';
  /** @deprecated To get both selection menu and subscription items: Use items instead. */
  item: AccountProvider;
  /** Use to present info on how the user can change their packages */
  items?: Maybe<Array<SubscriptionInfoItem>>;
};

export type SubscriptionInfoItem = {
  __typename?: 'SubscriptionInfoItem';
  buttonText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  link?: Maybe<SubscriptionInfoLink>;
  title: Scalars['String'];
};

export type SubscriptionInfoLink = HttpLink | LinkStatic;

export type SubscriptionLabel = {
  __typename?: 'SubscriptionLabel';
  /** Text label for the subscription */
  text?: Maybe<Scalars['String']>;
};

export type SubscriptionList = {
  __typename?: 'SubscriptionList';
  /** Use to list the user's subscriptions */
  list?: Maybe<Array<UserSubscription>>;
};

export type SubscriptionPanel = Panel & {
  __typename?: 'SubscriptionPanel';
  id: Scalars['ID'];
  subscriptionContent: SubscriptionPanelContent;
  title: Scalars['String'];
};

export type SubscriptionPanelContent = {
  __typename?: 'SubscriptionPanelContent';
  items?: Maybe<Array<SubscriptionPanelItem>>;
};

export type SubscriptionPanelItem = {
  __typename?: 'SubscriptionPanelItem';
  badge?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Channel>>;
  channelsInfo?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  /** @deprecated Please use packageIcons instead. */
  image?: Maybe<Image>;
  link?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  offeringBindingInfo?: Maybe<Scalars['String']>;
  offeringDescription?: Maybe<Scalars['String']>;
  offeringPrice?: Maybe<Scalars['String']>;
  packageIcons?: Maybe<Array<Maybe<Image>>>;
  sellingPoints: Array<Scalars['String']>;
  servicesInfo?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  sportsInfo?: Maybe<Scalars['String']>;
  streamingInfo?: Maybe<Scalars['String']>;
  subscription: SubscriptionProduct;
};

/** Only certain clients needs to support this panel. */
export type SubscriptionPanelX = {
  __typename?: 'SubscriptionPanelX';
  analytics: PanelAnalytics;
  id: Scalars['ID'];
  subscriptionContent: SubscriptionPanelContent;
  title?: Maybe<Scalars['String']>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead. */
  trackingData: Scalars['String'];
};

export type SubscriptionProduct = {
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the subscription */
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/** Subscription describes a Dual Entry package that the user subscribes/can subscribe to */
export type SubscriptionProductDualEntry = SubscriptionProduct & {
  __typename?: 'SubscriptionProductDualEntry';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the subscription */
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/**
 * Fictional subscription indicating that the user need to purchase externally. The user is a Dual Entry user.
 * This may only appear within buy.subscriptions collections
 */
export type SubscriptionProductExternalDualEntry = SubscriptionProduct & {
  __typename?: 'SubscriptionProductExternalDualEntry';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/**
 * Fictional subscription indicating that the user need to purchase externally. The user is a Fallback user.
 * This may only appear within buy.subscriptions collections
 */
export type SubscriptionProductExternalFallback = SubscriptionProduct & {
  __typename?: 'SubscriptionProductExternalFallback';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/**
 * Fictional subscription indicating that the user need to purchase externally. The user is a Field Sales user.
 * This may only appear within buy.subscriptions collections
 */
export type SubscriptionProductExternalFieldSales = SubscriptionProduct & {
  __typename?: 'SubscriptionProductExternalFieldSales';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/**
 * Fictional subscription indicating that the user need to purchase externally. The user is an IAP user.
 * This may only appear within buy.subscriptions collections
 */
export type SubscriptionProductExternalIap = SubscriptionProduct & {
  __typename?: 'SubscriptionProductExternalIAP';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/**
 * Fictional subscription indicating that the user need to purchase externally. The user is a Partner Sales user.
 * This may only appear within buy.subscriptions collections
 */
export type SubscriptionProductExternalPartnerSales = SubscriptionProduct & {
  __typename?: 'SubscriptionProductExternalPartnerSales';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/**
 * Fictional subscription indicating that the user need to purchase externally. The user is a Staff user.
 * This may only appear within buy.subscriptions collections
 */
export type SubscriptionProductExternalStaff = SubscriptionProduct & {
  __typename?: 'SubscriptionProductExternalStaff';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/**
 * Fictional subscription indicating that the user need to purchase externally. The user is a Standard user.
 * This may only appear within buy.subscriptions collections
 */
export type SubscriptionProductExternalStandard = SubscriptionProduct & {
  __typename?: 'SubscriptionProductExternalStandard';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/**
 * Fictional subscription indicating that the user need to purchase externally. The user is a TVE user.
 * This may only appear within buy.subscriptions collections
 */
export type SubscriptionProductExternalTve = SubscriptionProduct & {
  __typename?: 'SubscriptionProductExternalTVE';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/** Subscription describes a FALLBACK package which may be used when the purchase flows can not handle the load */
export type SubscriptionProductFallback = SubscriptionProduct & {
  __typename?: 'SubscriptionProductFallback';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the subscription */
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/** Subscription describes a Field Sales package that the user subscribes/can subscribe to */
export type SubscriptionProductFieldSales = SubscriptionProduct & {
  __typename?: 'SubscriptionProductFieldSales';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Optional label to present with the subscription for extra incentive to purchase it */
  label?: Maybe<SubscriptionLabel>;
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
  /** The price of the package */
  price: Price;
};

/** Subscription describes an IAP package that the user subscribes/can subscribe to */
export type SubscriptionProductIap = SubscriptionProduct & {
  __typename?: 'SubscriptionProductIAP';
  activationUrl?: Maybe<Scalars['String']>;
  /** iTunes connect id */
  iTunesConnectId: Scalars['String'];
  id: Scalars['ID'];
  /** Optional label to present with the subscription for extra incentive to purchase it */
  label?: Maybe<SubscriptionLabel>;
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
  /** The unique selling points for this subscription */
  uniqueSellingPoints: Array<SubscriptionProductUniqueSellingPoint>;
};

/** Subscription describes a Partner Sales package that the user subscribes/can subscribe to */
export type SubscriptionProductPartnerSales = SubscriptionProduct & {
  __typename?: 'SubscriptionProductPartnerSales';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Optional label to present with the subscription for extra incentive to purchase it */
  label?: Maybe<SubscriptionLabel>;
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
  /** The price of the package */
  price: Price;
};

/**
 * Subscription describes a SELECTION package which may be used for customers that
 * want to add the subscription to their "selection" list
 */
export type SubscriptionProductSelection = SubscriptionProduct & {
  __typename?: 'SubscriptionProductSelection';
  activationUrl?: Maybe<Scalars['String']>;
  /**
   * The channel id that can be selected
   * @deprecated No longer used. Use packageId instead.
   */
  channelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the subscription */
  name: Scalars['String'];
  packageId?: Maybe<Scalars['String']>;
  packageType?: Maybe<PackageType>;
  /**
   * The store id that can be selected
   * @deprecated No longer used. Use packageId instead.
   */
  storeId?: Maybe<Scalars['String']>;
};

/** Subscription describes a STAFF package which may be used for staff/select partners */
export type SubscriptionProductStaff = SubscriptionProduct & {
  __typename?: 'SubscriptionProductStaff';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the subscription */
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

/** Subscription describes a standard package that the user subscribes/can subscribe to */
export type SubscriptionProductStandard = SubscriptionProduct & {
  __typename?: 'SubscriptionProductStandard';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Optional label to present with the subscription for extra incentive to purchase it */
  label?: Maybe<SubscriptionLabel>;
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
  /** The price of the package */
  price: Price;
  /** The unique selling points for this subscription */
  uniqueSellingPoints: Array<SubscriptionProductUniqueSellingPoint>;
};

/** Subscription describes a TVE package that the user subscribes/can subscribe to */
export type SubscriptionProductTve = SubscriptionProduct & {
  __typename?: 'SubscriptionProductTVE';
  activationUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the subscription */
  name: Scalars['String'];
  packageType?: Maybe<PackageType>;
};

export type SubscriptionProductUniqueSellingPoint = {
  __typename?: 'SubscriptionProductUniqueSellingPoint';
  /** Optional description of the selling point */
  description?: Maybe<Scalars['String']>;
  /** The selling point */
  sellingPoint: Scalars['String'];
};

export type SubscriptionsDisplay = SubscriptionInfo | SubscriptionList;

export enum SupportedPanelCapability {
  RecentSearches = 'RECENT_SEARCHES',
}

export enum SupportedPosterCapability {
  ContinueWatching = 'CONTINUE_WATCHING',
  Download = 'DOWNLOAD',
  Favorite = 'FAVORITE',
  MoreInfo = 'MORE_INFO',
  Npvr = 'NPVR',
  Play = 'PLAY',
  RecentSearches = 'RECENT_SEARCHES',
}

export type SurroundSoundBadge = {
  __typename?: 'SurroundSoundBadge';
  text: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  name: Scalars['String'];
};

export type Teams = {
  __typename?: 'Teams';
  away?: Maybe<Team>;
  home?: Maybe<Team>;
};

export type Theme = {
  __typename?: 'Theme';
  colors: ThemeColors;
  id: Scalars['ID'];
  shadowImageUrl: Scalars['String'];
  topImageUrl: Scalars['String'];
  topSquareImageUrl: Scalars['String'];
};

export type ThemeColors = {
  __typename?: 'ThemeColors';
  background: Scalars['String'];
  iconsAndText: Scalars['String'];
  panelTitle: Scalars['String'];
  primary: Scalars['String'];
  secondary: Scalars['String'];
};

export type TimeProgress = {
  __typename?: 'TimeProgress';
  from: DateTime;
  to: DateTime;
};

export type TimeSensitiveLabel = {
  __typename?: 'TimeSensitiveLabel';
  spans: Array<TimeSensitiveLabelSpan>;
};

export type TimeSensitiveLabelContent = BasicLabel | DatePatternLabel;

export type TimeSensitiveLabelSpan = {
  __typename?: 'TimeSensitiveLabelSpan';
  from: DateTime;
  label: TimeSensitiveLabelContent;
  to: DateTime;
};

export type TimeSensitiveMetadataItemIcon = {
  __typename?: 'TimeSensitiveMetadataItemIcon';
  spans: Array<TimeSensitiveMetadataItemIconSpan>;
};

export type TimeSensitiveMetadataItemIconSpan = {
  __typename?: 'TimeSensitiveMetadataItemIconSpan';
  from: DateTime;
  icon: MetadataItemIcon;
  to?: Maybe<DateTime>;
};

/**
 * A Timeline panel is time dependent and may contain different content as times
 * goes by. Such a panel could be one that displays sports games which are live _right now_.
 *
 * The field _timelineContent.refreshAt_ contains the time for when this panel will get
 * new content. Clients are **strongly** urged to refetch data at this time,
 * to ensure a good user experience.
 *
 * Refetching is best done by requesting the specific panel via the
 * _panel(id: <panel-id>)_ query.
 */
export type TimelinePanel = Panel & {
  __typename?: 'TimelinePanel';
  /** Use displayHint to determine how to show the content when part of a page. If panel is shown by itself in a view, please use standAloneDisplayHint */
  displayHint: DisplayHint;
  id: Scalars['ID'];
  showMore?: Maybe<SelectableLink>;
  /** Use standAloneDisplayHint to determine how to show the content when then panel is shown by itself in a view (and not as part of a Page) */
  standAloneDisplayHint: DisplayHint;
  timelineContent: TimelinePanelContent;
  title: Scalars['String'];
};

/**
 * A Timeline panel is time dependent and may contain different content as times
 * goes by. Such a panel could be one that displays sports games which are live _right now_.
 *
 * The field _timelineContent.refreshAt_ contains the time for when this panel will get
 * new content. Clients are **strongly** urged to refetch data at this time,
 * to ensure a good user experience.
 *
 * Refetching is best done by requesting the specific panel via the
 * _panel(id: <panel-id>)_ query.
 */
export type TimelinePanelTimelineContentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TimelinePanelContent = {
  __typename?: 'TimelinePanelContent';
  id: Scalars['ID'];
  items?: Maybe<Array<TimelinePanelItem>>;
  pageInfo: PageInfo;
  /**
   * Time when this panel will get new content and the client should refetch the data.
   * The field has a small randomness to it and the returned time may be a few seconds later than the actual change.
   * This is done to spread the load from many clients hitting the Graph at the same time.
   */
  refreshAt?: Maybe<DateTime>;
};

export type TimelinePanelItem = {
  __typename?: 'TimelinePanelItem';
  endTime?: Maybe<DateTime>;
  id: Scalars['ID'];
  media: TimelinePanelItemContent;
  posterLabels: PosterLabels;
  startTime?: Maybe<DateTime>;
};

export type TimelinePanelItemContent = Episode | Movie | SportEvent;

export type TopShelf = {
  __typename?: 'TopShelf';
  showCase?: Maybe<ShowcasePanel>;
};

export type TopShelf2 = {
  __typename?: 'TopShelf2';
  sections: Array<TopShelfSection>;
};

export type TopShelfItem = {
  __typename?: 'TopShelfItem';
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Image;
  progress?: Maybe<Progress>;
  universalLinks: UniversalLinks;
};

export type TopShelfSection = {
  __typename?: 'TopShelfSection';
  heading: Scalars['String'];
  items: Array<TopShelfItem>;
};

export type TrackingItem = {
  __typename?: 'TrackingItem';
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead. */
  id: Scalars['ID'];
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2022-06-20.md Use analytics field instead. */
  trackingData: Scalars['String'];
};

export type Trailer = {
  __typename?: 'Trailer';
  playbackSpec: PlaybackSpec;
};

export type UiResources = {
  __typename?: 'UIResources';
  profile: ProfileResources;
};

export type UhdBadge = {
  __typename?: 'UhdBadge';
  text: Scalars['String'];
};

/** Holds market-aware links with url patterns registered by app receivers which allows deeplinking to content/actions. */
export type UniversalLinks = {
  __typename?: 'UniversalLinks';
  autoplay?: Maybe<Scalars['String']>;
  link: Scalars['String'];
};

/** Holds market-aware links with url patterns registered by app receivers which allows deeplinking to content/actions. */
export type UniversalMediaLinks = {
  __typename?: 'UniversalMediaLinks';
  autoplay: Scalars['String'];
  link: Scalars['String'];
};

export type UniversalPageLink = {
  __typename?: 'UniversalPageLink';
  link: Scalars['String'];
};

export type UniversalSeriesLink = {
  __typename?: 'UniversalSeriesLink';
  link: Scalars['String'];
};

export type UnscheduleRecordingResult =
  | UnscheduleRecordingResultError
  | UnscheduleRecordingResultOk;

export type UnscheduleRecordingResultError = Error & {
  __typename?: 'UnscheduleRecordingResultError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UnscheduleRecordingResultOk = {
  __typename?: 'UnscheduleRecordingResultOk';
  media?: Maybe<RecordingResultMedia>;
  refreshMedias?: Maybe<RefreshMedias>;
};

export type UpcomingEpisode = {
  __typename?: 'UpcomingEpisode';
  availability: Availability;
  /** @deprecated This field is not needed. */
  duration?: Maybe<Duration>;
  /** @deprecated Please use header.title instead. */
  episodeNumber: EpisodeNumber;
  header: UpcomingHeaderTitle;
  id: Scalars['ID'];
  images: UpcomingImages;
  labels: Labels;
  /** @deprecated Please use header.title instead. */
  seasonNumber: SeasonNumber;
  /** @deprecated Please use header.topic instead. */
  title: Scalars['String'];
};

export type UpcomingHeaderTitle = {
  __typename?: 'UpcomingHeaderTitle';
  title?: Maybe<Scalars['String']>;
  topic: Scalars['String'];
};

export type UpcomingImages = {
  __typename?: 'UpcomingImages';
  /** Backdrops are _not_ title treated and you need to present the media title to make sense. */
  backdrop16x9?: Maybe<Image>;
  id: Scalars['ID'];
};

export type UpdateChildLockInput = {
  enabled: Scalars['Boolean'];
  pinCode?: InputMaybe<Scalars['String']>;
};

export type UpdateChildLockResult =
  | UpdateChildLockResultMalformedPinCodeError
  | UpdateChildLockResultNoPinCodeAllowedError
  | UpdateChildLockResultOk;

export type UpdateChildLockResultMalformedPinCodeError = Error & {
  __typename?: 'UpdateChildLockResultMalformedPinCodeError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateChildLockResultNoPinCodeAllowedError = Error & {
  __typename?: 'UpdateChildLockResultNoPinCodeAllowedError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateChildLockResultOk = {
  __typename?: 'UpdateChildLockResultOk';
  enabled: Scalars['Boolean'];
};

export type UpdateProfileInput = {
  alias: Scalars['String'];
  avatarId: Scalars['ID'];
  id: Scalars['ID'];
};

export type UpdateProfileResult =
  | UpdateProfileResultGeneralError
  | UpdateProfileResultOk
  | UpdateProfileResultProfileDoesNotExistError
  | UpdateProfileResultThemeNotAllowedError;

export type UpdateProfileResultGeneralError = Error & {
  __typename?: 'UpdateProfileResultGeneralError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateProfileResultOk = {
  __typename?: 'UpdateProfileResultOk';
  profile: Profile;
};

export type UpdateProfileResultProfileDoesNotExistError = Error & {
  __typename?: 'UpdateProfileResultProfileDoesNotExistError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateProfileResultThemeNotAllowedError = Error & {
  __typename?: 'UpdateProfileResultThemeNotAllowedError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UpsellPage = {
  __typename?: 'UpsellPage';
  analytics: PageAnalytics;
  id: Scalars['ID'];
  pagePanels?: Maybe<PagePanels>;
};

export type UpsellPageInput = {
  id: Scalars['ID'];
  type: UpsellPageInputIdType;
};

export enum UpsellPageInputIdType {
  Media = 'MEDIA',
  Series = 'SERIES',
  Store = 'STORE',
}

export type User = {
  __typename?: 'User';
  /** Available subscriptions for the user to acquire */
  availableSubscriptions?: Maybe<Array<SubscriptionProduct>>;
  childLock: ChildLock;
  currentProfile?: Maybe<Profile>;
  /** User's username, customerUsername or a concatenation of both depending on the client */
  displayUsername?: Maybe<Scalars['String']>;
  /** User's email */
  email?: Maybe<Scalars['String']>;
  /** User's name */
  name?: Maybe<Scalars['String']>;
  profiles: Array<Profile>;
  /** @deprecated No longer needed. This item should always show for all NO users. */
  showSelectionMenuItem: Scalars['Boolean'];
  /**
   * User's subscriptions
   * @deprecated Use subscriptions2 instead
   */
  subscriptions?: Maybe<Array<UserSubscription>>;
  /** User's subscriptions (also includes partners) */
  subscriptions2?: Maybe<SubscriptionsDisplay>;
};

export type UserNameArgs = {
  type?: InputMaybe<UserNameType>;
};

export enum UserNameType {
  /** Returns the first name if it exists */
  First = 'FIRST',
  /** Returns the full name (first and last name combined) */
  Full = 'FULL',
  /** Returns the last name if it exists */
  Last = 'LAST',
}

export type UserSubscription = {
  __typename?: 'UserSubscription';
  subscription: SubscriptionProduct;
};

export enum VideoDefinition {
  Hd_720P = 'HD_720P',
  Hd_1080P = 'HD_1080P',
  Sd = 'SD',
}

export type Vignette = {
  __typename?: 'Vignette';
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used */
  url: Scalars['String'];
};

export type VignetteContainer = {
  __typename?: 'VignetteContainer';
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used */
  mpeg4?: Maybe<Vignette>;
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used */
  webm?: Maybe<Vignette>;
};

/**
 * Vignettes are short, very compressed, clips without sound, most commonly used in lieu of backdrop images.
 * Always opt for a Vignette instead of a backdrop if one is available.
 */
export type Vignettes = {
  __typename?: 'Vignettes';
  /** @deprecated https://github.com/telia-company/tv.common-graphql/blob/develop/docs/schema/2024-06-17.md No longer used */
  hero16x9: VignetteContainer;
};

export type Vod = {
  __typename?: 'Vod';
  /** @deprecated Please use audioLanguages instead. */
  audioLang: Language;
  audioLanguages: Array<Language>;
  /** @deprecated For debug purposes only */
  id?: Maybe<Scalars['String']>;
  playbackSpec: PlaybackSpec;
  price?: Maybe<Price>;
  validFrom: DateTime;
  validTo: DateTime;
};

export type Webview = {
  __typename?: 'Webview';
  /** @deprecated No longer used. */
  url: Scalars['String'];
};

export type YearProduction = {
  __typename?: 'YearProduction';
  number: Scalars['Int'];
  readable: Scalars['String'];
};

export type AssumeProfileMutationVariables = Exact<{
  input: AssumeProfileInput;
}>;

export type AssumeProfileMutation = {
  __typename?: 'Mutation';
  assumeProfile:
    | { __typename?: 'AssumeProfileResultOk'; profileToken: string }
    | { __typename?: 'AssumeProfileResultWrongPinCodeError' };
};

export type GetMainMenuQueryVariables = Exact<{ [key: string]: never }>;

export type GetMainMenuQuery = {
  __typename?: 'Query';
  mainMenu?: {
    __typename?: 'MainMenu';
    items: Array<{ __typename?: 'PageLink'; name?: string | null; id: string }>;
  } | null;
};

export type PageLinkFragment = { __typename?: 'PageLink'; name?: string | null; id: string };

export type GetRegionalChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegionalChannelsQuery = {
  __typename?: 'Query';
  regionalChannels?: {
    __typename?: 'RegionalChannelsList';
    items: Array<{
      __typename?: 'RegionalChannelsListItem';
      id: string;
      name: string;
      regionalChannels: Array<{ __typename?: 'RegionalChannel'; id: string; regionName: string }>;
    }>;
  } | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    name?: string | null;
    showSelectionMenuItem: boolean;
    childLock: { __typename?: 'ChildLock'; enabled: boolean };
    profiles: Array<{
      __typename?: 'Profile';
      id: string;
      alias: string;
      ageGroup: AgeGroup;
      isCurrent: boolean;
      avatar: { __typename?: 'Avatar'; square: { __typename?: 'Image'; sourceNonEncoded: string } };
    }>;
    subscriptions?: Array<{
      __typename?: 'UserSubscription';
      subscription:
        | {
            __typename: 'SubscriptionProductDualEntry';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductExternalDualEntry';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductExternalFallback';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductExternalFieldSales';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductExternalIAP';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductExternalPartnerSales';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductExternalStaff';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductExternalStandard';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductExternalTVE';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductFallback';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductFieldSales';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
            price: { __typename?: 'Price'; readable: string };
          }
        | {
            __typename: 'SubscriptionProductIAP';
            iTunesConnectId: string;
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
            label?: { __typename?: 'SubscriptionLabel'; text?: string | null } | null;
            uniqueSellingPoints: Array<{
              __typename?: 'SubscriptionProductUniqueSellingPoint';
              sellingPoint: string;
              description?: string | null;
            }>;
          }
        | {
            __typename: 'SubscriptionProductPartnerSales';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
            price: { __typename?: 'Price'; readable: string };
          }
        | {
            __typename: 'SubscriptionProductSelection';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductStaff';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          }
        | {
            __typename: 'SubscriptionProductStandard';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
            label?: { __typename?: 'SubscriptionLabel'; text?: string | null } | null;
            price: { __typename?: 'Price'; readable: string };
            uniqueSellingPoints: Array<{
              __typename?: 'SubscriptionProductUniqueSellingPoint';
              sellingPoint: string;
              description?: string | null;
            }>;
          }
        | {
            __typename: 'SubscriptionProductTVE';
            id: string;
            name: string;
            activationUrl?: string | null;
            packageType?: PackageType | null;
          };
    }> | null;
  } | null;
};

type SubscriptionProduct_SubscriptionProductDualEntry_Fragment = {
  __typename: 'SubscriptionProductDualEntry';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductExternalDualEntry_Fragment = {
  __typename: 'SubscriptionProductExternalDualEntry';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductExternalFallback_Fragment = {
  __typename: 'SubscriptionProductExternalFallback';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductExternalFieldSales_Fragment = {
  __typename: 'SubscriptionProductExternalFieldSales';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductExternalIap_Fragment = {
  __typename: 'SubscriptionProductExternalIAP';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductExternalPartnerSales_Fragment = {
  __typename: 'SubscriptionProductExternalPartnerSales';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductExternalStaff_Fragment = {
  __typename: 'SubscriptionProductExternalStaff';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductExternalStandard_Fragment = {
  __typename: 'SubscriptionProductExternalStandard';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductExternalTve_Fragment = {
  __typename: 'SubscriptionProductExternalTVE';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductFallback_Fragment = {
  __typename: 'SubscriptionProductFallback';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductFieldSales_Fragment = {
  __typename: 'SubscriptionProductFieldSales';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
  price: { __typename?: 'Price'; readable: string };
};

type SubscriptionProduct_SubscriptionProductIap_Fragment = {
  __typename: 'SubscriptionProductIAP';
  iTunesConnectId: string;
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
  label?: { __typename?: 'SubscriptionLabel'; text?: string | null } | null;
  uniqueSellingPoints: Array<{
    __typename?: 'SubscriptionProductUniqueSellingPoint';
    sellingPoint: string;
    description?: string | null;
  }>;
};

type SubscriptionProduct_SubscriptionProductPartnerSales_Fragment = {
  __typename: 'SubscriptionProductPartnerSales';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
  price: { __typename?: 'Price'; readable: string };
};

type SubscriptionProduct_SubscriptionProductSelection_Fragment = {
  __typename: 'SubscriptionProductSelection';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductStaff_Fragment = {
  __typename: 'SubscriptionProductStaff';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

type SubscriptionProduct_SubscriptionProductStandard_Fragment = {
  __typename: 'SubscriptionProductStandard';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
  label?: { __typename?: 'SubscriptionLabel'; text?: string | null } | null;
  price: { __typename?: 'Price'; readable: string };
  uniqueSellingPoints: Array<{
    __typename?: 'SubscriptionProductUniqueSellingPoint';
    sellingPoint: string;
    description?: string | null;
  }>;
};

type SubscriptionProduct_SubscriptionProductTve_Fragment = {
  __typename: 'SubscriptionProductTVE';
  id: string;
  name: string;
  activationUrl?: string | null;
  packageType?: PackageType | null;
};

export type SubscriptionProductFragment =
  | SubscriptionProduct_SubscriptionProductDualEntry_Fragment
  | SubscriptionProduct_SubscriptionProductExternalDualEntry_Fragment
  | SubscriptionProduct_SubscriptionProductExternalFallback_Fragment
  | SubscriptionProduct_SubscriptionProductExternalFieldSales_Fragment
  | SubscriptionProduct_SubscriptionProductExternalIap_Fragment
  | SubscriptionProduct_SubscriptionProductExternalPartnerSales_Fragment
  | SubscriptionProduct_SubscriptionProductExternalStaff_Fragment
  | SubscriptionProduct_SubscriptionProductExternalStandard_Fragment
  | SubscriptionProduct_SubscriptionProductExternalTve_Fragment
  | SubscriptionProduct_SubscriptionProductFallback_Fragment
  | SubscriptionProduct_SubscriptionProductFieldSales_Fragment
  | SubscriptionProduct_SubscriptionProductIap_Fragment
  | SubscriptionProduct_SubscriptionProductPartnerSales_Fragment
  | SubscriptionProduct_SubscriptionProductSelection_Fragment
  | SubscriptionProduct_SubscriptionProductStaff_Fragment
  | SubscriptionProduct_SubscriptionProductStandard_Fragment
  | SubscriptionProduct_SubscriptionProductTve_Fragment;

export type UpdateChildLockMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
  newPinCode?: InputMaybe<Scalars['String']>;
}>;

export type UpdateChildLockMutation = {
  __typename?: 'Mutation';
  updateChildLock:
    | { __typename?: 'UpdateChildLockResultMalformedPinCodeError'; code: string; message: string }
    | { __typename?: 'UpdateChildLockResultNoPinCodeAllowedError'; code: string; message: string }
    | { __typename?: 'UpdateChildLockResultOk'; enabled: boolean };
};
