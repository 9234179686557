import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, mq, pxToRem } from '@telia-company/tv.oneapp-web-ui';
import { headingPrimary, headingSecondary, textSecondary } from 'theme/theme.utils';

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: 0 ${pxToRem(16)}rem;
  margin-top: ${pxToRem(16)}rem;
  width: 100%;
  margin-bottom: ${pxToRem(200)}rem;

  ${mq.M`
    margin-top: ${pxToRem(48)}rem;
  `}
`;

export const StyledForm = styled.form`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: ${pxToRem(382)}rem;
  ${mq.M`
    width: auto;
    max-width: none;
  `}
`;

export const StyledHeading = styled.h1`
  margin-bottom: ${pxToRem(32)}rem;

  ${({ theme }) => headingSecondary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};

  width: 66.155%;
  text-align: center;
  align-self: center;
  text-transform: none;

  ${mq.M`
    margin-bottom: 4.75rem;
  `}
`;

export const StyledPinContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${mq.M`
    justify-content: center;
  `}
`;

type PinBoxState = 'DEFAULT' | 'VALID' | 'INVALID';

export const StyledPinBox = styled.input<{ state: PinBoxState }>`
  height: ${pxToRem(48)}rem;
  width: ${pxToRem(48)}rem;
  text-align: center;
  padding: 0;

  ${mq.M`
    height: ${pxToRem(80)}rem;
    width: ${pxToRem(80)}rem;
  `}

  border-radius: ${pxToRem(2)}rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.designTokens.color.button.secondaryDefault};
  border: ${pxToRem(1)}rem solid ${({ theme }) => theme.designTokens.color.border.subtle};
  ${({ theme }) => headingPrimary(theme)};
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  caret-color: ${({ theme }) => theme.designTokens.color.text.primary};

  &:focus {
    background-color: ${({ theme }) => theme.designTokens.color.button.secondaryFocus};
    border-color: ${({ theme }) => theme.designTokens.color.border.buttonSecondaryFocus};
  }

  ${({ theme, state }) => {
    switch (state) {
      case 'DEFAULT':
        return css`
          border-color: ${theme.designTokens.color.border.subtle};
        `;
      case 'INVALID':
        return css`
          border-color: ${theme.designTokens.color.infoDialogue.errorBackgroundDefault};
        `;
      case 'VALID':
        return css`
          border-color: ${theme.designTokens.color.infoDialogue.helpfulBackgroundDefault};
        `;
    }
  }}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  margin-right: ${pxToRem(11)}rem;
  width: 100%;
  max-width: ${pxToRem(48)}rem;

  ${mq.M`
      margin-right: ${pxToRem(8)}rem;
      width: ${pxToRem(80)}rem;
      max-width: none;
    `}

  &:last-of-type {
    margin-right: 0;
  }
`;

export const StyledError = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.designTokens.color.infoDialogue.errorBackgroundDefault};
  ${({ theme }) => textSecondary(theme)}
`;

export const StyledButton = styled(Button)`
  margin-top: ${pxToRem(48)}rem;
  max-width: ${pxToRem(382)}rem;
  width: 100%;
  align-self: center;

  ${mq.M`
    width: ${pxToRem(343)}rem;
  `}
`;
