import { PreferredLanguages } from '@telia-company/tv.common-sdk-legacy-pack/dist/PreferredLanguages';
import { useLogger } from 'hooks';
import { StyledSubSection } from 'pages/navigation.styles';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownItem, SettingsDropDown } from '../../SettingsDropDown';
import { useGetSubtitleLanguages } from './hooks';
import { isPreferredSubtitleLanguageCode } from './PreferredSubtitleLanguage.utils';
import { StyledLanguageLabel } from './styles';

export const PreferredSubtitleLanguage = () => {
  const logger = useLogger('PreferredSubtitleLanguage');
  const preferredLanguages = PreferredLanguages.instance();
  const { t } = useTranslation();
  const { subtitleLanguages, preferredSubtitleLanguage, refresh } = useGetSubtitleLanguages();

  const items: DropDownItem[] = subtitleLanguages.map(({ code, name }) => ({
    id: code,
    label: name,
  }));

  const selectedItem = preferredSubtitleLanguage
    ? items.find(({ id }) => id === preferredSubtitleLanguage.code)
    : undefined;

  const onSelectItem = useCallback(
    async ({ id }: DropDownItem) => {
      if (isPreferredSubtitleLanguageCode(id)) {
        await preferredLanguages.setPreferredSubtitleLanguage(id);
        refresh();
      } else {
        logger.error(`The code "${id}" is not a valid subtitle language code`);
      }
    },
    [logger, preferredLanguages, refresh],
  );

  return (
    <StyledSubSection forcedDirection="row">
      <StyledLanguageLabel>
        {t('SETTINGS_DEVICE_PREFERRED_LANGUAGES_SUBTITLES')}
      </StyledLanguageLabel>
      <SettingsDropDown
        items={items}
        label={preferredSubtitleLanguage?.name ?? '...'}
        selectedItem={selectedItem}
        onSelect={(item) => onSelectItem(item)}
      />
    </StyledSubSection>
  );
};
