import { LoggedinDevice } from 'services/deviceService';
import {
  StyledDeviceContainer,
  StyledDeviceInfoContainer,
  StyledDeviceLastUsed,
  StyledDeviceName,
} from './Device.styles';
import { getDeviceName } from './Device.utils';
import { useTranslation } from 'react-i18next';
import { StyledSubSection, StyledSubSectionContainer } from 'pages/navigation.styles';

type ThisDeviceProps = { device: LoggedinDevice };

export const ThisDevice = ({ device }: ThisDeviceProps) => {
  const { t } = useTranslation();
  return (
    <StyledSubSectionContainer>
      <StyledSubSection>
        <StyledDeviceContainer>
          <StyledDeviceInfoContainer>
            <StyledDeviceName>{getDeviceName(device)}</StyledDeviceName>
            <StyledDeviceLastUsed>{t('NAV_DEVICES_CURRENT_DEVICE_LAST_USED')}</StyledDeviceLastUsed>
          </StyledDeviceInfoContainer>
        </StyledDeviceContainer>
      </StyledSubSection>
    </StyledSubSectionContainer>
  );
};
