import { Country, Environment } from 'environment';
import { useEffect, useState, useContext, createContext, useCallback, ReactNode } from 'react';
import {
  COOKIE_HANDLER,
  COOKIE_NAMES,
  getCountryFromCookieOrEnv,
  getEnvironmentFromCookieOrEnv,
} from 'utils';
import { shouldUseEnvironmentsProviderWithCookies } from './utils';

type EnvironmentContextProps = {
  country: Country;
  environment: Environment;
  setEnvironment: (environment: Environment) => void;
  setCountry: (country: Country) => void;
};

const EnvironmentContext = createContext<EnvironmentContextProps>({
  environment: 'PROD',
  country: 'SE',
  setEnvironment: () => () => undefined,
  setCountry: () => () => undefined,
});

export const EnvironmentProvider = ({ children }: { children: ReactNode }) => {
  const [countryEnv, setCountryEnv] = useState<Country>('SE');
  const [environmentEnv, setEnvironmentEnv] = useState<Environment>('PROD');

  const setEnvironment = useCallback(
    (environment: Environment) => {
      // Early return when not using and setting cookies
      if (!shouldUseEnvironmentsProviderWithCookies()) return;

      COOKIE_HANDLER.setCookie(COOKIE_NAMES.ENVIRONMENT, environment);
      setEnvironmentEnv(environment);
    },
    [setEnvironmentEnv],
  );

  const setCountry = useCallback(
    async (country: Country) => {
      // Early return when not using and setting cookies
      if (!shouldUseEnvironmentsProviderWithCookies()) return;

      COOKIE_HANDLER.setCookie(COOKIE_NAMES.COUNTRY, country);
      setCountryEnv(country);
    },
    [setCountryEnv],
  );

  useEffect(() => {
    const getEnv = async () => {
      let environment = process.env.REACT_APP_ENVIRONMENT as Environment;
      let country = process.env.REACT_APP_COUNTRY as Country;

      if (shouldUseEnvironmentsProviderWithCookies()) {
        environment = await getEnvironmentFromCookieOrEnv();
        country = await getCountryFromCookieOrEnv();
      }

      setCountryEnv(country);
      setEnvironmentEnv(environment);
    };

    getEnv();
  }, []);

  return (
    <EnvironmentContext.Provider
      value={{
        country: countryEnv,
        environment: environmentEnv,
        setCountry,
        setEnvironment,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironmentContext = () => useContext(EnvironmentContext);
