import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { useState } from 'react';
import { User } from 'services/userService';
import { MenuItem } from '../../hooks';
import { StyledHamburgerButton } from './HamburgerMenu.styles';
import { SideMenu } from './SideMenu';
import { Portal } from 'components/Portal';

type HamburgerMenuProps = {
  user?: User;
  menuItems: MenuItem[];
  userMenuItems?: MenuItem[];
};

export const HamburgerMenu = ({ user, menuItems, userMenuItems }: HamburgerMenuProps) => {
  const [open, setOpen] = useState(false);

  const menu = Icon({ name: 'Menu' });

  return (
    <>
      <StyledHamburgerButton onClick={() => setOpen((prev) => !prev)}>{menu}</StyledHamburgerButton>
      <Portal>
        <SideMenu
          onDismiss={() => setOpen(false)}
          open={open}
          user={user}
          menuItems={menuItems}
          userMenuItems={userMenuItems}
        />
      </Portal>
    </>
  );
};
