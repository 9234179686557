import { ConfirmModal } from 'components/modals';
import { useLogger, useSnackbars } from 'hooks';
import { ComponentProps, useState } from 'react';
import { MfaStatus, requestMfaOneTimePassword } from 'services/userService';
import { StyledConfirmModal } from './RemoveMfaModal.styles';
import { useTranslation } from 'react-i18next';
import { VerifyMfaModal } from '../VerifyMfaModal';

type DeleteMfaModalProps = {
  isOpen: boolean;
  mfaStatus: MfaStatus;
  onClose: (deleted: boolean) => void;
};
export const DeleteMfaModal = ({ isOpen, mfaStatus, onClose }: DeleteMfaModalProps) => {
  const logger = useLogger('DeleteMfaModal');
  const [isVerifyModalOpen, setVerifyModalOpen] = useState(false);
  const { createNegativeSnackbar } = useSnackbars();
  const { t } = useTranslation();

  let title = '';
  let description = '';
  let confirmLabel = '';
  switch (mfaStatus.verificationMethod) {
    case 'email':
      title = t('MFA_DELETE_MODAL_TITLE_EMAIL');
      description = t('MFA_DELETE_MODAL_DESCRIPTION_EMAIL');
      confirmLabel = t('MFA_DELETE_MODAL_CONFIRM_EMAIL');
      break;

    case 'sms':
      title = t('MFA_DELETE_MODAL_TITLE_SMS');
      description = t('MFA_DELETE_MODAL_DESCRIPTION_SMS');
      confirmLabel = t('MFA_DELETE_MODAL_CONFIRM_SMS');
      break;

    default:
      throw new Error(`Invalid verification method: ${mfaStatus.verificationMethod}`);
  }

  const onResponse: ComponentProps<typeof ConfirmModal>['onResponse'] = (confirmed, setBusy) => {
    // Prevent multiple submissions
    if (isVerifyModalOpen) {
      return;
    }
    const execute = async () => {
      setBusy(true);
      try {
        await requestMfaOneTimePassword({
          value: mfaStatus.value,
          verificationMethod: mfaStatus.verificationMethod,
          action: 'remove',
        });
        setVerifyModalOpen(true);
      } catch (error) {
        createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
        logger.error('Failed to delete MFA', error);
      } finally {
        setBusy(false);
      }
    };

    if (confirmed) {
      execute();
    } else {
      onClose(false);
    }
  };

  return (
    <>
      <StyledConfirmModal
        isOpen={isOpen}
        title={title}
        confirmLabel={confirmLabel}
        onResponse={onResponse}
      >
        <p>{description}</p>
      </StyledConfirmModal>
      <VerifyMfaModal
        isOpen={isVerifyModalOpen}
        verificationMethod={mfaStatus.verificationMethod}
        action="remove"
        verificationTarget={mfaStatus.value}
        onClose={(verified) => {
          setVerifyModalOpen(false);
          if (verified) {
            onClose(true);
          }
        }}
      />
    </>
  );
};
