import { ConfirmModal } from 'components/modals';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedItem } from 'services/selectionMenuService';
import { StyledItemDescription, StyledItemState, StyledLogo } from './ItemModal.styles';
import { getImageProxyUrl } from 'utils';

export type FixedItemModalHandle = {
  open: (item: FixedItem) => void;
};

export const FixedItemModal = forwardRef<FixedItemModalHandle>((_props, ref) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<FixedItem>();

  useImperativeHandle(ref, () => ({
    open: (openItem) => {
      setItem(openItem);
    },
  }));

  if (!item) {
    return null;
  }

  const isActivatable = !!item.externalRegistration;
  const logoUrl = item && getImageProxyUrl({ url: item.logoUrl, height: 48 });

  const onResponse = (confirmed: boolean) => {
    if (confirmed && item.externalRegistration) {
      window.location.href = item.externalRegistration.url;
    }
    setItem(undefined);
  };

  return (
    <ConfirmModal
      isOpen={true}
      title={item.name}
      showTitle={false}
      showCancel={false}
      showConfirm={!!isActivatable}
      onResponse={onResponse}
      confirmLabel={isActivatable ? t('SELECTIONMENU_ITEM_MODAL_BUTTON_ACTIVATE') : ''}
    >
      <StyledLogo src={logoUrl} alt={item.name} />
      <StyledItemState>{t('SELECTIONMENU_ITEM_INCLUDED')}</StyledItemState>
      <StyledItemDescription>{item.description}</StyledItemDescription>
    </ConfirmModal>
  );
});
