import { useCallback, useEffect, useState } from 'react';
import { StyledContainer } from './EnvSelector.styles';
import { Country, Environment, Countries, Environments } from 'environment';
import { useEnvironmentContext } from 'providers';
import { shouldUseEnvironmentsProviderWithCookies } from 'providers/utils';

export const EnvSelector = () => {
  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const { setCountry, setEnvironment, country, environment } = useEnvironmentContext();

  useEffect(() => {
    setSelectedCountry(country);
    setSelectedEnvironment(environment);
  }, [country, environment]);

  const onSubmit = useCallback(async () => {
    if (selectedCountry) {
      setCountry(selectedCountry);
    }

    if (selectedEnvironment) {
      setEnvironment(selectedEnvironment);
    }

    window.location.reload();
  }, [selectedEnvironment, selectedCountry, setCountry, setEnvironment]);

  const isDirty = selectedEnvironment !== environment || selectedCountry !== country;

  // Return early if we are not to show the selector
  if (!shouldUseEnvironmentsProviderWithCookies()) return null;

  return (
    <StyledContainer>
      <select
        onChange={(e) => setSelectedCountry(e.target.value as Country)}
        value={selectedCountry || ''}
      >
        {Countries.map((country) => (
          <option key={country} value={country}>
            {country}
          </option>
        ))}
      </select>
      <select
        onChange={(e) => setSelectedEnvironment(e.target.value as Environment)}
        value={selectedEnvironment || ''}
      >
        {Environments.map((env) => (
          <option key={env} value={env}>
            {env}
          </option>
        ))}
      </select>
      <button disabled={!isDirty} onClick={onSubmit}>
        Save
      </button>
    </StyledContainer>
  );
};
