import styled from '@emotion/styled';

export const StyledInfoContainer = styled.ol`
  padding-inline-start: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledInfoLine = styled.li``;
