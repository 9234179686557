import { Button, Input } from '@telia-company/tv.oneapp-web-ui';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ErrorComponent } from 'components';
import { useLoadAccountInfo } from '../../hooks';
import { useSaveEmail } from './hooks';
import { useTranslation } from 'react-i18next';
import { useLogger, useSnackbars } from 'hooks';
import { RequestError } from '@telia-company/tv.common-sdk/dist/ErrorTypes';
import {
  StyledButtonStrip,
  StyledSectionHeading,
  StyledInputGroup,
} from '../../../navigation.styles';

const isValidEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email);

type ValidationErrors = {
  email?: string;
  password?: string;
};

export const ChangeCredentials = () => {
  const logger = useLogger('navigation/account/change-credentials');

  const { t } = useTranslation();
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();
  const { accountInfo, error: loadError } = useLoadAccountInfo();
  const { saving, save, saved, error: saveError } = useSaveEmail();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    if (accountInfo) {
      setEmail(accountInfo.email);
    }
  }, [accountInfo]);

  const validate = useCallback(() => {
    const newValidationErrors: ValidationErrors = {
      email: !isValidEmail(email) ? t('ACCOUNT_CHANGE_CREDENTIALS_WARN_FAULTY_EMAIL') : undefined,
      password: passwordVisible && !password ? t('COMMON_WARN_PASSWORD_REQUIRED') : undefined,
    };
    setValidationErrors(newValidationErrors);
    return Object.values(newValidationErrors).some(Boolean) === false;
  }, [email, password, passwordVisible, t]);

  const onSave = useCallback(() => {
    if (validate()) {
      save({ newEmail: email, password });
    }
  }, [email, save, password, validate]);

  useEffect(() => {
    if (saved) {
      createPositiveSnackbar(t('ACCOUNT_CHANGE_CREDENTIALS_NOTIFICATION_SAVED'));
    }
  }, [saved, createPositiveSnackbar, t]);

  useEffect(() => {
    if (saveError) {
      if (saveError instanceof RequestError) {
        if (saveError.status === 403) {
          setValidationErrors((prev) => ({
            ...prev,
            password: t('ACCOUNT_CHANGE_CREDENTIALS_WARN_FAULTY_PASSWORD'),
          }));
        }
      }
      logger.error('Failed to save credentials', saveError);
      createNegativeSnackbar(t('ACCOUNT_CHANGE_CREDENTIALS_NOTIFICATION_GENERAL_ERROR'));
    }
  }, [saveError, createNegativeSnackbar, t, logger]);

  if (loadError) {
    return <ErrorComponent error={loadError} />;
  }

  return (
    <>
      <StyledSectionHeading>{t('ACCOUNT_CHANGE_CREDENTIALS_HEADING')}</StyledSectionHeading>
      <StyledInputGroup>
        <div>
          <Input
            label={t('ACCOUNT_CHANGE_CREDENTIALS_EMAIL_LABEL')}
            type={'email'}
            defaultValue={accountInfo?.email}
            disabled={saving}
            state={!!validationErrors.email ? 'INVALID' : 'DEFAULT'}
            message={validationErrors.email}
            onChange={(e) => {
              setEmail(e.target.value);
              setPasswordVisible(true);
            }}
          ></Input>
        </div>
      </StyledInputGroup>
      {passwordVisible && (
        <StyledInputGroup>
          <div>
            <Input
              label={t('ACCOUNT_CHANGE_CREDENTIALS_PASSWORD_LABEL')}
              type="password"
              disabled={saving}
              showPasswordIcon={true}
              state={!!validationErrors.password ? 'INVALID' : 'DEFAULT'}
              message={validationErrors.password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </StyledInputGroup>
      )}
      <StyledButtonStrip>
        <Button as={Link} variant="ghost" to="..">
          {t('COMMON_BUTTON_CANCEL')}
        </Button>
        <Button
          disabled={!accountInfo || saving || !passwordVisible}
          onClick={() => onSave()}
          isLoading={saving}
        >
          {t('COMMON_BUTTON_SAVE')}
        </Button>
      </StyledButtonStrip>
    </>
  );
};
