import { useEffect, useRef } from 'react';

// Define the props for the hook
type UseOnAppInteractionProps = {
  skip?: boolean;
  callback: () => void;
  waitFor: number; // An optional delay in milliseconds
};

export const useOnAppInteraction = ({ callback, skip, waitFor }: UseOnAppInteractionProps) => {
  // Keep track of the start time
  const startTimeRef = useRef(Date.now());

  useEffect(() => {
    // Check if we should skip the interaction handling
    if (skip) {
      return;
    }

    // Define the interaction handler
    const onInteractionHandler = () => {
      const now = Date.now();
      const endTime = startTimeRef.current + waitFor;

      if (now > endTime) {
        // Trigger the callback and update the start time
        callback();
        startTimeRef.current = now;
      }
    };

    // Call the interaction handler immediately
    onInteractionHandler();

    // Define the event names for interactions
    const clickEventName = 'ontouchstart' in window ? 'touchstart' : 'mousedown';
    const eventNames = [clickEventName, 'keydown', 'focus'];

    // Add event listeners for the defined interactions
    eventNames.forEach((eventName) => {
      document.addEventListener(eventName, onInteractionHandler);
    });

    // Remove event listeners when the component unmounts
    return () => {
      eventNames.forEach((eventName) => {
        document.removeEventListener(eventName, onInteractionHandler);
      });
    };
  }, [callback, skip, waitFor]);
};
