import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { StyledButtonContainer } from 'pages/navigation.styles';

export const StyledInfoContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 8px;
`;

export const StyledInputWrapper = styled.div`
  flex-direction: inherit;
  align-items: flex-start;
  margin-bottom: 0;
  display: flex;
  gap: 16px;

  ${mq.S`
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  `}
`;
export const StyledRequestPinButtonContainer = styled(StyledButtonContainer)`
  width: 100%;
`;
