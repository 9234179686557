import { StyledFederationProviderTitle } from 'pages/federated.styles';
import { AppleIcon } from 'components';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { FC } from 'react';
import { LoggedinDevice } from 'services/deviceService';
import { AppleButton } from './AppleButton';
import { FeatureEnabled } from 'components/FeatureEnabled';

type AppleProps = {
  isAppleConnected: boolean;
  setIsAppleConnected: (isConnected: boolean) => void;
  thisDevice?: LoggedinDevice;
};
export const Apple: FC<AppleProps> = ({ isAppleConnected, setIsAppleConnected, thisDevice }) => {
  return (
    <FeatureEnabled featureToggles={['Account.Federated.Login.Apple']}>
      <StyledSubSection>
        <div>
          <StyledSubSectionLabel>
            <AppleIcon />
            <StyledFederationProviderTitle>{'Apple'}</StyledFederationProviderTitle>
          </StyledSubSectionLabel>
        </div>
        <StyledButtonContainer>
          <AppleButton
            isAppleConnected={isAppleConnected}
            setIsAppleConnected={setIsAppleConnected}
            thisDevice={thisDevice}
          />
        </StyledButtonContainer>
      </StyledSubSection>
    </FeatureEnabled>
  );
};
