import { DataManager } from '@telia-company/tv.common-sdk/dist/DataManager';
import { ServiceConfig } from '@telia-company/tv.common-sdk/dist/ServiceConfig';

export type LoggedinDevice = {
  deviceId: string;
  vendor?: string;
  vendorModel?: string;
  browser?: string;
  browserVersion?: string;
  os?: string;
  osVersion?: string;
  geographicLocation?: {
    city?: string;
    country?: string;
    region?: string;
  };
  lastSeen: string;
  loginIpAdress: string;
  tvClientName: string;
};

type GetLoggedinDevicesProps = {
  cacheClass: string;
};

export const getLoggedinDevices = async ({
  cacheClass,
}: GetLoggedinDevicesProps): Promise<LoggedinDevice[]> => {
  const dataManager = DataManager.instance();
  const logingateway = ServiceConfig.getService('logingateway');
  const url = logingateway.getUrl('/rest/secure/v1/loggedInDevices');
  const headers = logingateway.getHeaders();
  const response = (await dataManager.sendGet(url, cacheClass, {
    headers,
    secure: true,
  })) as unknown as LoggedinDevice[];
  return response;
};

type LogoutDeviceProps = {
  deviceId: string;
  cacheClass: string;
};

export const logoutDevice = async ({ deviceId, cacheClass }: LogoutDeviceProps): Promise<void> => {
  const dataManager = DataManager.instance();
  const logingateway = ServiceConfig.getService('logingateway');
  const url = logingateway.getUrl('/rest/secure/v1/logoutSelectedDevice');
  const headers = logingateway.getHeaders();
  await dataManager.sendDelete(url, cacheClass, {
    headers,
    secure: true,
    query: {
      deviceId,
    },
  });
};

type LoginDeviceProps = {
  userCode: string;
  cacheClass: string;
};

export const loginDevice = async ({ userCode, cacheClass }: LoginDeviceProps): Promise<void> => {
  const dataManager = DataManager.instance();
  const logingateway = ServiceConfig.getService('logingateway');
  const url = logingateway.getUrl('/rest/secure/v1/device/login');
  const headers = logingateway.getHeaders();
  await dataManager.sendPost(url, cacheClass, {
    headers,
    secure: true,
    post: {
      userCode,
    },
    postAsJson: true,
  });
};
