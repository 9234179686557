import { NavigatorSection } from 'pages';

type GetSectionProps = {
  pathname: string;
  sections: NavigatorSection[];
};
export const getSection = ({
  pathname,
  sections,
}: GetSectionProps): NavigatorSection | undefined => {
  const sectionPath = pathname.split('/')[1];
  return sections.find((section) => section.path === sectionPath);
};
