import styled from '@emotion/styled';
import { subHeading, textPrimary } from 'theme/theme.utils';

export const StyledCurrentUsernameHeader = styled.h2`
  ${({ theme }) => subHeading(theme)}
  margin-top: 24px;
  margin-bottom: 0px;
`;
export const StyledCurrentUsername = styled.p`
  margin-bottom: 24px;
  margin-top: 8px;
  ${({ theme }) => textPrimary(theme)};
  opacity: 0.7;
`;
