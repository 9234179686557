import { SVGProps } from 'react';

export const GiftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.15437 8.13542H4.00208C2.60296 8.13542 1.46875 9.26963 1.46875 10.6687V13.3354C1.46875 14.3004 2.00826 15.1393 2.80208 15.5671L2.80208 26.6687C2.80208 28.0679 3.9363 29.2021 5.33542 29.2021H26.6687C28.0679 29.2021 29.2021 28.0679 29.2021 26.6687V18.6687C29.2021 18.006 28.6648 17.4687 28.0021 17.4687C27.3393 17.4687 26.8021 18.006 26.8021 18.6687V26.6687C26.8021 26.7424 26.7424 26.8021 26.6687 26.8021H17.2021V15.8687H28.0021C29.4012 15.8687 30.5354 14.7345 30.5354 13.3354V10.6687C30.5354 9.26963 29.4012 8.13542 28.0021 8.13542H23.8498C23.7703 7.02962 23.4371 5.96022 22.7814 5.10836C21.9921 4.08292 20.8042 3.46875 19.3354 3.46875C17.9349 3.46875 16.7898 4.02714 16.0021 4.96802C15.2144 4.02714 14.0692 3.46875 12.6687 3.46875C11.2 3.46875 10.0121 4.08292 9.22275 5.10836C8.56702 5.96022 8.23386 7.02962 8.15437 8.13542ZM10.5635 8.13542H14.774C14.7017 7.4698 14.4949 6.93857 14.2129 6.57229C13.8879 6.15001 13.4091 5.86875 12.6687 5.86875C11.9284 5.86875 11.4496 6.15001 11.1246 6.57229C10.8426 6.93857 10.6358 7.4698 10.5635 8.13542ZM17.2301 8.13542H21.4407C21.3684 7.4698 21.1616 6.93857 20.8796 6.57229C20.5546 6.15001 20.0758 5.86875 19.3354 5.86875C18.595 5.86875 18.1163 6.15001 17.7912 6.57229C17.5093 6.93857 17.3024 7.4698 17.2301 8.13542ZM14.8021 10.5354H4.00208C3.92845 10.5354 3.86875 10.5951 3.86875 10.6687V13.3354C3.86875 13.4091 3.92845 13.4687 4.00208 13.4687H14.8021V10.5354ZM17.2021 13.4687V10.5354H28.0021C28.0757 10.5354 28.1354 10.5951 28.1354 10.6687V13.3354C28.1354 13.4091 28.0757 13.4687 28.0021 13.4687H17.2021ZM14.8021 15.8687H5.20208L5.20208 26.6687C5.20208 26.7424 5.26178 26.8021 5.33542 26.8021H14.8021V15.8687Z"
      fill="white"
    />
  </svg>
);
