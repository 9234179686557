import styled from '@emotion/styled';
import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { textPrimary } from 'theme/theme.utils';

export const StyledErrorMessage = styled.div`
  ${({ theme }) => textPrimary(theme)}
`;

export const StyledErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  gap: 8px;
`;

export const StyledIcon = styled(Icon)`
  height: 24px;
  width: 24px;
`;
