import { PreferredLanguages } from '@telia-company/tv.common-sdk-legacy-pack/dist/PreferredLanguages';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, useGetLanguages } from './useGetLanguages';

export const useGetAudioLanguages = () => {
  const preferredLanguages = PreferredLanguages.instance();
  const { t } = useTranslation();
  const languages = useGetLanguages();
  const { data: languageCode, refresh } = useFetchFunction(
    preferredLanguages.getPreferredAudioLanguage,
  );
  const [audioLanguages, setAudioLanguages] = useState(languages);
  const [preferredAudioLanguage, setPreferredAudioLanguage] = useState<Language>();

  useEffect(() => {
    if (languages) {
      setAudioLanguages([...languages]);
      if (languageCode) {
        setPreferredAudioLanguage(languages.find(({ code }) => code === languageCode));
      }
    }
  }, [languageCode, languages, t]);

  return { audioLanguages, preferredAudioLanguage, refresh };
};
