import '@telia-company/tv.common-sdk';
import '@telia-company/tv.common-sdk-authentication';
import '@telia-company/tv.common-sdk-platform-bom';
import '@telia-company/tv.common-sdk-log';
import '@telia-company/tv.common-sdk-statistics-http';
import '@telia-company/tv.common-sdk-remote-client-events';

import '@telia-company/tv.common-sdk-log/dist/providers/remoteclientlog';
import '@telia-company/tv.common-sdk-statistics-http/dist/providers/remoteclientlog';

import './implementations';
import './providers';
import './logoutListener';
import { isDev } from 'utils';
import { shouldUseEnvironmentsProviderWithCookies } from 'providers/utils';

export * from './ApolloClient';

export const initSdk = async () => {
  const importPromises = [];

  if (isDev()) {
    importPromises.push(import('@telia-company/tv.common-sdk-log/dist/providers/console'));
  }

  if (!shouldUseEnvironmentsProviderWithCookies()) {
    importPromises.push(import('@telia-company/tv.common-sdk-authentication/dist/cookiesupport'));
  }

  importPromises.push(import('@telia-company/tv.common-sdk-legacy-pack'));

  await Promise.all(importPromises);
};
