import { DeviceInformationIF } from '@telia-company/tv.common-sdk/dist/abstractions/device/DeviceInformationIF';
import { getModel, getPlatformVersion, getVendor } from 'utils/deviceInformation.utils';

DeviceInformationIF.implement(() => ({
  getDeviceInformation: async () => {
    const model = getModel();
    const version = getPlatformVersion();
    const vendor = getVendor();
    return {
      model,
      version,
      vendor,
      deviceType: 'WEB',
    };
  },
}));
