import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  z-index: 100;
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: inline-block;
  padding: 16px;
  margin: 0 -4px;
  > * {
    margin: 0 4px;
  }
  ${({ theme }) => css`
    background-color: ${theme.designTokens.color.background.surface};
    border-radius: ${theme.designTokens.radius.button};
    font-size: ${theme.designTokens.typography.textSecondary.fontSize};
    font-weight: ${theme.designTokens.typography.textSecondary.fontWeight};
    line-height: ${theme.designTokens.typography.textSecondary.lineHeight};
  `}
`;
