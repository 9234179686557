import { ConfirmModal } from 'components/modals';
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectableItem } from 'services/selectionMenuService';
import { SelectionMenuContext } from '../../SelectionMenuContext';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';

export type MissingPointsModalHandle = {
  open: (item: SelectableItem) => void;
};

export const MissingPointsModal = forwardRef<MissingPointsModalHandle>((_props, ref) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<SelectableItem>();
  const { totalPoints, selectedPoints } = useContext(SelectionMenuContext);

  useImperativeHandle(ref, () => ({
    open: (openItem) => {
      setItem(openItem);
    },
  }));

  if (!item) {
    return null;
  }

  const missingPoints = selectedPoints + (item.points ?? 0) - totalPoints;
  const buyPointsLink = Config.get('selection-menu-buy-points-link');

  return (
    <ConfirmModal
      isOpen={true}
      title={t('SELECTIONMENU_ITEM_MODAL_MISSING_POINTS_HEADER', { name: item.name })}
      onResponse={() => {
        setItem(undefined);
      }}
      showCancel={false}
      customButtons={
        !!buyPointsLink && (
          <Button variant="secondary" as={SmartLink} to={buyPointsLink}>
            {t('SELECTIONMENU_ITEM_MODAL_MISSING_POINTS_BUY_POINTS_BUTTON')}
          </Button>
        )
      }
      confirmLabel={t('COMMON_BUTTON_OK')}
    >
      <div>{t('SELECTIONMENU_ITEM_MODAL_MISSING_POINTS_INFO', { points: missingPoints })}</div>
    </ConfirmModal>
  );
});
