import { useCallback, useEffect, useState } from 'react';
import { Channel, getRegionalChannels, RegionalChannel } from 'services/regionalChannelsService';
import { PreferredRegionalChannels } from '@telia-company/tv.common-sdk-legacy-pack/dist/PreferredRegionalChannels';

export type ChannelWithRegionalPreference = Channel & {
  preferredRegionalChannel?: RegionalChannel;
};

const getChannelsWithRegionalPreferences = (
  channels: Channel[],
  regionalChannelMap: Record<string, string>,
): ChannelWithRegionalPreference[] => {
  return channels.map((channel) => {
    const preferredRegionalChannel = channel.regionalChannels.find(
      ({ id }) => id === regionalChannelMap[channel.id],
    );
    return {
      ...channel,
      preferredRegionalChannel,
    };
  });
};

export const useGetRegionalChannels = () => {
  const preferredRegionalChannels = PreferredRegionalChannels.instance();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const [channels, setChannels] = useState<(ChannelWithRegionalPreference | undefined)[]>([]);

  const refresh = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    try {
      const regionalChannelMap = await preferredRegionalChannels.getPreferredRegionalChannels();
      // Quickly allow the ui to provide placeholders for the channels
      setChannels(new Array<undefined>(Object.keys(regionalChannelMap).length).fill(undefined));
      const channelIds = Object.keys(regionalChannelMap);
      const channels = await getRegionalChannels(channelIds);
      setChannels(getChannelsWithRegionalPreferences(channels, regionalChannelMap));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [preferredRegionalChannels]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    loading,
    error,
    channels,
    refresh,
  };
};
