import { useEffect, useState } from 'react';

const hasWindow = typeof window === 'object';

export type WindowSize = {
  width: number;
  height: number;
};

export const readWindowSize = (): WindowSize => ({
  width: hasWindow ? window.innerWidth : -1,
  height: hasWindow ? window.innerHeight : -1,
});

type UseWindowSizeProps = { active?: boolean };

export const useWindowSize = (props?: UseWindowSizeProps): WindowSize => {
  const [windowSize, setWindowSize] = useState(readWindowSize);

  useEffect(() => {
    if (!hasWindow) {
      return () => ({ width: -1, height: -1 });
    }

    const handleResize = () => {
      setWindowSize(readWindowSize());
    };

    const shouldListenToResize = !props || typeof props.active === 'undefined' || props.active;

    if (shouldListenToResize) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (shouldListenToResize) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [props, props?.active]);

  return windowSize;
};
