import i18next from 'i18next';
import { format, isValid } from 'date-fns';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { MediaAccessHistoryItem } from 'services/backend/purchaseGateway';
import { TFunction } from 'react-i18next';

export const isActiveRental = (item: MediaAccessHistoryItem): boolean => {
  if (item.accessType !== 'RENTAL') {
    return false;
  }
  const endTime = new Date(item.endTime);
  const now = new Date();
  return isValid(endTime) && endTime.getTime() > now.getTime();
};

export const formatDate = (dateString: string): string => {
  const endTime = new Date(dateString);
  return isValid(endTime) ? format(endTime, 'yyyy-MM-dd') : dateString;
};

export const getMediaUrl = (item: MediaAccessHistoryItem): string => {
  const baseUrl = Config.get('one-app-base-url');
  switch (item.contentType) {
    case 'MOVIE':
      return `${baseUrl}/movie/${item.mediaId}`;
    case 'EPISODE':
      return `${baseUrl}/episode/${item.mediaId}`;
    default:
      return `${baseUrl}/`;
  }
};

export const getReadableDistance = (dateString: string): string => {
  const endTime = new Date(dateString);
  if (!isValid(endTime)) return dateString;

  const now = new Date();
  const diff = endTime.getTime() - now.getTime();
  if (diff < 0) return '';

  const minutes = Math.max(Math.round(diff / (60 * 1000)), 1); // Minimum time is 1 min

  return minutes < 60
    ? `${minutes} ${i18next.t('SUBSCRIPTION_PURCHASE_HISTORY_RENTAL_MINUTES_LEFT')}`
    : `${Math.round(minutes / 60)} ${i18next.t('SUBSCRIPTION_PURCHASE_HISTORY_RENTAL_HOURS_LEFT')}`;
};

export const getReadableAccessType = (
  { accessType, contentType }: MediaAccessHistoryItem,
  t: TFunction<'translation', undefined>,
) => {
  switch (accessType) {
    case 'RENTAL':
      switch (contentType) {
        case 'EPISODE':
          return t('SUBSCRIPTION_PURCHASE_HISTORY_RENTAL_EPISODE');
        default:
          return t('SUBSCRIPTION_PURCHASE_HISTORY_RENTAL_MOVIE');
      }
    case 'EST':
      switch (contentType) {
        case 'EPISODE':
          return t('SUBSCRIPTION_PURCHASE_HISTORY_PURCHASE_EPISODE');
        default:
          return t('SUBSCRIPTION_PURCHASE_HISTORY_PURCHASE_MOVIE');
      }
    default:
      return '';
  }
};

export const getReadableRentedOrBoughtOn = (
  { accessType, startTime }: MediaAccessHistoryItem,
  t: TFunction<'translation', undefined>,
) => {
  switch (accessType) {
    case 'EST':
      return t('SUBSCRIPTION_PURCHASE_HISTORY_PURCHASED_ON', { date: new Date(startTime) });
    case 'RENTAL':
      return t('SUBSCRIPTION_PURCHASE_HISTORY_RENTED_ON', { date: new Date(startTime) });
    default:
      return '';
  }
};
