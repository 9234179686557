import { useTranslation } from 'react-i18next';
import { BasePinModal, BasePinModalOnResponse } from '..';

type SetPinModalProps = {
  isOpen: boolean;
  onResponse: BasePinModalOnResponse;
};

export const SetPinModal = ({ isOpen, onResponse }: SetPinModalProps) => {
  const { t } = useTranslation();

  return (
    <BasePinModal
      isOpen={isOpen}
      title={t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}
      testid={'SETTINGS_LOCKER_CHANGE_PIN_CODE'}
      information={t('SETTINGS_LOCKER_SET_PIN_CODE_INFO')}
      confirmLabel={t('SETTINGS_LOCKER_SET_PIN_CODE_BUTTON_NEXT')}
      onResponse={onResponse}
    />
  );
};
