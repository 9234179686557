import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type FullscreenContextProps = {
  fullscreen: boolean;
  setFullscreen: (value: boolean) => void;
};

const FullscreenContext = createContext<FullscreenContextProps>({
  fullscreen: false,
  setFullscreen: () => {},
});

type FullscreenContextProviderProps = {
  children?: ReactNode;
};

export const FullscreenContextProvider = ({ children }: FullscreenContextProviderProps) => {
  const [fullscreenCounter, setFullscreenCounter] = useState(0);

  const setFullscreen = useCallback((fullscreen: boolean) => {
    setFullscreenCounter((prev) => (fullscreen ? prev + 1 : prev - 1));
  }, []);

  return (
    <FullscreenContext.Provider
      value={{
        fullscreen: fullscreenCounter > 0,
        setFullscreen,
      }}
    >
      {children}
    </FullscreenContext.Provider>
  );
};

export const useFullscreenContext = () => useContext(FullscreenContext);
