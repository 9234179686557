import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { StyledContainer, StyledHeader } from './UpdatePinDone.styles';
import {
  StyledCheckmarkIconWrapper,
  StyledIcon,
  StyledInformation,
  StyledButton,
} from './UpdatePinDone.styles';
import { useTranslation } from 'react-i18next';

export const UpdatePinCodeDone = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledCheckmarkIconWrapper>
        <StyledIcon name="Check"></StyledIcon>
      </StyledCheckmarkIconWrapper>
      <StyledHeader>{t('RESET_PURCHASE_PIN_SUCCESS_HEADER')} </StyledHeader>
      <StyledInformation>{t('RESET_PURCHASE_PIN_SUCCESS_INFORMATION')}</StyledInformation>
      <StyledButton as="a" href={Config.get('one-app-base-url')}>
        {t('RESET_PURCHASE_PIN_SUCCESS_BUTTON_LABEL')}
      </StyledButton>
    </StyledContainer>
  );
};
