import { FormEvent, useEffect, useRef, useState } from 'react';
import {
  StyledButton,
  StyledErrorIcon,
  StyledErrorMessage,
  StyledForm,
  StyledHeader,
  StyledLabel,
  StyledPinField,
} from './UpdatePinForm.styles';
import { PinFieldChangeEvent, PinFieldHandle } from '.';
import { useTranslation } from 'react-i18next';
import { resetPinCode } from 'services/userService';
import { useLogger, useSnackbars } from 'hooks';
import { useSearchParams } from 'react-router-dom';

type UpdatePinFormProps = {
  onSuccess: () => void;
};

export const UpdatePinCodeForm = ({ onSuccess }: UpdatePinFormProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const logger = useLogger('UpdatePinForm');
  const { createNegativeSnackbar } = useSnackbars();

  const newPinRef = useRef<PinFieldHandle>(null);
  const verifyPinRef = useRef<PinFieldHandle>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [isUpdatingPin, setIsUpdatingPin] = useState(false);
  const [newPin, setNewPin] = useState('');
  const [verifyPin, setVerifyPin] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onNewPinChanged = ({ value, lastIndexChanged }: PinFieldChangeEvent) => {
    setNewPin(value);

    if (verifyPin !== '') {
      verifyPinRef.current?.clear();
    }

    if (value.length === 4 && lastIndexChanged === 3) {
      verifyPinRef.current?.focus();
    }
  };

  const onVerifyPinChanged = ({ value, lastIndexChanged }: PinFieldChangeEvent) => {
    setVerifyPin(value);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setIsUpdatingPin(true);

    try {
      const token = searchParams.get('token') ?? '';
      await resetPinCode(newPin, token);
      onSuccess();
    } catch (error: unknown) {
      logger.error('Failed to reset pin code', error);
      createNegativeSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATE_ERROR'));
    } finally {
      setIsUpdatingPin(false);
    }
  };

  const focusSubmitButton = () => {
    const submitButton: HTMLButtonElement | undefined =
      formRef.current?.querySelector('.submit-button') ?? undefined;
    if (submitButton) {
      submitButton.focus();
    }
  };

  useEffect(() => {
    if (newPin.length === 4 && verifyPin.length === 4) {
      if (newPin === verifyPin) {
        setErrorMessage('');
        focusSubmitButton();
      } else {
        setErrorMessage(t('SETTINGS_LOCKER_PIN_CODE_MATCH_ERROR'));
      }
    } else {
      setErrorMessage('');
    }
  }, [newPin, verifyPin, t]);

  return (
    <>
      <StyledHeader>{t('RESET_PURCHASE_PIN_HEADER')}</StyledHeader>
      <StyledForm onSubmit={onSubmit} ref={formRef}>
        <StyledLabel>
          {t('RESET_PURCHASE_PIN_NEW_PIN_LABEL')}
          <StyledPinField ref={newPinRef} onChange={onNewPinChanged} autoFocus />
        </StyledLabel>
        <StyledLabel>
          {t('RESET_PURCHASE_PIN_VERIFY_PIN_LABEL')}
          <StyledPinField ref={verifyPinRef} onChange={onVerifyPinChanged} />
        </StyledLabel>
        {errorMessage && (
          <StyledErrorMessage>
            <StyledErrorIcon name="Alert" />
            {errorMessage}
          </StyledErrorMessage>
        )}
        <StyledButton
          className="submit-button"
          disabled={isUpdatingPin || !newPin || newPin !== verifyPin}
          isLoading={isUpdatingPin}
        >
          {t('RESET_PURCHASE_PIN_SAVE_BUTTON_LABEL')}
        </StyledButton>
      </StyledForm>
    </>
  );
};
