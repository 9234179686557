import styled from '@emotion/styled';
import { LogoHorizontal } from 'components/LogoHorizontal';
import { HeaderContainer } from '../HeaderContainer';
import { mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledHeaderContainer = styled(HeaderContainer)`
  position: relative;
  height: 56px;
  z-index: 3;
  padding: 0 16px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);

  ${mq.M`
    height: 80px;
  `}
`;

export const StyledLogo = styled(LogoHorizontal)`
  z-index: -1;
  inset: 0;
  top: 0;
  justify-self: center;
  margin: 0;
  svg {
    height: 32px;
  }
`;
