import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePinModal, BasePinModalOnResponse, BasePinModalGetValidationError } from '..';

type DeactivatePinModalProps = {
  isOpen: boolean;
  validatePinCode?: (pinCode: string) => Promise<boolean>;
  onResponse: BasePinModalOnResponse;
};

export const DeactivatePinModal = ({
  isOpen,
  validatePinCode,
  onResponse,
}: DeactivatePinModalProps) => {
  const { t } = useTranslation();

  const getValidationError: BasePinModalGetValidationError = useCallback(
    async (pinCode) => {
      if (!validatePinCode) {
        return;
      }
      const validateOk = await validatePinCode(pinCode);
      if (!validateOk) {
        return t('SETTINGS_LOCKER_WRONG_PIN_ERROR');
      }
    },
    [t, validatePinCode],
  );

  return (
    <BasePinModal
      isOpen={isOpen}
      title={t('SETTINGS_LOCKER_DEACTIVATE_PIN_CODE')}
      testid={'SETTINGS_LOCKER_DEACTIVATE_PIN_CODE'}
      information={t('SETTINGS_LOCKER_ENTER_CURRENT_PIN_CODE')}
      confirmLabel={t('SETTINGS_LOCKER_DEACTIVATE_PIN_CODE_BUTTON')}
      onResponse={onResponse}
      getValidationError={validatePinCode ? getValidationError : undefined}
    />
  );
};
