import { Log } from '@telia-company/tv.common-sdk/dist/Log';

export const GOOGLE_BUTTON_ID = 'google-button-id';

declare global {
  interface Window {
    google: {
      accounts: GoogleApi;
    };
  }
}

export type GoogleAuthResponse = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};
export type ShouldRenderGoogleButtonCallback = (shouldRender: boolean) => void;
export type ErrorCallback = (error: Error) => void;
export type SuccessCallback = (
  googleAuth: GoogleAuthResponse,
  signInButtonRendered?: boolean,
) => void;

type GoogleApi = {
  oauth2: {
    initTokenClient: (props: {
      client_id: string;
      callback: (val: any) => void;
      scope: string;
      error_callback: (error: Error) => void;
    }) => Promise<void>;
    requestAccessToken: () => Promise<{}>;
  };
  id: {
    initialize: (props: {
      client_id: string;
      callback: (googleAuth: GoogleAuthResponse) => void;
      error_callback: (error: Error) => void;
      cancel_on_tap_outside?: boolean;
      use_fedcm_for_prompt?: boolean;
    }) => Promise<void>;
    prompt: (val: any) => void;
    renderButton: (element: HTMLElement | null, config: Record<string, unknown>) => void;
  };
};

enum ERROR_MESSAGE {
  NO_CLIENT_ID = 'No client id found...',
  GOOGLE_API_NOT_FOUND = 'Google sign-in api not found...',
  NO_SUCCESS_CALLBACK = 'No success callback found...',
  NO_ERROR_CALLBACK = 'No error callback found...',
  NO_SHOULD_GOOGLE_BUTTON_RENDER_CALLBACK = 'No should google button render callback found...',
  FAILED_TO_INITIALIZE = 'Failed to initialize.. ',
}

const googleConnectLogger = {
  error: (...args: unknown[]) => {
    const logger = Log.instance()('GoogleConnect');
    logger.error('- Error -', ...args);
  },
  info: (...args: unknown[]) => {
    const logger = Log.instance()('GoogleConnect');
    logger.info('- Info -', ...args);
  },
};

type GoogleConnectProps = {
  clientId: string | undefined;
  errorCallback: ErrorCallback;
  successCallback: SuccessCallback;
  shouldRenderGoogleButtonCallback: ShouldRenderGoogleButtonCallback;
};
/**
 * GoogleConnect
 * @description
 * This class is used to connect to the google sign-in api
 * and trigger the google prompt to let the user sign in
 * or as a fallback renders the google sign-in button
 * Read more about the google sign-in api here:
 * https://developers.google.com/identity/gsi/web/guides/migration#popup-mode
 */
export class GoogleConnect {
  // Properties
  private _googleApi: GoogleApi | undefined;
  private _clientId: string | undefined;
  private _initialized: boolean = false;
  private _signInButtonRendered: boolean = false;
  // Methods
  private _successCallback: SuccessCallback | undefined;
  private _errorCallback: ErrorCallback | undefined;
  private _shouldRenderGoogleButtonCallback: ShouldRenderGoogleButtonCallback | undefined;

  constructor(props: GoogleConnectProps) {
    this._init(props);
  }

  private async _init({
    clientId,
    successCallback,
    errorCallback,
    shouldRenderGoogleButtonCallback,
  }: GoogleConnectProps) {
    if (!clientId) {
      googleConnectLogger.error(ERROR_MESSAGE.NO_CLIENT_ID);
      return;
    }

    if (!successCallback) {
      googleConnectLogger.error(ERROR_MESSAGE.NO_SUCCESS_CALLBACK);
      return;
    }

    if (!errorCallback) {
      googleConnectLogger.error(ERROR_MESSAGE.NO_ERROR_CALLBACK);
      return;
    }

    if (!shouldRenderGoogleButtonCallback) {
      googleConnectLogger.error(ERROR_MESSAGE.NO_SHOULD_GOOGLE_BUTTON_RENDER_CALLBACK);
      return;
    }

    this._clientId = clientId;
    this._successCallback = successCallback;
    this._errorCallback = errorCallback;
    this._shouldRenderGoogleButtonCallback = shouldRenderGoogleButtonCallback;

    if (!window?.google?.accounts) {
      googleConnectLogger.error(ERROR_MESSAGE.GOOGLE_API_NOT_FOUND);
      return;
    }

    this._googleApi = window?.google?.accounts;
    this._googleSignInApiIntialize();
  }

  private async _googleSignInApiIntialize() {
    try {
      if (!this._clientId) return googleConnectLogger.error(ERROR_MESSAGE.NO_CLIENT_ID);

      await this._googleApi?.id?.initialize({
        client_id: this._clientId,
        callback: this._onSuccesfullConnect,
        error_callback: this._onFailedConnect,
        cancel_on_tap_outside: false,
        use_fedcm_for_prompt: true,
      });

      this._initialized = true;
    } catch (e) {
      googleConnectLogger.error(ERROR_MESSAGE.FAILED_TO_INITIALIZE, e);
    }
  }

  private async _renderGoogleSignInButton() {
    const element = document?.getElementById(GOOGLE_BUTTON_ID);
    if (element) {
      this._googleApi?.id.renderButton(element, {
        shape: 'pill',
        text: 'signin',
        size: 'large',
      });
    }
  }

  // Note: this callback method, is a partly to prepare for the ticket and issue that is coming up in
  // https://jira.atlassian.teliacompany.net/browse/CWEB-2567 (the need to handle to the upcoming fedCM changes)
  private async _handlePromptWithFedCMEnabled(notification: any) {
    const reason = notification.getMomentType();
    const reasonInText = notification['j'];
    const surpressedByUser = reasonInText === 'suppressed_by_user';
    const optOut = reasonInText === 'opt_out_or_no_session';
    // When we are using fedCM for prompt, we do not have the same options in what we get back from the notification
    // object as we do when we are not using fedCM for prompt, but during development it seemed that we can access this property
    // g to at least see if we the reason for the notification is skipped
    // Also it seems that there is a time-delay between a notification is triggered
    // reason can only be set to 'skipped', 'display', or 'dismissed'
    // https://developers.google.com/identity/gsi/web/reference/js-reference#PromptMomentNotification:~:text=flow_restarted-,getMomentType(),-Return%20a%20string
    if (reason === 'skipped' || surpressedByUser || optOut) {
      // If we fail to render the OneTap moment,
      // lets render a login button as fallback for the user
      // this way the user can still login
      // (even if the OneTap is on cooldown for instance)
      this._renderGoogleSignInButton();
      this._triggerShouldRenderGoogleButtonCallback();
      this._signInButtonRendered = true;
    }
  }

  public async connect() {
    if (!this._googleApi) {
      googleConnectLogger.error(ERROR_MESSAGE.GOOGLE_API_NOT_FOUND);
      return;
    }

    if (!this._initialized) {
      googleConnectLogger.error(ERROR_MESSAGE.FAILED_TO_INITIALIZE);
      return;
    }

    if (!this._clientId) {
      googleConnectLogger.error(ERROR_MESSAGE.NO_CLIENT_ID);
      return;
    }

    // Lets trigger the google prompt,
    // to let the user sign in
    this._googleApi.id.prompt((notification: any) => {
      this._handlePromptWithFedCMEnabled(notification);
    });
  }

  private _onSuccesfullConnect = (googleAuth: GoogleAuthResponse) => {
    const signInButtonRendered = this._signInButtonRendered ? true : undefined;
    this._successCallback?.(googleAuth, signInButtonRendered);
  };
  private _triggerShouldRenderGoogleButtonCallback = () => {
    this._shouldRenderGoogleButtonCallback?.(true);
  };

  private _onFailedConnect = (error: Error) => {
    this._errorCallback?.(error);
  };
}
