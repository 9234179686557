import { useCallback, useEffect, useRef, useState } from 'react';
import { SelectionMenuScoreTableContent } from './components';
import {
  StyledExpandContractScoreTableButton,
  StyledScoreTableContainer,
} from './SelectionMenuScoreTable.styles';
import { motion, useAnimation } from 'framer-motion';
import { Chevron, Overlay } from 'components';
import { useMediaQueryMinSize, useWindowSize } from 'hooks';

const MotionScoreTableContainer = motion(StyledScoreTableContainer);

export const SelectionMenuScoreTableSmall = () => {
  const supportsDvh = CSS.supports('height: 100dvh');

  const [expanded, setExpanded] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const scoreTableControls = useAnimation();
  const [animating, setAnimating] = useState(false);
  const mqM = useMediaQueryMinSize('M');
  const { height: windowHeight } = useWindowSize({ active: !supportsDvh });

  // How much will the score table peek from the bottom
  const peekABoo = mqM ? 112 : 102;

  useEffect(() => {
    // The footer need to be able to be scrolled into view, even if we have
    // the draggable score table at the bottom
    document.body.style.marginBottom = `${peekABoo + 16}px`;
    return () => {
      document.body.style.removeProperty('margin-bottom');
    };
  }, [peekABoo]);

  // Calculate the max height of the score table, if dvh is not supported
  // by the browser
  useEffect(() => {
    if (!supportsDvh) {
      containerRef.current?.style.setProperty('--max-height', `${windowHeight - 32}px`);
    }
  }, [windowHeight, supportsDvh]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setContainerHeight(containerRef.current?.offsetHeight ?? 0);
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const startAnimation = async () => {
      setAnimating(true);

      if (supportsDvh) {
        await scoreTableControls.start({
          top: expanded ? `calc(100dvh - ${containerHeight}px)` : `calc(100dvh - ${peekABoo}px)`,
          y: 0,
          transition: { duration: 0.3 },
        });
      } else {
        // if dvh is not supported, we need to calculate the expansion height
        await scoreTableControls.start({
          top: expanded ? windowHeight - containerHeight : windowHeight - peekABoo,
          transition: { duration: 0.3 },
        });
      }

      setAnimating(false);
    };
    startAnimation();
  }, [containerHeight, scoreTableControls, expanded, peekABoo, supportsDvh, windowHeight]);

  const onClick = useCallback(() => {
    // click and drag seem to go hand in hand - need to
    // keep track that we're not currently animating
    // so not to cancel the expansion effect
    if (!animating) {
      setExpanded(!expanded);
    }
  }, [animating, expanded]);

  return (
    <>
      <Overlay visible={expanded} noScroll={true} />
      <MotionScoreTableContainer
        onClick={onClick}
        ref={containerRef}
        whileHover={{ y: expanded ? 0 : -10 }}
        drag={'y'}
        animate={scoreTableControls}
        dragConstraints={{ top: 0, bottom: 0 }}
        onDragEnd={(e) => {
          if (e.type === 'pointercancel') {
            return;
          }
          setExpanded(!expanded);
        }}
      >
        <StyledExpandContractScoreTableButton variant="ghost">
          <Chevron open={!expanded} />
        </StyledExpandContractScoreTableButton>
        <SelectionMenuScoreTableContent />
      </MotionScoreTableContainer>
    </>
  );
};
