import { DataManager } from '@telia-company/tv.common-sdk/dist/DataManager';
import { ServiceConfig } from '@telia-company/tv.common-sdk/dist/ServiceConfig';

const sendGet = async <T>(path: string, cacheClass: string): Promise<T> => {
  const engagementinformationgateway = ServiceConfig.getService('engagementinformationgateway');
  const dataManager = DataManager.instance();
  const url = engagementinformationgateway.getUrl(path);
  return (await dataManager.sendGet(url, cacheClass, {
    secure: true,
  })) as T;
};

type EngagementInfo = {
  // There are more information here, but only these are
  // needed
  pinActivated: boolean;
  pinCodeSet: boolean;
};

type GetEngagementInfoProps = {
  cacheClass: string;
};

export const getEngagementInfo = async ({
  cacheClass,
}: GetEngagementInfoProps): Promise<EngagementInfo> => {
  return await sendGet('/rest/secure/v2/engagementinfo', cacheClass);
};
