import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, mq } from '@telia-company/tv.oneapp-web-ui';
import { Chevron, SectionSpinner } from 'components';
import {
  headingSecondary,
  headingTertiary,
  textPrimary,
  textPrimaryEmphasis,
  textSecondary,
} from 'theme/theme.utils';

export const StyledScoreTableHeading = styled.h3`
  margin: 0;

  ${({ theme }) => css`
    ${headingTertiary(theme)}
    ${mq.L`
      ${headingSecondary(theme)}
    `}
  `}
`;

export const StyledPointsAvailableInfo = styled.div`
  ${({ theme }) => textPrimary(theme)};
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  margin: 8px 0 0 0;
`;

export const StyledSelectionHeading = styled.h4`
  ${({ theme }) => textPrimaryEmphasis(theme)};
`;

export const StyledSelectionContainer = styled.div`
  margin-top: 8px;
  gap: 1px;
  overflow-y: auto;

  border-radius: 8px;

  > * {
    margin-bottom: 1px;
  }

  > *:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  > *:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ${mq.L`
    overflow-y: initial;
  `}
`;

export const StyledIncludedContainer = styled.div`
  background-color: ${({ theme }) => theme.designTokens.color.button.secondaryDefault};
  margin-top: 24px;
  border-radius: 8px;
  padding: 16px;
`;

export const StyledIncludedHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => textPrimary(theme)};
  color: ${({ theme }) => theme.designTokens.color.text.primary}
  margin: 0;
`;

export const StyledExpandButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  display: flex;
  align-items: center;
`;

export const StyledChevron = styled(Chevron)`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  display: inline;
`;

export const StyledIncludedContentList = styled.div<{ expanded: boolean }>`
  ${({ theme }) => textSecondary(theme)};
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  margin-top: 8px;

  ${({ expanded }) =>
    !expanded &&
    `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export const StyledButtonsContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  justify-content: space-around;
`;

export const StyledResetButton = styled(Button)`
  flex: 1;
  white-space: nowrap;
`;

export const StyledConfirmChangesButton = styled(Button)`
  margin-left: 8px;
  flex: 2;
`;

export const StyledSectionSpinner = styled(SectionSpinner)`
  width: 100%;
  min-height: 50vh;
  align-items: start;
  padding-top: 8px;
  ${mq.L`
    align-items:center;
  `}
`;

export const StyledScrollableContainer = styled.div`
  margin-top: 16px;

  ${mq.L`
    margin-top: 24px;
  `}

  scrollbar-width: thin;

  scrollbar-color: ${({ theme }) => theme.designTokens.color.text.tertiary} transparent;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: ${({ theme }) => theme.designTokens.color.text.tertiary};
    border-radius: 8px;
    border: 3px solid ${({ theme }) => theme.designTokens.color.background.tertiary};
  }

  overflow-y: auto;
`;
