import styled from '@emotion/styled';
import { Icon } from '@telia-company/tv.oneapp-web-ui';

export const StyledFederationProviderTitle = styled.span`
  object-fit: fit;
  padding: 0px 0px 0px 12px;
`;

export const StyledButtonContentWrapper = styled.div`
  display: flex;
`;

export const StyledIconWrapper = styled.div`
  width: 50px;
`;

export const StyledRemoveIcon = styled(Icon)`
  border-left: 2px solid transparent;
  display: flex;
  padding: 0px 24px 0px 0px;
`;
