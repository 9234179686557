import { MenuItem } from '../../hooks';
import { StyledLinkLabel, StyledNavItem } from './MenuItemLink.styles';

type MenuItemLinkProps = { menuItem: MenuItem };
export const MenuItemLink = ({ menuItem }: MenuItemLinkProps) => {
  return (
    <StyledNavItem key={menuItem.id} to={menuItem.url}>
      <StyledLinkLabel>{menuItem.label}</StyledLinkLabel>
    </StyledNavItem>
  );
};
