import { ConfirmModal } from 'components/modals';
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectableItem } from 'services/selectionMenuService';
import { StyledItemDescription, StyledItemState, StyledLogo } from './ItemModal.styles';
import { getImageProxyUrl } from 'utils';
import { SelectionMenuContext } from '../../SelectionMenuContext';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { SmartLink } from 'components';

export type SelectableItemModalHandle = {
  open: (item: SelectableItem) => void;
};

export const SelectableItemModal = forwardRef<SelectableItemModalHandle>((_props, ref) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<SelectableItem>();
  const { onSelectableItemChange } = useContext(SelectionMenuContext);
  const { totalPoints, selectedPoints, oldSelectedItems } = useContext(SelectionMenuContext);

  useImperativeHandle(ref, () => ({
    open: (openItem) => {
      setItem(openItem);
    },
  }));

  if (!item) {
    return null;
  }

  const isActivatable =
    item.isSelected &&
    item.externalRegistration &&
    !!oldSelectedItems.find((i) => i.id === item.id);

  const missingPoints = item.isSelected
    ? 0
    : Math.max(0, selectedPoints + (item.points ?? 0) - totalPoints);

  const logoUrl = item && getImageProxyUrl({ url: item.logoUrl, height: 48 });

  const itemState = missingPoints
    ? t('SELECTIONMENU_ITEM_MISSING_POINTS', { points: missingPoints })
    : t('SELECTIONMENU_ITEM_POINTS', { points: item.points ?? 0 });

  const confirmLabel = isActivatable
    ? t('SELECTIONMENU_ITEM_MODAL_BUTTON_ACTIVATE')
    : item.isSelected
    ? t('SELECTIONMENU_ITEM_MODAL_BUTTON_REMOVE')
    : missingPoints
    ? t('COMMON_BUTTON_OK')
    : t('SELECTIONMENU_ITEM_MODAL_BUTTON_ADD');

  const buyPointsLink = Config.get('selection-menu-buy-points-link');

  const removeItem = () => {
    item.isSelected = false;
    onSelectableItemChange(item);
  };

  const addItem = () => {
    item.isSelected = true;
    onSelectableItemChange(item);
  };

  const activate = () => {
    if (!item.externalRegistration) {
      return;
    }
    window.location.href = item.externalRegistration?.url;
  };

  const noop = () => {};

  const confirmAction = isActivatable
    ? activate
    : item.isSelected
    ? removeItem
    : missingPoints
    ? noop
    : addItem;

  return (
    <ConfirmModal
      isOpen={true}
      title={item.name}
      showTitle={false}
      onResponse={(confirmed) => {
        if (confirmed) {
          confirmAction();
        }
        setItem(undefined);
      }}
      customButtons={
        isActivatable ? (
          <Button
            variant="secondary"
            onClick={() => {
              removeItem();
              setItem(undefined);
            }}
          >
            {t('SELECTIONMENU_ITEM_MODAL_BUTTON_REMOVE')}
          </Button>
        ) : missingPoints && buyPointsLink ? (
          <Button variant="secondary" as={SmartLink} to={buyPointsLink}>
            {t('SELECTIONMENU_ITEM_MODAL_MISSING_POINTS_BUY_POINTS_BUTTON')}
          </Button>
        ) : null
      }
      showCancel={false}
      showConfirm={!!confirmLabel}
      confirmLabel={confirmLabel}
    >
      <StyledLogo src={logoUrl} alt={item.name} />
      <StyledItemState>{itemState}</StyledItemState>
      <StyledItemDescription>{item.description}</StyledItemDescription>
    </ConfirmModal>
  );
});
