import { useEffect, useState } from 'react';
import {
  GoogleConnect,
  SuccessCallback,
  ErrorCallback,
  ShouldRenderGoogleButtonCallback,
} from '../utils/googleConnect.utils';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';

type UseGoogleConnectProps = {
  successCallback: SuccessCallback;
  errorCallback: ErrorCallback;
  shouldRenderGoogleButtonCallback: ShouldRenderGoogleButtonCallback;
};
export const useGoogleConnect = ({
  successCallback,
  errorCallback,
  shouldRenderGoogleButtonCallback,
}: UseGoogleConnectProps) => {
  const [googleConnect, setGoogleConnect] = useState<GoogleConnect | null>(null);
  useEffect(() => {
    const clientId = Config.get('federated-login')['google-client-id'];
    setGoogleConnect(
      new GoogleConnect({
        clientId,
        successCallback,
        errorCallback,
        shouldRenderGoogleButtonCallback,
      }),
    );
  }, [successCallback, errorCallback, shouldRenderGoogleButtonCallback]);

  return {
    googleConnect,
  };
};
