import styled from '@emotion/styled';
import { textPrimary } from 'theme/theme.utils';

export const StyledNewTag = styled.div`
  ${({ theme }) => textPrimary(theme)};
  font-weight: 700;
  background-color: ${({ theme }) => theme.designTokens.color.content.labelAnnouncement};
  text-transform: uppercase;
  margin-left: 8px;
  padding: 4px 5px;
  border-radius: 2px;
  font-size: 8px;
  line-height: 8px;
`;

export const StyledSelectedItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  background-color: ${({ theme }) => theme.designTokens.color.button.secondaryDefault};
  padding: 8px 16px;
`;

export const StyledSelectedItemName = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => textPrimary(theme)};
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  flex: 1;
`;

export const StyledSelectedItemPoints = styled.div`
  ${({ theme }) => textPrimary(theme)};
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  margin-left: 16px;
`;
