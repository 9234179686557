import { useMediaQueryMinSize } from 'hooks';
import { SelectionMenuScoreTableLarge } from './SelectionMenuScoreTableLarge';
import { SelectionMenuScoreTableSmall } from './SelectionMenuScoreTableSmall';

export const SelectionMenuScoreTable = () => {
  const isAtLeastSmall = useMediaQueryMinSize('L');
  const ScoreTableComponent = isAtLeastSmall
    ? SelectionMenuScoreTableLarge
    : SelectionMenuScoreTableSmall;

  return <ScoreTableComponent />;
};
