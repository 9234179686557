import { Input } from '@telia-company/tv.oneapp-web-ui';
import { ConfirmModal } from 'components/modals';
import { useLogger } from 'hooks';
import {
  ComponentProps,
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MfaStatus, requestMfaOneTimePassword } from 'services/userService';
import { VerifyMfaModal } from '../VerifyMfaModal';
import { StyledConfirmModal } from './CreateMfaModal.styles';
import { useTranslation } from 'react-i18next';
import { getValidEmailAddress } from './utils/getValidEmailAddress.utils';
import { getValidPhoneNumber } from './utils/getValidPhoneNumber.utils';
import { getPhoneNumberPlaceholder } from './utils/getPhoneNumberPlaceholder.utils';
import { country } from 'environment';

type CreateMfaModalProps = {
  isOpen: boolean;
  onClose: (created: boolean) => void;
  verificationMethod: MfaStatus['verificationMethod'];
  value: string;
};

export const CreateMfaModal = ({
  isOpen,
  onClose,
  verificationMethod,
  value,
}: CreateMfaModalProps) => {
  const logger = useLogger('CreateMfaModal');
  const [currentValue, setCurrentValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');
  const [isVerifyModalOpen, setVerifyModalOpen] = useState(false);
  const { t } = useTranslation();

  const getValidatedValue = useMemo<(value: string) => string | undefined>(() => {
    switch (verificationMethod) {
      case 'email':
        return (value) => getValidEmailAddress(value);
      case 'sms':
        return (value) => getValidPhoneNumber(value, country);
      default:
        return (value) => value.trim();
    }
  }, [verificationMethod]);

  const onResponse = useCallback<NonNullable<ComponentProps<typeof ConfirmModal>['onResponse']>>(
    (confirmed, setBusy) => {
      // Prevent multiple submissions
      if (isVerifyModalOpen) {
        return;
      }
      const create = async (value: string) => {
        setBusy(true);
        setErrorMessage('');
        try {
          await requestMfaOneTimePassword({
            verificationMethod,
            value,
            action: 'create',
          });
          setVerifyModalOpen(true);
        } catch (error) {
          logger.error('Failed to create MFA', error);
          setErrorMessage(t('ERROR_GENERIC_INFORMATION'));
        } finally {
          setBusy(false);
        }
      };

      if (confirmed) {
        const validatedValue = getValidatedValue(currentValue);
        if (!validatedValue) {
          switch (verificationMethod) {
            case 'email':
              setErrorMessage(t('MFA_CREATE_MODAL_INVALID_EMAIL_ERROR'));
              break;
            case 'sms':
              setErrorMessage(t('MFA_CREATE_MODAL_INVALID_PHONE_NUMBER_ERROR'));
              break;
            default:
              setErrorMessage(t('ERROR_GENERIC_INFORMATION'));
          }
          setBusy(false);
          return;
        }
        setCurrentValue(validatedValue);
        create(validatedValue);
      } else {
        onClose(false);
      }
    },
    [isVerifyModalOpen, verificationMethod, logger, t, getValidatedValue, currentValue, onClose],
  );

  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setCurrentValue(value);
    }
  }, [value, isOpen]);

  let title = '';
  let description = '';
  let label = '';
  let placeholder = '';
  let inputType: HTMLInputTypeAttribute = 'text';
  switch (verificationMethod) {
    case 'email':
      title = t('MFA_CREATE_MODAL_EMAIL_TITLE');
      description = t('MFA_CREATE_MODAL_EMAIL_DESCRIPTION');
      label = t('MFA_CREATE_MODAL_EMAIL_INPUT_LABEL');
      placeholder = t('MFA_CREATE_MODAL_EMAIL_INPUT_PLACEHOLDER');
      inputType = 'email';
      break;
    case 'sms':
      title = t('MFA_CREATE_MODAL_SMS_TITLE');
      description = t('MFA_CREATE_MODAL_SMS_DESCRIPTION');
      label = t('MFA_CREATE_MODAL_SMS_INPUT_LABEL');
      placeholder = getPhoneNumberPlaceholder(country);
      inputType = 'tel';
      break;
    default:
      throw new Error('Invalid verification method');
  }

  return (
    <>
      <StyledConfirmModal
        isOpen={isOpen}
        onResponse={onResponse}
        title={title}
        confirmLabel={t('MFA_ADD_BUTTON')}
      >
        <p>{description}</p>
        <Input
          autoFocus={true}
          label={label}
          state={!!errorMessage ? 'INVALID' : 'VALID'}
          message={errorMessage}
          type={inputType}
          placeholder={placeholder}
          value={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
        />
      </StyledConfirmModal>
      <VerifyMfaModal
        isOpen={isVerifyModalOpen}
        verificationMethod={verificationMethod}
        action="create"
        verificationTarget={currentValue}
        onClose={(verified) => {
          setVerifyModalOpen(false);
          if (verified) {
            onClose(true);
          }
        }}
      />
    </>
  );
};
