import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePinModal, BasePinModalOnResponse, BasePinModalGetValidationError } from '..';

type ConfirmPinModalProps = {
  isOpen: boolean;
  validatePinCode: (pinCode: string) => Promise<boolean>;
  onResponse: BasePinModalOnResponse;
};

export const ConfirmPinModal = ({ isOpen, validatePinCode, onResponse }: ConfirmPinModalProps) => {
  const { t } = useTranslation();

  const getValidationError: BasePinModalGetValidationError = useCallback(
    async (pinCode) => {
      const validateOk = await validatePinCode(pinCode);
      if (!validateOk) {
        return t('SETTINGS_LOCKER_PIN_CODE_MATCH_ERROR');
      }
    },
    [t, validatePinCode],
  );

  return (
    <BasePinModal
      isOpen={isOpen}
      title={t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}
      testid={'SETTINGS_LOCKER_CHANGE_PIN_CODE'}
      information={t('SETTINGS_LOCKER_CONFIRM_PIN_CODE_INFO')}
      confirmLabel={t('COMMON_BUTTON_SAVE')}
      onResponse={onResponse}
      getValidationError={getValidationError}
    />
  );
};
