import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { contentLabel } from 'theme/theme.utils';

export const StyledActivateTag = styled.div`
  ${({ theme }) => contentLabel(theme)}
  text-transform: uppercase;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.designTokens.color.button.primaryDefault};
  padding: 4px;

  ${mq.S`
    display: none;
  `};
`;
