import { Boot } from '@telia-company/tv.common-sdk/dist/Boot';
import { HttpRequestInterceptors } from '@telia-company/tv.common-sdk/dist/collections/HttpRequestInterceptors';
import { ServiceConfig } from '@telia-company/tv.common-sdk/dist/ServiceConfig';
import { country } from 'environment';

HttpRequestInterceptors.provide(() => [
  {
    interceptRequest(request) {
      const cvsGateway = ServiceConfig.getService('cvsgateway');
      const commerceGateway = ServiceConfig.getService('commercegateway');
      if (
        request.url.startsWith(cvsGateway.getUrl('')) ||
        request.url.startsWith(commerceGateway.getUrl(''))
      ) {
        const boot = Boot.instance();

        request.headers['tv-client-name'] = 'settingsweb';
        request.headers['tv-client-boot-id'] = boot.getBootId();
        request.headers['tv-client-version'] = process.env.REACT_APP_VERSION || '<unknown>';
        // @todo: need to adjust here so we have dynamic country
        request.headers['x-country'] = country;
      }
    },
  },
]);
