import { RequestError } from '@telia-company/tv.common-sdk/dist/ErrorTypes';
import { createLogger } from './createLogger.utils';

export const hasErrorCode = (requestError: RequestError, errorCode: string) => {
  const logger = createLogger('hasErrorCode');
  try {
    const response = JSON.parse(requestError.responseText);
    return response.errorCode === errorCode;
  } catch (ex) {
    logger.error('Failed to parse response', ex);
    return false;
  }
};
