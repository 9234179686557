import styled from '@emotion/styled';
import { buttonText } from 'theme/theme.utils';
import { SmartLink } from 'components';

export const StyledMenuItemImageLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledMenuItemImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const StyledMenuItemLabel = styled.div`
  color: #fff;
  ${({ theme }) => buttonText(theme)}
`;

export const StyledMenuItemInfo = styled.div`
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
`;

export const StyledMenuItem = styled.div<{ variant?: 'normal' | 'dark' }>`
  padding: 12px 24px;
  list-style-type: none;

  transition: background 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.designTokens.color.button.ghostHover};
  }

  background-color: ${({ theme, variant }) =>
    variant === 'dark' ? theme.designTokens.color.background.surfaceDark : 'transparent'};
`;

export const StyledSmartLink = styled(SmartLink)`
  display: block;
`;
