import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { SectionSpinner } from 'components';

export const StyledGridContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  > *:not(:last-child) {
    margin-bottom: 2px;
  }

  ${mq.S`
    border-radius: 0;
    gap: 16px;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: auto;
    align-self: baseline;
  `}
`;

export const StyledSectionSpinner = styled(SectionSpinner)`
  width: 100vw;
  min-height: 70vh;
`;
