import styled from '@emotion/styled';
import { Button } from '@telia-company/tv.oneapp-web-ui';

export const StyledSectionBackButton = styled(Button)`
  margin-top: -16px;
  padding-left: 12px;
  > div {
    gap: 8px;
  }
`;
