import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionContent,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';
import { useGetPasswordChangeLabelAndLink } from './hooks/useGetPasswordChangeLabelAndLink';

type PasswordProps = {
  user?: User;
};

export const Password = ({ user }: PasswordProps) => {
  const { t } = useTranslation();
  const { changeLabel, changeLink } = useGetPasswordChangeLabelAndLink();

  return (
    <StyledSubSection>
      <div>
        <StyledSubSectionLabel>{t('ACCOUNT_INFO_PASSWORD_SECTION_HEADING')}</StyledSubSectionLabel>
        <StyledSubSectionContent>
          {user ? <>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</> : '...'}
        </StyledSubSectionContent>
      </div>
      <StyledButtonContainer>
        <Button as={SmartLink} variant="secondary" disabled={!user} to={changeLink}>
          {changeLabel}
        </Button>
      </StyledButtonContainer>
    </StyledSubSection>
  );
};
