import styled from '@emotion/styled';
import { textPrimary, textSecondary } from 'theme/theme.utils';
import { GiftIcon } from './components';
import { mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledInformationContainer = styled.div`
  width: 100%;
`;

export const StyledVoucherSectionBorder = styled.div`
  background-image: linear-gradient(to right, #c46cf2, #990ae3);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 2px solid transparent;
  border-radius: 6px;
`;

export const StyledVoucherSectionContent = styled.div`
  position: relative;
  display: flex;
  padding: 16px;
  align-items: center;
  background-color: ${({ theme }) => theme.designTokens.color.background.tertiary};
  border-radius: 4px;
  color: transparent;
  margin: 2px;
`;

export const StyledGiftIcon = styled(GiftIcon)`
  flex-shrink: 0;
  margin-right: 16px;

  ${mq.S`
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
  `};
`;

export const StyledRemainingContainer = styled.h3`
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

export const StyledRemainingInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.S`
    flex-direction: row;
    width: 100%;
    margin-left: 48px;
  `}
`;

export const StyledRemainingLabel = styled.span`
  flex: 1;
`;

export const StyledValidUntilLabel = styled.span`
  flex-shrink: 0;
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
`;

export const StyledInformation = styled.div`
  ${({ theme }) => textSecondary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  ${mq.S`
    margin-left: 48px;
  `}
`;

export const StyledVoucherSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
