import { Account } from '@telia-company/tv.common-sdk-authentication/dist/Account';
import { useCallback, useState } from 'react';

export const useChangeUsername = () => {
  const [busy, setBusy] = useState(false);
  const [changed, setChanged] = useState(false);
  const [error, setError] = useState<unknown>();

  const account = Account.instance();

  const changeUsername = useCallback(
    async (newUsername: string, password: string) => {
      try {
        setChanged(false);
        setBusy(true);
        setError(undefined);
        await account.changeUsername(newUsername, password);
        setChanged(true);
      } catch (error) {
        setError(error);
      } finally {
        setBusy(false);
      }
    },
    [account],
  );

  return { changeUsername, error, busy, changed };
};
