import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import { FeatureEnabled } from 'components/FeatureEnabled';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionContent,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';

type UsernameProps = {
  user?: User;
};

export const Username = ({ user }: UsernameProps) => {
  const { t } = useTranslation();
  return (
    <StyledSubSection>
      <div>
        <StyledSubSectionLabel>{t('ACCOUNT_INFO_USERNAME_SECTION_HEADING')}</StyledSubSectionLabel>
        <StyledSubSectionContent>
          {user ? user.username || t('COMMON_NOT_AVAILABLE') : '...'}
        </StyledSubSectionContent>
      </div>
      <FeatureEnabled featureToggles={['Account.Username.Change']}>
        <StyledButtonContainer>
          <Button as={SmartLink} variant="secondary" to={'/account/username'}>
            {t('COMMON_BUTTON_CHANGE')}
          </Button>
        </StyledButtonContainer>
      </FeatureEnabled>
    </StyledSubSection>
  );
};
