import styled from '@emotion/styled';
import { pxToRem } from '@telia-company/tv.oneapp-web-ui';
import { textSecondary } from 'theme/theme.utils';

export const StyledSubSectionButtonContainer = styled.div`
  margin-top: ${pxToRem(16)}rem;
`;

export const StyledChangeInformation = styled.div`
  margin-top: ${pxToRem(16)}rem;
  ${({ theme }) => textSecondary(theme)}

  a {
    text-decoration: underline;
  }
`;
