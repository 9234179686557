import { useLogger } from 'hooks';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { ReactNode } from 'react';
import { Authentication } from 'sdk/authentication';

type LoggedinProps = {
  children?: ReactNode;
};
export const Loggedin = ({ children }: LoggedinProps) => {
  const logger = useLogger('Loggedin');
  const authentication = Authentication.instance();
  const { data: isLoggedin, loading, error } = useFetchFunction(authentication.isLoggedin);

  if (error) {
    logger.error('Failed to check if user is logged in', error);
    throw error;
  }

  if (loading || !isLoggedin) {
    return null;
  }

  return <>{children}</>;
};
