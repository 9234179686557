import { CenteredSpinner } from 'components';
import { StyledSubSection, StyledSubSectionContainer } from 'pages/navigation.styles';

export const LoadingSubSection = () => (
  <StyledSubSectionContainer>
    <StyledSubSection>
      <CenteredSpinner />
    </StyledSubSection>
  </StyledSubSectionContainer>
);
