import { Button } from '@telia-company/tv.oneapp-web-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestError } from '@telia-company/tv.common-sdk/dist/ErrorTypes';
import {
  AuhorizationPayload,
  doDeleteConnection,
  federatorAuthorization,
} from 'services/userService';
import { useLogger, useSnackbars } from 'hooks';
import { LoggedinDevice } from 'services/deviceService';
import { getCountry } from 'utils';
import {
  StyledRemoveIcon,
  StyledButtonContentWrapper,
  StyledIconWrapper,
} from 'pages/federated.styles';
import { useGoogleConnect } from './hooks/useGoogleConnect';
import { GOOGLE_BUTTON_ID, GoogleAuthResponse } from './utils/googleConnect.utils';

type DoDeleteConnectionResponse = {
  error?: Error;
};

type GoogleButtonProps = {
  isGoogleConnected?: boolean;
  setIsGoogleConnected: (isConnected: boolean) => void;
  thisDevice?: LoggedinDevice;
};
export const GoogleButton = ({
  isGoogleConnected,
  setIsGoogleConnected,
  thisDevice,
}: GoogleButtonProps) => {
  const { t } = useTranslation();
  const logger = useLogger('navigation/account/google-button');

  const { createPositiveSnackbar, createNegativeSnackbar, createNeutralSnackbar } = useSnackbars();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const country = getCountry();

  const handleGoogleError = useCallback(
    (error: Error) => {
      createNegativeSnackbar(t('ACCOUNT_FEDERATED_GENERAL_ERROR'));
      setIsGoogleConnected(false);
      setButtonDisabled(false);
      logger.error('An error happened...', error);
    },
    [createNegativeSnackbar, setIsGoogleConnected, logger, t],
  );

  const googleResponse = useCallback(
    async (response: GoogleAuthResponse, signinButtonRendered?: boolean) => {
      const openIdToken = response.credential;
      const payload: AuhorizationPayload = {
        country: country,
        openIdToken,
        code: '',
        federatedBy: 'google',
        deviceType: 'WEB',
        deviceId: thisDevice?.deviceId || '',
      };

      const connectionResult = await federatorAuthorization(payload);

      if (connectionResult.error) {
        createNegativeSnackbar(t('ACCOUNT_FEDERATED_GENERAL_ERROR'));
        setIsGoogleConnected(false);
      } else {
        createPositiveSnackbar(t('ACCOUNT_FEDERATED_CONNECTION_SUCCESS'));
        setIsGoogleConnected(true);
      }

      setButtonDisabled(false);
      if (signinButtonRendered) {
        window.location.reload();
      }
    },
    [country, createNegativeSnackbar, createPositiveSnackbar, setIsGoogleConnected, thisDevice, t],
  );

  const shouldRenderGoogleButtonCallback = useCallback(
    (shouldRender: boolean) => {
      shouldRender && createNeutralSnackbar(t('ACCOUNT_FEDERATED_GOOGLE_COOLDOWN_ERROR'));
    },
    [createNeutralSnackbar, t],
  );

  const deleteConnection = useCallback(async () => {
    setButtonDisabled(true);
    const doDeleteStatus = (await doDeleteConnection('google')) as DoDeleteConnectionResponse;

    if (doDeleteStatus?.error) {
      let errorMessage = t('ACCOUNT_FEDERATED_REMOVAL_ERROR');
      if (doDeleteStatus.error instanceof RequestError) {
        switch (doDeleteStatus.error.status) {
          case 500:
            errorMessage = t('ACCOUNT_FEDERATED_REMOVAL_ERROR');
            break;
        }
      }
      logger.error('Failed to make new connection', doDeleteStatus.error);
      setButtonDisabled(false);
      return createNegativeSnackbar(errorMessage);
    }
    setIsGoogleConnected(false);
    createPositiveSnackbar(t('ACCOUNT_FEDERATED_REMOVAL_SUCCESS'));
    setButtonDisabled(false);
  }, [createNegativeSnackbar, createPositiveSnackbar, logger, setIsGoogleConnected, t]);

  const { googleConnect } = useGoogleConnect({
    errorCallback: handleGoogleError,
    successCallback: googleResponse,
    shouldRenderGoogleButtonCallback,
  });

  const googleLogin = useCallback(() => googleConnect?.connect(), [googleConnect]);

  const addConnection = useCallback(() => {
    setButtonDisabled(true);
    return googleLogin && googleLogin();
  }, [googleLogin]);

  return (
    <>
      {!isGoogleConnected ? (
        <div id={GOOGLE_BUTTON_ID}>
          <Button disabled={buttonDisabled} variant="secondary" onClick={addConnection}>
            {t('ACCOUNT_FEDERATED_ADD')}
          </Button>
        </div>
      ) : (
        <Button disabled={buttonDisabled} variant="secondary" onClick={deleteConnection}>
          <StyledButtonContentWrapper>
            <StyledIconWrapper>
              <StyledRemoveIcon name="Remove" />
            </StyledIconWrapper>
            <span style={{ whiteSpace: 'nowrap' }}>{t('ACCOUNT_FEDERATED_REMOVE')}</span>
          </StyledButtonContentWrapper>
        </Button>
      )}
    </>
  );
};
