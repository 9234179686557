import { isNorway } from 'utils';

export const isUsernameVisible = () => {
  return !isNorway();
};

export const isNameVisible = () => {
  return isNorway();
};

export const isCustomerNumberVisible = () => {
  return isNorway();
};
