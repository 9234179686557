import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { StyledSearchLink } from './SearchLink.styles';

type SearchLinkProps = {
  className?: string;
};

export const SearchLink = ({ className }: SearchLinkProps) => {
  const searchIcon = Icon({ name: 'Search' });
  return (
    <StyledSearchLink className={className} href={`${Config.get('one-app-base-url')}/search`}>
      {searchIcon}
    </StyledSearchLink>
  );
};
