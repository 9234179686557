import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export type PortalProps = {
  children: React.ReactNode;
};

export function Portal({ children }: PortalProps): JSX.Element | null {
  const [portalElement, setPortalElement] = useState<HTMLDivElement>();

  useEffect(() => {
    const createdElement = document.createElement('div');
    document.getElementById('root')?.appendChild(createdElement);
    setPortalElement(createdElement);

    return () => {
      if (createdElement) {
        document.getElementById('root')?.removeChild(createdElement);
      }
    };
  }, []);

  if (!portalElement) return null;

  return createPortal(children, portalElement);
}
