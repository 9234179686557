import { FooterDataType } from '../Footer';
import { country } from 'environment';
import { footerTeliaDanish, footerTeliaFinnish, footerTeliaSwedish, footerTeliaNorwegian } from '.';
import { oneAppUrl } from 'utils';
import { getMainMenu } from 'services/backend/commonGraph';
import { PageLinkFragment } from 'gql/generated';
import { ColumnLink } from '../column';

// @todo: when we have data points from graphql, we can move the data fetching
// to a query and pass the data down similar as to the header handles data.
// fallback to swedish footer.

const createFooterLink = ({ id, name }: PageLinkFragment): ColumnLink => {
  return {
    name: name ?? '',
    link: oneAppUrl(id),
  };
};

export const fetchFooter = async (): Promise<FooterDataType | undefined> => {
  const appLinks = (await getMainMenu()).data.mainMenu?.items.map(createFooterLink) ?? [];

  switch (country) {
    case 'DK':
      return footerTeliaDanish(appLinks);
    case 'SE':
      return footerTeliaSwedish(appLinks);
    case 'FI':
      return footerTeliaFinnish(appLinks);
    case 'NO':
      return footerTeliaNorwegian(appLinks);
  }
};
