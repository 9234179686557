import { ReactNode, createContext, useContext, useState } from 'react';

type HeaderSizeContextProps = {
  height: number;
  setHeight: (height: number) => void;
};

const HeaderSizeContext = createContext<HeaderSizeContextProps>({
  height: 0,
  setHeight: (height: number) => {},
});

type HeaderSizeContextProviderProps = {
  children?: ReactNode;
};

export const HeaderSizeContextProvider = ({ children }: HeaderSizeContextProviderProps) => {
  const [height, setHeight] = useState(0);
  return (
    <HeaderSizeContext.Provider value={{ height, setHeight }}>
      {children}
    </HeaderSizeContext.Provider>
  );
};

export const useHeaderSizeContext = () => useContext(HeaderSizeContext);
