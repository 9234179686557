import {
  StyledSectionSubHeading,
  StyledSubSection,
  StyledSubSectionContainer,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';

type DualEntrySubscriptionProps = {
  user: User;
};

export const DualEntrySubscription = ({ user }: DualEntrySubscriptionProps) => {
  const { t } = useTranslation();
  const subscriptionHeadingKey =
    user.subscriptions.length === 1
      ? 'SUBSCRIPTION_SECONDARY_HEADING'
      : 'SUBSCRIPTION_SECONDARY_HEADING_MULTIPLE_SUBSCRIPTIONS';

  return (
    <>
      <StyledSectionSubHeading>{t(subscriptionHeadingKey)}</StyledSectionSubHeading>
      <StyledSubSectionContainer>
        <StyledSubSection>
          {user.subscriptions.map(({ id, name }) => (
            <StyledSubSectionLabel key={id}>{name}</StyledSubSectionLabel>
          ))}
        </StyledSubSection>
      </StyledSubSectionContainer>
    </>
  );
};
