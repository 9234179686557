import { useTranslation } from 'react-i18next';
import {
  StyledButtonsContainer,
  StyledChevron,
  StyledConfirmChangesButton,
  StyledExpandButton,
  StyledIncludedContainer,
  StyledIncludedContentList,
  StyledIncludedHeadingContainer,
  StyledPointsAvailableInfo,
  StyledResetButton,
  StyledScoreTableHeading,
  StyledScrollableContainer,
  StyledSectionSpinner,
  StyledSelectionContainer,
  StyledSelectionHeading,
} from './SelectionMenuScoreTableContent.styles';
import { ProgressBar } from './ProgressBar';
import { SelectedItem } from './SelectedItem';
import { useContext, useState } from 'react';
import { SelectionMenuContext } from '../../../SelectionMenuContext';

export const SelectionMenuScoreTableContent = () => {
  const { t } = useTranslation();
  const {
    selectedPoints,
    totalPoints,
    newSelectedItems,
    oldSelectedItems,
    fixedItems,
    dirty,
    reset,
    resetting,
    save,
    saving,
    loading,
  } = useContext(SelectionMenuContext);
  const [seeAllChannels, setSeeAllChannels] = useState(false);
  const allSelectedItems = newSelectedItems.concat(oldSelectedItems);
  const pointsRemaining = totalPoints - selectedPoints;
  const includedItems = fixedItems.map((item) => item.name).join(', ');

  const saveEnabled = dirty && !saving && !resetting && selectedPoints <= totalPoints;

  return loading ? (
    <StyledSectionSpinner />
  ) : (
    <>
      <StyledScoreTableHeading>
        {t('SELECTIONMENU_SCORETABLE_POINTS_USED', {
          current: selectedPoints,
          total: totalPoints,
        })}
      </StyledScoreTableHeading>
      <StyledPointsAvailableInfo>
        {t('SELECTIONMENU_SCORETABLE_POINTS_AVAILABLE', {
          points: Math.max(pointsRemaining, 0),
        })}
      </StyledPointsAvailableInfo>
      <ProgressBar selectedPoints={selectedPoints} pointsTotal={totalPoints} />
      <StyledScrollableContainer>
        <StyledSelectionHeading>
          {t('SELECTIONMENU_SCORETABLE_YOUR_SELECTION')}
        </StyledSelectionHeading>
        <StyledSelectionContainer>
          {allSelectedItems.map((item, index) => (
            <SelectedItem key={item.id} item={item} isNew={index < newSelectedItems.length} />
          ))}
        </StyledSelectionContainer>

        <StyledIncludedContainer
          onClick={(e) => {
            e.stopPropagation();
            setSeeAllChannels(!seeAllChannels);
          }}
        >
          <StyledIncludedHeadingContainer>
            {t('SELECTIONMENU_SCORETABLE_INCLUDED_CHANNELS')}
            <StyledExpandButton>
              {seeAllChannels
                ? t('SELECTIONMENU_SCORETABLE_SHOW_LESS')
                : t('SELECTIONMENU_SCORETABLE_SEE_ALL')}
              <StyledChevron open={seeAllChannels} />
            </StyledExpandButton>
          </StyledIncludedHeadingContainer>
          <StyledIncludedContentList expanded={seeAllChannels}>
            {includedItems}
          </StyledIncludedContentList>
        </StyledIncludedContainer>
      </StyledScrollableContainer>
      <StyledButtonsContainer>
        <StyledResetButton
          variant="secondary"
          disabled={!dirty || saving || resetting}
          isLoading={resetting}
          onClick={(e) => {
            e.stopPropagation();
            reset();
          }}
        >
          {t('SELECTIONMENU_SCORETABLE_BUTTON_UNDO_CHANGES')}
        </StyledResetButton>
        <StyledConfirmChangesButton
          variant="primary"
          disabled={!saveEnabled}
          isLoading={saving}
          onClick={(e) => {
            e.stopPropagation();
            save();
          }}
        >
          {t('SELECTIONMENU_SCORETABLE_BUTTON_CONFIRM_CHANGES')}
        </StyledConfirmChangesButton>
      </StyledButtonsContainer>
    </>
  );
};
