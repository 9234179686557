import { useCallback, useEffect, useState } from 'react';
import { RequestError } from '@telia-company/tv.common-sdk/dist/ErrorTypes';
import { useTranslation } from 'react-i18next';
import { Federator } from 'services/backend/engagementGateway';
import { isNonEmptyObject } from '../utils/isNonEmptyObject.utils';

export type ConnectedStatusResponse = {
  error?: Error;
  connectedFederators?: [] | Array<Federator>;
};
type UseIsConnectedProps = {
  dataConnectedStatus: ConnectedStatusResponse | undefined;
};

export const useIsConnectedFederator = ({ dataConnectedStatus }: UseIsConnectedProps) => {
  const [connected, setConnected] = useState<Array<Federator>>([]);
  const [error, setError] = useState<Error | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [theDataConnectedStatus, setTheDataConnectedStatus] = useState<
    ConnectedStatusResponse | undefined
  >();

  const { t } = useTranslation();

  useEffect(() => {
    if (isNonEmptyObject(dataConnectedStatus)) {
      setTheDataConnectedStatus(dataConnectedStatus);
    }
  }, [dataConnectedStatus]);

  const isConnected = useCallback(
    (accountType: Federator) => {
      return (
        theDataConnectedStatus &&
        theDataConnectedStatus?.connectedFederators &&
        theDataConnectedStatus.connectedFederators?.length > 0 &&
        (theDataConnectedStatus.connectedFederators as Array<Federator>).includes(accountType)
      );
    },
    [theDataConnectedStatus],
  );

  const setConnectedFederator = (federator: Federator) => {
    return (isConnected: boolean) => {
      setConnected((prevConnected) => {
        let newConnected = prevConnected;

        // Lets remove the current federator if it exists
        if (newConnected.includes(federator)) {
          newConnected = newConnected.filter((f) => f !== federator);
        }

        // Only if we add the value with isConnected,
        // we should add the current federator
        if (isConnected) {
          return [...newConnected, federator];
        }
        // Otherwise we should return the newConnected array
        // (since its missing the current federator)
        return [...newConnected];
      });
    };
  };

  useEffect(() => {
    if (!theDataConnectedStatus) return;

    if (theDataConnectedStatus?.error) {
      let errorMessage = 'Error connecting';
      if (theDataConnectedStatus.error instanceof RequestError) {
        switch (theDataConnectedStatus.error.status) {
          case 400:
            errorMessage = t('ACCOUNT_FEDERATED_INVALID_DATA_ERROR');
            break;
          case 404:
            errorMessage = t('ACCOUNT_FEDERATED_NOT_FOUND_ERROR');
            break;
          case 409:
            errorMessage = t('ACCOUNT_FEDERATED_ALREADY_CONNECTED_ERROR');
            break;
          case 500:
            errorMessage = t('ACCOUNT_FEDERATED_GENERAL_ERROR');
            break;
        }
      }

      setError(theDataConnectedStatus.error);
      setErrorMessage(errorMessage);
    }

    let connectedFederators = [] as Array<Federator>;
    if (isConnected('google')) {
      connectedFederators.push('google');
    }
    if (isConnected('apple')) {
      connectedFederators.push('apple');
    }

    setConnected(connectedFederators);
  }, [theDataConnectedStatus, isConnected, t]);

  return {
    setConnectedFederator,
    connectedFederator: connected,
    error,
    errorMessage,
  };
};
