import { InMemoryCache } from '@apollo/client';
import { createApolloClient } from '@telia-company/tv.common-sdk-apollo-react-client';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Log } from '@telia-company/tv.common-sdk/dist/Log';
import { createSingleton } from '@telia-company/tv.common-sdk/dist/Singleton';
import generated from 'gql/generated';

const cache = new InMemoryCache({ possibleTypes: generated.possibleTypes });

export const ApolloClient = createSingleton(
  'sdk/ApolloClient',
  () => {
    return createApolloClient({
      graphqlUrl: Config.get('common-graphql-url'),
      cache,
      showRequestLogs: process.env.NODE_ENV === 'development',
      onError: (error) => {
        const logger = Log.instance()('apollo/client');
        logger.error('Error in apollo client', error);
      },
    });
  },
  {},
);
