import { AnimatePresence, Variants } from 'framer-motion';
import { MouseEventHandler, ReactNode, useEffect } from 'react';
import { StyledOverlay, StyledOverlayBackground } from './Overlay.styles';

const animations: Variants = {
  visible: { opacity: 1.0, transition: { duration: 0.3 } },
  hidden: { opacity: 0.0, transition: { duration: 0.3 } },
};

type OverlayProps = {
  visible: boolean;
  noScroll?: boolean;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const Overlay = ({ visible, noScroll, children, onClick }: OverlayProps) => {
  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    if (visible && noScroll) {
      document.body.classList.add('no-scroll');
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    }
    if (!visible && noScroll) {
      document.body.classList.remove('no-scroll');
      document.body.style.paddingRight = '0';
    }
    return () => {
      if (noScroll) {
        document.body.classList.remove('no-scroll');
        document.body.style.paddingRight = '0';
      }
    };
  }, [noScroll, visible]);

  return (
    <AnimatePresence>
      {visible && (
        <StyledOverlay>
          <StyledOverlayBackground
            animate="visible"
            initial="hidden"
            exit="hidden"
            variants={animations}
            onClick={onClick}
          />
          {children}
        </StyledOverlay>
      )}
    </AnimatePresence>
  );
};
