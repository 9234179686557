import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { FeatureToggle } from 'featureToggles';

export const FederatedLoginGoogleEnabled: FeatureToggle = async () => {
  // In theory we could probably just return the value of the config,
  // but this is how it's done in the other feature toggles
  // and such we might wanna keep things consistent
  // but at the same time have the granularity
  // to change the implementation
  const googleClientId = Config.get('federated-login')['google-client-id'];
  const googleEnabled = Config.get('federated-login')['google-enabled'];
  return !!(googleClientId && googleEnabled);
};
