import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledLogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 1rem;

  & svg {
    height: 24px;
    cursor: pointer;

    ${mq.L`
    height: 32px;
  `}
  }
`;

export const IconWrapper = styled.a`
  display: inline-flex;
`;
