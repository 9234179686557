import { useCallback, useEffect, useState } from 'react';

type FetchFunction<I extends unknown[], O> = (...args: I) => Promise<O>;

export const useFetchFunction = <Input extends unknown[], Output>(
  fetchFunction: FetchFunction<Input, Output>,
  ...fetchArgs: Input
) => {
  const [data, setData] = useState<Output>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown>();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError(undefined);
      setData(undefined);
      setData(await fetchFunction(...fetchArgs));
    } catch (e: unknown) {
      setError(e);
    } finally {
      setLoading(false);
    }
    /**
     * Spreading fetchArgs prevents an infinite re-render.
     * When changing this function, make sure to enable react-hooks/exhaustive-deps
     * to get everything right before spreading fetchArgs and re-disabling it.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...fetchArgs, fetchFunction]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    error,
    refresh: fetchData,
  };
};
