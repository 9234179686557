export const COOKIE_NAMES = {
  COUNTRY: 'PreferredCountry',
  ENVIRONMENT: 'PreferredEnvironment',
};

const deleteCookie = (cname: string) => {
  setCookie(cname, '', 0);
};

export const deleteAllEnvCookies = () => {
  const cookieNames = Object.keys(COOKIE_NAMES).map((key) => {
    return COOKIE_NAMES[key as keyof typeof COOKIE_NAMES];
  });

  for (const cookieName of cookieNames) {
    deleteCookie(cookieName);
  }
};

const getCookie = (cname: string) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setCookie = (cname: string, cvalue: string, exdays: number = 365) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const COOKIE_HANDLER = {
  getCookie,
  setCookie,
};
