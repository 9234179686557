import { Locale } from 'date-fns';
import { da, enUS, fi, sv, nb } from 'date-fns/locale';
import { getCountry, getEnvironment } from 'utils';

export const Environments = ['PROD', 'PILOT'] as const;
export const Countries = ['SE', 'DK', 'FI', 'NO'] as const;

export type Environment = (typeof Environments)[number];
export type Country = (typeof Countries)[number];

if (
  !process.env.REACT_APP_ENVIRONMENT ||
  !Environments.includes(process.env.REACT_APP_ENVIRONMENT as Environment)
) {
  throw new Error(
    `REACT_APP_ENVIRONMENT not valid. Allowed values: ${Environments.join(', ')}. Current value: ${
      process.env.REACT_APP_ENVIRONMENT
    }`,
  );
}

if (
  !process.env.REACT_APP_COUNTRY ||
  !Countries.includes(process.env.REACT_APP_COUNTRY as Country)
) {
  throw new Error(
    `REACT_APP_COUNTRY not valid. Allowed values: ${Countries.join(', ')}. Current value: ${
      process.env.REACT_APP_COUNTRY
    }`,
  );
}

const locales: { [key in Country]: Locale } = {
  SE: sv,
  DK: da,
  FI: fi,
  NO: nb,
};

export const environment: Environment = getEnvironment();
export const country: Country = getCountry();

export const locale = locales[country] || enUS;
