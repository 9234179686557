import { ComponentProps } from 'react';
import {
  StyledMessageItem,
  StyledIcon,
  StyledDismissButton,
  StyledMessage,
  StyledLink,
  StyledMessageContainer,
} from './MessageItem.styles';
import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { useTranslation } from 'react-i18next';
import { Message, MessageItemVariant } from 'services/messagesService';

type MessageItemProps = {
  message: Message;
  onDismiss?: () => void;
  link?: string;
};

const iconNames: Record<MessageItemVariant, ComponentProps<typeof StyledIcon>['name']> = {
  attention: 'Alert',
  negative: 'Warning',
  neutral: 'Info',
  informative: 'Info',
};

export const MessageItem = ({ message: { text, link, variant }, onDismiss }: MessageItemProps) => {
  const { t } = useTranslation();
  return (
    <StyledMessageItem variant={variant ?? 'neutral'}>
      <StyledIcon name={iconNames[variant ?? 'neutral']} />
      <StyledMessageContainer>
        <StyledMessage>{text}</StyledMessage>
        {!!link && <StyledLink to={link}>{t('MESSAGE_BAR_READ_MORE_LABEL')}</StyledLink>}
      </StyledMessageContainer>
      {onDismiss && (
        <StyledDismissButton onClick={() => onDismiss()}>
          <Icon name="Close" />
        </StyledDismissButton>
      )}
    </StyledMessageItem>
  );
};
