import { FixedItem, SelectableItem } from 'services/selectionMenuService';
import { FilterOption } from './components';
import { SelectionMenuResponse } from 'services/backend/purchaseGateway';
import { byRegistrationState } from './byRegistrationState.utils';

type GetFilerItemsProps = {
  activatableTvPackageIds: string[];
  filter: FilterOption;
  data: SelectionMenuResponse;
};

export const getFilteredItems = ({ activatableTvPackageIds, filter, data }: GetFilerItemsProps) => {
  let items: (SelectableItem | FixedItem)[] = [];

  switch (filter) {
    case 'STREAMING_SERVICES':
      items = data.selectables.filter((item) => item.vod) ?? [];
      break;
    case 'CHANNELS':
      items = data.selectables.filter((item) => !item.vod) ?? [];
      break;
    case 'FIXED':
      items = data.fixed ?? [];
      break;
    default:
      items = [];
  }

  return items.sort(byRegistrationState(activatableTvPackageIds));
};
