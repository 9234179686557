import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type Language = {
  name: string;
  code: string;
};

export const useGetLanguages = () => {
  const { t } = useTranslation();

  const languages: Language[] = useMemo(
    () => [
      {
        name: t('SETTINGS_DEVICE_PREFERRED_LANGUAGE_SWEDISH'),
        code: 'sv',
      },
      {
        name: t('SETTINGS_DEVICE_PREFERRED_LANGUAGE_DANISH'),
        code: 'da',
      },
      {
        name: t('SETTINGS_DEVICE_PREFERRED_LANGUAGE_ENGLISH'),
        code: 'en',
      },
      {
        name: t('SETTINGS_DEVICE_PREFERRED_LANGUAGE_FINNISH'),
        code: 'fi',
      },
      {
        name: t('SETTINGS_DEVICE_PREFERRED_LANGUAGE_NORWEGIAN'),
        code: 'no',
      },
      {
        name: t('SETTINGS_DEVICE_PREFERRED_LANGUAGE_NORWEGIAN_NB'),
        code: 'nb',
      },
    ],
    [t],
  );

  languages.sort((a, b) => a.name.localeCompare(b.name));

  return languages;
};
