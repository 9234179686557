import { AccountInfo } from '@telia-company/tv.common-sdk-authentication/dist/abstractions/data/AccountIF';
import { Account } from '@telia-company/tv.common-sdk-authentication/dist/Account';
import { useEffect, useState } from 'react';

export const useLoadAccountInfo = () => {
  const account = Account.instance();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [accountInfo, setAccountInfo] = useState<AccountInfo | undefined>(undefined);

  useEffect(() => {
    account
      .getAccountInformation()
      .then((accountInfo) => {
        setAccountInfo(accountInfo);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [account]);

  return { loading, accountInfo, error };
};
