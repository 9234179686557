import { AuthenticateIF } from '@telia-company/tv.common-sdk-authentication/dist/abstractions/client/AuthenticateIF';
import { Authentication } from '../authentication';

AuthenticateIF.implement(() => {
  return {
    authenticateStart: async () => {
      return new Promise((_res, _rej) => {
        // Avoid cirular dependencies by instancing it on demand
        Authentication.instance().openLoginPage();
      });
    },
    authenticateEnd: async () => {},
    requestCredentials: async () => undefined,
  };
});
