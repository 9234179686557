import styled from '@emotion/styled';
import { textLink, textPrimary } from 'theme/theme.utils';
import { Icon, mq } from '@telia-company/tv.oneapp-web-ui';
import { css } from '@emotion/react';
import { SmartLink } from 'components/SmartLink';
import { MessageItemVariant } from 'services/messagesService';

type StyledMessageItemProps = { variant: MessageItemVariant };

export const StyledMessageItem = styled.div<StyledMessageItemProps>`
  ${({ theme }) => textPrimary(theme)}
  display: flex;
  width: 100%;
  align-items: center;

  padding: 16px;
  min-height: 56px;

  ${mq.M`
  padding: 16px 24px;
  min-height: 64px;
  border-radius: 4px;
  `}

  ${({ theme, variant }) => {
    switch (variant) {
      case 'informative':
        return css`
          background-color: ${theme.designTokens.color.infoDialogue.helpfulBackgroundDefault};
          color: ${theme.designTokens.color.text.primaryDark};
          path:last-child[fill] {
            fill: ${theme.designTokens.color.text.primaryDark};
          }
          *[stroke] {
            stroke: ${theme.designTokens.color.text.primaryDark};
          }
          a {
            color: ${theme.designTokens.color.text.primaryDark};
          }
        `;
      case 'negative':
        return css`
          background-color: ${theme.designTokens.color.infoDialogue.errorBackgroundDefault};
          color: ${theme.designTokens.color.text.primaryDark};
          path:last-child[fill] {
            fill: ${theme.designTokens.color.text.primaryDark};
          }
          *[stroke] {
            stroke: ${theme.designTokens.color.text.primaryDark};
          }
          a {
            color: ${theme.designTokens.color.text.primaryDark};
          }
        `;
      case 'attention':
        return css`
          background-color: ${theme.designTokens.color.infoDialogue.importantBackgroundDefault};
          color: ${theme.designTokens.color.text.primaryDark};
          path:last-child[fill] {
            fill: ${theme.designTokens.color.text.primaryDark};
          }
          *[stroke] {
            stroke: ${theme.designTokens.color.text.primaryDark};
          }
          a {
            color: ${theme.designTokens.color.text.primaryDark};
          }
        `;
      default:
        return css`
          background-color: ${theme.designTokens.color.infoDialogue.neutralBackgroundDefault};
          color: ${theme.designTokens.color.text.primary};
          path:last-child[fill] {
            fill: ${theme.designTokens.color.text.primary};
          }
          *[stroke] {
            stroke: ${theme.designTokens.color.text.primary};
          }
          a {
            color: ${theme.designTokens.color.text.primary};
          }
        `;
    }
  }}
`;

export const StyledMessageContainer = styled.div`
  flex-grow: 1;
  ${mq.M`
    display: flex;
  `}
`;

export const StyledMessage = styled.span`
  ${mq.M`
    flex-grow: 1;
  `}
`;

export const StyledIcon = styled(Icon)`
  width: 24px;
  margin-right: 12px;
  flex-shrink: 0;
  align-self: flex-start;
  ${mq.M`
    width: 32px;
    margin-right: 16px;
  `}
`;

export const StyledDismissButton = styled.button`
  background-color: transparent;
  align-self: flex-start;
  flex-shrink: 0;
  border: 0;
  padding: 0;
  margin-left: 8px;
  height: 24px;

  ${mq.M`
    height: 32px;
  `}

  svg {
    width: 24px;
    height: 24px;

    ${mq.M`
      width: 32px;
      height: 32px;
    `}
  }
`;

export const StyledLink = styled(SmartLink)`
  white-space: nowrap;

  display: inline;
  margin-inline: 8px;

  ${mq.M`
    display: inline-block;
    margin-left: 8px;
  `}

  ${({ theme }) => textLink(theme)}
`;
