import styled from '@emotion/styled';
import { textLink } from 'theme/theme.utils';
import { StyledSubSectionContent } from '../../../navigation.styles';
import { css } from '@emotion/react';

export const StyledForgotPasswordLink = styled(StyledSubSectionContent)`
  ${({ theme }) => textLink(theme)};
  ${({ theme }) => {
    return css`
      color: ${theme.designTokens.color.text.primary};
    `;
  }};
  cursor: pointer;
  margin-top: -16px;
`;
