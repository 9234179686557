import { PreferredLanguages } from '@telia-company/tv.common-sdk-legacy-pack/dist/PreferredLanguages';
import { useLogger } from 'hooks';
import { StyledSubSection } from 'pages/navigation.styles';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownItem, SettingsDropDown } from '../../SettingsDropDown';
import { useGetAudioLanguages } from './hooks';
import { isPreferredAudioLanguageCode } from './PreferredAudioLanguage.utils';
import { StyledLanguageLabel } from './styles';

export const PreferredAudioLanguage = () => {
  const logger = useLogger('PreferredAudioLanguage');
  const preferredLanguages = PreferredLanguages.instance();
  const { t } = useTranslation();
  const { audioLanguages, preferredAudioLanguage, refresh } = useGetAudioLanguages();

  const items: DropDownItem[] = audioLanguages.map(({ code, name }) => ({
    id: code,
    label: name,
  }));

  const selectedItem = preferredAudioLanguage
    ? items.find(({ id }) => id === preferredAudioLanguage.code)
    : undefined;

  const onSelectItem = useCallback(
    async ({ id }: DropDownItem) => {
      if (isPreferredAudioLanguageCode(id)) {
        await preferredLanguages.setPreferredAudioLanguage(id);
        refresh();
      } else {
        logger.error(`The code "${id}" is not a valid audio language code`);
      }
    },
    [logger, preferredLanguages, refresh],
  );

  return (
    <StyledSubSection forcedDirection="row">
      <StyledLanguageLabel>{t('SETTINGS_DEVICE_PREFERRED_LANGUAGES_AUDIO')}</StyledLanguageLabel>
      <SettingsDropDown
        items={items}
        label={preferredAudioLanguage?.name || '...'}
        selectedItem={selectedItem}
        onSelect={(item) => onSelectItem(item)}
      />
    </StyledSubSection>
  );
};
