import { oneAppUrl } from 'utils';
import { useTranslation } from 'react-i18next';
import { HelpLink, HelpLinkType } from '../HelpLink';
import { StyledSubSectionHeading, StyledSubSectionContainer } from '../../../navigation.styles';
import { getLinkUrlFromOtherLinksInConfig } from '../../utils';

export const OtherLinks = () => {
  const { t } = useTranslation();

  const links: HelpLinkType[] = [
    {
      labelText: t('HELP_RESPONSIBLE_PUBLISHERS_TITLE'),
      url: oneAppUrl(getLinkUrlFromOtherLinksInConfig('publishers')),
      buttonText: t('HELP_BUTTON_READ_MORE'),
    },
    {
      labelText: t('HELP_USER_TERMS_TITLE'),
      url: oneAppUrl(getLinkUrlFromOtherLinksInConfig('terms')),
      buttonText: t('HELP_BUTTON_READ_MORE'),
    },
  ];

  return (
    <>
      <StyledSubSectionHeading>{t('HELP_OTHER_LINKS_SUBHEADING')}</StyledSubSectionHeading>
      <StyledSubSectionContainer>
        {links.map((link, id) => (
          <HelpLink link={link} key={id} />
        ))}
      </StyledSubSectionContainer>
    </>
  );
};
