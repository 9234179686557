const LETTER_REGEX = /[a-zA-Z]/;
const ALLOWED_CHARACTERS_REGEX = /^[-a-zA-Z0-9._!#$%&'/=?^_+`{|}~@*]+$/;

export const validateUsername = (username: string) => {
  // must be beween 4 and 128 characters long
  if (username.length < 4 || username.length > 128) {
    return false;
  }
  // must contain at least one letter (uppercase or lowercase)
  if (!LETTER_REGEX.test(username)) {
    return false;
  }
  // may only contain letters, digits and special symbols
  return ALLOWED_CHARACTERS_REGEX.test(username);
};
