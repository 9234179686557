import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogger, useSnackbars } from 'hooks';
// @TODO replace with feature toggles
import { getUser, updateChildLock, User } from 'services/userService';

import { PinCodeSubSection } from '..';

import { ChangePinModal, ConfirmPinModal, BasePinModalOnResponse, SetPinModal } from '..';
import { useFetchFunction } from 'hooks/useFetchFunction';

export const SettingsParentalControls = () => {
  const { t } = useTranslation();
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();

  const logger = useLogger('SettingsParentalControls');

  const {
    loading,
    data: newUser,
    error: userError,
    refresh: refreshUser,
  } = useFetchFunction(getUser);
  const [busy, setBusy] = useState(false);
  const [user, setUser] = useState<User>();
  const [newPinCode, setNewPinCode] = useState<string>();
  const [isSetPinModalOpen, setIsSetPinModalOpen] = useState(false);
  const [isConfirmPinModalOpen, setIsConfirmPinModalOpen] = useState(false);
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);

  // The childlockEnabled state is used to control the switch's state, since it will animate
  // twice when disabling it due to the old value being true and the new value being false.
  // Updating the state manually will prevent the animation from playing twice.
  const [childLockEnabled, setChildLockEnabled] = useState(user?.childLock.enabled || false);

  const modifyChildLock = useCallback<typeof updateChildLock>(
    async ({ enabled, newPinCode }) => {
      setBusy(true);
      try {
        await updateChildLock({ enabled, newPinCode });
        setChildLockEnabled(enabled);
        refreshUser();
      } finally {
        setBusy(false);
      }
    },
    [refreshUser],
  );

  useEffect(() => {
    if (newUser) {
      if (!user) {
        setChildLockEnabled(newUser.childLock.enabled);
      }
      setUser(newUser);
    }
  }, [newUser, user]);

  const onSetPinModalResponse = useCallback<BasePinModalOnResponse>((pinCode) => {
    setIsSetPinModalOpen(false);
    if (pinCode) {
      setNewPinCode(pinCode);
      setIsConfirmPinModalOpen(true);
    }
  }, []);

  const onConfirmModalResponse = useCallback<BasePinModalOnResponse>(
    async (pinCode, setBusy) => {
      if (pinCode) {
        setBusy(true);
        try {
          await modifyChildLock({ enabled: true, newPinCode: pinCode });
          createPositiveSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATED'));
        } catch (err) {
          logger.error(`Failed to change PIN for user`, err);
          createNegativeSnackbar(t('COMMON_ERROR'));
        } finally {
          setBusy(false);
          setIsConfirmPinModalOpen(false);
          setNewPinCode(undefined);
        }
      } else {
        setIsConfirmPinModalOpen(false);
        setNewPinCode(undefined);
      }
    },
    [createPositiveSnackbar, t, modifyChildLock, logger, createNegativeSnackbar],
  );

  const onChangePinModalResponse = useCallback<BasePinModalOnResponse>((pinCode) => {
    setIsChangePinModalOpen(false);
    if (pinCode) {
      setIsSetPinModalOpen(true);
    }
  }, []);

  const deactivateChildLock = useCallback(async () => {
    try {
      await modifyChildLock({ enabled: false }); // disable child lock
      createPositiveSnackbar(t('SETTINGS_LOCKER_PIN_CODE_DEACTIVATED'));
    } catch (err) {
      logger.error(`Failed to Deactivate PIN for user`, err);
      createNegativeSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATE_ERROR'));
    }
  }, [createNegativeSnackbar, createPositiveSnackbar, logger, modifyChildLock, t]);

  const onPinButtonClick = useCallback(() => {
    if (user?.childLock.enabled) {
      setIsChangePinModalOpen(true);
      return;
    }
    setIsSetPinModalOpen(true);
  }, [user?.childLock.enabled]);

  const onParentalControlSwitch = useCallback(
    (enabled: boolean) => {
      if (enabled) {
        setIsSetPinModalOpen(true);
      } else {
        deactivateChildLock();
      }
    },
    [deactivateChildLock],
  );

  if (userError) {
    logger.error('Failed to fetch user', userError);
    throw userError;
  }

  return (
    <>
      <PinCodeSubSection
        label={t('SETTINGS_LOCKER_PIN_CODE')}
        pinCodeState={childLockEnabled}
        onChangePinCodeState={onParentalControlSwitch}
        loading={loading || busy}
        canChangePinCode={childLockEnabled}
        onChangePinCode={onPinButtonClick}
      />

      <SetPinModal isOpen={isSetPinModalOpen} onResponse={onSetPinModalResponse} />
      <ChangePinModal isOpen={isChangePinModalOpen} onResponse={onChangePinModalResponse} />
      <ConfirmPinModal
        isOpen={isConfirmPinModalOpen}
        validatePinCode={async (pinCode) => pinCode === newPinCode}
        onResponse={onConfirmModalResponse}
      />
    </>
  );
};
