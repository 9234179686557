import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestError } from '@telia-company/tv.common-sdk/dist/ErrorTypes';
import AppleLogin from 'react-apple-login';
import {
  AuhorizationPayload,
  doDeleteConnection,
  federatorAuthorization,
} from 'services/userService';
import { useLogger, useSnackbars } from 'hooks';
import { LoggedinDevice } from 'services/deviceService';
import { getCountry } from 'utils';
import {
  StyledRemoveIcon,
  StyledButtonContentWrapper,
  StyledIconWrapper,
} from 'pages/federated.styles';

type appleAuthorizationresponse = {
  authorization?: {
    state: string;
    code: string;
    id_token: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
  error?: any;
};

type DoDeleteConnectionResponse = {
  error?: Error;
};

type FederatedLoginProps = {
  isAppleConnected?: boolean;
  setIsAppleConnected: (isConnected: boolean) => void;
  thisDevice?: LoggedinDevice;
};
export const AppleButton = ({
  isAppleConnected,
  setIsAppleConnected,
  thisDevice,
}: FederatedLoginProps) => {
  const clientId = Config.get('federated-login')['apple-client-id'];
  const { t } = useTranslation();
  const logger = useLogger('navigation/account/apple-button');

  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const country = getCountry();

  const appleResponse = async (response: appleAuthorizationresponse) => {
    if (!response.error) {
      const payload: AuhorizationPayload = {
        country: country,
        openIdToken: response.authorization?.id_token || '',
        code: response.authorization?.code || '',
        federatedBy: 'apple',
        deviceType: 'WEB',
        deviceId: thisDevice?.deviceId || '',
      };

      const connectionResult = await federatorAuthorization(payload);

      if (connectionResult.error) {
        createNegativeSnackbar(t('ACCOUNT_FEDERATED_GENERAL_ERROR'));
        setIsAppleConnected(false);
      } else {
        createPositiveSnackbar(t('ACCOUNT_FEDERATED_CONNECTION_SUCCESS'));
        setIsAppleConnected(true);
      }
    } else {
      createNegativeSnackbar(t('ACCOUNT_FEDERATED_GENERAL_ERROR'));
      setIsAppleConnected(false);
    }
    setButtonDisabled(false);
  };

  const deleteConnection = useCallback(async () => {
    setButtonDisabled(true);
    const doDeleteStatus = (await doDeleteConnection('apple')) as DoDeleteConnectionResponse;

    if (doDeleteStatus?.error) {
      let errorMessage = t('ACCOUNT_FEDERATED_REMOVAL_ERROR');
      if (doDeleteStatus.error instanceof RequestError) {
        switch (doDeleteStatus.error.status) {
          case 500:
            errorMessage = t('ACCOUNT_FEDERATED_REMOVAL_ERROR');
            break;
        }
      }
      logger.error('Failed to make new connection', doDeleteStatus.error);
      createNegativeSnackbar(errorMessage);
    }
    setIsAppleConnected(false);
    createPositiveSnackbar(t('ACCOUNT_FEDERATED_REMOVAL_SUCCESS'));
    setButtonDisabled(false);
  }, [createNegativeSnackbar, createPositiveSnackbar, logger, setIsAppleConnected, t]);

  const addConnection = useCallback((renderProps: { onClick: () => void }) => {
    setButtonDisabled(true);
    return renderProps.onClick();
  }, []);

  return (
    <>
      {!isAppleConnected ? (
        <AppleLogin
          clientId={clientId}
          redirectURI={window.location.href}
          usePopup={true}
          callback={appleResponse}
          scope="email name"
          state="U01VUkY"
          responseType="id_token"
          responseMode="form_post"
          render={(renderProps) => (
            <Button
              disabled={buttonDisabled}
              variant="secondary"
              onClick={() => addConnection(renderProps)}
            >
              <i className="fa-brands fa-apple px-2 "></i>
              {t('ACCOUNT_FEDERATED_ADD')}
            </Button>
          )}
        />
      ) : (
        <Button disabled={buttonDisabled} variant="secondary" onClick={() => deleteConnection()}>
          <StyledButtonContentWrapper>
            <StyledIconWrapper>
              <StyledRemoveIcon name="Remove" />
            </StyledIconWrapper>
            <span style={{ whiteSpace: 'nowrap' }}>{t('ACCOUNT_FEDERATED_REMOVE')}</span>
          </StyledButtonContentWrapper>
        </Button>
      )}
    </>
  );
};
