import styled from '@emotion/styled';
import { Modal } from 'components';
import { headingSecondary, textSecondary } from 'theme/theme.utils';

export const StyledModal = styled(Modal)`
  margin: 0 16px;
  position: relative;
  max-width: 550px;
  border-radius: 8px;
  background: ${({ theme }) => theme.designTokens.color.background.tertiary};
  padding: 24px;
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  ${({ theme }) => textSecondary(theme)}
  white-space: pre-line;
`;

export const StyledHeading = styled.h1`
  ${({ theme }) => headingSecondary(theme)}
  color: #fff;
  margin-top: 0;
  margin-right: 48px;
`;

export const StyledButtonStrip = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
  margin-top: 24px;
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  margin-right: 24px;
  margin-top: 24px;
  > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const StyledContent = styled.div``;
