import { ReactNode, useEffect, useState } from 'react';
import { ConfirmModal, OnResponse } from 'components/modals';
import { StyledSubSectionContent } from 'pages/navigation.styles';
import { PinInputContainer } from '../../PinCode/PinInputContainer';

export type BasePinModalOnResponse = (
  pinCode: string | undefined,
  setBusy: (busy: boolean) => void,
) => void;

export type BasePinModalGetValidationError = (pinCode: string) => Promise<string | undefined>;

type BasePinModalProps = {
  isOpen: boolean;
  title: string;
  testid?: string;
  information: string;
  confirmLabel: string;
  onResponse: BasePinModalOnResponse;
  getValidationError?: BasePinModalGetValidationError;
  showCancel?: boolean;
  customButtons?: ReactNode;
};

export const BasePinModal = ({
  isOpen,
  title,
  testid = 'pin-input-form',
  information,
  confirmLabel,
  getValidationError,
  onResponse,
  customButtons,
  showCancel = true,
}: BasePinModalProps) => {
  const [invalidPinCode, setInvalidPinCode] = useState(true);
  const [enteredPinCode, setEnteredPinCode] = useState('');
  const [pinInputErrorMessage, setPinInputErrorMessage] = useState('');
  const [pinInputSuccessMessage, setPinInputSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setEnteredPinCode('');
      setPinInputErrorMessage('');
      setPinInputSuccessMessage('');
      return;
    }
    if (!enteredPinCode) {
      setInvalidPinCode(true);
      return;
    }

    if (getValidationError) {
      setIsValidating(true);
      getValidationError(enteredPinCode)
        .then((validateResponse) => {
          if (validateResponse) {
            setInvalidPinCode(true);
            setPinInputErrorMessage(validateResponse);
          } else {
            setInvalidPinCode(false);
            setPinInputErrorMessage('');
          }
        })
        .finally(() => {
          setIsValidating(false);
        });
    } else {
      setInvalidPinCode(false);
      setPinInputErrorMessage('');
    }
  }, [isOpen, enteredPinCode, invalidPinCode, getValidationError]);

  useEffect(() => {
    if (!isOpen) {
      setPinInputErrorMessage('');
      setInvalidPinCode(false);
    }
  }, [isOpen]);

  const onModalResponse: OnResponse = (confirmed, setBusy) => {
    if (!confirmed) {
      onResponse(undefined, setBusy);
      return;
    }
    onResponse(enteredPinCode, setBusy);
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title={title}
      confirmLabel={confirmLabel}
      onResponse={onModalResponse}
      confirmDisabled={invalidPinCode}
      showCancel={showCancel}
      customButtons={customButtons}
    >
      <StyledSubSectionContent>{information}</StyledSubSectionContent>
      <form aria-label="form" data-testid={testid} autoComplete="off" noValidate>
        <PinInputContainer
          enteredPinCode={enteredPinCode}
          pinInputErrorMessage={pinInputErrorMessage}
          pinInputSuccessMessage={pinInputSuccessMessage}
          isLoading={isLoading || isValidating}
          setEnteredPinCode={setEnteredPinCode}
          setPinInputErrorMessage={setPinInputErrorMessage}
          setPinInputSuccessMessage={setPinInputSuccessMessage}
          setIsLoading={setIsLoading}
        />
      </form>
    </ConfirmModal>
  );
};
