import { gql } from '@apollo/client';

export const ASSUME_PROFILE_MUTATION = gql`
  mutation assumeProfile($input: AssumeProfileInput!) {
    assumeProfile(input: $input) {
      ... on AssumeProfileResultOk {
        profileToken
      }
    }
  }
`;
