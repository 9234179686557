import { Config } from '@telia-company/tv.common-sdk/dist/Config';

const regexpEndingForwardSlash = /(\/)+$/;
const regexpStartingForwardSlash = /^\//;

export const oneAppUrl = (path: string) => {
  const oneAppBaseUrl = Config.get('one-app-base-url').replace(regexpEndingForwardSlash, '');
  const pathWithoutForwardSlash = path.replace(regexpStartingForwardSlash, '');
  return `${oneAppBaseUrl}${pathWithoutForwardSlash ? `/${pathWithoutForwardSlash}` : ''}`;
};
