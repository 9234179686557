import styled from '@emotion/styled';
import { m } from 'framer-motion';

export const StyledModalContainer = styled(m.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  display: grid;
  place-content: center;
  background: ${({ theme }) => theme.designTokens.color.background.overlay60};
  z-index: 101;
`;

export const StyledModal = styled(m.div)`
  background: ${({ theme }) => theme.designTokens.color.background.tertiary};
  border-radius: 6px;
  padding: 1.5rem;
  overflow-y: auto;
`;
