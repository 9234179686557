import { useLogger } from 'hooks';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Authentication } from 'sdk/authentication';

type ProtectedRouteProps = {
  /**
   * If user is not logged in, the appliction will try to log in,
   * either with a "login" view, or a "create account" view.
   */
  loginMethod: 'LOGIN' | 'CREATE_ACCOUNT';
};

export const ProtectedRoute = ({ loginMethod = 'LOGIN' }: ProtectedRouteProps) => {
  const logger = useLogger('ProtectedRoute');
  const authentication = Authentication.instance();
  const { data: isLoggedin, loading, error } = useFetchFunction(authentication.isLoggedin);
  const [authenticationError, setAuthenticationError] = useState<unknown>();

  if (loading) {
    return null;
  }

  if (error) {
    logger.error('Failed to check if user is logged in', error);
    throw error;
  }

  if (authenticationError) {
    logger.error('Failed to invoke authenticaiton', authenticationError);
    throw authenticationError;
  }

  if (!isLoggedin) {
    if (loginMethod === 'CREATE_ACCOUNT') {
      authentication.openCreateAccountPage().catch((err) => {
        setAuthenticationError(err);
      });
    } else {
      authentication.openLoginPage().catch((err) => {
        setAuthenticationError(err);
      });
    }
    return null;
  }

  return <Outlet />;
};
