import { OneAppUIProvider } from '@telia-company/tv.oneapp-web-ui';
import { useEnvironmentContext } from 'providers';
import { ReactNode } from 'react';

type UIProviderProps = {
  children?: ReactNode;
};

export const UIProvider = ({ children }: UIProviderProps) => {
  const { country } = useEnvironmentContext();

  return <OneAppUIProvider market={country}>{children}</OneAppUIProvider>;
};
