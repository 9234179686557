export const getPin = (inputRefs: React.MutableRefObject<HTMLInputElement[]>) => {
  return inputRefs.current.map((node: HTMLInputElement) => {
    if (node !== null) {
      return node.value;
    }
    return node;
  });
};

export const clear = (inputRefs: React.MutableRefObject<HTMLInputElement[]>) => {
  inputRefs.current.map((node: HTMLInputElement) => (node.value = ''));
};

export const getPinId = (index: number) => `pinNumber-${index}`;

export const moveToInput = (
  target: HTMLInputElement,
  forwardOrBack: -1 | 1,
  inputRefs: React.MutableRefObject<HTMLInputElement[]>,
): HTMLInputElement | null => {
  const currentIndex = target.id?.split('-').pop();
  if (typeof currentIndex !== 'undefined') {
    const index = parseInt(currentIndex, 10);
    const nextIndex = Math.max(Math.min(index + forwardOrBack, inputRefs.current.length - 1), 0);
    const el: HTMLInputElement = inputRefs.current[nextIndex];
    el.focus();
    return el;
  }

  return null;
};

export const focusFirstInputField = (inputRefs: React.MutableRefObject<HTMLInputElement[]>) => {
  const ref: HTMLInputElement = inputRefs.current[0];
  ref.focus();
};

export const pinCodeEntered = (
  enteredPinCode: string | null,
  inputRefs: React.MutableRefObject<HTMLInputElement[]>,
) => {
  const firstElement = inputRefs.current[0]?.value;
  return !!(enteredPinCode || firstElement);
};

export const isOnlyNumbers = (pin: string): boolean => {
  const onlyNumbers = /^\d+$/;
  return onlyNumbers.test(pin);
};
