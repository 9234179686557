import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionHeading,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { UserSubscription } from 'services/userService';
import { VAS } from './components';
import { VASActivation } from './components/VASActivation';

type TeliaSubscriptionProps = {
  userSubscription: UserSubscription;
};

export const TeliaSubscription = ({ userSubscription }: TeliaSubscriptionProps) => {
  const { t } = useTranslation();

  const manageSubscriptionLink = Config.get('telia-manage-subscription-link');

  return (
    <StyledSubSection forcedDirection="column">
      <div>
        <StyledSubSectionHeading>{userSubscription.name}</StyledSubSectionHeading>
        {userSubscription.services.length > 0 && <VAS userSubscription={userSubscription} />}
        {!!manageSubscriptionLink && (
          <StyledButtonContainer>
            {userSubscription.packageType === 'VAS' ? (
              <VASActivation activationUrl={userSubscription.activationUrl} />
            ) : (
              <Button as={SmartLink} variant="secondary" to={manageSubscriptionLink}>
                {t('SUBSCRIPTION_BUTTON_MANAGE_SUBSCRIPTION_TELIA')}
              </Button>
            )}
          </StyledButtonContainer>
        )}
      </div>
    </StyledSubSection>
  );
};
