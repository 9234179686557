import { useTranslation } from 'react-i18next';
import { StyledFiltersContainer, StyledFilter } from './Filters.styles';
import { useEffect, useState } from 'react';
import { useScroll } from 'framer-motion';

export type FilterOption = 'STREAMING_SERVICES' | 'CHANNELS' | 'FIXED';

type FiltersProps = {
  selectedFilter: FilterOption;
  onFilterChange: (filter: FilterOption) => void;
};

export const Filters = ({ selectedFilter, onFilterChange: onChange }: FiltersProps) => {
  const { t } = useTranslation();
  const [opaqueFilter, setOpaqueFilter] = useState(false);

  const { scrollY } = useScroll();

  useEffect(() => {
    return scrollY.onChange((value) => {
      setOpaqueFilter(value > 0);
    });
  }, [scrollY]);

  return (
    <StyledFiltersContainer opaque={opaqueFilter}>
      <StyledFilter
        onClick={(e) => {
          e.currentTarget.scrollIntoView(true);
          onChange('STREAMING_SERVICES');
        }}
        active={selectedFilter === 'STREAMING_SERVICES'}
      >
        {t('SELECTIONMENU_FILTERS_STREAMING_SERVICES')}
      </StyledFilter>
      <StyledFilter
        onClick={(e) => {
          e.currentTarget.scrollIntoView(true);
          onChange('CHANNELS');
        }}
        active={selectedFilter === 'CHANNELS'}
      >
        {t('SELECTIONMENU_FILTERS_CHANNELS')}
      </StyledFilter>
      <StyledFilter
        onClick={(e) => {
          e.currentTarget.scrollIntoView(true);
          onChange('FIXED');
        }}
        active={selectedFilter === 'FIXED'}
      >
        {t('SELECTIONMENU_FILTERS_INCLUDED_CONTENT')}
      </StyledFilter>
    </StyledFiltersContainer>
  );
};
