import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as medallia from 'utils/medallia';

export const useUpdateMedalliaOnPageChange = () => {
  const location = useLocation();
  const previousPathname = useRef<string>();

  useEffect(() => {
    if (previousPathname.current === location.pathname) {
      return;
    }
    previousPathname.current = location.pathname;
    medallia.updatePageView();
  }, [location.pathname]);
};
