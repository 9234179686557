import styled from '@emotion/styled';

export const StyledWrapper = styled.div<{ width: string; height: string }>`
  max-width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  margin: 0 auto;
`;
