import styled from '@emotion/styled';
import { LogoHorizontal } from 'components/LogoHorizontal';

export const StyledNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  & > div:first-of-type {
    margin-right: 32px;
  }
`;

export const StyledUserAndSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledLogoHorizontal = styled(LogoHorizontal)`
  margin-right: 32px;
`;
