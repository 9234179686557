import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { textSecondary } from 'theme/theme.utils';

export const StyledFooterContainer = styled.footer`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border-top: 1px solid ${({ theme }) => theme.designTokens.color.border.backgroundSurface};

  ${mq.L`
    flex-direction: row;
    padding: 3rem 5rem 3rem 5rem;
    margin-top: 4.625rem;
  `}
`;

export const StyledFooterCopyright = styled.div`
  ${({ theme }) => css`
    ${textSecondary(theme)}
    color: ${theme.designTokens.color.text.tertiary};
  `}
  text-align: right;
  width: 220px;
  white-space: nowrap;
  & > ul {
    margin: 0;
    padding: 0;

    & > li {
      list-style: none;
    }
  }
`;
export const StyledCopyrightLine = styled.div``;

export const StyledFooterInner = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem 1rem 0;
  margin-left: auto;
  display: flex;
  justify-content: space-between;

  ${mq.L`
    justify-content: flex-end;
    width: unset;
    padding: 0;
  `}
`;

export const StyledSpaceBetweenColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
