import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from 'tracking';

export const PageTracker = () => {
  const location = useLocation();
  useEffect(() => {
    trackPage();
  }, [location]);

  return null;
};
