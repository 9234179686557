import { InvalidPasswordError } from 'errorTypes';
import { MfaStatus, createMfa, removeMfa } from 'services/userService';
import { ConfirmModal } from 'components/modals';
import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import { StyledConfirmModal } from './VerifyMfaModal.styles';
import { PinField } from 'components';
import { useTranslation } from 'react-i18next';
import { useLogger } from 'hooks';

type VerifyMfaModalProps = {
  isOpen: boolean;
  onClose: (verified: boolean) => void;
  verificationMethod: MfaStatus['verificationMethod'];
  verificationTarget: string;
  action: 'create' | 'remove';
};

export const VerifyMfaModal = ({
  isOpen,
  onClose,
  verificationMethod,
  verificationTarget,
  action,
}: VerifyMfaModalProps) => {
  const logger = useLogger('VerifyMfaModal');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const oneTimePasswordRef = useRef('');

  useEffect(() => {
    if (isOpen) {
      setOneTimePassword('');
      setErrorMessage('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (oneTimePasswordRef.current !== oneTimePassword) {
      oneTimePasswordRef.current = oneTimePassword;
      setErrorMessage('');
    }
  }, [oneTimePassword]);

  let title = '';
  switch (verificationMethod) {
    case 'email':
      title = t('MFA_VERIFY_MODAL_EMAIL_TITLE');
      break;
    case 'sms':
      title = t('MFA_VERIFY_MODAL_SMS_TITLE');
      break;
    default:
      throw new Error(`Invalid verification method: ${verificationMethod}`);
  }

  const onResponse = useCallback<NonNullable<ComponentProps<typeof ConfirmModal>['onResponse']>>(
    async (confirmed, setBusy) => {
      if (!confirmed) {
        onClose(false);
        return;
      }

      setBusy(true);
      try {
        switch (action) {
          case 'create':
            await createMfa({ oneTimePassword });
            break;
          case 'remove':
            await removeMfa({ oneTimePassword });
            break;
          default:
            throw new Error(`Invalid action: ${action}`);
        }
        onClose(true);
      } catch (error) {
        if (error instanceof InvalidPasswordError) {
          setErrorMessage(t('MFA_VERIFY_MODAL_WRONG_ONE_TIME_PASSWORD'));
        } else {
          logger.error('Failed to verify MFA', error);
          setErrorMessage(t('ERROR_GENERIC_INFORMATION'));
        }
      } finally {
        setBusy(false);
      }
    },
    [action, logger, onClose, oneTimePassword, t],
  );

  return (
    <StyledConfirmModal
      isOpen={isOpen}
      onResponse={onResponse}
      title={title}
      cancelLabel={t('COMMON_BUTTON_BACK')}
      confirmVariant={oneTimePassword.length === 6 ? 'primary' : 'secondary'}
    >
      <p>
        {t('MFA_VERIFY_MODAL_INFORMATION')}
        <br />
        <strong>{verificationTarget}</strong>
      </p>
      <PinField
        length={6}
        onValueChanged={(value) => setOneTimePassword(value)}
        state={!!errorMessage ? 'INVALID' : 'DEFAULT'}
        message={errorMessage}
        autocomplete="one-time-code"
      />

      <p>{t('MFA_VERIFY_MODAL_RETRY_MESSAGE')}</p>
    </StyledConfirmModal>
  );
};
