import { createContext } from 'react';
import { FixedItem, SelectableItem } from 'services/selectionMenuService';

export const SelectionMenuContext = createContext({
  selectedPoints: 0,
  totalPoints: 0,
  dirty: false,
  openItemModal: (item: SelectableItem | FixedItem) => {},
  onSelectableItemChange: (item: SelectableItem) => {},
  reset: async () => {},
  save: async () => {},
  saving: false,
  resetting: false,
  loading: false,
  fixedItems: [] as FixedItem[],
  newSelectedItems: [] as SelectableItem[],
  oldSelectedItems: [] as SelectableItem[],
  removedItems: [] as SelectableItem[],
});
