import { useLogger, useSnackbars } from 'hooks';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  changeRentalPinCode,
  createRentalPinCode,
  setRentalPinCodeActive,
  validateRentalPinCode,
} from 'services/userService';
import {
  ActivatePinModal,
  BasePinModalOnResponse,
  ChangePinModal,
  ConfirmPinModal,
  DeactivatePinModal,
  SetPinModal,
} from '../modals';
import { PinCodeSubSection } from '../PinCodeSubSection';
import { useGetRentalPinCodeState } from './hooks';
import { ForgotPinButton } from './components';
import { PinCodeSubSectionWithPinRequestButton } from '../PinCodeSubSection/PinCodeSubSectionWithPinRequestButton';

export const SettingsRentalPinControls = () => {
  const logger = useLogger('SettingsRentalPinControls');
  const { loading, error, rentalPinCodeState, refreshRentalPinCodeState } =
    useGetRentalPinCodeState();
  const { t } = useTranslation();
  const [isSetPinModalOpen, setIsSetPinModalOpen] = useState(false);
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const [isActivatePinModalOpen, setIsActivatePinModalOpen] = useState(false);
  const [isDeactivatePinModalOpen, setIsDeactivatePinModalOpen] = useState(false);
  const [isConfirmPinModalOpen, setIsConfirmPinModalOpen] = useState(false);
  const [newPinCode, setNewPinCode] = useState<string>();
  const [oldPinCode, setOldPinCode] = useState<string>();
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();

  const onChangePinState = useCallback(
    (value: boolean) => {
      if (!value) {
        setIsDeactivatePinModalOpen(true);
        return;
      }
      if (rentalPinCodeState?.set) {
        setIsActivatePinModalOpen(true);
        return;
      }
      setIsSetPinModalOpen(true);
    },
    [rentalPinCodeState?.set],
  );

  const onChangePinCode = useCallback(() => {
    setIsChangePinModalOpen(true);
  }, []);

  const onSetPinModalResponse = useCallback<BasePinModalOnResponse>((pinCode) => {
    setIsSetPinModalOpen(false);
    if (pinCode) {
      setNewPinCode(pinCode);
      setIsConfirmPinModalOpen(true);
    }
  }, []);

  const onConfirmModalResponse = useCallback<BasePinModalOnResponse>(
    async (pinCode, setBusy) => {
      if (pinCode) {
        setBusy(true);
        try {
          // If we're creating the pin code
          if (!rentalPinCodeState?.set) {
            await createRentalPinCode(pinCode);
            createPositiveSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATED'));
            refreshRentalPinCodeState();
          }
          // We are updating the pin code
          else if (oldPinCode) {
            await changeRentalPinCode(oldPinCode, pinCode);
            createPositiveSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATED'));
            refreshRentalPinCodeState();
          } else {
            logger.error('No old PIN was provided for change');
            createNegativeSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATE_ERROR'));
          }
        } catch (err) {
          logger.error(`Failed to change PIN for user`, err);
          createNegativeSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATE_ERROR'));
        } finally {
          setBusy(false);
          setIsConfirmPinModalOpen(false);
          setNewPinCode(undefined);
        }
      } else {
        setIsConfirmPinModalOpen(false);
        setNewPinCode(undefined);
      }
    },
    [
      rentalPinCodeState?.set,
      oldPinCode,
      createPositiveSnackbar,
      t,
      refreshRentalPinCodeState,
      logger,
      createNegativeSnackbar,
    ],
  );

  const onChangePinModalResponse = useCallback<BasePinModalOnResponse>((pinCode) => {
    setIsChangePinModalOpen(false);
    if (pinCode) {
      setIsSetPinModalOpen(true);
    }
  }, []);

  const onActivatePinModalResponse = useCallback<BasePinModalOnResponse>(
    async (pinCode, setBusy) => {
      if (pinCode) {
        setBusy(true);
        try {
          await setRentalPinCodeActive(pinCode, true);
          createPositiveSnackbar(t('SETTINGS_RENTAL_PIN_ACTIVATED'));
          refreshRentalPinCodeState();
        } catch (err) {
          logger.error(`Failed to activate PIN for user`, err);
          createNegativeSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATE_ERROR'));
        } finally {
          setBusy(false);
          setIsActivatePinModalOpen(false);
        }
      } else {
        setIsActivatePinModalOpen(false);
      }
    },
    [createNegativeSnackbar, createPositiveSnackbar, logger, refreshRentalPinCodeState, t],
  );

  const onDeactivatePinModalResponse = useCallback<BasePinModalOnResponse>(
    async (pinCode, setBusy) => {
      if (pinCode) {
        setBusy(true);
        try {
          await setRentalPinCodeActive(pinCode, false);
          createPositiveSnackbar(t('SETTINGS_LOCKER_PIN_CODE_DEACTIVATED'));
          refreshRentalPinCodeState();
        } catch (err) {
          logger.error(`Failed to Deactivate PIN for user`, err);
          createNegativeSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATE_ERROR'));
        } finally {
          setBusy(false);
          setIsDeactivatePinModalOpen(false);
        }
      } else {
        setIsDeactivatePinModalOpen(false);
      }
    },
    [createNegativeSnackbar, createPositiveSnackbar, logger, refreshRentalPinCodeState, t],
  );

  const validatePinCode = useCallback(async (pinCode: string): Promise<boolean> => {
    const validatedOk = await validateRentalPinCode(pinCode);
    if (validatedOk) {
      setOldPinCode(pinCode);
    }
    return validatedOk;
  }, []);

  if (error) {
    logger.error('Failed to fetch pin code state', error);
    return null;
  }
  const shouldRequestPin = !rentalPinCodeState?.set;

  return (
    <>
      {shouldRequestPin ? (
        <PinCodeSubSectionWithPinRequestButton label={t('SETTINGS_RENTAL_PIN_CODE')} />
      ) : (
        <PinCodeSubSection
          label={t('SETTINGS_RENTAL_PIN_CODE')}
          loading={loading}
          pinCodeState={rentalPinCodeState?.active || false}
          onChangePinCodeState={onChangePinState}
          canChangePinCode={rentalPinCodeState?.set || false}
          onChangePinCode={onChangePinCode}
        />
      )}
      {/* //@todo: remove SetPinModal if not needed anymore */}
      <SetPinModal isOpen={isSetPinModalOpen} onResponse={onSetPinModalResponse} />
      <ChangePinModal
        isOpen={isChangePinModalOpen}
        validatePinCode={async (pinCode) => validatePinCode(pinCode)}
        onResponse={onChangePinModalResponse}
        showCancel={false}
        customButtons={[<ForgotPinButton key="forgot-pin-button" />]}
      />
      <ActivatePinModal
        isOpen={isActivatePinModalOpen}
        validatePinCode={async (pinCode) => validatePinCode(pinCode)}
        onResponse={onActivatePinModalResponse}
      />
      <DeactivatePinModal
        isOpen={isDeactivatePinModalOpen}
        validatePinCode={async (pinCode) => validatePinCode(pinCode)}
        onResponse={onDeactivatePinModalResponse}
      />
      <ConfirmPinModal
        isOpen={isConfirmPinModalOpen}
        validatePinCode={async (pinCode) => pinCode === newPinCode}
        onResponse={onConfirmModalResponse}
      />
    </>
  );
};
