import {
  StyledInputGroup,
  StyledSubSection,
  StyledSubSectionContainer,
} from 'pages/navigation.styles';
import styled from '@emotion/styled';
import { Button, mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledSubSectionContainerShort = styled(StyledSubSectionContainer)`
  margin-bottom: 0;
`;

export const StyledInputGroupShort = styled(StyledInputGroup)`
  margin-bottom: 0;
  margin-top: 0;
`;

export const StyledSubSectionShort = styled(StyledSubSection)`
  flex-direction: row;
  justify-content: space-between;
  ${mq.S`
    padding: 20px 20px;
  `}
`;

export const StyledChangePinCodeButton = styled(Button)`
  margin-top: 12px;
`;
