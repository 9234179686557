import { LoggedinDevice } from 'services/deviceService';

/**
 * @param loggedinDevice
 * @returns The name that will be displayed to the user
 */
export const getDeviceName = ({ vendorModel }: LoggedinDevice) => vendorModel;

/**
 *
 * @param loggedinDevice
 * @returns The location of the device
 */
export const getDeviceLocation = ({ geographicLocation }: LoggedinDevice) => {
  if (!geographicLocation) {
    return undefined;
  }
  const { city, region, country } = geographicLocation;
  return [city ?? region, country].filter(Boolean).join(', ') || undefined;
};
