const camelCaseToDash = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

/**
 * This function assumes that the object writes numeric values as strings.
 */
export const objectStyleToString = (obj: unknown): string => {
  if (typeof obj !== 'object' || !obj) return '';

  return Object.entries(obj).reduce((prev, entry) => {
    const [key, value] = entry;
    const dashKey = camelCaseToDash(key);

    if (typeof value === 'object') {
      return `${prev}${dashKey}{${objectStyleToString(value)}}`;
    }

    return `${prev}${dashKey}:${value};`;
  }, '');
};
