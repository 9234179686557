import { breakpointWidths } from '@telia-company/tv.oneapp-web-ui';
import { useEffect, useState } from 'react';

type MediaQueryMap = {
  [k in keyof typeof breakpointWidths]: MediaQueryList | undefined;
};

const mediaQueryLists: MediaQueryMap = {
  S: undefined,
  M: undefined,
  L: undefined,
  XL: undefined,
};

const getMediaQueryList = (minSize: keyof typeof breakpointWidths): MediaQueryList => {
  if (!mediaQueryLists[minSize]) {
    mediaQueryLists[minSize] = window.matchMedia(`(min-width:${breakpointWidths[minSize]}px)`);
  }
  return mediaQueryLists[minSize]!;
};

export const useMediaQueryMinSize = (minSize: keyof typeof breakpointWidths) => {
  const [matches, setMatches] = useState(getMediaQueryList(minSize)?.matches);

  useEffect(() => {
    const mediaQueryList = getMediaQueryList(minSize);

    const onChange = (e: { matches: boolean | ((prevState: boolean) => boolean) }) => {
      setMatches(e.matches);
    };

    mediaQueryList.addEventListener('change', onChange);

    return () => mediaQueryList.removeEventListener('change', onChange);
  }, [minSize]);

  return matches;
};
