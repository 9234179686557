import styled from '@emotion/styled';

export const StyledHamburgerButton = styled.button`
  background: none;
  border: 0;
  padding: 8px 16px;
  display: flex;
  border-radius: ${({ theme }) => theme.designTokens.radius.button};
  transition: background-color 0.2s, color 0.2s, opacity 0.2s;
  justify-self: flex-start;

  &:hover {
    background-color: ${({ theme }) => theme.designTokens.color.button.ghostHover};
  }

  > svg {
    width: 24px;
    height: 24px;
  }
`;
