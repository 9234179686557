import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { FooterDataType } from '../Footer';
import { ColumnLink } from '../column';
import { oneAppUrl } from 'utils';

export const footerTeliaSwedish = (appLinks: ColumnLink[]): FooterDataType => ({
  copyrightText: ['© Telia · Part of Telia Company', 'Orgnr. 556430-0142'],
  footerColumns: [
    {
      heading: 'Telia Play',
      linkList: [...appLinks],
    },
    {
      heading: 'Information',
      linkList: [
        {
          link: `${Config.get('one-app-telia-url')}/privat/kontakt`,
          name: 'Kontakta Telia',
        },
        {
          link: oneAppUrl('/about'),
          name: 'Om tjänsten',
        },
        {
          link: Config.get('support-web-url'),
          name: 'Support',
        },
        {
          link: oneAppUrl('/privacy-policy'),
          name: 'Integritetsinformation',
        },
        {
          link: oneAppUrl('/dynamic-advertisement'),
          name: 'Dynamisk reklam',
        },
        {
          link: oneAppUrl('/cookie-policy'),
          name: 'Cookies',
        },
        {
          link: '',
          name: 'Cookie-inställningar',
          type: 'button',
        },
      ],
    },
  ],
});
