import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Icon } from '@telia-company/tv.oneapp-web-ui';
import { textPrimary } from 'theme/theme.utils';

export const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 416px;
  margin: 0 auto;
`;

export const StyledIcon = styled(Icon)`
  width: 32px;
  height: 32px;

  ${({ theme }) => css`
    path {
      stroke: ${theme.designTokens.color.text.primary};
      &:nth-of-type(2) {
        fill: ${theme.designTokens.color.text.primary};
      }
    }
  `}
`;

export const StyledTitle = styled.h3`
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  text-transform: initial;
  text-align: center;
  margin: 8px 0 0;

  a {
    text-decoration-line: underline;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;
`;
