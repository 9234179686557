import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, mq } from '@telia-company/tv.oneapp-web-ui';
import { textPrimaryEmphasis } from 'theme/theme.utils';

export const StyledCard = styled.div<{ active: boolean; activatable?: boolean }>`
  cursor: pointer;
  position: relative;
  display: flex;
  user-select: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.designTokens.color.background.tertiary};
  }
  background-color: ${({ theme, active }) =>
    active
      ? theme.designTokens.color.background.tertiary
      : theme.designTokens.color.background.secondary};
  height: 74px;
  overflow: hidden;

  color: ${({ active, theme }) =>
    active ? theme.designTokens.color.text.primary : theme.designTokens.color.text.secondary};

  ${({ theme, activatable, active }) =>
    !activatable &&
    css`
      button,
      button:focus:not(:hover) {
        ${active &&
        css`
          background-color: transparent;
          outline: 1px solid rgba(255, 255, 255, 0.25);
        `}
      }
      button:disabled {
        outline: none;
      }
    `}

  padding: 12px 13px;
  align-items: center;

  ${mq.S`
    align-items: stretch;
    padding: 0;
    flex-direction: column;
    width: 200px;
    height: 200px;
    border-radius: 4px;
  `}
`;

export const StyledCardImage = styled.img`
  display: block;
  height: 36px;
  ${mq.S`
    margin: 28px auto 0px auto;
    width: 144px;
    height: 64px;
  `};
`;

export const StyledCardInfo = styled.div`
  ${({ theme }) => css`
    ${textPrimaryEmphasis(theme)}
  `}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  text-align: right;
  align-self: start;
  margin: 0;

  ${mq.S`
    align-self: stretch;
    margin: 12px 16px 0 16px;
    text-align: center;
  `}
`;

export const StyledButton = styled(Button)`
  display: none;
  ${mq.S`
    display: block;
    overflow: hidden;
    white-space: nowrap;
    margin: 12px 16px;
  `}
`;

export const StyledTagContainer = styled.div`
  display: flex;
  position: absolute;
  left: auto;
  right: 12px;
  bottom: 13px;
  gap: 8px;
  ${mq.S`
    top: 0;
    left: 0;  
    right: auto;
    bottom: auto;
  `};
`;
