import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledContainer,
  StyledPinInputWrapper,
  StyledPinInput,
  StyledErrorMessage,
  StyledErrorIcon,
  StyledSuccessMessage,
} from './PinInputContainer.styles';
import * as pinUtils from './utils/pinUtils.utils';

type Props = {
  textSecurity?: boolean;
  useAutoFocus?: boolean;
  enteredPinCode: string | null;
  pinInputErrorMessage: string | null;
  pinInputSuccessMessage: string | null;
  isLoading: boolean;
  setEnteredPinCode: (code: string) => void;
  setPinInputErrorMessage: (message: string) => void;
  setPinInputSuccessMessage: (message: string) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export const PinInputContainer = ({
  textSecurity = true,
  useAutoFocus = true,
  enteredPinCode,
  pinInputErrorMessage,
  pinInputSuccessMessage,
  isLoading,
  setEnteredPinCode,
  setPinInputErrorMessage,
  setPinInputSuccessMessage,
  setIsLoading,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const inputFields = new Array(4).fill('');
  const inputRefs = useRef<Array<HTMLInputElement>>([]);

  useEffect(() => {
    const clearState = () => {
      setPinInputErrorMessage('');
      setPinInputSuccessMessage('');
    };

    if (!pinUtils.pinCodeEntered(enteredPinCode, inputRefs)) {
      pinUtils.clear(inputRefs);
      clearState();
    }
  }, [enteredPinCode, setPinInputErrorMessage, setPinInputSuccessMessage]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const target: HTMLInputElement = e.currentTarget;
    setPinInputErrorMessage('');
    setPinInputSuccessMessage('');

    const pin = pinUtils.getPin(inputRefs).join('');

    if (!pinUtils.isOnlyNumbers(pin)) {
      target.value = '';
      setPinInputErrorMessage(t('SETTINGS_LOCKER_PIN_INPUT_ERROR'));
      return;
    }

    const isComplete = pin.length === inputRefs.current.length;

    if (isComplete) {
      target.blur();
      setEnteredPinCode(pin);
      return;
    }

    if (parseInt(target.value) > -1) {
      // move to next input
      pinUtils.moveToInput(target, 1, inputRefs);
    }
  };

  const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      // move to previous input
      const el = pinUtils.moveToInput(e.currentTarget, -1, inputRefs);
      if (el) {
        el.value = '';
      }
    }
  };

  return (
    <StyledContainer {...props}>
      <StyledPinInputWrapper>
        {inputFields.map((_, index) => {
          const pinId = pinUtils.getPinId(index);
          return (
            <StyledPinInput
              // @TODO: for iOS
              // textSecurity={textSecurity}
              autoFocus={useAutoFocus && index === 0}
              onKeyDown={handleBackspace}
              onClick={() => {
                pinUtils.clear(inputRefs);
                setEnteredPinCode('');
                pinUtils.focusFirstInputField(inputRefs);
              }}
              onChange={handleChange}
              key={index}
              id={pinId}
              name={pinId}
              data-testid={pinId}
              ref={(el: HTMLInputElement) => {
                if (el) {
                  inputRefs.current[index] = el;
                }
              }}
              type="password"
            />
          );
        })}
      </StyledPinInputWrapper>
      {pinInputErrorMessage && (
        <>
          <StyledErrorMessage data-testid="error-message">
            <StyledErrorIcon name="Alert" />
            {pinInputErrorMessage}
          </StyledErrorMessage>
        </>
      )}
      {pinInputSuccessMessage && (
        <StyledSuccessMessage>{pinInputSuccessMessage}</StyledSuccessMessage>
      )}
    </StyledContainer>
  );
};
