export const getFaviconLinkHTMLAttributes = (): Array<JSX.IntrinsicElements['link']> => {
  return [
    {
      href: `/favicons/telia/apple-touch-icon.png`,
      rel: 'apple-touch-icon',
      sizes: '180x180',
    },
    {
      href: `/favicons/telia/favicon-32x32.png`,
      rel: 'icon',
      sizes: '32x32',
      type: 'image/png',
    },
    {
      href: `/favicons/telia/favicon-16x16.png`,
      rel: 'icon',
      sizes: '16x16',
      type: 'image/png',
    },
    {
      color: '#990ae3',
      href: `/favicons/telia/safari-pinned-tab.svg`,
      rel: 'mask-icon',
    },
    {
      href: `/favicons/telia/favicon.ico`,
      rel: 'icon',
    },
    {
      href: `/favicons/telia/apple-touch-icon.png`,
      rel: 'apple-touch-icon',
    },
  ];
};
