import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { t } from 'i18next';
import { getAccountInfo } from 'services/userService';

type CreateEmailModalInfo = {
  confirmLabel: string;
  description: string;
  onConfirmModalResponse?: (confirmed: boolean) => void;
};

export const getCreateEmailModalInfo = (
  accountInfo: Awaited<ReturnType<typeof getAccountInfo>>,
): CreateEmailModalInfo => {
  const accountProviderType = accountInfo?.accountProvider?.type;
  const changeEmailUrl = Config.get('change-email-url') ?? Config.get('one-app-telia-url');

  const modalType: 'TELIA' | 'PARTNER' | undefined =
    accountProviderType === 'TELIA' && changeEmailUrl
      ? 'TELIA'
      : accountProviderType === 'PARTNER'
      ? 'PARTNER'
      : undefined;

  switch (modalType) {
    case 'TELIA':
      return {
        confirmLabel: t('SETTINGS_RENTAL_PIN_REQUEST_EMAIL_MISSING_INFO_LINK_CONFIRM_LABEL'),
        description: t('SETTINGS_RENTAL_PIN_REQUEST_EMAIL_MISSING_INFO_LINK'),
        onConfirmModalResponse: (confirmed: boolean) => {
          if (confirmed) {
            window.location.href = changeEmailUrl;
          }
        },
      };

    case 'PARTNER':
      return {
        confirmLabel: t('COMMON_BUTTON_OK'),
        description: t('SETTINGS_RENTAL_PIN_REQUEST_EMAIL_MISSING_INFO_OPERATOR'),
      };
  }

  // Just give information that the user need to have an email address to be able
  // to create pin code
  return {
    confirmLabel: t('COMMON_BUTTON_OK'),
    description: t('SETTINGS_RENTAL_PIN_REQUEST_EMAIL_MISSING_INFO'),
  };
};
