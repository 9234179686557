import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Logo } from '@telia-company/tv.oneapp-web-ui';
import { t } from 'i18next';
import { IconWrapper, StyledLogoContainer } from './LogoHorizontal.styles';

export const LogoHorizontal = ({ ...props }) => {
  return (
    <StyledLogoContainer {...props}>
      <IconWrapper href={Config.get('one-app-base-url')} aria-label={t('COMMON_BRAND_TELIA')}>
        <Logo />
      </IconWrapper>
    </StyledLogoContainer>
  );
};
