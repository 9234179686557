import styled from '@emotion/styled';
import { headingTertiary, textPrimary } from 'theme/theme.utils';

export const StyledConfirmInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledSubHeader = styled.h3`
  ${({ theme }) => headingTertiary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  margin-bottom: 4px;
`;

export const StyledDeselectedItems = styled.div`
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
`;

export const StyledSelectedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
`;

export const StyledSelectedItemName = styled.span``;

export const StyledSelectedItemInfo = styled.span`
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
`;
