import { useEffect, useState } from 'react';
import { useLogger } from 'hooks';
import { getUser, User, userChangeEvent } from 'services/userService';

export const useGetUser = () => {
  const [user, setUser] = useState<User | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const logger = useLogger('useGetUser');

  useEffect(() => {
    const refresh = async () => {
      setLoading(true);
      setError(undefined);
      try {
        setUser(await getUser());
      } catch (error: unknown) {
        if (error instanceof Error) {
          logger.error('Failed to fetch user', error);
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };

    refresh();

    const userChangeListener = userChangeEvent.on(() => {
      refresh();
    });

    return () => userChangeEvent.off(userChangeListener);
  }, [logger]);

  return { loading, user, error };
};
