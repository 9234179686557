import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 50px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 64px;

  ${mq.L`
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 64px;
    margin-bottom: 124px;
    flex-direction: row;
  `}
`;
