import { country } from 'environment';
import { KampylePrivateData } from 'utils/medallia';
import { medalliaEnvironment } from './medalliaEnvironment.utils';

let updatePageViewInterval: ReturnType<typeof setInterval> | undefined;

export const updatePageView = (data?: KampylePrivateData) => {
  if (data) {
    window._KAMPYLE_PRIVATE_DATA = { ...data, market: country, environment: medalliaEnvironment };
  }

  // The script loads asynchronously so in case we get the updatePageView request before we
  // have it loaded, we should wait for it and invoke it when it's there.
  if (window.KAMPYLE_ONSITE_SDK?.updatePageView) {
    if (updatePageViewInterval) {
      clearInterval(updatePageViewInterval);
      updatePageViewInterval = undefined;
    }
    window.KAMPYLE_ONSITE_SDK.updatePageView();
  } else if (!updatePageViewInterval) {
    // Try and try till we get the script loaded
    updatePageViewInterval = setInterval(() => {
      if (window.KAMPYLE_ONSITE_SDK?.updatePageView) {
        clearInterval(updatePageViewInterval);
        updatePageViewInterval = undefined;
        window.KAMPYLE_ONSITE_SDK?.updatePageView();
      }
    }, 100);
  }
};
