import { Variants } from 'framer-motion';
import { MenuItem } from '../../hooks';
import { StyledDropDownMenuContainer } from './DropDownMenu.styles';
import { DropDownMenuItem } from './DropDownMenuItem';

type DropDownMenuProps = {
  open: boolean;
  menuItems: MenuItem[];
};

const animations: Variants = {
  open: {
    display: 'block',
    opacity: 1,
    transition: { duration: 0.3 },
  },
  closed: {
    opacity: 0,
    transition: { duration: 0.3 },
    transitionEnd: { display: 'none' },
  },
};

export const DropDownMenu = ({ open, menuItems }: DropDownMenuProps) => {
  return (
    <StyledDropDownMenuContainer
      animate={open ? 'open' : 'closed'}
      initial="closed"
      variants={animations}
    >
      {menuItems.map((menuItem, i) => (
        <DropDownMenuItem
          key={i}
          menuItem={menuItem}
          variant={menuItem.id === 'logout' ? 'dark' : 'normal'}
        />
      ))}
    </StyledDropDownMenuContainer>
  );
};
