import { country } from 'environment';
import { Authentication } from 'sdk/authentication';
import { getUser } from 'services/userService';
import { medalliaEnvironment } from './medalliaEnvironment.utils';
import { updatePageView } from './updatePageView.utils';

export const init = () => {
  window._KAMPYLE_PRIVATE_DATA = {
    environment: medalliaEnvironment,
    market: country,
  };

  Authentication.statics.events.authentication.on(async ({ loggedin }) => {
    if (loggedin) {
      const user = await getUser();
      updatePageView({ isLoggedIn: loggedin, accountID: user.id });
    } else {
      updatePageView({ isLoggedIn: false, accountID: undefined });
    }
  });
};
