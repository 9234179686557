import { useTranslation } from 'react-i18next';
import { TeliaSettings } from './components';
import { StyledSectionHeading } from '../navigation.styles';

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledSectionHeading>{t('SETTINGS_HEADING')}</StyledSectionHeading>
      <TeliaSettings />
    </>
  );
};
