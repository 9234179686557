import { FocusEventHandler, MouseEventHandler, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

type SmartLinkProps = {
  className?: string;
  to?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onFocus?: FocusEventHandler<HTMLAnchorElement>;
  onBlur?: FocusEventHandler<HTMLAnchorElement>;
  tabIndex?: number;
};

export const SmartLink = ({
  className,
  to,
  children,
  onClick,
  onFocus,
  onBlur,
  tabIndex,
}: SmartLinkProps) => {
  return to?.startsWith('/') ? (
    <NavLink
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      to={to}
      tabIndex={tabIndex}
    >
      {children}
    </NavLink>
  ) : (
    <a
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      href={to}
      tabIndex={tabIndex}
    >
      {children}
    </a>
  );
};
