import {
  StyledSectionSubHeading,
  StyledSectionSubHeadingDescription,
  StyledSubSectionContainer,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { SettingsRegionalChannels } from '../SettingsRegionalChannels';
import { SettingsParentalControls } from '../SettingsParentalControls';
import { SettingsRentalPinControls } from '../SettingsRentalPinControls';
import { FeatureEnabled } from 'components/FeatureEnabled';
import { SettingsPreferredLanguages } from '../SettingsPreferredLanguages';
import { StyledPinCodeContainer } from './TeliaSettings.styles';

export const TeliaSettings = () => {
  const { t } = useTranslation();
  return (
    <>
      <StyledSectionSubHeading>{t('SETTINGS_ACCOUNT_SUBHEADING')}</StyledSectionSubHeading>
      <StyledSectionSubHeadingDescription>
        {t('SETTINGS_ACCOUNT_INFORMATION')}
      </StyledSectionSubHeadingDescription>
      <StyledSubSectionContainer>
        <StyledPinCodeContainer>
          {/* Separation is made between elements, and since the settings controls are placing buttons as
        siblings, they would be separated as well - therefore we're adding 'div' around them */}
          <div>
            <SettingsParentalControls />
          </div>
          <div>
            <SettingsRentalPinControls />
          </div>
        </StyledPinCodeContainer>
      </StyledSubSectionContainer>
      <StyledSectionSubHeading>{t('SETTINGS_DEVICE_SUBHEADING')}</StyledSectionSubHeading>
      <StyledSectionSubHeadingDescription>
        {t('SETTINGS_DEVICE_INFORMATION')}
      </StyledSectionSubHeadingDescription>
      <FeatureEnabled featureToggles={['Settings.RegionalChannels']}>
        <SettingsRegionalChannels />
      </FeatureEnabled>
      <SettingsPreferredLanguages />
    </>
  );
};
