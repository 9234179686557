import { useTranslation } from 'react-i18next';
import { SelectableItem } from 'services/selectionMenuService';
import {
  StyledNewTag,
  StyledSelectedItemContainer,
  StyledSelectedItemName,
  StyledSelectedItemPoints,
} from './SelectedItem.styles';
import { useContext } from 'react';
import { SelectionMenuContext } from '../../../SelectionMenuContext';

type SelectedItemProps = {
  item: SelectableItem;
  isNew: boolean;
};

export const SelectedItem = ({ item, isNew }: SelectedItemProps) => {
  const { t } = useTranslation();
  const { openItemModal } = useContext(SelectionMenuContext);

  return (
    <StyledSelectedItemContainer
      key={item.id}
      onClick={(e) => {
        e.stopPropagation();
        openItemModal(item);
      }}
    >
      <StyledSelectedItemName>
        {item.name}
        {isNew && <StyledNewTag>{t('SELECTIONMENU_SCORETABLE_NEW_TAG')}</StyledNewTag>}
      </StyledSelectedItemName>
      <StyledSelectedItemPoints>
        {t('SELECTIONMENU_SCORETABLE_POINTS', { points: item.points ?? 0 })}
      </StyledSelectedItemPoints>
    </StyledSelectedItemContainer>
  );
};
