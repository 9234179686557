import { useHeaderSizeContext } from 'components/Header/components/HeaderSizeContext';
import { SelectionMenuScoreTableContent } from './components';
import { StyledScoreTableContainer } from './SelectionMenuScoreTable.styles';

export const SelectionMenuScoreTableLarge = () => {
  const { height } = useHeaderSizeContext();
  return (
    <StyledScoreTableContainer style={{ top: `${height}px` }}>
      <SelectionMenuScoreTableContent />
    </StyledScoreTableContainer>
  );
};
