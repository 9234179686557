import {
  StyledSectionSubHeading,
  StyledSubSection,
  StyledSubSectionContainer,
  StyledSubSectionContent,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';

type DualEntryInformationProps = {
  user: User;
};

export const DualEntryInformation = ({
  user: { tvAccountId, id, username, email },
}: DualEntryInformationProps) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledSectionSubHeading>{t('DUAL_ENTRY_HEADING')}</StyledSectionSubHeading>
      <StyledSubSectionContainer>
        <StyledSubSection>
          <div>
            {!!tvAccountId && (
              <>
                <StyledSubSectionLabel>{t('DUAL_ENTRY_TELIA_LABEL')}</StyledSubSectionLabel>
                <StyledSubSectionContent>{tvAccountId}</StyledSubSectionContent>
              </>
            )}
            <StyledSubSectionLabel>{t('DUAL_ENTRY_TV4_LABEL')}</StyledSubSectionLabel>
            <StyledSubSectionContent>{id}</StyledSubSectionContent>
            <StyledSubSectionLabel>{t('DUAL_ENTRY_ACCOUNT_LABEL')}</StyledSubSectionLabel>
            <StyledSubSectionContent>{username}</StyledSubSectionContent>
            <StyledSubSectionLabel>{t('DUAL_ENTRY_EMAIL_LABEL')}</StyledSubSectionLabel>
            <StyledSubSectionContent>{email}</StyledSubSectionContent>
          </div>
        </StyledSubSection>
      </StyledSubSectionContainer>
    </>
  );
};
