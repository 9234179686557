import { useEffect, useState } from 'react';
import {
  StyledColumn,
  StyledHeading,
  StyledLinkList,
  StyledMotion,
  StyledOptAnonButton,
} from './Column.styles';
import { useMediaQueryMinSize as useMediaQueryMinWidth } from 'hooks';
import { Chevron } from 'components/Chevron';

export type ColumnLink = {
  name?: string;
  link?: string;
  type?: 'link' | 'button';
};

export type ColumnType = {
  heading?: string;
  linkList?: ColumnLink[];
};

export const Column = ({ heading, linkList }: ColumnType) => {
  const isLargeWidth = useMediaQueryMinWidth('L');
  const [open, setOpen] = useState<boolean>(isLargeWidth);
  const toggleOpen = () => setOpen(!open);

  useEffect(() => {
    setOpen(isLargeWidth);
  }, [isLargeWidth]);

  return (
    <StyledColumn>
      {isLargeWidth ? (
        <StyledHeading className="open">{heading}</StyledHeading>
      ) : (
        <StyledHeading className={open ? 'open' : ''}>
          {
            <button onClick={toggleOpen}>
              {heading}
              <Chevron open={open} size={'small'} />
            </button>
          }
        </StyledHeading>
      )}

      <StyledMotion
        animate={{
          height: open ? 'auto' : 0,
          opacity: open ? 1 : 0,
          transition: {
            duration: 0.2,
            ease: 'easeInOut',
          },
        }}
      >
        <StyledLinkList>
          {linkList?.map((link, index) => (
            // currently the only item using type button is the cookie-settings, In case another item also needs to be a button the logic has to be changed.
            <li key={index}>
              {link.type === 'button' ? (
                <StyledOptAnonButton className="optanon-toggle-display" id="ot-sdk-btn">
                  {link.name}
                </StyledOptAnonButton>
              ) : (
                <a href={link.link || '/'}>{link.name}</a>
              )}
            </li>
          ))}
        </StyledLinkList>
      </StyledMotion>
    </StyledColumn>
  );
};
