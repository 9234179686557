import { MouseEvent, useEffect, useState } from 'react';
import { PageLinkFragment } from 'gql/generated';
import { oneAppUrl } from 'utils';
import { useLogger } from 'hooks';
import { getMainMenu } from 'services/backend/commonGraph';

export type MenuItem = {
  id: string;
  label: string;
  url: string;
  imageUrl?: string;
  info?: string;
  onClick?: (e: MouseEvent) => void;
};

const createMenuItem = ({ id, name }: PageLinkFragment): MenuItem => {
  return {
    id: id,
    label: name || '',
    url: oneAppUrl(id),
  };
};

export const useGetMenuItems = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const logger = useLogger('components/Header/hooks/useGetMenuItems');

  useEffect(() => {
    const query = async () => {
      try {
        const result = await getMainMenu();
        setMenuItems((result.data.mainMenu?.items || []).map(createMenuItem));
      } catch (error) {
        logger.error('Failed to get menu items', error);
        if (error instanceof Error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    query();
  }, [logger]);

  return {
    loading,
    menuItems,
    error,
  };
};
