import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { buttonText, textSecondary } from 'theme/theme.utils';
import { SmartLink } from 'components';

export const StyledMenuItemImageLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledMenuItemImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const StyledSmartLink = styled(SmartLink)`
  ${({ theme }) => css`
    ${buttonText(theme)}
    display: block;
    padding: 12px 24px;

    &.active {
      background-color: ${theme.designTokens.color.button.ghostPressed};
    }
  `}
`;

export const StyledMenuItemInfo = styled.div`
  ${({ theme }) => css`
    ${textSecondary(theme)}
    color: ${theme.designTokens.color.text.secondary}
  `}
`;
