import { ReactNode } from 'react';
import { isFeatureEnabled, FeatureToggleType } from 'featureToggles';

type FetureEnabledProps = {
  featureToggles?: FeatureToggleType[];
  children: ReactNode;
};

export const FeatureEnabled = ({ featureToggles, children }: FetureEnabledProps) => {
  return !featureToggles || isFeatureEnabled(featureToggles) ? <>{children}</> : null;
};
