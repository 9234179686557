import { Account } from '@telia-company/tv.common-sdk-authentication/dist/Account';
import { useCallback, useState } from 'react';

type SaveProps = {
  newPassword: string;
  oldPassword: string;
};

export const useSavePassword = () => {
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const account = Account.instance();

  const save = useCallback(
    async ({ newPassword, oldPassword }: SaveProps) => {
      setSaved(false);
      setSaving(true);
      setError(undefined);
      try {
        await account.setPassword(newPassword, oldPassword);
        setSaved(true);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        }
      } finally {
        setSaving(false);
      }
    },
    [account],
  );

  return {
    saving,
    saved,
    error,
    save,
  };
};
