import { ConfirmModal } from 'components/modals';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectableItem } from 'services/selectionMenuService';
import {
  StyledConfirmInfoContainer,
  StyledDeselectedItems,
  StyledSelectedItemInfo,
  StyledSelectedItemName,
  StyledSelectedItemsContainer,
  StyledSubHeader,
} from './SaveConfirmModal.styles';

export type SaveConfirmModalHandle = {
  open: (args: { newSelectedItems: SelectableItem[]; removedItems: SelectableItem[] }) => void;
};

export const SaveConfirmModal = forwardRef<SaveConfirmModalHandle>((_props, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [newSelectedItems, setNewSelectedItems] = useState<SelectableItem[]>([]);
  const [removedItems, setRemovedItems] = useState<SelectableItem[]>([]);

  useImperativeHandle(ref, () => ({
    open: (args) => {
      setNewSelectedItems(args.newSelectedItems);
      setRemovedItems(args.removedItems);
      setOpen(true);
    },
  }));

  if (!open) {
    return null;
  }

  const deselectedItems = removedItems.map(({ name }) => name).join(', ');

  return (
    <ConfirmModal
      isOpen={true}
      title={t('SELECTIONMENU_CONFIRM_MODAL_HEADER')}
      onResponse={() => {
        setOpen(false);
      }}
      showCancel={false}
      confirmLabel={t('COMMON_BUTTON_OK')}
    >
      <StyledConfirmInfoContainer>
        {removedItems.length > 0 && (
          <div>
            <StyledSubHeader>{t('SELECTIONMENU_CONFIRM_MODAL_DESELECTED_HEADER')}</StyledSubHeader>
            <StyledDeselectedItems>{deselectedItems}</StyledDeselectedItems>
          </div>
        )}
        {newSelectedItems.length > 0 && (
          <div>
            <StyledSubHeader>{t('SELECTIONMENU_CONFIRM_MODAL_SELECTED_HEADER')}</StyledSubHeader>
            <StyledSelectedItemsContainer>
              {newSelectedItems.map(({ name, id, externalRegistration }) => (
                <div key={id}>
                  <StyledSelectedItemName>{name}</StyledSelectedItemName>
                  {!!externalRegistration && (
                    <StyledSelectedItemInfo>
                      {' '}
                      {t('SELECTIONMENU_CONFIRM_MODAL_ITEM_ACTIVATE_TAG')}
                    </StyledSelectedItemInfo>
                  )}
                </div>
              ))}
            </StyledSelectedItemsContainer>
          </div>
        )}
      </StyledConfirmInfoContainer>
    </ConfirmModal>
  );
});
