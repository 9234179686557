import {
  StyledFooterInner,
  StyledSpaceBetweenColumn,
  StyledFooterContainer,
  StyledFooterCopyright,
  StyledCopyrightLine,
} from './Footer.styles';
import { Column, ColumnType } from './column';
import { useMediaQueryMinSize as useMediaQueryMinWidth } from 'hooks';
import { fetchFooter } from './utils/fetchFooter.utils';
import { LogoFooter } from './components/LogoFooter';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { useFetchFunction } from 'hooks/useFetchFunction';

export type FooterDataType = {
  footerColumns?: ColumnType[];
  copyrightText: string[];
};

export const Footer = () => {
  const { data: footer } = useFetchFunction(fetchFooter);
  const copyright = footer?.copyrightText;
  const isLargeWidth = useMediaQueryMinWidth('L');

  const href = Config.get('one-app-telia-url');

  return !footer ? null : (
    <StyledFooterContainer>
      {isLargeWidth && <LogoFooter href={href} />}
      {footer?.footerColumns?.map((column, index) => (
        <Column key={`footer-column-${index}`} {...column} />
      ))}
      <StyledFooterInner>
        {!isLargeWidth && <LogoFooter href={href} />}
        <StyledSpaceBetweenColumn>
          <StyledFooterCopyright>
            {copyright &&
              copyright.map((copyrightLine, index) => (
                <StyledCopyrightLine key={`copyright-line-${index}`}>
                  {copyrightLine}
                </StyledCopyrightLine>
              ))}
          </StyledFooterCopyright>
        </StyledSpaceBetweenColumn>
      </StyledFooterInner>
    </StyledFooterContainer>
  );
};
