import { Chevron } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledPurchaseHistoryCollapsable,
  StyledPurchaseList,
  StyledShowMoreButton,
} from '../PurchaseHistory.styles';
import { PurchaseHistoryItem } from './PurchaseHistoryItem';
import { MediaAccessHistoryItem } from 'services/backend/purchaseGateway';

type PurchaseHistoryListProps = {
  items: MediaAccessHistoryItem[];
};

const N_STATIC_ITEMS = 2;

export const PurchaseHistoryList = ({ items }: PurchaseHistoryListProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();

  const staticItems = items.slice(0, N_STATIC_ITEMS);
  const collapsibleItems = items.slice(N_STATIC_ITEMS);
  const hasCollapsibleItems = !!collapsibleItems.length;

  return (
    <StyledPurchaseList collapsed={collapsed}>
      {staticItems.map((item) => (
        <PurchaseHistoryItem key={item.mediaId + item.startTime} item={item} />
      ))}

      {hasCollapsibleItems && (
        <>
          <StyledPurchaseHistoryCollapsable
            animate={{
              height: collapsed ? 0 : 'auto',
              transition: {
                duration: 0.2,
                ease: 'easeInOut',
              },
            }}
          >
            {collapsibleItems.map((item, i) => (
              <PurchaseHistoryItem key={item.mediaId + item.startTime} item={item} />
            ))}
          </StyledPurchaseHistoryCollapsable>
          <StyledShowMoreButton onClick={() => setCollapsed(!collapsed)}>
            {t(
              collapsed
                ? 'SUBSCRIPTION_PURCHASE_HISTORY_SEE_MORE_PURCHASE_HISTORY'
                : 'SUBSCRIPTION_PURCHASE_HISTORY_SEE_LESS_PURCHASE_HISTORY',
            )}
            <Chevron open={!collapsed} />
          </StyledShowMoreButton>
        </>
      )}
    </StyledPurchaseList>
  );
};
