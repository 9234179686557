import { NavigatorSection } from 'pages';
import { StyledSectionIcon, StyledSectionLabel } from './NavigationDropDown.styles';

type SectionLabelProps = {
  section: NavigatorSection;
};

export const SectionLabel = ({ section }: SectionLabelProps) => {
  return (
    <StyledSectionLabel>
      <StyledSectionIcon>{section.icon}</StyledSectionIcon>
      {section.label}
    </StyledSectionLabel>
  );
};
