import { DataManager } from '@telia-company/tv.common-sdk/dist/DataManager';
import { ServiceConfig } from '@telia-company/tv.common-sdk/dist/ServiceConfig';
import { RequestHeaders } from '@telia-company/tv.common-sdk/dist/abstractions/platform/HttpRequestIF';
import { country } from 'environment';

const sendPost = async <T>(
  path: string,
  cacheClass: string,
  body: Record<string, unknown>,
  spec?: {
    secure?: boolean;
    headers?: RequestHeaders;
  },
): Promise<T> => {
  const engagementgateway = ServiceConfig.getService('engagementgateway');
  const dataManager = DataManager.instance();
  const url = engagementgateway.getUrl(path);
  return (await dataManager.sendPost(url, cacheClass, {
    secure: spec?.secure ?? true,
    postAsJson: true,
    post: body,
    ...(spec?.headers && { headers: spec.headers }),
  })) as T;
};

const sendPut = async <T>(
  path: string,
  cacheClass: string,
  body: Record<string, unknown>,
  spec?: {
    secure?: boolean;
    headers?: RequestHeaders;
  },
): Promise<T> => {
  const engagementgateway = ServiceConfig.getService('engagementgateway');
  const dataManager = DataManager.instance();
  const url = engagementgateway.getUrl(path);
  return (await dataManager.sendPut(url, cacheClass, {
    secure: spec?.secure ?? true,
    postAsJson: true,
    post: body,
    ...(spec?.headers && { headers: spec.headers }),
  })) as T;
};

const sendGet = async <T>(path: string, cacheClass: string) => {
  const engagementgateway = ServiceConfig.getService('engagementgateway');
  const dataManager = DataManager.instance();
  const url = engagementgateway.getUrl(path);
  const headers = engagementgateway.getHeaders();

  return dataManager.sendGet(url, cacheClass, {
    headers,
    secure: true,
  }) as T;
};

const sendDelete = async <T>(
  path: string,
  cacheClass: string,
  body: Record<string, unknown>,
  spec?: {
    secure?: boolean;
    headers?: RequestHeaders;
  },
): Promise<T> => {
  const engagementgateway = ServiceConfig.getService('engagementgateway');
  const dataManager = DataManager.instance();
  const url = engagementgateway.getUrl(path);
  return (await dataManager.sendDelete(url, cacheClass, {
    secure: spec?.secure ?? true,
    postAsJson: true,
    post: body,
    ...(spec?.headers && { headers: spec.headers }),
  })) as T;
};

type ValidateRentalPinProps = {
  pinCode: string;
  cacheClass: string;
};

export const validateRentalPinCode = async ({
  pinCode,
  cacheClass,
}: ValidateRentalPinProps): Promise<boolean> => {
  const result = await sendPost<{ validated: boolean }>(
    '/rest/secure/v1/pin/1/validation',
    cacheClass,
    {
      verificationPinCode: pinCode,
    },
  );
  return result.validated;
};

type ChangeRentalPinCodeProps = {
  oldPinCode: string;
  newPinCode: string;
  cacheClass: string;
};

export const changeRentalPinCode = async ({
  oldPinCode,
  newPinCode,
  cacheClass,
}: ChangeRentalPinCodeProps) => {
  await sendPost<{ validated: boolean }>('/rest/secure/v1/pin/1', cacheClass, {
    newPinCode,
    verificationPinCode: oldPinCode,
  });
};

type CreateRentalPinCodeProps = {
  newPinCode: string;
  cacheClass: string;
};

export const createRentalPinCode = async ({ newPinCode, cacheClass }: CreateRentalPinCodeProps) => {
  await sendPost<{ validated: boolean }>('/rest/secure/v1/pin/1', cacheClass, {
    newPinCode,
  });
};

type SetRentalPinCodeActiveProps = {
  pinCode: string;
  active: boolean;
  cacheClass: string;
};

export const setRentalPinCodeActive = async ({
  pinCode,
  active,
  cacheClass,
}: SetRentalPinCodeActiveProps) => {
  await sendPost<{ validated: boolean }>('/rest/secure/v1/pin/1/activation', cacheClass, {
    pinCode,
    activatePinCode: active,
  });
};

type ResetPinCodeProps = {
  newPinCode: string;
  token: string;
  cacheClass: string;
};

export const resetPinCode = async ({ newPinCode, token, cacheClass }: ResetPinCodeProps) => {
  await sendPost(
    '/rest/v1/pin/reset/exchange',
    cacheClass,
    {
      token,
      newPinCode,
    },
    {
      secure: false,
      headers: {
        'x-country': country,
      },
    },
  );
};

type ForgotPinCodeProps = {
  pinIndex: number;
  cacheClass: string;
};

export const forgotPinCode = async ({ pinIndex, cacheClass }: ForgotPinCodeProps) => {
  await sendPost(`/rest/secure/v1/pin/reset/${pinIndex}`, cacheClass, {});
};

type AccountResponse = {
  accountProvider?: {
    type?: string;
    url?: string;
  };
};

export const accountInfo = async (cacheClass: string) => {
  return (await sendGet('/rest/secure/v2/account/info', cacheClass)) as AccountResponse;
};

type ConnectedStatusResponse = {
  error?: Error;
  connectedFederators: [] | Array<'apple' | 'google'>;
};
export const connectedStatus = async (cacheClass: string) => {
  let statusResponse: ConnectedStatusResponse = {
    connectedFederators: [],
  };

  try {
    statusResponse = (await sendGet(
      '/rest/secure/v2/federated/connected',
      cacheClass,
    )) as ConnectedStatusResponse;
  } catch (error) {
    if (error instanceof Error) {
      statusResponse.error = error;
    }
  } finally {
    return statusResponse;
  }
};

// Note if do we have more federators
// than google and apple, lets add those here
export type Federator = 'apple' | 'google';

type DeleteConnectionProps = {
  federator: Federator;
  cacheClass: string;
};
type DeletedConnectionResponse = {
  error?: Error;
};
export const deleteConnection = async ({ federator, cacheClass }: DeleteConnectionProps) => {
  let deleteResponse: DeletedConnectionResponse = {};

  try {
    deleteResponse = (await sendDelete(
      `/rest/secure/v2/federated/remove?federator=${federator}`,
      cacheClass,
      {},
    )) as DeletedConnectionResponse;
  } catch (error) {
    if (error instanceof Error) {
      deleteResponse.error = error;
    }
  } finally {
    return deleteResponse;
  }
};

type AuhorizationPayload = {
  country: string;
  openIdToken: string;
  code: string;
  federatedBy: 'apple' | 'google';
  deviceType: string;
  deviceId: string;
};

type FederatorAuthorizationProps = {
  payload: AuhorizationPayload;
  cacheClass: string;
};
type FederatorAuthorizationResponse = {
  error?: Error;
};
export const federatorAuthorization = async ({
  payload,
  cacheClass,
}: FederatorAuthorizationProps) => {
  let federatorAuthorizationResponse: FederatorAuthorizationResponse = {};

  try {
    federatorAuthorizationResponse = (await sendPost(
      '/rest/secure/v2/federated/connect',
      cacheClass,
      {
        ...payload,
      },
    )) as FederatorAuthorizationResponse;
  } catch (error) {
    if (error instanceof Error) {
      federatorAuthorizationResponse.error = error;
    }
  } finally {
    return federatorAuthorizationResponse;
  }
};

type GetMfaStatusProps = {
  cacheClass: string;
};

type MfaDetails = {
  verificationMethod: 'email' | 'sms';
  value: string;
};

export type MfaStatus = MfaDetails & {
  status: 'ACTIVE' | 'INACTIVE';
};

export const getMfaStatus = async ({ cacheClass }: GetMfaStatusProps): Promise<MfaStatus[]> =>
  sendGet('/rest/secure/v2/twofactor', cacheClass);

export type UpdateMfaStatusPayload = MfaStatus;

type UpdateMfaStatusProps = {
  payload: UpdateMfaStatusPayload;
  cacheClass: string;
};

export const updateMfaStatus = async ({ payload, cacheClass }: UpdateMfaStatusProps) => {
  await sendPut('/rest/secure/v2/twofactor/status', cacheClass, {
    ...payload,
  });
};

export type RequestMfaOneTimePasswordPayload = MfaDetails & {
  action: 'create' | 'remove';
};

type RequestMfaOneTimePasswordProps = {
  payload: RequestMfaOneTimePasswordPayload;
  cacheClass: string;
};

export const requestMfaOneTimePassword = async ({
  cacheClass,
  payload,
}: RequestMfaOneTimePasswordProps) => {
  await sendPost('/rest/secure/v2/twofactor/otp', cacheClass, { ...payload });
};

export type CreateMfaPayload = {
  oneTimePassword: string;
};

type CreateMfaProps = {
  payload: CreateMfaPayload;
  cacheClass: string;
};

export const createMfa = async ({ payload, cacheClass }: CreateMfaProps) => {
  await sendPost('/rest/secure/v2/twofactor/create', cacheClass, { ...payload });
};

export type RemoveMfaPayload = {
  oneTimePassword: string;
};

type RemoveMfaProps = {
  payload: RemoveMfaPayload;
  cacheClass: string;
};

export const removeMfa = async ({ payload, cacheClass }: RemoveMfaProps) => {
  await sendDelete('/rest/secure/v2/twofactor/remove', cacheClass, {
    ...payload,
  });
};
