import styled from '@emotion/styled';
import { Chevron } from 'components';
import { textPrimary } from 'theme/theme.utils';
import { m } from 'framer-motion';
import { css } from '@emotion/react';

export const StyledDropDownContainer = styled.div``;

export const StyledChevron = styled(Chevron)`
  path {
    stroke: ${({ theme }) => theme.designTokens.color.text.secondary};
  }
`;

export const StyledDropDown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
`;

type StyledDropDownListProps = { scrollable?: boolean };

export const StyledDropDownList = styled(m.ul, {
  shouldForwardProp: (prop) => prop !== 'scrollable',
})<StyledDropDownListProps>`
  margin: 0;
  right: -16px;
  top: -16px;
  padding: 8px;
  position: absolute;
  background: ${({ theme }) => theme.designTokens.color.background.tertiary};
  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.5));
  border-radius: 6px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ scrollable, theme }) =>
    scrollable &&
    css`
      max-height: 300px;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: ${theme.designTokens.color.button.secondaryDefault}
        ${theme.designTokens.color.background.tertiary};
      ::-webkit-scrollbar {
        width: 14px;
      }
      ::-webkit-scrollbar-track {
        margin: 6px;
        background: ${theme.designTokens.color.background.tertiary};
      }
      ::-webkit-scrollbar-thumb {
        background: ${theme.designTokens.color.button.secondaryDefault};
        border: 4px solid ${theme.designTokens.color.background.tertiary};
        border-radius: 7px;
      }
    `}
`;

type StyledDropDownListItemProps = {
  selected?: boolean;
};

export const StyledDropDownListItem = styled.li<StyledDropDownListItemProps>`
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  display: block;
  padding: 8px 16px;
  border-radius: 6px;
  white-space: nowrap;

  ${({ selected, theme }) =>
    selected &&
    css`
      background: ${theme.designTokens.color.button.ghostHover};
    `}

  &:hover {
    background: ${({ theme }) => theme.designTokens.color.button.ghostHover};
  }
`;
