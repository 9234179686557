import { useFullscreenContext } from 'components';
import { useMediaQueryMinSize } from 'hooks';
import { NavigatorSection } from 'pages/AllRoutes';
import { Outlet } from 'react-router-dom';
import { NavigationLarge, NavigationSmall } from 'components';
import { StyledNavigationContainer, StyledSectionContainer } from './NavigatorLayout.styles';

type NavigatorLayoutProps = {
  sections: NavigatorSection[];
};

export const NavigatorLayout = ({ sections }: NavigatorLayoutProps) => {
  const { fullscreen } = useFullscreenContext();
  const mqS = useMediaQueryMinSize('S');
  return (
    <StyledNavigationContainer style={fullscreen ? { display: 'none' } : {}}>
      {mqS ? <NavigationLarge sections={sections} /> : <NavigationSmall sections={sections} />}
      <StyledSectionContainer>
        <Outlet />
      </StyledSectionContainer>
    </StyledNavigationContainer>
  );
};
