import { StyledSectionSubHeading } from 'pages/navigation.styles';
import { MediaPrepaid } from 'services/mediaPrepaidService';
import {
  StyledInformation,
  StyledRemainingLabel,
  StyledVoucherSectionContainer,
  StyledValidUntilLabel,
  StyledGiftIcon,
  StyledVoucherSectionBorder,
  StyledVoucherSectionContent,
  StyledRemainingContainer,
  StyledInformationContainer,
  StyledRemainingInformationContainer,
} from './MediaPrepaids.styles';
import { useTranslation } from 'react-i18next';

type MediaPrepaidsProps = {
  mediaPrepaids: MediaPrepaid[];
};

export const MediaPrepaids = ({ mediaPrepaids }: MediaPrepaidsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledSectionSubHeading>{t('FREE_RENTALS_HEADER')}</StyledSectionSubHeading>
      <StyledVoucherSectionContainer>
        {mediaPrepaids.map(({ timesUsed, quantity, end }, index) => (
          <StyledVoucherSectionBorder key={index}>
            <StyledVoucherSectionContent>
              <StyledInformationContainer>
                <StyledRemainingContainer>
                  <StyledGiftIcon />
                  <StyledRemainingInformationContainer>
                    <StyledRemainingLabel>
                      {t('FREE_RENTALS_REMAINING', {
                        count: quantity - timesUsed,
                        postProcess: 'pluralizer',
                      })}
                    </StyledRemainingLabel>
                    <StyledValidUntilLabel>
                      {t('FREE_RENTALS_EXPIRE', { date: end })}
                    </StyledValidUntilLabel>
                  </StyledRemainingInformationContainer>
                </StyledRemainingContainer>
                <StyledInformation>{t('FREE_RENTALS_INFORMATION')}</StyledInformation>
              </StyledInformationContainer>
            </StyledVoucherSectionContent>
          </StyledVoucherSectionBorder>
        ))}
      </StyledVoucherSectionContainer>
    </>
  );
};
