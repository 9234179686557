import { useTranslation } from 'react-i18next';
import {
  StyledPurchaseHistoryItem,
  StyledPurchaseHistoryItemInfo,
  StyledPurchaseHistoryItemInfoWrapper,
  StyledPurchaseHistoryItemLabel,
  StyledPurchaseHistoryItemPrice,
  StyledPurchaseHistoryItemWrapper,
} from '../PurchaseHistory.styles';
import {
  isActiveRental,
  getMediaUrl,
  getReadableDistance,
  getReadableAccessType,
  getReadableRentedOrBoughtOn,
} from './PurchaseHistoryItem.utils';
import { MediaAccessHistoryItem } from 'services/backend/purchaseGateway';

type PurchaseHistoryItemProps = {
  item: MediaAccessHistoryItem;
};

export const PurchaseHistoryItem = ({ item }: PurchaseHistoryItemProps) => {
  const { t } = useTranslation();

  const readableAccessType = getReadableAccessType(item, t);
  const readableRentedOrBoughtOn = getReadableRentedOrBoughtOn(item, t);

  return (
    <StyledPurchaseHistoryItem href={getMediaUrl(item)}>
      <StyledPurchaseHistoryItemWrapper>
        <StyledPurchaseHistoryItemLabel>{`${readableAccessType} - ${item.title}`}</StyledPurchaseHistoryItemLabel>
        <StyledPurchaseHistoryItemPrice variant="primary">
          {item.localizedPricePaid}
        </StyledPurchaseHistoryItemPrice>
      </StyledPurchaseHistoryItemWrapper>
      <StyledPurchaseHistoryItemInfoWrapper>
        <StyledPurchaseHistoryItemInfo>{readableRentedOrBoughtOn}</StyledPurchaseHistoryItemInfo>
        {isActiveRental(item) && (
          <StyledPurchaseHistoryItemInfo>
            {`${t('SUBSCRIPTION_PURCHASE_HISTORY_RENTAL_AVAILABLE')} ${getReadableDistance(
              item.endTime,
            )}`}
          </StyledPurchaseHistoryItemInfo>
        )}
      </StyledPurchaseHistoryItemInfoWrapper>
    </StyledPurchaseHistoryItem>
  );
};
