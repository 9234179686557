import { User } from 'services/userService';
import { MenuItem } from '../../hooks';
import { SearchLink } from '../SearchLink';
import { HamburgerMenu } from './HamburgerMenu';
import { StyledHeaderContainer, StyledLogo } from './HeaderViewSmall.styles';

type HeaderViewSmallProps = {
  user?: User;
  menuItems: MenuItem[];
  userMenuItems?: MenuItem[];
};

export const HeaderViewSmall = ({ user, menuItems, userMenuItems }: HeaderViewSmallProps) => {
  return (
    <StyledHeaderContainer>
      <HamburgerMenu user={user} menuItems={menuItems} userMenuItems={userMenuItems} />
      <StyledLogo />
      <SearchLink />
    </StyledHeaderContainer>
  );
};
