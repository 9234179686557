import { forwardRef, useImperativeHandle, useRef } from 'react';
import { FixedItem, SelectableItem } from 'services/selectionMenuService';
import { isSelectableItem } from '../../isSelectableItem.utils';
import { SelectableItemModal, SelectableItemModalHandle } from './SelectableItemModal';
import { FixedItemModal, FixedItemModalHandle } from './FixedItemModal';

export type ItemModalHandle = {
  open: (item: SelectableItem | FixedItem) => void;
};

export const ItemModal = forwardRef<ItemModalHandle>((props, ref) => {
  const fixedItemModalRef = useRef<FixedItemModalHandle>(null);
  const selectableItemModalRef = useRef<SelectableItemModalHandle>(null);

  useImperativeHandle(ref, () => ({
    open: (openItem) => {
      if (isSelectableItem(openItem)) {
        selectableItemModalRef.current?.open(openItem);
      } else {
        fixedItemModalRef.current?.open(openItem);
      }
    },
  }));

  return (
    <>
      <SelectableItemModal ref={selectableItemModalRef} />:
      <FixedItemModal ref={fixedItemModalRef} />
    </>
  );
});
