import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import { useLogger } from 'hooks';
import {
  StyledButtonContainer,
  StyledSectionSubHeading,
  StyledSubSection,
  StyledSubSectionContainer,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { StyledPaymentMethod } from './TeliaPayment.styles';

export const TeliaPayment = () => {
  var { t } = useTranslation();
  var logger = useLogger('TeliaPayment');

  const managePaymentLink = Config.get('telia-manage-payment-link');

  if (!managePaymentLink) {
    logger.error('No link to manage payment configured');
  }

  return (
    <>
      <StyledSectionSubHeading>{t('SUBSCRIPTION_PAYMENT_HEADING')}</StyledSectionSubHeading>
      <StyledSubSectionContainer>
        <StyledSubSection>
          <StyledPaymentMethod>{t('SUBSCRIPTION_PAYMENT_INVOICE')}</StyledPaymentMethod>
          {!!managePaymentLink && (
            <StyledButtonContainer>
              <Button as={SmartLink} variant="secondary" to={managePaymentLink}>
                {t('SUBSCRIPTION_PAYMENT_BUTTON_MANAGE_TELIA')}
              </Button>
            </StyledButtonContainer>
          )}
        </StyledSubSection>
      </StyledSubSectionContainer>
    </>
  );
};
