import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from './PinCodeRequestButton.styles';
import { getUser, requestPinCode } from 'services/userService';
import { useLogger, useSnackbars } from 'hooks';
import { CreateEmailModal } from '../modals/CreateEmailModal';

export const PinCodeRequestButton = () => {
  const { t } = useTranslation();
  const [successfulRequest, setSuccessfulRequest] = useState(false);
  const [isCreateEmailModalOpen, setIsCreateEmailModalOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();
  const logger = useLogger('PinCodeRequestButton');

  const onSendPinCodeRequest = async () => {
    setBusy(true);
    try {
      const { email } = await getUser();
      if (email) {
        await requestPinCode();
        setSuccessfulRequest(true);
        createPositiveSnackbar(t('SETTINGS_RENTAL_PIN_REQUEST_BUTTON', { email }));
      } else {
        // if user has no email, open a dialog to allow them to create one
        setIsCreateEmailModalOpen(true);
      }
    } catch (error) {
      logger.error('Failed to send request pin email', error);
      createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      {successfulRequest ? (
        <StyledButton variant="secondary" iconName="Check">
          {t('SETTINGS_RENTAL_PIN_REQUEST_EMAIL_SENT')}
        </StyledButton>
      ) : (
        <StyledButton
          variant="secondary"
          disabled={busy}
          isLoading={busy}
          onClick={() => onSendPinCodeRequest()}
        >
          {t('SETTINGS_RENTAL_PIN_REQUEST_BUTTON_LABEL')}
        </StyledButton>
      )}
      <CreateEmailModal
        isOpen={isCreateEmailModalOpen}
        onClose={() => {
          setIsCreateEmailModalOpen(false);
        }}
      />
    </>
  );
};
