import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { useTranslation } from 'react-i18next';
import { isFeatureEnabled } from 'featureToggles';
import { HelpLink, HelpLinkType } from '../HelpLink';
import { StyledSectionSubHeading, StyledSubSectionContainer } from '../../../navigation.styles';

export const FeedbackAndFaq = () => {
  const { t } = useTranslation();

  const links: HelpLinkType[] = [
    {
      labelText: t('HELP_FAQ_TITLE'),
      labelContent: t('HELP_FAQ_TEXT'),
      url: Config.get('one-app-faq-path'),
      buttonText: t('HELP_BUTTON_GO_TO_FAQ'),
    },
  ];

  const showFeedback = isFeatureEnabled(['Help.ShowFeedback']);
  if (showFeedback) {
    links.push({
      labelText: t('HELP_FEEDBACK_TITLE'),
      labelContent: t('HELP_FEEDBACK_TEXT'),
      url: Config.get('help-page-links')['feedback'],
      buttonText: t('HELP_BUTTON_GO_TO_SUGGESTIONS'),
    });
  }

  return (
    <>
      <StyledSectionSubHeading>{t('HELP_FEEDBACK_AND_FAQ_SUBHEADING')}</StyledSectionSubHeading>
      <StyledSubSectionContainer>
        {links.map((link, id) => (
          <HelpLink link={link} key={id} />
        ))}
      </StyledSubSectionContainer>
    </>
  );
};
