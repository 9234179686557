import { FixedItem, SelectableItem } from 'services/selectionMenuService';
import { isSelectableItem } from './isSelectableItem.utils';

const isItemSelected = (item: SelectableItem | FixedItem) =>
  (isSelectableItem(item) && item.isSelected) || !isSelectableItem(item);

const shouldActivateItem = (
  item: SelectableItem | FixedItem,
  activatableTvPackageIds: string[],
) => {
  return (
    !!item.externalRegistration &&
    (!item.tvPackageId || activatableTvPackageIds.includes(item.tvPackageId))
  );
};

/**
 * Sorts items by registration state. An active item with registration should come first... otherwise keep
 * the sorting order
 *
 * @param  a - the first item to compare
 * @param  b - the second item to compare
 * @return negative if a should come before b, positive if a should come after b, 0 if they are equal
 */
export const byRegistrationState =
  (activatableTvPackageIds: string[]) =>
  (a: SelectableItem | FixedItem, b: SelectableItem | FixedItem) => {
    if (
      shouldActivateItem(a, activatableTvPackageIds) &&
      isItemSelected(a) &&
      (!shouldActivateItem(b, activatableTvPackageIds) || !isItemSelected(b))
    ) {
      return -1;
    }
    if (
      (!shouldActivateItem(a, activatableTvPackageIds) || !isItemSelected(a)) &&
      shouldActivateItem(b, activatableTvPackageIds) &&
      isItemSelected(b)
    ) {
      return 1;
    }
    return 0;
  };
