import { SelectableItem } from 'services/selectionMenuService';

export const areSelectedItemsDifferent = (
  selectedItemsA: SelectableItem[],
  selectedItemsB: SelectableItem[],
) => {
  const selectedItemIdsA = selectedItemsA.map(({ id }) => id);
  const selectedItemIdsBSet = new Set(selectedItemsB.map(({ id }) => id));
  return (
    selectedItemIdsA.length !== selectedItemIdsBSet.size ||
    selectedItemIdsA.some((id) => !selectedItemIdsBSet.has(id))
  );
};
