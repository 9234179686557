import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { StyledSectionBackButton } from './SectionBackButton.styles';

type SectionBackButtonProps = {
  to: string;
};
export const SectionBackButton = ({ to }: SectionBackButtonProps) => {
  const { t } = useTranslation();
  const chevronLeft = Icon({
    name: 'ChevronLeft',
    width: '24px',
  });
  return (
    <StyledSectionBackButton as={Link} to={to} variant="ghost">
      {chevronLeft} {t('COMMON_BUTTON_BACK')}
    </StyledSectionBackButton>
  );
};
