import styled from '@emotion/styled';

export const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 33%;
  min-height: 200px;
`;
