import { SectionSpinner } from 'components';
import { UserNotFoundError } from 'errorTypes';
import { useLogger } from 'hooks';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { StyledSectionHeading } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { getUser } from 'services/userService';
import { getSubscriptionsComponent } from './Subscription.utils';

export const Subscription = () => {
  const logger = useLogger('Subscription');
  const { t } = useTranslation();
  const { data: user, loading, error } = useFetchFunction(getUser);

  if (loading) {
    return <SectionSpinner />;
  }
  if (error) {
    logger.error('Failed to load user', error);
    throw error;
  }
  if (!user) {
    logger.error('No user found');
    throw new UserNotFoundError();
  }

  return (
    <>
      <StyledSectionHeading>{t('SUBSCRIPTION_HEADING')}</StyledSectionHeading>
      {getSubscriptionsComponent(user)}
    </>
  );
};
