import { MouseEventHandler, useRef } from 'react';
import { StyledModal, StyledModalContainer } from './Modal.styles';
import { AnimatePresence } from 'framer-motion';
import { Portal } from 'components';

export type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  onClose: () => void;
  closeOnOverlayClick?: boolean;
};

export function Modal({
  children,
  isOpen,
  onClose,
  closeOnOverlayClick = true,
  className,
  ...props
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  const close = () => {
    onClose();
  };

  const handleContainerClick: MouseEventHandler = (e) => {
    // Only close if the parent is clicked.
    // A problem here is that if you start clicking within the modal, and end the click on the container, it will close.

    if (closeOnOverlayClick && e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <Portal>
      <AnimatePresence>
        {isOpen ? (
          <StyledModalContainer
            onClick={handleContainerClick}
            animate="visible"
            exit="hidden"
            initial="hidden"
            variants={{
              visible: { opacity: 1, transition: { duration: 0.3 } },
              hidden: { opacity: 0, transition: { duration: 0.3 } },
            }}
          >
            <StyledModal
              onClick={(e) => e.stopPropagation()}
              className={className}
              ref={modalRef}
              {...props}
              animate={'visible'}
              exit={'hidden'}
              initial={'hidden'}
              variants={{
                hidden: { scale: 0.95, opacity: 0, transition: { duration: 0.2 } },
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: { stiffness: 100, damping: 10, duration: 0.3 },
                },
              }}
            >
              {children}
            </StyledModal>
          </StyledModalContainer>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
}
