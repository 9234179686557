import { Config } from '@telia-company/tv.common-sdk/dist/Config';

export const getScript = () => {
  const script = [];

  // OneTrust config
  const oneTrustId = Config.get('oneTrust/id');
  if (Config.get('oneTrust/enabled') && oneTrustId) {
    script.push(
      {
        charSet: 'UTF-8',
        'data-document-language': true,
        'data-domain-script': oneTrustId,
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        type: 'text/javascript',
      },
      {
        innerHTML: `function OptanonWrapper() { }`,
        type: 'text/javascript',
      },
    );
  }

  // Medallia config
  script.push({
    src: 'https://resources.digital-cloud.medallia.eu/wdceu/401265/onsite/embed.js',
    type: 'text/javascript',
  });

  return script;
};
