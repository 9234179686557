import { DeviceIdentificationIF } from '@telia-company/tv.common-sdk/dist/abstractions/device/DeviceIdentificationIF';
import * as logingateway from './backend/loginGateway';
import { CacheManager } from '@telia-company/tv.common-sdk/dist/CacheManager';
import { Client } from '@telia-company/tv.common-sdk/dist/abstractions/client/ClientInformationIF';
import { Authentication } from '@telia-company/tv.common-sdk-authentication/dist/Authentication';
const cacheClass = 'deviceService';

export type LoggedinDevice = Omit<logingateway.LoggedinDevice, 'tvClientName'> & { client: Client };

const convertLoggedinDevice = (loggedinDevice: logingateway.LoggedinDevice): LoggedinDevice => ({
  ...loggedinDevice,
  client: loggedinDevice.tvClientName.toUpperCase() as Client,
});

export type LoggedinDevices = {
  thisDevice?: LoggedinDevice;
  otherDevices: LoggedinDevice[];
};

/**
 *
 * @returns The current loggedin device (if available) and a list of other logged in devices
 */
export const getLoggedinDevices = async (): Promise<LoggedinDevices> => {
  const [deviceId, loggedinDevices] = await Promise.all([
    DeviceIdentificationIF.resolve().getDeviceId(),
    logingateway.getLoggedinDevices({ cacheClass }),
  ]);

  const thisDeviceIndex = loggedinDevices.findIndex((device) => device.deviceId === deviceId);
  const thisDevice = loggedinDevices[thisDeviceIndex];

  if (thisDeviceIndex > -1) {
    loggedinDevices.splice(thisDeviceIndex, 1);
  }

  return {
    thisDevice: thisDevice ? convertLoggedinDevice(thisDevice) : undefined,
    otherDevices: [...loggedinDevices.map(convertLoggedinDevice)],
  };
};

export const logoutOtherDevices = async (): Promise<void> => {
  const authentication = Authentication.instance();
  await authentication.logoutOtherDevices();

  // Since we've logged other devices - clear the cache so we can reload the loggedin devices
  clearCache();
};

export const logoutDevice = async (deviceId: string): Promise<void> => {
  await logingateway.logoutDevice({ deviceId, cacheClass });
  // Since we've logged out a device - clear the cache so we can reload the loggedin devices
  clearCache();
};

const clearCache = () => {
  CacheManager.instance().clearCacheClass(cacheClass);
};

export const loginDevice = async (userCode: string): Promise<void> => {
  await logingateway.loginDevice({ userCode, cacheClass });
};
