import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { StyledChevronContainer } from './Chevron.styles';

type ChevronProps = {
  className?: string;
  open: boolean;
  size?: 'small' | 'normal';
};

export const Chevron = ({ className, open, size = 'normal' }: ChevronProps) => {
  const chevron = Icon({ name: 'ChevronDown' });
  return (
    <StyledChevronContainer className={className} open={open} size={size}>
      {chevron}
    </StyledChevronContainer>
  );
};
