import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePinModal, BasePinModalOnResponse, BasePinModalGetValidationError } from '..';

type ActivatePinModalProps = {
  isOpen: boolean;
  validatePinCode: (pinCode: string) => Promise<boolean>;
  onResponse: BasePinModalOnResponse;
};

export const ActivatePinModal = ({
  isOpen,
  validatePinCode,
  onResponse,
}: ActivatePinModalProps) => {
  const { t } = useTranslation();

  const getValidationError: BasePinModalGetValidationError = useCallback(
    async (pinCode) => {
      const validateOk = await validatePinCode(pinCode);
      if (!validateOk) {
        return t('SETTINGS_LOCKER_WRONG_PIN_ERROR');
      }
    },
    [t, validatePinCode],
  );

  return (
    <BasePinModal
      isOpen={isOpen}
      title={t('SETTINGS_RENTAL_PIN_ACTIVATE_TITLE')}
      testid={'SETTINGS_RENTAL_PIN_ACTIVATE'}
      information={t('SETTINGS_RENTAL_PIN_ACTIVATE_INFORMATION')}
      confirmLabel={t('SETTINGS_RENTAL_PIN_ACTIVATE_BUTTON')}
      onResponse={onResponse}
      getValidationError={getValidationError}
    />
  );
};
