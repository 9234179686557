import { gql } from '@apollo/client';

export const UPDATE_CHILD_LOCK = gql`
  mutation updateChildLock($enabled: Boolean!, $newPinCode: String) {
    updateChildLock(input: { enabled: $enabled, pinCode: $newPinCode }) {
      ... on Error {
        code
        message
      }
      ... on UpdateChildLockResultOk {
        enabled
      }
    }
  }
`;
