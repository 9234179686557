import { StyledProgressBarFill, StyledProgressBarWrapper } from './ProgressBar.styles';

type ProgressBarProps = {
  selectedPoints: number;
  pointsTotal: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ selectedPoints, pointsTotal }) => {
  const maxPoints = pointsTotal;
  const totalPoints = selectedPoints;
  const fillPercentage = (totalPoints / maxPoints) * 100;

  return (
    <StyledProgressBarWrapper>
      <StyledProgressBarFill percent={fillPercentage} />
    </StyledProgressBarWrapper>
  );
};
