import { gql } from '@apollo/client';

export const GET_REGIONAL_CHANNEL_QUERY = gql`
  query getRegionalChannels {
    regionalChannels {
      items {
        id
        name
        regionalChannels {
          id
          regionName
        }
      }
    }
  }
`;
