import { PageNotFoundError } from 'errorTypes';
import { ReactNode } from 'react';
import { ErrorBoundary, PageNotFound } from 'components';

type RouteErrorBoundaryProps = {
  children?: ReactNode;
};

export const RouteErrorBoundary = ({ children }: RouteErrorBoundaryProps) => {
  return (
    <ErrorBoundary
      onError={(error) => {
        if (error instanceof PageNotFoundError) {
          return PageNotFound;
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
