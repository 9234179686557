import { HelpShowFeedback } from './HelpShowFeedback.utils';
import { HelpShowOtherLinksEnabled } from './HelpShowOtherLinksEnabled.utils';
import { SettingsRegionalChannels } from './SettingsRegionalChannels.utils';
import { SubscriptionSelectionMenu } from './SubscriptionSelectionMenu.utils';
import { ChangeUsernameEnabled } from './ChangeUsernameEnabled';
import { FederatedLoginEnabled } from './FederatedLoginEnabled';
import { FederatedLoginAppleEnabled } from './FederatedLoginAppleEnabled.utils';
import { FederatedLoginGoogleEnabled } from './FederatedLoginGoogleEnabled.utils';
import { MfaEnabled } from './MfaEnabled.utils';
import { MfaChangeEnabled } from './MfaChangeEnabled.utils';
import { SupportWebEnabled } from './SupportWebEnabled.utils';

export type FeatureToggle = () => Promise<boolean>;

const featureToggles = {
  'Help.ShowFeedback': HelpShowFeedback,
  'Help.ShowOtherLinksEnabled': HelpShowOtherLinksEnabled,
  'Settings.RegionalChannels': SettingsRegionalChannels,
  'Subscription.SelectionMenu': SubscriptionSelectionMenu,
  'Account.Username.Change': ChangeUsernameEnabled,
  'Account.Federated.Login': FederatedLoginEnabled,
  'Account.Federated.Login.Apple': FederatedLoginAppleEnabled,
  'Account.Federated.Login.Google': FederatedLoginGoogleEnabled,
  Mfa: MfaEnabled,
  MfaChange: MfaChangeEnabled,
  SupportWeb: SupportWebEnabled,
};

export type FeatureToggleType = keyof typeof featureToggles;

const resolvedFeatureToggles = new Map<keyof typeof featureToggles, boolean>();

export const isFeatureEnabled = (toggles: (keyof typeof featureToggles)[]) => {
  return toggles.every((toggle) => resolvedFeatureToggles.get(toggle));
};

/**
 * Refresh asynchronous feature toggles
 */
export const refresh = async () => {
  await Promise.all(
    Object.entries(featureToggles).map(([key, toggle]) => {
      return toggle().then((toggled) => {
        resolvedFeatureToggles.set(key as keyof typeof featureToggles, toggled);
      });
    }),
  );
};
