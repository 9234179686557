import { useEffect } from 'react';
import { useGetUser, useLogger, useMediaQueryMinSize as useMediaQueryMinWidth } from 'hooks';
import { HeaderViewLarge } from './components';
import { useGetMenuItems, useGetUserMenuItems } from './hooks';
import { HeaderViewSmall } from './components/HeaderViewSmall/HeaderViewSmall';

export const Header = () => {
  const logger = useLogger('components/Header');
  const isLargeWidth = useMediaQueryMinWidth('L');

  const { menuItems, error: menuItemsError } = useGetMenuItems();
  const { user, error: userError } = useGetUser();
  const { userMenuItems } = useGetUserMenuItems({ user });

  useEffect(() => {
    if (userError) {
      logger.error('Failed to fetch user', userError);
    }
  }, [userError, logger]);

  useEffect(() => {
    if (menuItemsError) {
      logger.error('Failed to fetch menuItems', menuItemsError);
    }
  }, [menuItemsError, logger]);

  return isLargeWidth ? (
    <HeaderViewLarge menuItems={menuItems} user={user} userMenuItems={userMenuItems} />
  ) : (
    <HeaderViewSmall menuItems={menuItems} user={user} userMenuItems={userMenuItems} />
  );
};
